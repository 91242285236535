export default function QuizIcon({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="0 0 29 29" stroke="none" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.98328 16.9316C6.40372 16.9316 5.11914 18.2162 5.11914 19.7958C5.11914 21.953 5.75195 24.0979 6.94839 25.8934L8.88637 28.8004H16.6088L16.8427 28.334C18.0044 26.0096 18.6191 23.3504 18.6191 20.7516V19.4629C18.6191 18.0671 17.4837 16.9316 16.0879 16.9316H13.5566V11.8691C13.5566 10.9371 12.8011 10.1816 11.8691 10.1816C10.9371 10.1816 10.1816 10.9371 10.1816 11.8691V20.3066C10.1816 20.7726 9.80387 21.1504 9.33789 21.1504C8.87192 21.1504 8.49414 20.7726 8.49414 20.3066V16.9316H7.98328Z"
        fill="currentColor"
      />
      <path
        d="M28.8004 6.80625C28.8004 3.08436 25.716 0 21.9941 0C18.2722 0 15.2441 3.08436 15.2441 6.80625C15.2441 10.5281 18.2722 13.5563 21.9941 13.5563C25.716 13.5563 28.8004 10.5281 28.8004 6.80625ZM24.8748 8.49375L23.6816 9.68687L21.9941 7.99937L20.3066 9.68687L19.1135 8.49375L20.801 6.80625L19.1135 5.11875L20.3066 3.92563L21.9941 5.61313L23.6816 3.92563L24.8748 5.11875L23.1873 6.80625L24.8748 8.49375Z"
        fill="currentColor"
      />
      <path
        d="M6.80625 13.5563C7.39249 13.5563 7.95139 13.4581 8.49375 13.3176V11.8688C8.49375 10.0074 10.0074 8.49375 11.8688 8.49375C12.3527 8.49375 12.8109 8.60023 13.2274 8.78439C13.4214 8.1549 13.5563 7.49942 13.5563 6.80625C13.5563 3.07828 10.5342 0 6.80625 0C3.07828 0 0 3.07828 0 6.80625C0 10.5341 3.07828 13.5563 6.80625 13.5563ZM5.9625 9.68687L3.08188 6.80625L4.275 5.61313L5.9625 7.30063L9.3375 3.92563L10.5306 5.11875L5.9625 9.68687Z"
        fill="currentColor"
      />
    </svg>
  );
}
