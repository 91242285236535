/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-useless-escape */

const arabicDiacritics = [
  "\u060C",
  "\u0618",
  "\u0619",
  "\u061A",
  "\u061F",
  "\u064B",
  "\u064C",
  "\u064D",
  "\u064E",
  "\u064F",
  "\u0650",
  "\u0651",
  "\u0652",
  "\u0653",
  "\u0654",
  "\u0655",
  "\u066C",
  "\u0674",
].join("");

// eslint-disable-next-line no-misleading-character-class
const arabicDiacriticsRegex = new RegExp(`[${arabicDiacritics}]`, "g");

/**
 * Visual only
 */
export function replaceAmbiguousCharacters(text: string) {
  return (
    text
      .replace(/([“”])/g, '"')
      .replace(/([‘’])/g, "'")
      .replace(/([？])/g, "?")
      .replace(/([！])/g, "!")
      // alif hamza => alif
      .replace(/([\u0623\u0625\u0622])/g, "\u0627")
      // alif + hamza (separate chars) => alif
      .replace(/(\u0627\u0654)/g, "\u0627")
      // ta marbuta => ha
      .replace(/(\u0629)/g, "\u0647")
      // ta marbuta final => ha final
      .replace(/(\uFE94)/g, "\uFEEA")
  );
}

export const allPunctuationRegexGlobal = new RegExp(/[»«…‘’"'“”.／-～〜~¿،,！、!　。؟？，¡?*!　「」]/g);
export const allPunctuationRegex = new RegExp(/[»«…‘’"'“”.／～〜~¿,،！、!　。؟？，¡?*!　「」]/);
/** Matches "(present tense)" in "conocer (present tense)", and not regex "/(asdasd|asdasd)/" */
export const withoutBracketsRegex = new RegExp(/\([^()]*\)\s*?$/g);

export const markdownRegex = /[|*]/g;
export const htmlRegex = /<[^>]*>/g;
export const tabsRegex = /\t/gm;

// eslint-disable-next-line no-useless-escape
export const emailValidationRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const basicMarkdownRegex = /\*\*|__/gm;

const spaceBetweenSpanTagRegex = /(<\/span>|\*\*) <span/gm;
const doubleNewLineBetweenListsRegex = /\n\n\n(?=-)/gm;

export const replaceSpacesBetweenSpanTags = (str: string) => {
  return str.replace(spaceBetweenSpanTagRegex, "$1&nbsp;<span");
};

export const replaceNewLinesBetweenLists = (str: string) => {
  return str.replace(doubleNewLineBetweenListsRegex, "<div>&nbsp;</div>\n");
};

/**
 * Strips all spaces from any given text
 * @param {string} text
 */
export function stripSpaces(text: string): string {
  return text.replace(spacesRegex, "");
}

/**
 * Normalizes a string & Removes all punctuation
 */
export function prepareString(
  text?: string,
  options?: {
    stripSpaces?: boolean;
    isRegexString?: boolean;
  },
): string {
  if (typeof text !== "string") {
    return "";
  }

  let formatted = text
    .toLowerCase()
    .replace(htmlRegex, "") // `Bonjour <span style="color:red">Maria</span> !` => `Bonjour Maria !`
    .replace(tabsRegex, " ") // `Bonjour\tMaria !` => `Bonjour Maria !`
    .replace(withoutBracketsRegex, "")
    .replace(allPunctuationRegexGlobal, "")
    .replace(arabicDiacriticsRegex, "")
    .replace(/(\w)\/(\w)/g, "$1 $2") // Replace "poder/querer" => "poder querer"
    // Replace dashes with spaces
    .replace(/-/g, " ")
    .normalize("NFKD")
    .trim();

  if (options?.stripSpaces) {
    formatted = formatted.replace(spacesRegex, "");
  }

  // Make sure we don't remove "|" from a regex string
  if (!options?.isRegexString) {
    formatted = formatted.replace(markdownRegex, "");
  }

  return replaceAmbiguousCharacters(formatted);
}

const spacesRegex = /\s/g;

const purgers = {
  html: htmlRegex,
  markdown: markdownRegex,
  punctuation: allPunctuationRegexGlobal,
  spaces: spacesRegex,
};

type PurgeKey = keyof typeof purgers;

export function purgeString(str: string, options: { [key in PurgeKey]?: boolean }) {
  let builtString = str;
  for (const key in options) {
    if (options[key as PurgeKey]) {
      builtString = builtString.replace(purgers[key as PurgeKey], "");
    }
  }
  return builtString;
}

/** Measures the difference (in number of character changes) between two strings */
export function leven(first: string, second: string): number {
  if (first === second) {
    return 0;
  }

  const array = [];
  const characterCodeCache = [];

  const swap = first;

  // Swapping the strings if `a` is longer than `b` so we know which one is the
  // shortest & which one is the longest
  if (first.length > second.length) {
    first = second;
    second = swap;
  }

  let firstLength = first.length;
  let secondLength = second.length;

  // Performing suffix trimming:
  // We can linearly drop suffix common to both strings since they
  // don't increase distance at all
  // Note: `~-` is the bitwise way to perform a `- 1` operation
  while (firstLength > 0 && first.charCodeAt(~-firstLength) === second.charCodeAt(~-secondLength)) {
    firstLength--;
    secondLength--;
  }

  // Performing prefix trimming
  // We can linearly drop prefix common to both strings since they
  // don't increase distance at all
  let start = 0;

  while (start < firstLength && first.charCodeAt(start) === second.charCodeAt(start)) {
    start++;
  }

  firstLength -= start;
  secondLength -= start;

  if (firstLength === 0) {
    return secondLength;
  }

  let bCharacterCode;
  let result = 0;
  let temporary: number | undefined;
  let temporary2: number | undefined;
  let index = 0;
  let index2 = 0;

  while (index < firstLength) {
    characterCodeCache[index] = first.charCodeAt(start + index);
    array[index] = ++index;
  }

  while (index2 < secondLength) {
    bCharacterCode = second.charCodeAt(start + index2);
    temporary = index2++;
    result = index2;

    for (index = 0; index < firstLength; index++) {
      temporary2 = bCharacterCode === characterCodeCache[index] ? temporary : (temporary || 0) + 1;
      temporary = Number(array[index]);
      array[index] =
        // eslint-disable-next-line no-nested-ternary
        temporary > result
          ? temporary2 > result
            ? result + 1
            : temporary2
          : temporary2 > temporary
            ? temporary + 1
            : temporary2;

      result = Number(array[index]);
    }
  }

  return result;
}

export function normalize(string: string) {
  return string
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
}

export const characterNameRegex = /^([a-zA-Z ]+): /;
