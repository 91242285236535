import { HTMLAttributes, ReactNode, Ref, forwardRef } from "react";

import RateableTestUIContextProvider from "./includes/context";
import { clsx } from "clsx";

type Props = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
  rateableTestTypeId?: number;
  instructions?: JSX.Element;
  settings?: JSX.Element;
};
const Container = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const { rateableTestTypeId, instructions, settings, children, ...rest } = props;

  return (
    <RateableTestUIContextProvider
      instructions={instructions}
      settings={settings}
      rateableTestTypeId={rateableTestTypeId}
    >
      <div
        className={clsx("flex h-full flex-1 flex-col outline-none", rest.className)}
        tabIndex={0}
        role="application"
        aria-label="Test Container. Tap enter to reveal the phrase, tap 1, 2 or 3 to rate the phrase."
        ref={ref}
        {...rest}
      >
        {children}
      </div>
    </RateableTestUIContextProvider>
  );
});

export default Container;
