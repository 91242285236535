import styles from "./FormInput.module.scss";

interface Props {
  label: string | React.ReactNode;
  helperText?: string | React.ReactNode;
  use?: "input" | "textarea";
  tooltip?: JSX.Element;
  children?: React.ReactChild;
  required?: boolean;
}

function FormInput(props: Props & React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>) {
  const { label, helperText, tooltip, children, required, use, ...rest } = props;

  function onInputFocus(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (props.onFocus) {
      props.onFocus(e);
    }
  }

  function onInputBlur(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (props.onBlur) {
      props.onBlur(e);
    }
  }

  return (
    <div className={"flex flex-col py-2"}>
      <label htmlFor="" className={"mb-2 text-sm font-bold"}>
        {required && label && typeof label === "string" && "*"}
        {label}
      </label>
      {helperText && <p className="mb-2 text-sm">{helperText}</p>}
      {children ? (
        <div className={styles.children}>{children}</div>
      ) : (
        <>
          {use === "textarea" ? (
            <textarea
              className="min-h-[150px] resize-y rounded-md border-2 border-brand bg-surface1 p-4 focus:ring"
              required={required}
              {...rest}
              onFocus={onInputFocus}
              onBlur={onInputBlur}
            />
          ) : (
            <input
              type="text"
              className="h-[48px] rounded-md border-2 border-brand bg-surface1 px-4 focus:ring"
              required={required}
              {...rest}
              onFocus={onInputFocus}
              onBlur={onInputBlur}
            />
          )}
        </>
      )}
      {tooltip && <span className={styles.tooltip}>{tooltip}</span>}
    </div>
  );
}

export default FormInput;
