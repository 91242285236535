export default function PlayItIcon({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_556_15897)">
        <path
          d="M2.04 19.02C2.04 17.2801 3.3915 15.66 5.52 15.66C7.3728 15.66 8.88 17.1672 8.88 19.02C8.88 20.7599 7.5285 22.38 5.4 22.38C3.5472 22.38 2.04 20.8728 2.04 19.02ZM5.88 23.28H5.04C2.2608 23.28 0 25.5408 0 28.32C0 28.5852 0.2148 28.8 0.48 28.8H10.44C10.7052 28.8 10.92 28.5852 10.92 28.32C10.92 25.5408 8.6592 23.28 5.88 23.28ZM23.4 22.38C25.2528 22.38 26.76 20.8728 26.76 19.02C26.76 17.2801 25.4085 15.66 23.28 15.66C21.4272 15.66 19.92 17.1672 19.92 19.02C19.92 20.7599 21.2715 22.38 23.4 22.38ZM23.76 23.28H22.92C20.1408 23.28 17.88 25.5408 17.88 28.32C17.88 28.5852 18.0948 28.8 18.36 28.8H28.32C28.5852 28.8 28.8 28.5852 28.8 28.32C28.8 25.5408 26.5392 23.28 23.76 23.28ZM24.6 3.12H15.42C14.0964 3.12 13.02 4.1964 13.02 5.52V11.34C13.02 12.6636 14.0964 13.74 15.42 13.74H16.4904C17.1325 14.9945 18.4032 15.7287 19.782 15.7794C20.1013 15.8114 20.4047 15.4359 20.2296 15.0858C20.016 14.6574 19.8582 14.2056 19.761 13.74H24.6C25.9236 13.74 27 12.6636 27 11.34V5.52C27 4.1964 25.9236 3.12 24.6 3.12ZM19.0274 1.50618C18.6724 0.624 17.8078 0 16.8 0H2.4C1.07454 0 0 1.07454 0 2.4V9.318C0 10.641 1.0764 11.718 2.4 11.718H6.1944C6.13596 12.3951 5.94414 13.0783 5.6304 13.7058C5.47164 14.0233 5.70384 14.4 6.06 14.4C7.5345 14.4 9.1113 13.4236 9.6288 11.718H11.5808C11.8471 11.718 12.0626 11.5015 12.0608 11.2351C12.0569 10.6774 12.0601 9.29352 12.0601 5.52042C12.06 3.66474 13.5643 2.16 15.42 2.16H18.5797C18.9172 2.16 19.1534 1.81926 19.0274 1.50618Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_556_15897">
          <rect width="28.8" height="28.8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
