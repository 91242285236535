import { PhraseString } from "@rocket/types";
import { PhraseStringNotationButton } from "../../Notation";
import { sortNotations } from "../../../utils";

interface Props {
  phraseString: PhraseString;
  variant?: "faded";
  hiddenNotationIds?: number[] | undefined;
}

export default function PhraseStringNotationButtons({ phraseString, variant, hiddenNotationIds }: Props) {
  const filtered = hiddenNotationIds
    ? phraseString.notations.filter((n) => !hiddenNotationIds.includes(n.id))
    : phraseString.notations;

  return (
    <>
      {sortNotations(filtered).map((psNotation) => (
        <span key={psNotation.id} className="mx-0.5">
          <PhraseStringNotationButton
            phraseStringNotation={psNotation}
            phraseText={phraseString.text}
            variant={variant}
            ws={phraseString.writing_system_id}
          />
        </span>
      ))}
    </>
  );
}
