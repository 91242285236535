import { FaCheckCircle, FaChevronCircleRight, FaLock } from "react-icons/fa";
import { ReactNode, useState } from "react";

import BuyNowModal from "~/components/BuyNowModal";
import { LessonEntity } from "@rocket/types";
import { Link } from "react-router-dom";
import { RateableTestIconWithTooltip } from "~/components/RateableTestIcon";
import { Tooltip } from "@rocket/ui";
import { clsx } from "clsx";
import { memo } from "react";
import styles from "./FaceliftLessonItem.module.scss";
import useLessonRateableTests from "@rocket/shared/hooks/useLessonRateableTests";
import useLessonStatus from "@rocket/shared/hooks/useLessonStatus";
import { useStoreSelector } from "~/store";
import useTranslation from "@rocket/shared/hooks/useTranslation";
import { withoutExtraTestsAndEnabled } from "@rocket/shared/utils";
import { LessonTypeId } from "@rocket/shared/utils/constants";

interface LessonItemProps {
  lesson: LessonEntity;
  isAvailable: boolean;
  suggested: boolean;
  /** Progress 1-100 */
  progress?: number;
}

function FaceliftLessonItem({ lesson, isAvailable, suggested, progress = 0 }: LessonItemProps) {
  const t = useTranslation();
  return (
    <LessonLink
      className={clsx(
        suggested && "shadow-sm",
        "relative overflow-hidden rounded-lg bg-surface2 font-medium text-brand hover:bg-brand2 focus:outline-none focus:ring focus-visible:ring dark:text-text2 hover:dark:bg-neutral-700",
      )}
      lessonId={lesson.id}
      isAvailable={isAvailable}
    >
      <div className="flex items-center p-2 md:p-5">
        {lesson.image_url && (
          <img
            className="mr-2 h-12 w-12 rounded-full bg-surface1 md:mr-4 md:h-20 md:w-20"
            src={lesson.image_url}
            loading="lazy"
            alt={`${lesson.number} ${lesson.name}`}
          />
        )}
        <div className="flex flex-col justify-start gap-2">
          <div className="flex items-center gap-2 text-left">
            {(lesson.status === "hidden" || lesson.status === "draft") && `[${lesson.status.toUpperCase()}] `}
            {`${lesson.number} ${lesson.name}`}
            {!isAvailable && <FaLock />}
            <Tags lessonId={lesson.id} />
          </div>
          <LessonItemTestIcons lesson={lesson} />
        </div>
        {suggested && (
          <div className="flex-1 text-right font-bold text-rocketred dark:text-[#f86a62]">
            <span className="flex items-center justify-end gap-2">
              <span className="hidden uppercase md:inline-block">{t("continue")}</span> <FaChevronCircleRight />
            </span>
          </div>
        )}
      </div>
      {lesson.duration_minutes ? (
        <Tooltip
          label={
            lesson.lesson_type_id === LessonTypeId.IAC
              ? "The audio track play time (does not include activity time)"
              : "The estimated lesson read time (does not include activity time)"
          }
          className="absolute bottom-2 right-2 text-xs font-semibold md:bottom-5 md:right-4"
          width={350}
        >
          <div>{lesson.duration_minutes} min</div>
        </Tooltip>
      ) : null}
      {/** progress bar at bottom */}
      <div className="flex h-1 flex-1 bg-slate-300 dark:bg-slate-600">
        <div className="bg-rocketgreen-light" style={{ width: `${progress}%` }} />
      </div>
    </LessonLink>
  );
}

const MemoizedFaceliftLessonItem = memo(FaceliftLessonItem);

export default MemoizedFaceliftLessonItem;

function LessonLink(props: { isAvailable: boolean; lessonId: number; children: ReactNode; className?: string }) {
  const productId = useStoreSelector((store) => store.preferences.activeProduct?.id || 0);

  if (!props.isAvailable) {
    return <UnavailableLessonLink className={props.className}>{props.children}</UnavailableLessonLink>;
  }

  return (
    <Link
      id={`lesson.${props.lessonId}`}
      className={props.className}
      to={`/members/products/${productId}/lesson/${props.lessonId}`}
    >
      {props.children}
    </Link>
  );
}

function UnavailableLessonLink(props: { className?: string; children: ReactNode }) {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <button className={props.className} onClick={() => setModalOpen(!modalOpen)}>
        {props.children}
      </button>
      <BuyNowModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
}

function Tags({ lessonId }: { lessonId: number }) {
  const tags = useStoreSelector((store) => store.lesson.entities.user_lesson_tags[lessonId]) || [];
  return (
    <>
      {tags.map((tag, i) => {
        return !tag.seen ? (
          //@ts-ignore
          <span key={i} className={clsx(styles.lessonTag, styles[tag.variant])}>
            {tag.label}
          </span>
        ) : null;
      })}
    </>
  );
}

const LessonItemTestIcons = memo(({ lesson }: { lesson: LessonEntity }) => {
  const t = useTranslation();
  const rateableTests = useLessonRateableTests({ lessonId: lesson.id });
  const filteredRateableTests = withoutExtraTestsAndEnabled(rateableTests);
  const isDone = useLessonStatus(lesson.id)?.is_done;

  if (filteredRateableTests.length === 0) {
    return (
      <Tooltip label={isDone ? t("complete") : t("incomplete")}>
        <div
          className={`${
            isDone ? "bg-rocketgreen" : "bg-brand2 dark:bg-neutral-600"
          } flex h-7 w-7 items-center justify-center rounded-full text-white outline outline-2 outline-surface2 dark:outline-0`}
        >
          <FaCheckCircle />
        </div>
      </Tooltip>
    );
  }

  return (
    <div className="flex gap-2">
      {filteredRateableTests.map((rateableTest) => (
        <RateableTestIconWithTooltip key={`rt.${rateableTest.id}`} rateableTest={rateableTest} />
      ))}
    </div>
  );
});
