import { generate } from "shortid";
import { DashboardPayload, LessonEntity, LessonTypeCode, APIResource } from "@rocket/types";

import { activeProductSelector, dashboardProductsSelector } from "../../../../../store/selectors";
import { createSelector } from "reselect";
import type { RootState } from "../../../../../store/types";
import memoizeOne from "memoize-one";

export interface SectionData {
  id: string;
  code: LessonTypeCode;
  lessons: LessonEntity[];
}

export interface ModuleData {
  id: number;
  title: string;
  /** Featured modules include body text */
  description?: string | null;
  data: SectionData[];
}

export type DashboardSelectedData = {
  productId: number;
  suggestedLessonId: number;
  sectionTitleCodes: LessonTypeCode[];
  expandedModuleId: number;
  modules: ModuleData[];
};

export type DashboardProps = APIResource<DashboardSelectedData> | undefined;

const jsonEqual = (a: any, b: any) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

/**
 * Main method for gathering dashboard items & rateable tests in to a UI-presentable format
 */
const dashboardSelector = memoizeOne(
  (
    productId: number,
    payload: { status: "loaded"; data: DashboardPayload["dashboard"] },
    allLessons: ReturnType<typeof lessonsSelector>,
  ): DashboardProps => {
    if (!("modules" in payload.data)) {
      // Return custom data to Dashboard Screen
      return {
        status: "loaded",
        data: {
          productId: -1,
          suggestedLessonId: 0,
          expandedModuleId: 0,
          modules: [],
          sectionTitleCodes: [],
        },
      };
    }

    const { modules: productModules, suggestedLessonId } = payload.data;

    const modules: ModuleData[] = [];
    const sectionTitleCodes: LessonTypeCode[] = [];

    // First section should be expanded
    let expandedModuleId = 0;

    // For each module..
    for (let i = 0; i < productModules.length; i += 1) {
      const subsectionList: SectionData[] = [];
      const productModule = productModules[i];
      if (!productModule) {
        continue;
      }
      // For each grouped lessons in the module (grouped by lesson type)
      for (const { code, lessons: lessonIds } of productModule.grouped_lessons) {
        const lessonList = [];
        // For each lesson...
        for (const lessonId of lessonIds) {
          // Update lesson offset
          if (suggestedLessonId && lessonId === suggestedLessonId) {
            expandedModuleId = productModule.id;
          }

          // Find lesson
          const lesson = allLessons[lessonId];
          // Add lesson to the lesson list
          if (lesson) {
            lessonList.push(lesson);
          }
        }

        const id = generate();

        if (!sectionTitleCodes.some((title) => title === code)) {
          sectionTitleCodes.push(code);
        }

        // Splice in the subsection title with the percentage
        subsectionList.push({
          id,
          code,
          lessons: lessonList,
        });
      }

      const { title, number: moduleNumber } = productModule;

      // Add module
      modules.push({
        id: productModule.id,
        title: title || `Module ${moduleNumber || i + 1}`,
        description: productModule.description,
        data: subsectionList,
      });
      // headerIndices.push(moduleIndex);
    }

    // Return custom data to Dashboard Screen
    return {
      status: payload.status,
      data: {
        productId,
        suggestedLessonId,
        expandedModuleId: expandedModuleId || modules[0]?.id || 0,
        modules,
        sectionTitleCodes,
      },
    };
  },
  jsonEqual,
);

const lessonsSelector = (state: RootState) => state.lesson.entities.lessons;

/**
 * Gathers and organizes lessons based on the currently active product
 */
export default createSelector(
  [activeProductSelector, dashboardProductsSelector, lessonsSelector],
  (activeProduct, products, lessons): DashboardProps => {
    if (!activeProduct || !products) {
      return { status: "error", errorText: "No active product or products" };
    }

    const activeProductId = activeProduct.id;
    const product = products[activeProductId];

    if (!product || !lessons) {
      return;
    }

    switch (product.status) {
      case "error":
      case "loading":
        return product;
      default:
        return dashboardSelector(activeProductId, product, lessons);
    }
  },
);
