import type { APIFormData as APIFormDataType } from "@rocket/types/api/post";

export default class APIFormData<TProperties extends Record<string, unknown>> implements APIFormDataType<TProperties> {
  formData: FormData;

  constructor(props?: Partial<TProperties>) {
    this.formData = new FormData();

    if (props) {
      for (const key in props) {
        this.formData.set(key, props[key] as Blob | string);
      }
    }
  }

  set(name: keyof TProperties, value: Blob | string) {
    this.formData.set(name as string, value);
  }
}
