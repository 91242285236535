import React, { useEffect, useRef } from "react";
import { disablePageScroll, enablePageScroll } from "scroll-lock";

import { FiX } from "react-icons/fi";
import { IconButton } from "../Button";
import ReactDOM from "react-dom";
import classes from "./Modal.module.scss";
import { clsx } from "clsx";

interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  /** When set to true, ignores taps on the overlay that would otherwise call `onClose()` */
  sticky?: boolean;
  image?: string;
}

export default function Modal({ children, isOpen, onClose, sticky, image }: ModalProps) {
  const overlayRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      disablePageScroll();
    } else {
      enablePageScroll();
    }
    return () => {
      enablePageScroll();
    };
  }, [isOpen]);

  useEffect(() => {
    function onKeyPress(ev: KeyboardEvent) {
      // 27 = escape
      if (ev.keyCode === 27 && isOpen) {
        onClose();
      }
    }
    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.focus();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div ref={modalRef} tabIndex={-1} className="fixed z-[9999]">
      <div
        ref={overlayRef}
        className={clsx(
          "fixed inset-0 z-[999] flex items-center justify-center overflow-auto bg-black/[0.25]",
          !isOpen ? "pointer-events-none" : "pointer-events-auto",
          classes.overlay,
        )}
        onClick={
          !sticky
            ? (e) => {
                if (overlayRef.current === e.target) {
                  onClose();
                }
              }
            : undefined
        }
      >
        <ActualModal onClose={onClose} image={image}>
          {children}
        </ActualModal>
      </div>
    </div>,
    document.querySelector("body") as Element,
  );
}

function ActualModal({ onClose, children, image }: { children: React.ReactNode; onClose: () => void; image?: string }) {
  return (
    <div
      className="z-[999] max-h-screen min-w-[100%] p-4 sm:min-w-[50%] sm:max-w-screen-sm lg:min-w-[40%] lg:max-w-screen-md xl:min-w-[30%]"
      aria-modal
      aria-haspopup
    >
      <div className="relative overflow-hidden rounded-md bg-white shadow-md dark:bg-surface2">
        {image && (
          <>
            <div className="absolute right-5 top-5">
              <IconButton aria-label="Close" color="grey" onClick={onClose}>
                <FiX />
              </IconButton>
            </div>
            <img src={image} alt="promo banner" width={600} height={375} className="object-cover" />
          </>
        )}

        <div className="p-4">
          {!image && (
            <div className="mb-2 flex w-full justify-end">
              <IconButton aria-label="Close" color="grey" onClick={onClose}>
                <FiX />
              </IconButton>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}
