import PhraseString from "./PhraseString";
import { RocketRecordContext } from "../../../context/RocketRecordContext";
import { TextDiff } from "../../../ui/TextDiff";
import { clsx } from "clsx";
import { isRTL } from "../../../utils";
import { shallowEqual } from "react-redux";
import { useContext } from "react";

/**
 * Displays speech results
 */
function RocketRecordSpeech() {
  const context = useContext(RocketRecordContext);

  const state = context?.useRocketRecordState(
    (state) => ({
      flag: state.flag,
      result: state.result,
    }),
    shallowEqual,
  );

  if (!context || !state) {
    return null;
  }

  const phraseString = context.phrase.strings[0];

  if (!phraseString) {
    return null;
  }

  const { flag, result } = state;

  switch (flag.status) {
    case "INACTIVE": {
      if (result) {
        if (result.ratingLevel === 3) {
          return (
            <PhraseString
              phraseString={phraseString}
              options={{ hideNotations: true, clearFormattingStyles: true }}
              color="#00b050"
            />
          );
        }
        if (result.diffResult) {
          return (
            <span className={`font-serif ws-${phraseString.writing_system_id}`}>
              <TextDiff writingDiff={result.diffResult} isRTL={isRTL(context.phrase.course_id)} />
            </span>
          );
        }
      }
      return null;
    }
    case "ACTIVE": {
      if (result?.rawTranscription) {
        return (
          <div
            className={clsx("font-serif text-gray-400", `ws-${phraseString.writing_system_id}`)}
          >{`${result.rawTranscription}..`}</div>
        );
      }
      return <div className={"font-serif text-rocketred"}>Speak now...</div>;
    }
    case "FINISHING":
      if (flag.fetchingTranscript && !result?.rawTranscription) {
        return <div className="font-serif text-gray-400">Checking pronunciation...</div>;
      }
      if (result?.rawTranscription) {
        return (
          <div
            className={clsx("font-serif text-gray-400", `ws-${phraseString.writing_system_id}`)}
          >{`${result.rawTranscription}..`}</div>
        );
      }
      // Prevent any layout jank by keeping the same "Speak now..." text between ACTIVE -> FINISHING -> INACTIVE
      return <div className={"font-serif text-rocketred"}>Speak now...</div>;
    default:
      return null;
  }
}

export default RocketRecordSpeech;
