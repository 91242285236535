/* Displays a tooltip when the user hovers over the parent node */
/* Usage
  <div data-tooltip={true}>
    <Tooltip>content</Tooltip>
  </div>
*/

import { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import TooltipPositioner from "./components/TooltipPositioner";

type DeferredReactNode = () => React.ReactNode;

export type TooltipProps = {
  children: React.ReactNode;
  className?: string;
  /** Width of tooltip */
  width?: number;
  /** Offset of tooltip from origin */
  offset?: number;
  offsetX?: number;
  label?: string | React.ReactNode | DeferredReactNode;
  orientation?: "above" | "beneath";
  /** Add "fixed" to tooltips inside fixed elements to avoid scroll issues */
  mode?: "fixed" | "absolute";
};

// @ts-ignore
// const rootElement = window.__STORYBOOK_ADDONS ? "docs-root" : "root";

function Tooltip(props: TooltipProps) {
  const [state, setState] = useState({
    visible: false,
    top: props.offset || 0,
    left: props.offsetX || 0,
    parentWidth: 0,
  });

  // const visible = useDebounce(state.visible, 100) || state.visible;

  const tooltipRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (!tooltipRef.current) {
      return;
    }

    function show() {
      if (!tooltipRef.current) {
        return;
      }

      const { top, left, width } = tooltipRef.current.getBoundingClientRect();

      if (tooltipRef.current.parentElement) {
        const topOffset = (props.mode !== "fixed" ? window.scrollY : 0) + top - 10;

        setState({
          top: Math.max(0, topOffset + state.top),
          left: left + (props.offsetX || 0),
          parentWidth: width,
          visible: true,
        });
      }
    }

    function hide() {
      setState((prevState) => ({ ...prevState, visible: false }));
    }

    const { current } = tooltipRef;
    current.addEventListener("mouseenter", show);
    current.addEventListener("focus", show);
    current.addEventListener("mouseleave", hide);
    current.addEventListener("blur", hide);

    return () => {
      current.removeEventListener("mouseenter", show);
      current.removeEventListener("focus", show);
      current.removeEventListener("mouseleave", hide);
      current.removeEventListener("blur", hide);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!state.visible) {
    return (
      <span ref={tooltipRef} className={props.className}>
        {props.children}
      </span>
    );
  }

  const label = (() => {
    if (typeof props.label === "function") {
      return props.label();
    }
    return props.label;
  })();

  const tooltipElement = ReactDOM.createPortal(
    <TooltipPositioner
      width={props.width}
      parentWidth={state.parentWidth}
      left={state.left}
      top={state.top}
      mode={props.mode}
      orientation={props.orientation}
    >
      {label}
    </TooltipPositioner>,
    document.querySelector("body") as Element,
  );

  return (
    <span ref={tooltipRef} className={props.className}>
      {props.children}
      {tooltipElement}
    </span>
  );
}

export default Tooltip;
