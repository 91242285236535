import { HTMLAttributes, ReactNode, useMemo } from "react";

import type { MarkdownToJSX } from "markdown-to-jsx";
import type { PhraseString as PhraseStringType } from "@rocket/types";
import RocketMarkdown from "../../Lesson/MarkdownComponent/includes/RocketMarkdown";
import { clsx } from "clsx";
import styles from "./PhraseString.module.scss";
import useParseVocab from "../../../hooks/useParseVocab";

export interface PhraseStringOptions {
  /** Hides the notation button associated with the phrase string */
  hideNotations?: boolean | number[];
  /** Hides the literal string notation button associated with the phrase string */
  hideLiteralStringNotation?: boolean;
  /** Overrides `phraseString.text` for display purposes */
  displayText?: string;
  /** Whether the phrase string is blurred */
  blur?: boolean;
  /** Hides underlining of phrase strings. */
  disableVocabUnderline?: boolean;
  /** Switches out the notation "%s" with the provided string */
  notationReplacerText?: string;
  /** Clear styles that change the formatting of the text (not the color), eg: bold, italic */
  clearFormattingStyles?: boolean;
  /** Class name of the phrase string */
  className?: string;
}

interface PhraseStringProps {
  /** Single phrase string that will be rendered. Text can be a markdown column table */
  phraseString: PhraseStringType;
  /** Sets the color of the phrase string. Used to set the color to green. */
  color?: string;
  /** Custom options for controlling visibility */
  options?: PhraseStringOptions;
  /** Notation displayed in-line with text */
  notation?: JSX.Element | null;
}

function InlineParagraph(props: { children: ReactNode }) {
  return <p className="inline">{props.children}</p>;
}

/**
 * Renders a single phrase string
 */
export default function PhraseString({ phraseString, options, color, notation }: PhraseStringProps) {
  const inputText = (() => {
    if (typeof options?.displayText === "string") {
      if (options?.clearFormattingStyles) {
        return options.displayText.replace(/<[^>]*>/gim, "");
      }
      return options.displayText;
    }
    if (options?.clearFormattingStyles) {
      return phraseString.text.replace(/<[^>]*>/gim, "");
    }
    return phraseString.text;
  })();

  const { text, markdownOptions } = useParseVocab(inputText, phraseString.writing_system_id, {
    color,
    phraseStringOptions: {
      disableVocabUnderline: options?.disableVocabUnderline,
    },
  });

  const markdownOptionsWithInlineParagraph = useMemo(
    () =>
      ({
        ...markdownOptions,
        overrides: {
          ...markdownOptions.overrides,
          p: {
            component: InlineParagraph,
          },
        },
      }) satisfies MarkdownToJSX.Options,
    [markdownOptions],
  );

  const isTable = useMemo(() => Boolean(text.indexOf("|") > -1), [text]);

  const textProps: HTMLAttributes<HTMLElement> = useMemo(() => {
    return {
      className: clsx(
        `ws-${phraseString.writing_system_id}`,
        "font-serif text-text2 mr-1",
        options?.blur && "blur select-none pointer-events-none",
        options?.clearFormattingStyles && styles.clearFormattingStyles,
        options?.className,
      ),
      style: { color },
    };
  }, [color, options?.blur, options?.clearFormattingStyles, phraseString.writing_system_id, options?.className]);

  if (isTable) {
    return (
      <PhraseStringTable
        id={phraseString.id}
        text={text}
        textProps={textProps}
        markdownOptions={markdownOptionsWithInlineParagraph}
      />
    );
  }

  return (
    <span>
      <span {...textProps}>
        <RocketMarkdown options={markdownOptionsWithInlineParagraph}>{text}</RocketMarkdown>
      </span>
      {notation}
    </span>
  );
}

interface PhraseStringTableProps {
  id: number;
  text: string;
  textProps: any;
  markdownOptions: MarkdownToJSX.Options | undefined;
}

/**
 * Markdown column table representation of a phrase string. Every "|" is split in to an even column.
 */
function PhraseStringTable({ id, text, textProps, markdownOptions }: PhraseStringTableProps) {
  return (
    <>
      {text
        .split("|")
        .filter((_text) => !!_text)
        .map((t, i) => {
          // const formattingLength = (columnString.match(/\*/g) || []).length;
          return (
            <div key={`${id}.${i}`} className="flex flex-1">
              <RocketMarkdown className={textProps.className} style={textProps.style} options={markdownOptions}>
                {t.replace("&nbsp;", "").trim()}
              </RocketMarkdown>
            </div>
          );
        })}
    </>
  );
}
