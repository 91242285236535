import { Button, Checkbox } from "@rocket/ui";
import { useContext, useMemo } from "react";

import { RateableTestUIContext } from "../../RateableTestUI/includes/context";
import { RomanizedWritingSystemIds, WritingSystemIds, WritingSystemLanguages } from "../../../../utils/constants";
import { asyncUpdatePreference } from "../../../../store/user/actions";
import useActiveCourse from "../../../../hooks/useActiveCourse";
import { useDispatch } from "react-redux";
import { useSharedSelector } from "../../../../store";
import useTranslation from "../../../../hooks/useTranslation";
import { useSyncedCoursePreference } from "../../../../hooks/usePreference";

interface FlashcardSettingsProps {
  audioEnabled: boolean;
  writingSystemIds: number[];
  onConfirm?: () => void;
}

const useVisibleWritingSystems = (writingSystemIds: number[]) => {
  const hideRomanized = useSharedSelector((store) => !!Number(store.user.preferences?.hide_romanized));
  const hideKana = useSharedSelector((store) => !!Number(store.user.preferences?.hide_kana));

  return useMemo(() => {
    return writingSystemIds.filter((ws) => {
      // hide settings option for Traditional Chinese - not implemented yet
      if (ws === 32) {
        return false;
      }
      if (hideRomanized && RomanizedWritingSystemIds.includes(ws)) {
        return false;
      }
      if (hideKana && ws === WritingSystemIds.hiragana_katakana) {
        return false;
      }
      return true;
    });
  }, [hideKana, hideRomanized, writingSystemIds]);
};

export default function Settings({ audioEnabled, writingSystemIds, onConfirm }: FlashcardSettingsProps) {
  const dispatch = useDispatch();
  const t = useTranslation();
  const preferences = useSharedSelector((store) => store.user.preferences);
  const context = useContext(RateableTestUIContext);
  const activeCourse = useActiveCourse();

  // What writing systems we can show as options to the user
  // The user may opt-out of seeing certain writing systems in different interfaces
  // (e.g. the top bar allows hiding romanized scripts)
  const visibleWritingSystems = useVisibleWritingSystems(writingSystemIds);

  const defaultFrontWs =
    activeCourse?.slug === "ingles" || activeCourse?.slug === "eigo"
      ? // Return whatever the last writing system is
        [visibleWritingSystems.at(-1) || WritingSystemIds.english]
      : [WritingSystemIds.english];

  // By default, we want English on the front of the card
  const [flashcardWsFront, setFlashcardWsFront] = useSyncedCoursePreference("flashcard_ws_front", defaultFrontWs);

  // Everything besides English on the back by default
  const [flashcardWsBack, setFlashcardWsBack] = useSyncedCoursePreference(
    "flashcard_ws_back",
    visibleWritingSystems.filter((ws) => ws !== defaultFrontWs[0]),
  );

  if (!preferences || !activeCourse) {
    return null;
  }

  return (
    <div className="mx-2 mb-2 sm:mx-8 sm:mb-8">
      <div className="mb-4">
        <h4 className="mb-2">{t("front-of-card")}</h4>
        <div className="mb-2 sm:mb-4">
          {visibleWritingSystems.map((ws) => {
            return (
              <div key={ws} className="mb-2 sm:mb-4">
                <Checkbox
                  key={ws}
                  checked={flashcardWsFront.includes(ws)}
                  onChange={() => {
                    if (flashcardWsFront.includes(ws)) {
                      setFlashcardWsFront(flashcardWsFront.filter((fws) => fws !== ws));
                    } else {
                      setFlashcardWsFront([...flashcardWsFront, ws]);
                    }
                  }}
                  label={(() => {
                    if (WritingSystemLanguages[ws] === "Spanish") return t("spanish");
                    if (WritingSystemLanguages[ws] === "English") return t("english");
                    return WritingSystemLanguages[ws] || t("english");
                  })()}
                />
              </div>
            );
          })}
        </div>
        <h4 className="mb-2">{t("back-of-card")}</h4>
        <div className="mb-2 sm:mb-4">
          {visibleWritingSystems.map((ws) => {
            return (
              <div key={ws} className="mb-2 sm:mb-4">
                <Checkbox
                  key={ws}
                  checked={flashcardWsBack.includes(ws)}
                  onChange={() => {
                    if (flashcardWsBack.includes(ws)) {
                      setFlashcardWsBack(flashcardWsBack.filter((fws) => fws !== ws));
                    } else {
                      setFlashcardWsBack([...flashcardWsBack, ws]);
                    }
                  }}
                  label={(() => {
                    if (WritingSystemLanguages[ws] === "Spanish") return t("spanish");
                    if (WritingSystemLanguages[ws] === "English") return t("english");
                    return WritingSystemLanguages[ws] || t("english");
                  })()}
                />
              </div>
            );
          })}
        </div>
        <div className="mb-2 sm:mb-4">
          <h4 className="mb-2">{t("sound")}</h4>
          <Checkbox
            label={t("play-audio-on-reveal")}
            checked={audioEnabled}
            value={audioEnabled}
            onChange={() => dispatch(asyncUpdatePreference("flashcard_volume", audioEnabled ? "0" : "1"))}
          />
        </div>
      </div>
      <div className="my-4 flex justify-center sm:my-6">
        <Button
          color="primary"
          onClick={() => {
            context?.toggleSettings();
            onConfirm?.();
          }}
        >
          <span className="uppercase">{t("confirm")}</span>
        </Button>
      </div>
    </div>
  );
}
