import { Button } from "@rocket/ui";
import useTranslation from "../../../../hooks/useTranslation";

interface RevealContinueButtonsProps {
  state: "reveal" | "continue";
  onContinue(): void;
  onReveal(): void;
  revealDisabled?: boolean;
}

export default function RevealContinueButtons({
  state,
  onContinue,
  onReveal,
  revealDisabled,
}: RevealContinueButtonsProps) {
  const t = useTranslation();
  return (
    <div className="mt-5 flex justify-center">
      {state === "continue" ? (
        <Button color="secondary" onClick={onContinue} className="uppercase">
          {t("continue")}
        </Button>
      ) : (
        <Button
          color="secondary"
          onClick={onReveal}
          // Only enable this button once user has selected an item
          disabled={revealDisabled}
          className="uppercase"
        >
          {t("reveal")}
        </Button>
      )}
    </div>
  );
}
