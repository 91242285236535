import { Button, Centered, CircularProgress, ModalDeprecated, PromiseResolver, Stack } from "@rocket/ui";
import { Fragment, ReactNode, useMemo, useState } from "react";
import { useStoreDispatch, useStoreSelector } from "~/store";

import API from "@rocket/shared/res/Api";
import type APISchema from "@rocket/types/api/schema";
import CourseButtonGrid from "~/components/ui/CourseButtonGrid";
import CourseSelectionButton from "~/components/ui/CourseSelectionButton";
import CourseSelectionButtonInactive from "~/components/ui/CourseSelectionButtonInactive";
import Courses from "@rocket/shared/res/courses";
import { CoursesTopBar } from "~/layouts/components/TopBar/TopBar";
import { FaPlusSquare } from "react-icons/fa";
import FaceliftPage from "~/components/FaceliftPage";
import { FiChevronDown } from "react-icons/fi";
import ProductCardLink from "~/pages/members/courses/includes/ProductCardLink";
import { UserCourseProductLevel } from "@rocket/types";
import { asyncSelectCourse } from "~/store/user/actions";
import { clsx } from "clsx";
import { stopLoading } from "@rocket/shared/store/user/actions";
import useCanSeePurchaseCTA from "@rocket/shared/hooks/useCanSeePurchaseCTA";
import useMediaQuery from "~/hooks/useMediaQuery";
import usePromise from "@rocket/shared/hooks/usePromise";
import useTranslation from "@rocket/shared/hooks/useTranslation";

export default function CoursesPage() {
  const { state } = usePromise(() => API.getJson("v2/courses"), { once: true, cacheKey: "v2/courses" });

  return (
    <>
      <CoursesTopBar />
      <FaceliftPage title="Courses">
        <PromiseResolver
          state={state}
          loading={
            <Centered>
              <CircularProgress />
            </Centered>
          }
        >
          {(resolved) => <LoadedCoursesView data={resolved} />}
        </PromiseResolver>
      </FaceliftPage>
    </>
  );
}

function LoadedCoursesView(props: { data: APISchema["GET"]["v2/courses"]["response"] }) {
  const defaultSelectedCourse = props.data.user_courses[0]?.id || 0;
  const dispatch = useStoreDispatch();
  const [selectedCourse, setSelectedCourse] = useState(defaultSelectedCourse);
  const { user_courses, inactive_course_ids } = props.data;

  const isMobileResolution = useMediaQuery("(max-width: 768px)");

  const inactiveCourses = useMemo(() => {
    return Courses.filter((c) => inactive_course_ids.includes(c.id));
  }, [inactive_course_ids]);

  if (!isMobileResolution && selectedCourse === -1) {
    setSelectedCourse(defaultSelectedCourse);
  }

  const CourseAndProductSelector = isMobileResolution
    ? CourseAndProductSelectorMobile
    : CourseAndProductSelectorDesktop;

  return (
    <div>
      <CourseSignupStatusModal />
      {user_courses.length > 0 && (
        <CourseAndProductSelector
          onSelectCourse={setSelectedCourse}
          selectedCourse={selectedCourse}
          userCourses={user_courses}
          TryMoreCoursesButton={
            <>
              {inactiveCourses.length > 0 && (
                <button
                  type="button"
                  className={clsx(
                    "flex h-16 w-full items-center gap-2 rounded-lg p-4 font-sans text-sm font-medium text-brand hover:bg-brand2 dark:bg-surface2 dark:text-white hover:dark:bg-brand",
                    selectedCourse === 0 ? "bg-brand2" : "bg-white",
                  )}
                  onClick={() => setSelectedCourse((s) => (s === 0 ? -1 : 0))}
                >
                  <FaPlusSquare size={32} />
                  <span className="flex flex-1 text-left">Try another language</span>
                  {!selectedCourse && <FiChevronDown className="ml-2 inline-block h-6 w-6" />}
                </button>
              )}
            </>
          }
        />
      )}
      {selectedCourse === 0 && inactive_course_ids.length > 0 && (
        <div className={"mt-4 p-2"}>
          <p className="text-sans text-brand dark:text-white">
            Just choose the language that you want to learn and let's go!
          </p>
          <div className="mt-2">
            <CourseButtonGrid>
              {inactiveCourses.map((course) => {
                return (
                  <CourseSelectionButtonInactive
                    key={course.id}
                    courseSlug={course.slug}
                    title={course.name}
                    onClick={() => {
                      // Find the hardcoded course
                      const fullCourse = Courses.find((c) => c.id === course.id);
                      if (fullCourse) {
                        dispatch(asyncSelectCourse(fullCourse, true));
                      }
                    }}
                  />
                );
              })}
            </CourseButtonGrid>
          </div>
        </div>
      )}
    </div>
  );
}

interface CourseAndProductSelectorProps {
  userCourses: APISchema["GET"]["v2/courses"]["response"]["user_courses"];
  selectedCourse: number;
  onSelectCourse: (courseId: number) => void;
  TryMoreCoursesButton: ReactNode;
}

function CourseAndProductSelectorDesktop({
  selectedCourse,
  onSelectCourse,
  userCourses,
  TryMoreCoursesButton,
}: CourseAndProductSelectorProps) {
  const productLevelCards = useMemo(() => {
    return userCourses.find((userCourse) => userCourse.id === selectedCourse)?.product_levels ?? [];
  }, [userCourses, selectedCourse]);

  const hasStartedAnyLevel = productLevelCards.some((productLevel) => productLevel.resume);
  return (
    <div className={"flex gap-x-2 p-2 md:gap-x-12"}>
      <div className="flex w-full flex-col gap-2 pb-2 md:w-72">
        <CourseSelectionButtons courses={userCourses} selectedCourse={selectedCourse} onSelectCourse={onSelectCourse} />
        {TryMoreCoursesButton}
      </div>
      <div className={"flex flex-1 flex-col gap-4 pb-4 pt-2 sm:py-0"}>
        {productLevelCards.map((productLevel, index) => (
          <ProductLevelCard
            key={`pl.${productLevel.course_id}.${productLevel.id}`}
            productLevel={productLevel}
            shouldStart={!hasStartedAnyLevel && index === 0}
          />
        ))}
      </div>
    </div>
  );
}

function CourseAndProductSelectorMobile({
  selectedCourse,
  onSelectCourse,
  userCourses,
  TryMoreCoursesButton,
}: CourseAndProductSelectorProps) {
  const productLevelCards = useMemo(() => {
    return userCourses.find(({ id }) => id === selectedCourse)?.product_levels ?? [];
  }, [userCourses, selectedCourse]);

  const hasStartedAnyLevel = productLevelCards.some((productLevel) => productLevel.resume);

  return (
    <div className={"flex gap-x-2 p-2 md:gap-x-12"}>
      <div className="flex w-full flex-col gap-2 pb-2">
        <CourseSelectionButtons
          courses={userCourses}
          selectedCourse={selectedCourse}
          // on Mobile, toggle the course
          onSelectCourse={(courseId) => onSelectCourse(courseId === selectedCourse ? -1 : courseId)}
        >
          <div className={"flex flex-1 flex-col gap-4 py-2 sm:py-0"}>
            {productLevelCards?.map((productLevel, index) => (
              <ProductLevelCard
                key={`pl.${productLevel.course_id}.${productLevel.id}`}
                productLevel={productLevel}
                shouldStart={!hasStartedAnyLevel && index === 0}
              />
            ))}
          </div>
        </CourseSelectionButtons>
        {TryMoreCoursesButton}
      </div>
    </div>
  );
}

function ProductLevelCard({
  productLevel,
  shouldStart,
}: {
  productLevel: UserCourseProductLevel;
  shouldStart: boolean;
}) {
  const canSeePurchaseCTA = useCanSeePurchaseCTA();

  if (!productLevel.product_id && !canSeePurchaseCTA) {
    return null;
  }

  const title = productLevel.label;
  const description = productLevel.description || "";
  const moduleLabel = productLevel.module_label;
  const imageUrl =
    productLevel.image_url ||
    "https://images.unsplash.com/photo-1605653799416-595e2b020d08?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=256&q=80";

  if (!productLevel.product_id) {
    return (
      <ProductCardLink
        status="locked"
        to={getPricingLink({ courseId: productLevel.course_id, productLevelId: productLevel.id })}
        title={title}
        imageUrl={imageUrl}
        moduleLabel={moduleLabel}
        description={description}
      />
    );
  }

  const link = `/members/products/${productLevel.product_id}/dashboard`;

  if (productLevel.resume) {
    return (
      <ProductCardLink
        status="resume"
        to={link}
        title={title}
        imageUrl={imageUrl}
        moduleLabel={moduleLabel}
        description={description}
      />
    );
  }

  return (
    <ProductCardLink
      status={shouldStart ? "getStarted" : undefined}
      to={link}
      title={title}
      imageUrl={imageUrl}
      moduleLabel={moduleLabel}
      description={description}
    />
  );
}

function getPricingLink(options: { courseId: number; productLevelId: number }) {
  const courseSlug = Courses.find((c) => c.id === options.courseId)?.slug;

  if (!courseSlug) {
    return `https://www.rocketlanguages.com/pricing`;
  }

  // Travelogues
  if (options.productLevelId === 4) {
    return `https://www.rocketlanguages.com/${courseSlug}/rocket-${courseSlug}-travelogues`;
  }

  if (options.productLevelId === 10) {
    return `https://www.rocketlanguages.com/${courseSlug}/rocket-${courseSlug}-play-the-part`;
  }

  return `https://www.rocketlanguages.com/pricing?language=${courseSlug}`;
}

interface CourseSelectionButtonsProps {
  courses: APISchema["GET"]["v2/courses"]["response"]["user_courses"];
  selectedCourse: number;
  onSelectCourse: (courseId: number) => void;
  children?: ReactNode;
}

function CourseSelectionButtons(props: CourseSelectionButtonsProps) {
  return (
    <>
      {props.courses.map((course) => {
        const selected = course.id === props.selectedCourse;
        return (
          <Fragment key={`select.${course.id}`}>
            <CourseSelectionButton
              onClick={() => props.onSelectCourse(course.id)}
              courseSlug={course.slug}
              title={course.name}
              dialect={course.dialect}
              selected={selected}
            />
            {selected && props.children}
          </Fragment>
        );
      })}
    </>
  );
}

function CourseSignupStatusModal() {
  const t = useTranslation();
  const courseSignupStatus = useStoreSelector((store) => store.user.requestStatus.courseSignup);
  const dispatch = useStoreDispatch();

  const handleCloseError = () => {
    dispatch(stopLoading({ key: "courseSignup" }));
  };

  return (
    <>
      <ModalDeprecated.CardContainer
        verticallyCentered
        isOpen={courseSignupStatus === "error"}
        onClose={handleCloseError}
      >
        <ModalDeprecated.Title>{t("whoops")}</ModalDeprecated.Title>
        <ModalDeprecated.Body>{t("error-navigating-to-course")}</ModalDeprecated.Body>
        <ModalDeprecated.Actions>
          <Button color="secondary" onClick={handleCloseError}>
            {t("close")}
          </Button>
        </ModalDeprecated.Actions>
      </ModalDeprecated.CardContainer>

      <ModalDeprecated.CardContainer verticallyCentered isOpen={courseSignupStatus === "loading"}>
        <ModalDeprecated.Body>
          <Stack>
            <Centered>
              <CircularProgress />
            </Centered>
            <div className="text-center">{t("getting-course-ready")}</div>
          </Stack>
        </ModalDeprecated.Body>
      </ModalDeprecated.CardContainer>
    </>
  );
}
