import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import Phrasebox from "../../Phrasebox";
import { RateableTestTypeIds, WritingSystemLanguages } from "../../../utils/constants";
import {
  allPunctuationRegexGlobal,
  getRatingLevel,
  getWritingSystemIdFromCourse,
  getWritingSystemIndex,
  languageSplitCharacters,
  reorderArray,
} from "../../../utils";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import usePhraseTest, { usePhraseTestProps } from "../../../hooks/usePhraseTest/usePhraseTest";

import Instructions from "./includes/Instructions";
import LessonContext from "../../../context/LessonContext";
import RateableTest from "../RateableTest";
import RateableTestContext from "../includes/context";
import { RateableTestUIContext } from "../RateableTestUI/includes/context";
import RatingButtons from "../RateableTestUI/buttons/RatingButtons";
import { WriteItItem } from "@rocket/types";
import { clsx } from "clsx";
import getCharacterButtons from "./utils/getCharacterButtons";
import shuffler from "./utils/shuffler";
import styles from "./SortIt.module.scss";
import { useSharedSelector } from "../../../store";
import useTranslation from "../../../hooks/useTranslation";
import { OutlineButton } from "@rocket/ui";

interface SortItProps {
  rateableTestId: number;
  rateableTestTypeId: number;
}

export default function SortIt({ rateableTestId, rateableTestTypeId }: SortItProps) {
  const t = useTranslation();
  const lesson = useContext(LessonContext);
  const activeCourseSlug = useSharedSelector((store) => store.preferences.activeCourse?.slug) || "";
  const isNative = rateableTestTypeId === RateableTestTypeIds.SORT_IT_NATIVE;

  const phraseTestProps = useMemo(
    (): usePhraseTestProps => ({
      testTypeId: rateableTestTypeId,
      lessonId: lesson.id,
      rateableTestId,
      mode: "unrated_components",
      phraseFilter(phrase) {
        // filter out duplicate kanji/kana phrases from kanji sort it
        if (phrase.course_id === 1 && isNative) {
          if (
            !phrase.strings[0] ||
            // Note unusual phrase string in RJ 3.8 with one string entry.
            !phrase.strings[1] ||
            phrase.strings[0].text.replace(allPunctuationRegexGlobal, "") ===
              phrase.strings[1].text.replace(allPunctuationRegexGlobal, "")
          ) {
            return false;
          }
        }

        const mainWsIndex = getWritingSystemIndex(activeCourseSlug, phrase, isNative);

        const wsId = phrase.strings[mainWsIndex]?.writing_system_id;

        if (!wsId) {
          return false;
        }

        const splitCharacter =
          wsId in languageSplitCharacters ? languageSplitCharacters[wsId] : languageSplitCharacters[1];

        const phraseStringArray = (phrase.strings[mainWsIndex]?.text || "")
          .split(splitCharacter)
          .filter((_string) => !!_string);

        return phraseStringArray.length > 1 && phraseStringArray[0] !== phraseStringArray[1];
      },
    }),
    [rateableTestTypeId, lesson.id, rateableTestId, isNative, activeCourseSlug],
  );

  const phraseTest = usePhraseTest(phraseTestProps);

  const currentPhrase = phraseTest.components.testPhrases[phraseTest.state.index];

  const phraseStringIndex = getWritingSystemIndex(activeCourseSlug, currentPhrase, isNative);

  // Get testName for romanized romantic languages, eg: Pinyin
  const testName = (() => {
    if (!currentPhrase || !rateableTestTypeId) {
      return WritingSystemLanguages[getWritingSystemIdFromCourse(activeCourseSlug, isNative)] || "";
    }
    const phraseString = currentPhrase.strings[phraseStringIndex];
    if (!phraseString) {
      return WritingSystemLanguages[getWritingSystemIdFromCourse(activeCourseSlug, isNative)] || "";
    }
    return WritingSystemLanguages[phraseString.writing_system_id] || "";
  })();

  return (
    <RateableTest
      phraseTest={phraseTest}
      testTypeId={rateableTestTypeId}
      testName={`${t("sort-it")} (${testName})`}
      testSubheading={t("sort-it-subheading")}
      instructions={<Instructions />}
    >
      <SortItPhraseTest />
    </RateableTest>
  );
}

export function SortItPhraseTest() {
  const { testTypeId, phraseTest } = useContext(RateableTestContext);
  const uiContext = useContext(RateableTestUIContext);

  const activeCourseSlug = useSharedSelector((store) => store.preferences.activeCourse?.slug) || "";
  const [hideAnswers, setHideAnswers] = useState(false);
  const isArabic = activeCourseSlug === "arabic";

  const [topArray, setTopArray] = useState<WriteItItem[]>([]);
  const [bottomArray, setBottomArray] = useState<WriteItItem[]>([]);

  const didReveal = phraseTest.state.revealed.has(phraseTest.state.index);
  const currentPhrase = phraseTest.components.testPhrases[phraseTest.state.index];

  const phraseStringIndex = getWritingSystemIndex(
    activeCourseSlug,
    currentPhrase,
    testTypeId === RateableTestTypeIds.SORT_IT_NATIVE,
  );

  useEffect(() => {
    if (!currentPhrase) {
      return;
    }
    setBottomArray((currentState) => {
      if (!currentState || currentState.length === 0) {
        const buttons = getCharacterButtons({ phrase: currentPhrase, phraseStringIndex });

        const shuffledButtons = shuffler({
          phrases: phraseTest.components.testPhrases,
          phrase: currentPhrase,
          phraseArray: buttons,
          phraseStringIndex,
        });

        return shuffledButtons || [];
      }
      return currentState;
    });
  }, [currentPhrase, phraseStringIndex, phraseTest.components.testPhrases]);

  const onReveal = useCallback(() => {
    function getCorrectAnswers() {
      // if answer is undefined, it is a shuffled array, determine getratinglevel from order
      // and re-order question array back into its correct order, including highlighting which phrases were in the correct order
      let correctCount = 0;

      topArray.forEach((answer, i) => {
        // if an answer is in the same position in the answers array as it is in the original index
        // set its answer value to true and add to the correct count
        // and the corresponding value in the answeredbottomArray to true
        if (i === answer.originalOrder) {
          answer.correct = true;
          correctCount++;
          return;
        }

        // Else handle the possibility that the answer was a duplicate value that the user had no way of determining the original index of
        const duplicateAnswers = topArray.filter((el) => el.questionText === answer.questionText);

        if (duplicateAnswers.length > 1) {
          duplicateAnswers.forEach((duplicateAnswer) => {
            if (i === duplicateAnswer.originalOrder) {
              answer.correct = true;
              correctCount++;
            }
          });
        }
      });

      // Sort the answered array by original order
      const correctAnswers = [...topArray, ...bottomArray].sort(
        (a, b) => (a.originalOrder || 0) - (b.originalOrder || 0),
      );

      return { correctCount, correctAnswers };
    }

    const { correctCount, correctAnswers } = getCorrectAnswers();

    setBottomArray(correctAnswers);

    // If the user answered all questions correctly, hide the answers
    if (correctCount === topArray.length && bottomArray.length === 0) {
      setHideAnswers(true);
    }

    const rating = (() => {
      // if the user got no answers right, check if they attempted or not
      if (correctCount) {
        return (correctCount / topArray.length) * 100;
      }
      // if attempted, return hard suggestion, otherwise highlight all buttons
      return bottomArray.length ? 1 : 0;
    })();

    // Notify phrasetest of suggested rating level
    return getRatingLevel(rating);
  }, [topArray, bottomArray]);

  function onSelectAnswer(phraseItem: WriteItItem) {
    setTopArray((answers) => answers.concat(phraseItem));
    setBottomArray((arr) => arr?.filter((item) => item !== phraseItem) || []);
  }

  function onRemoveAnswer(answerItem: WriteItItem, destinationIndex = bottomArray.length) {
    if (didReveal) {
      return;
    }
    const currentAnswerIndex = topArray.findIndex((answer) => answer.originalOrder === answerItem.originalOrder);
    if (currentAnswerIndex === -1) {
      return;
    }
    setTopArray((state) => {
      const newArray = [...state];
      newArray.splice(currentAnswerIndex, 1);
      return newArray;
    });

    setBottomArray((state) => {
      if (!state) {
        return state;
      }
      return [...state.slice(0, destinationIndex), answerItem, ...state.slice(destinationIndex)];
    });
  }

  function onDragEnd(result: DropResult) {
    if (!result.destination) return;

    const destinationIndex = result.destination.index;
    const sourceIndex = result.source.index;

    // The user just reordered the list
    if (result.destination.droppableId === "questions" && result.source.droppableId === "questions") {
      setBottomArray((state) => reorderArray(state as any[], sourceIndex, destinationIndex));
    }

    // drag from questions to answers
    if (result.source.droppableId === "questions" && result.destination.droppableId === "answers") {
      const phraseItem = bottomArray[sourceIndex];
      if (phraseItem) {
        setTopArray((answers) => {
          const newState = [...answers];
          newState.splice(destinationIndex, 0, phraseItem);
          return newState;
        });
      }
      setBottomArray((state) => {
        if (!state) {
          return state;
        }
        return [...state.slice(0, sourceIndex), ...state.slice(sourceIndex + 1)];
      });
    }
    // drag within answers
    if (result.source.droppableId === "answers" && result.destination.droppableId === "answers") {
      setTopArray((state) => reorderArray(state, sourceIndex, destinationIndex));
    }
    // drag from answers to questions
    if (result.source.droppableId === "answers" && result.destination.droppableId === "questions") {
      const answerItem = topArray[result.source.index];
      if (answerItem) {
        onRemoveAnswer(answerItem, destinationIndex);
      }
    }
  }

  return (
    <div className="space-y-6">
      <div className="rounded-md bg-rocketorange-light p-4 dark:bg-neutral-800">
        {currentPhrase ? (
          <Phrasebox
            recordButtonHidden={!didReveal}
            // re-render on revealed for playOnMount to re-trigger
            key={`${currentPhrase.id}`}
            getPhraseStringOptions={() => ({
              className: !didReveal ? "invisible" : undefined,
            })}
            phrase={currentPhrase}
            playOnMount={!uiContext?.instructionsVisible}
          />
        ) : null}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable key={"questions"} droppableId={"questions"} direction="horizontal">
            {(provided) => (
              <div
                className={clsx("flex min-h-[90px] w-full flex-wrap", isArabic && "flex-row-reverse")}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {!hideAnswers &&
                  bottomArray.map((writeItItem, i) => {
                    if (!writeItItem) {
                      // "undefined is not an object (evaluating 'writeItItem.originalOrder')"
                      return null;
                    }
                    return (
                      <Draggable
                        key={writeItItem.originalOrder}
                        isDragDisabled={didReveal || isArabic}
                        draggableId={`question.${writeItItem.originalOrder}`}
                        index={i}
                      >
                        {(provided) => (
                          <div
                            id={writeItItem.questionText}
                            onClick={!didReveal ? () => onSelectAnswer(writeItItem) : undefined}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={clsx(
                              "m-2 h-min select-none rounded border-2 border-brand !p-3 dark:border-transparent dark:bg-neutral-700",
                              didReveal
                                ? "bg-rocketgreen-extra-light text-rocketgreen dark:bg-rocketgreen dark:text-rocketgreen-extra-light"
                                : "bg-surface1 hover:cursor-pointer hover:bg-surface1-dark dark:border-neutral-600",
                              isArabic && styles.arabic,
                            )}
                          >
                            {writeItItem.questionText}
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable key={"answers"} droppableId={"answers"} direction="horizontal">
            {(provided, snapshot) => (
              <div
                className={clsx(
                  "flex min-h-[90px] w-full rounded-md border-2 border-brand p-2 transition-all dark:border-neutral-600",
                  snapshot.isDraggingOver
                    ? "bg-rocketblue-extra-light dark:bg-neutral-600"
                    : "bg-white dark:bg-neutral-700",
                  isArabic ? "flex-row-reverse" : "flex-wrap",
                )}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {topArray.map((answerItem, i) => (
                  <Draggable
                    key={answerItem.originalOrder}
                    isDragDisabled={didReveal || isArabic}
                    draggableId={`answer.${answerItem.originalOrder}`}
                    index={i}
                  >
                    {(provided) => (
                      <div
                        id={`answer${i}`}
                        className={clsx(
                          "m-2 h-min select-none rounded border-2 !p-3 dark:bg-neutral-800",
                          didReveal &&
                            (answerItem.correct
                              ? "border-rocketgreen-extra-light bg-rocketgreen-extra-light text-rocketgreen dark:border-rocketgreen dark:bg-rocketgreen dark:text-rocketgreen-extra-light"
                              : "border-rocketred-extra-light bg-rocketred-extra-light text-rocketred hover:bg-rocketred-extra-light dark:border-rocketred dark:bg-rocketred dark:text-rocketred-extra-light"),
                          !didReveal &&
                            "border-brand bg-surface1 hover:cursor-pointer hover:bg-surface1-dark dark:border-neutral-600",
                          isArabic && styles.arabic,
                        )}
                        onClick={() => onRemoveAnswer(answerItem)}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {answerItem.questionText}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <RatingButtons
        RevealButtonComponent={OutlineButton}
        onReveal={onReveal}
        onRate={() => {
          setHideAnswers(false);
          setTopArray([]);
          setBottomArray([]);
        }}
      />
    </div>
  );
}
