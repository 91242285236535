import * as authConstants from "../auth/constants";
import * as constants from "./constants";
import * as lessonConstants from "../lesson/constants";
import * as userConstants from "../user/constants";

// Type imports
import { DashboardState } from "./types";
import { REHYDRATE } from "redux-persist/lib/constants";
import { SharedRootAction } from "../types";

// Dashboard products indexed by product ID which contains an array

const initialState: DashboardState = {
  products: {},
};

export default function DashboardReducer(
  state: DashboardState = initialState,
  action: SharedRootAction,
): DashboardState {
  switch (action.type) {
    case REHYDRATE:
    case authConstants.LOGOUT:
      return { ...initialState };
    case lessonConstants.VISIT: {
      const { productId, lessonId } = action.payload;
      const product = state.products[productId];
      if (!product || product.status !== "loaded") {
        return state;
      }
      return {
        ...state,
        products: {
          ...state.products,
          [productId]: {
            ...product,
            data: {
              ...product.data,
              suggestedLessonId: lessonId,
            },
          },
        },
      };
    }
    case userConstants.SYNC_USER_AND_PRODUCT: {
      const { productId } = action.payload;
      const { suggestedLessonId } = action.payload.payload;
      const currentProduct = state.products[productId];
      if (!currentProduct || currentProduct.status !== "loaded") {
        return state;
      }
      // Update suggested lesson ID
      return {
        ...state,
        products: {
          ...state.products,
          [productId]: {
            status: "loaded",
            data: {
              ...currentProduct.data,
              suggestedLessonId,
            },
          },
        },
      };
    }
    // Dashboard request has started
    case constants.DASHBOARD_REQUEST_START: {
      const currentDashboard = state.products[action.payload.productId];
      // Case: User manually drags-to-refresh
      if (currentDashboard?.status === "loaded") {
        return {
          ...state,
          products: {
            ...state.products,
            [action.payload.productId]: {
              ...currentDashboard,
              refreshing: {
                value: true,
                silent: false,
              },
            },
          },
        };
      }

      // Case: Default load request
      return {
        ...state,
        products: {
          ...state.products,
          [action.payload.productId]: {
            status: "loading",
          },
        },
      };
    }
    case constants.ADMIN_DASHBOARD_REQUEST_START: {
      // Case: Default load request
      return {
        ...state,
        products: {
          ...state.products,
          [action.payload.productId]: {
            status: "loading",
          },
        },
      };
    }
    // Request was unsuccessful
    case constants.DASHBOARD_REQUEST_FAIL: {
      return {
        ...state,
        products: {
          ...state.products,
          [action.payload.productId]: {
            status: "error",
            errorText: action.payload.errorText,
            errorCode: action.payload.errorCode,
            statusCode: action.payload.statusCode,
          },
        },
      };
    }
    // Request was successful
    case constants.DASHBOARD_REQUEST_SUCCESS: {
      const { productId } = action.payload;

      return {
        ...state,
        products: {
          ...state.products,
          [productId]: {
            status: "loaded",
            data: action.payload.payload.dashboard,
          },
        },
      };
    }
    // Remove the dashboard object so that it is requested again when hit next
    case constants.DASHBOARD_RESET: {
      const { productId } = action.payload;

      const products = state.products;
      delete products[productId];

      return {
        ...state,
        products,
      };
    }
    default:
      return state;
  }
}
