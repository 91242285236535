import courses from "@rocket/shared/res/courses";
import type { Course } from "@rocket/types";
import useTranslation from "@rocket/shared/hooks/useTranslation";
import FaceliftPage from "~/components/FaceliftPage";
import CourseSelectionButtonInactive from "~/components/ui/CourseSelectionButtonInactive";
import Sidebar from "~/components/ui/Sidebar";
import CourseButtonGrid from "~/components/ui/CourseButtonGrid";

export default function Signup() {
  const t = useTranslation();
  // const sortedCourses = sortAlphabetically(courses);

  function renderCourseButtons(courses: Course[]) {
    return courses.map((course) => {
      if (["ebook", "eigo", "arabic-msa"].includes(course.slug)) {
        return null;
      }
      return (
        <CourseSelectionButtonInactive
          key={course.id}
          title={course.name}
          courseSlug={course.slug}
          use="a"
          href={`https://www.rocketlanguages.com/${course.slug}/premium`}
          target="_blank"
          rel="noopener noreferrer"
        />
      );
    });
  }

  return (
    <FaceliftPage title="Sign Up" className="p-2">
      <FaceliftPage.TwoColumns>
        <Sidebar>
          <Sidebar.BackButton label="Back to Log In" to="/login" />
        </Sidebar>
        <div>
          <h1 className="mb-6 font-sans">{t("sign-up-for-a-free-trial-here")}</h1>
          <p className="my-2">{t("sign-up-for-a-free-trial-here-description")}</p>
          <h3 className={"mb-4 mt-8 font-sans"}>{t("try-it-for-free")}</h3>
          <CourseButtonGrid>{renderCourseButtons(courses)}</CourseButtonGrid>
        </div>
      </FaceliftPage.TwoColumns>
    </FaceliftPage>
  );
}
