import { FocusOnMount } from "@rocket/ui";
import { clsx } from "clsx";
import { FaChevronCircleRight, FaLock } from "react-icons/fa";
import DynamicLink from "~/components/DynamicLink";
import useTranslation from "@rocket/shared/hooks/useTranslation";
import { useMemo } from "react";

type ProductCardLinkProps = {
  title: string;
  to: string;
  /** Optional module label. If empty, defaults to "Modules" */
  moduleLabel?: string;
  description: string;
  imageUrl: string | null;
  status?: "resume" | "getStarted" | "locked";
};

function CallToAction({ text, icon }: { text: string; icon: React.ReactNode }) {
  return (
    <div className="flex flex-1 items-end">
      <span className="flex flex-1 items-center justify-end gap-2 font-bold uppercase text-rocketred dark:text-[#f86a62]">
        {text}
        {icon}
      </span>
    </div>
  );
}
export default function ProductCardLink(props: ProductCardLinkProps) {
  const t = useTranslation();

  // The description can be formatted with bold text in the form of **bold text**
  const formattedDescription = useMemo(
    () =>
      props.description.split(/\*\*(.*?)\*\*/g).reduce(
        (acc, curr, i) => {
          if (i % 2 === 0) {
            return [...acc, curr];
          }
          return [...acc, <strong key={i}>{curr}</strong>];
        },
        [] as (string | JSX.Element)[],
      ),
    [props.description],
  );

  return (
    <FocusOnMount value={props.status === "resume" || props.status === "getStarted"}>
      <DynamicLink
        path={props.to}
        newTab={true}
        className={clsx(
          "flex gap-2 overflow-hidden rounded-lg bg-surface2 hover:bg-brand2 focus:outline-none focus:ring focus:ring-rocketblue focus-visible:ring dark:hover:bg-neutral-700 sm:h-48",
          (props.status === "resume" || props.status === "getStarted") && "border-2 border-brand shadow-xl",
        )}
      >
        <div className="flex-1 px-4 py-4 md:px-8 md:py-6">
          <div className="flex h-full flex-col gap-2">
            <h3 className="font-sans text-2xl font-bold text-brand dark:text-white">{props.title}</h3>
            <p
              className={"text-sans overflow-hidden text-sm font-medium text-text2 dark:text-white"}
              style={{ display: "-webkit-box", WebkitLineClamp: 3, WebkitBoxOrient: "vertical" }}
            >
              {formattedDescription}
            </p>
            {props.status === "getStarted" && <CallToAction text="START" icon={<FaChevronCircleRight />} />}
            {props.status === "resume" && <CallToAction text={t("continue")} icon={<FaChevronCircleRight />} />}
            {props.status === "locked" && <CallToAction text="BUY NOW" icon={<FaLock />} />}
          </div>
        </div>
        <div className="hidden justify-end sm:flex">
          {props.imageUrl && (
            <img
              className="h-full w-36 bg-brand2 object-cover lg:w-64"
              src={props.imageUrl}
              alt={props.title}
              loading="lazy"
            />
          )}
        </div>
      </DynamicLink>
    </FocusOnMount>
  );
}
