import { useMemo } from "react";
import { useSharedSelector } from "../../store";
import type { UserComponentRatingEntity } from "@rocket/types";
import { ComponentTypeIds } from "../useRateableTest/utils";
import type { SharedRootState } from "../../store/types";

export type RatingGroups = { easy: Set<number>; good: Set<number>; hard: Set<number> };

/**
 * Returns three sets of component IDs, ordered by easy, good, hard
 */
export default function useRatingGroups(
  componentType: keyof typeof ComponentTypeIds,
  rateableTestId: number,
  /** use ratings from props instad of store if passed in - for custom flashcards */
  propRatings?: UserComponentRatingEntity[],
): RatingGroups {
  const componentTypeId = ComponentTypeIds[componentType];
  // create unique instance of memoized getRatings function based on rateable test id
  const storeRatings = useSharedSelector(
    (state) => state.lesson.entities.user_rateable_test_component_ratings[rateableTestId],
  );

  const ratings = propRatings || storeRatings;

  return useMemo(() => {
    // (There can be records of the user rating components that are not in the phrase test)
    const filteredRatings = (ratings || []).filter((rating) => rating.component_type_id === componentTypeId);
    return getRatingGroups(filteredRatings);
  }, [componentTypeId, ratings]);
}

export function ratingGroupsSelector(
  state: SharedRootState,
  componentType: "phrase" | "custom_flashcard" | "video" | "script_writer",
  rateableTestId: number,
) {
  const ratings = state.lesson.entities.user_rateable_test_component_ratings[rateableTestId];
  const componentTypeId = ComponentTypeIds[componentType];
  const filteredRatings = (ratings || []).filter((rating) => rating.component_type_id === componentTypeId);
  return getRatingGroups(filteredRatings);
}

export function ratingGroupsTupleSelector(
  state: SharedRootState,
  componentType: "phrase" | "custom_flashcard" | "video" | "script_writer",
  rateableTestId: number,
): [number, number, number] {
  const groups = ratingGroupsSelector(state, componentType, rateableTestId);
  return [groups.easy.size, groups.good.size, groups.hard.size];
}

function getRatingGroups(componentRatings: UserComponentRatingEntity[]): RatingGroups {
  const easy = new Set<number>();
  const good = new Set<number>();
  const hard = new Set<number>();

  for (const ratedComponent of componentRatings) {
    const id = ratedComponent.component_id;
    if (ratedComponent.value >= 90) {
      easy.add(id);
    } else if (ratedComponent.value >= 50) {
      good.add(id);
    } else if (ratedComponent.value > 0) {
      hard.add(id);
    }
  }

  return { hard, good, easy };
}
