import { noop } from "../utils";
import * as React from "react";
import useCustomFlashcardTest from "../hooks/useCustomFlashcardTest";

const CustomFlashcardsContext = React.createContext<{
  phraseTest: ReturnType<typeof useCustomFlashcardTest>;
  testTypeId: number;
  /** Container DOM Ref, used for focus key events */
  testContainerRef: React.MutableRefObject<HTMLDivElement | null>;
}>({
  phraseTest: {
    state: {
      currentSuggestedRatingLevel: 0,
      gameSessionId: "",
      index: 0,
      mode: "unrated_components",
      revealed: new Map(),
      status: "idle",
    },
    components: {
      flashcards: [],
    },
    events: {
      registerOnCompleteListener: noop,
      unregisterOnCompleteListener: noop,
    },
    rateableTestId: 0,
    status: "",
    computed: {
      hasCompleted: false,
      languageName: "",
      position: 0,
      ratingLevel: 0,
      ratings: {
        easy: new Set(),
        good: new Set(),
        hard: new Set(),
      },
      totalComponents: 0,
    },
    methods: {
      dispatch: noop,
      rate: noop,
      redoNonEasyPhrases: noop,
      reload: noop,
      reset: noop,
    },
  },
  testTypeId: 0,
  testContainerRef: { current: null },
});

export default CustomFlashcardsContext;
