import { Phrase, SetStateFunction } from "@rocket/types";
import { createContext, Dispatch, useContext, useMemo, useState } from "react";
import usePlayIt, { PlayItActions, State as PlayItState } from "./usePlayIt";
import LessonContext from "../../../../../context/LessonContext";

export type PlayItContextValue = {
  state: PlayItState;
  dispatch: Dispatch<PlayItActions>;
  hideWords: boolean;
  setHideWords: SetStateFunction<boolean>;
  filteredWritingSystems: Set<number>;
  setFilteredWritingSystems: SetStateFunction<Set<number>>;
  /** Set when used in admin area, renders phrasebox within an editable wrapper instead */
  phraseboxWrapper?: (
    key: string | number,
    phrase: Phrase,
    component: JSX.Element,
    phraseIndex: number,
  ) => JSX.Element | null;
};

export const PlayItContext = createContext<PlayItContextValue | null>(null);

interface Props {
  onStart?: (transcriptId: number) => void;
  children: React.ReactNode;
  onEnd?: (state: PlayItState, transcriptId: number) => void;
  /** Set when used in admin area, renders phrasebox within an editable wrapper instead */
  phraseboxWrapper?: (
    key: string | number,
    phrase: Phrase,
    component: JSX.Element,
    phraseIndex: number,
  ) => JSX.Element | null;
}

/**
 * This is a UI component that contains the PlayIt context.
 *
 * Use the PlayItProviderUI component as a child component to lay out the UI (i.e. countdown & stop button)
 *
 * Note: has no idea of the list of phrases.
 * It just works by containing state of the current play it game
 *
 * Used in: TranscriptComponent
 *
 * Usage:
 *
 * ```js
 * <LessonContext.Provider value={{ id: 1, ... }}>
 *   <PlayItProviderUI onStart={() => {}} onEnd={() => {}}>
 *      <TranscriptComponent />
 *   </PlayItProviderUI>
 * </LessonContext.Provider>
 * ```
 */
export default function PlayItProvider(props: Props) {
  const lessonId = useContext(LessonContext)!.id;
  const [hideWords, setHideWords] = useState(false);
  /** List of writing system IDs that are filtered */
  const [filteredWritingSystems, setFilteredWritingSystems] = useState<Set<number>>(new Set());

  const playItProps = useMemo(
    () => ({
      callbacks: {
        onEnd: props.onEnd,
        onStart: props.onStart,
      },
      lessonId,
    }),
    [lessonId, props.onEnd, props.onStart],
  );

  const PlayIt = usePlayIt(playItProps);

  return (
    <PlayItContext.Provider
      value={{
        ...PlayIt,
        phraseboxWrapper: props.phraseboxWrapper,
        hideWords,
        setHideWords,
        filteredWritingSystems,
        setFilteredWritingSystems,
      }}
    >
      {props.children}
    </PlayItContext.Provider>
  );
}
