import { Button, FaceliftInput } from "@rocket/ui";
import { useState } from "react";

import { Client } from "@rocket/shared/res/Api";
import { Link } from "react-router-dom";
import { getErrorMessage } from "@rocket/shared/utils";
import { toast } from "react-toastify";
import usePromise from "@rocket/shared/hooks/usePromise";
import useTranslation from "@rocket/shared/hooks/useTranslation";
import FaceliftPage from "~/components/FaceliftPage";

export default function ForgotPasswordPage() {
  const t = useTranslation();
  const [email, setEmail] = useState("");
  const { state, execute } = usePromise<{ error: boolean; message: string } | void>();
  const isSuccessful = state?.status === "loaded";

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    execute(() =>
      Client.axios
        .post<{ error: boolean; message: string }>("v2/password/forgot", { email })
        .then((res) => res.data)
        .catch((e) => {
          toast.error(getErrorMessage(e));
          throw Error;
        }),
    );
  }

  function updateEmail(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.currentTarget.value);
  }

  return (
    <FaceliftPage title="Forgot Password" className="flex justify-center">
      <div className={"flex w-full max-w-[850px] flex-col self-start rounded-lg bg-surface2"}>
        <div className="border-b-2 border-b-brand2 px-4 py-4 md:px-10">
          <h1>{t("forgot-your-password")}</h1>
        </div>
        <div className={"p-4 md:p-10"}>
          {!isSuccessful && <p>{t("forgot-your-password-description")}</p>}

          {state?.status === "error" && (
            <div className={"text-center font-semibold text-rocketred"}>
              {state?.errorText || "It looks like something went wrong. Please try again."}
            </div>
          )}

          {isSuccessful && (
            <div
              className={
                "mt-3 flex flex-col items-center justify-center gap-2 rounded-lg bg-rocketorange-light px-6 py-10 dark:bg-neutral-800"
              }
            >
              <p>We have e-mailed your password reset link.</p>
              <p>Please check your inbox and spam folders.</p>
              <p>Note that the link is only valid for 60 minutes, so check your inbox now.</p>
            </div>
          )}

          {!isSuccessful && (
            <form
              method="post"
              action="/user/login"
              onSubmit={submit}
              className="mt-3 flex flex-col gap-4 rounded-lg bg-rocketorange-light px-6 py-10 dark:bg-neutral-800"
            >
              <div>
                <FaceliftInput
                  label={t("email-address")}
                  //icon={FaEnvelope}
                  name="email"
                  type="email"
                  value={email}
                  onChange={updateEmail}
                  required
                  minLength={3}
                  maxLength={255}
                />
              </div>
              <div className="mt-5 flex justify-center">
                <Button color="primary" loading={state?.status === "loading"}>
                  {t("email-me-the-password-reset-link")}
                </Button>
              </div>
            </form>
          )}

          <div className="mt-8 text-center">
            <Link
              className={
                "inline-block text-sm font-bold uppercase text-brand underline hover:text-brand-dark dark:text-rocketblue"
              }
              to="/login"
            >
              {t("back-to-login")}
            </Link>
          </div>
        </div>
      </div>
    </FaceliftPage>
  );
}
