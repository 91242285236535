import type { ScriptWriterComponent } from "@rocket/types";
import { createSelector } from "reselect";
import type {
  RateableTestSelectors,
  UnratedComponentsParams,
  AllComponentsParams,
  NonEasyComponentsParams,
} from "../types";

export default function createDrawItSelectors(): RateableTestSelectors<ScriptWriterComponent> {
  return {
    allComponents: createSelector(
      ({ state, lessonId }: AllComponentsParams) => state.lesson.entities.lesson_components[lessonId],
      (components) => {
        return components
          ?.filter((component) => component.type === "script_writer")
          .map((item) => item.component) as ScriptWriterComponent[];
      },
    ),

    unratedComponents: createSelector(
      [
        ({ state, rateableTestId }: UnratedComponentsParams<ScriptWriterComponent>) =>
          state.lesson.entities.user_rateable_test_component_ratings[rateableTestId] || [],
        ({ components }: UnratedComponentsParams<ScriptWriterComponent>) => components,
      ],
      (componentRatings, components) => {
        const unratedComponents = components.filter(
          (component) => !componentRatings.find((item) => item.component_id === component.id),
        );
        return unratedComponents;
      },
    ),

    nonEasyComponents: createSelector(
      [
        ({ state, rateableTestId }: NonEasyComponentsParams<ScriptWriterComponent>) =>
          state.lesson.entities.user_rateable_test_component_ratings[rateableTestId] || [],
        ({ components }: NonEasyComponentsParams<ScriptWriterComponent>) => components,
      ],
      (componentRatings, components) => {
        const nonEasyRatings = componentRatings.filter((item) => item.value < 100);

        const nonEasyComponents = components.filter((component) =>
          nonEasyRatings.some((item) => item.component_id === component.id),
        );

        return nonEasyComponents;
      },
    ),
  };
}
