import useGetUserRating from "./utils/useGetUserRating";
import { RatingButton } from "./RatingButton";

interface StoreRatingButtonProps {
  lessonId?: number;
  initializeWithRating: boolean;
}

export default function StoreRatingButton({ lessonId, initializeWithRating }: StoreRatingButtonProps) {
  const results = useGetUserRating({ lessonId, initializeWithRating });

  if (!results) {
    return null;
  }

  return <RatingButton percentage={results.percentage} />;
}
