import { LessonComponent, TranscriptComponent } from "@rocket/types";
import { useContext, useMemo } from "react";

import { Checkbox } from "@rocket/ui";
import { PlayItContext } from "./context";
import Voice from "../../../../Phrasebox/includes/RocketRecord/Voice";
import usePlayItLanguages from "./usePlayItLanguages";
import { useSharedSelector } from "../../../../../store";
import useTranslation from "../../../../../hooks/useTranslation";

interface SettingsProps {
  lessonComponent: LessonComponent<"transcript", TranscriptComponent>;
}

export default function Settings({ lessonComponent }: SettingsProps) {
  const t = useTranslation();
  const { state, dispatch, hideWords, setHideWords, filteredWritingSystems, setFilteredWritingSystems } =
    useContext(PlayItContext)!;

  const debugEnabled = useSharedSelector((state) => state.preferences.debugEnabled);

  // show draft lines if debug mode enabled or on admin domain
  const filteredLines = useMemo(() => {
    if (debugEnabled || (typeof window !== "undefined" && window.location.hostname === "staff.rocketlanguages.com"))
      return lessonComponent.component.lines;
    return lessonComponent.component.lines.filter((_line) => !!_line.status);
  }, [debugEnabled, lessonComponent.component.lines]);

  const languages = usePlayItLanguages(filteredLines);

  function toggleDisplayWritingSystem(writingSystemId: number) {
    setFilteredWritingSystems((state) => {
      const newFilters = new Set(state);
      if (newFilters.has(writingSystemId)) {
        newFilters.delete(writingSystemId);
      } else {
        newFilters.add(writingSystemId);
      }
      return newFilters;
    });
  }

  return (
    <div className="mx-2 mb-2 sm:mx-8 sm:mb-8">
      <h4 className="mb-2">Display</h4>
      <div className="mb-4">
        Adjust the difficulty of Play It by adjusting what writing systems or languages you want to appear.
      </div>
      <div className="mb-4">
        <div className="mb-4">
          {Array.from(languages).map(([writingSystemId, languageName], index) => {
            const label = (() => {
              if (languageName === "English") return t("english");
              if (languageName === "Spanish") return t("spanish");
              return languageName;
            })();

            return (
              <div key={`ws.${writingSystemId}`} className="mb-2 sm:mb-4">
                {index === languages.size - 1 && (
                  <div className="mb-2 sm:mb-4">
                    <Checkbox
                      label={t("every-second-word-character")}
                      checked={hideWords}
                      onChange={() => setHideWords((state) => !state)}
                    />
                  </div>
                )}
                <Checkbox
                  label={label}
                  checked={!filteredWritingSystems.has(writingSystemId)}
                  onChange={() => toggleDisplayWritingSystem(writingSystemId)}
                />
              </div>
            );
          })}
        </div>
      </div>
      <h4 className="mb-2">{t("voice-recognition")}</h4>
      {Voice.isAnyKindOfRecognitionSupported() && (
        <Checkbox
          label={t("enable-voice-recognition-ratings")}
          checked={!state.disableVoiceRecognition}
          onChange={() => {
            dispatch({
              type: "SET_DISABLE_VOICE_RECOGNITION",
              payload: { disabled: !state.disableVoiceRecognition },
            });
          }}
        />
      )}
    </div>
  );
}
