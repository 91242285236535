import type { TimeoutType } from "@rocket/types";
import { useCallback, useEffect, useRef } from "react";

export default function useImperativeTimeout(callback: () => void) {
  const timeoutId = useRef<TimeoutType | null>(null);
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const clear = useCallback(() => {
    clearTimeout(timeoutId.current!);
  }, []);

  const run = useCallback(
    (delay: number) => {
      clear();
      timeoutId.current = setTimeout(() => {
        savedCallback.current?.();
      }, delay);
    },
    [clear],
  );

  // also, clear the timeout on unmount
  useEffect(() => clear, [clear]);

  return { run, clear };
}
