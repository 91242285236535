import AudioRecorder, { ContextSingleton } from "./RocketRecord/AudioRecorder";
import { useContext, useEffect, useRef } from "react";

import { RocketRecordContext } from "../../../context/RocketRecordContext";

export default function RocketRecordVisualizer() {
  const context = useContext(RocketRecordContext);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const requestRef = useRef<number>();
  const isRecording =
    context?.useRocketRecordState((state) => state.flag.status === "ACTIVE") && context.computed.canUseAudioRecorder;

  useEffect(() => {
    if (!isRecording) {
      return;
    }
    const mediaStreamSource = AudioRecorder.getMediaStreamSource();
    const audioCtx = ContextSingleton.get();
    const canvas = canvasRef.current;
    if (!mediaStreamSource || !audioCtx || !canvas || !canvas.getContext) {
      return;
    }
    const canvasCtx = canvas.getContext("2d");

    if (!canvasCtx) {
      return;
    }

    // Dark Blue bars
    canvasCtx.fillStyle = "#33aedb";

    const analyser = audioCtx.createAnalyser();
    analyser.fftSize = 32;
    // bufferLength should be 16 (half of fftSize)
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    // Canvas bar settings
    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;
    const barGap = 5;
    const numBars = 8;
    const barWidth = (WIDTH - barGap * numBars) / numBars;
    let barHeight;

    mediaStreamSource.connect(analyser);
    function draw() {
      // Reset background
      canvasCtx?.clearRect(0, 0, WIDTH, HEIGHT);
      analyser.getByteFrequencyData(dataArray);

      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        barHeight = (dataArray[i] || 1) / 2;
        canvasCtx?.fillRect(x, HEIGHT - barHeight / 2, barWidth, barHeight);
        x += barWidth + barGap;
      }
      requestRef.current = requestAnimationFrame(draw);
    }

    requestRef.current = requestAnimationFrame(draw);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return () => cancelAnimationFrame(requestRef.current!);
  }, [isRecording]);

  if (!isRecording) {
    return null;
  }

  return <canvas ref={canvasRef} className="mb-2 h-[35px] w-[90px]" />;
}
