import type { Course, CourseLocale, Phrase } from "@rocket/types";
import Courses from "../../../../res/courses";
import memoizeOne from "memoize-one";
import { detected, isLegacySafariDesktop } from "../../../../utils/browser";
import { create } from "zustand";
/**
 * Because speech recognition support can change between browser versions
 * we'd want to set this up again if the browser changes
 */
const nodeSpeechApiStorageKey = `useNodeSpeechApi-${detected?.version}`;

interface OptedIntoNodeSpeechApiState {
  value: boolean;
  setValue(val: boolean): void;
}

const hasDebugSpeechQueryParam =
  typeof location !== "undefined" &&
  typeof location.search === "string" &&
  location.search.includes("debug_speech_api=true");

/**
 * Some browsers that do not support Web Speech API, but can opt to use Node Speech API
 * `optedIntoNodeSpeechApi` is a flag that controls this.
 * Currently we're opting in to this for browsers that throw the `service-not-allowed` error while using the Web Speech API.
 */
export const optedIntoNodeSpeechApi = create<OptedIntoNodeSpeechApiState>((set) => ({
  value:
    (typeof window !== "undefined" && window.localStorage.getItem(nodeSpeechApiStorageKey) === "true") ||
    hasDebugSpeechQueryParam,
  setValue(value) {
    if (typeof window !== "undefined") {
      window.localStorage.setItem(nodeSpeechApiStorageKey, value ? "true" : "false");
    }
    set({ value });
  },
}));

/**
 * Whether or not to use the Node Speech API
 */
export const useShouldUseNodeSpeechApi = (phrase: Phrase) => {
  const { value } = optedIntoNodeSpeechApi();
  return Boolean(phrase?.use_remote_speech_recognition) || shouldUseNodeSpeechApi() || value;
};

/** Browsers that aren't able to use native speech recognition */
export const shouldUseNodeSpeechApi = () => {
  // Chrome iOS & old Safari versions don't support speech recognition
  return isLegacySafariDesktop || optedIntoNodeSpeechApi.getState().value;
};

const getCourseLocaleById = memoizeOne((courseId: number) => {
  return Courses.find((c) => c.id === courseId)?.locale;
});

const locales = {
  "ar-EG": "ar-EG",
  "ar-SA": "ar-SA",
  "de-DE": "de-DE",
  "en-US": "en_US",
  "es-CL": "es-CL",
  "fr-FR": "fr-FR",
  hi: "hi-IN",
  "it-IT": "it-IT",
  ja: "ja-JP",
  ko: "ko-KR",
  "pt-BR": "pt-BR",
  ru: "ru-RU",
  "zh-CN": "cmn-Hans-CN",
  "hi-IN": "hi-IN",
  "ja-JP": "ja-JP",
  "ko-KR": "ko-KR",
  "ru-RU": "ru-RU",
} satisfies Record<CourseLocale, string>;

export function getSpeechRecognitionLocale({ phrase, course }: { phrase: Phrase; course: Course | undefined }) {
  const courseLocale = getCourseLocaleById(phrase.course_id);
  return locales[courseLocale || course?.locale || "es-CL"];
}
