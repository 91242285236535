import useActiveCourse from "@rocket/shared/hooks/useActiveCourse";
import { Modal } from "@rocket/ui";
import useActiveProduct from "@rocket/shared/hooks/useActiveProduct";
import { FaCheck } from "react-icons/fa";
import useCountdown from "@rocket/shared/hooks/useCountdown";
import { useBannerStore } from "~/layouts/components/Banner/Banner.store";
import { shallow } from "zustand/shallow";
import { Fragment } from "react";

/** Used in Padlocked Lesson Items & Padlocked Tools sidebar */
export default function BuyNowModal(props: { isOpen: boolean; onClose: () => void }) {
  const productId = useActiveProduct()?.id;
  if (!props.isOpen || !productId) {
    return null;
  }
  return <BuyNowModalView onClose={props.onClose} productId={productId} />;
}

function BuyNowModalView(props: { onClose(): void; productId: number }) {
  const activeCourse = useActiveCourse();
  const bannerState = useBannerStore(
    (state) => ({
      modalImageUrl: state.modalImageUrl,
      expiresAtEpochSeconds: state.expiresAtEpochSeconds,
      pricingPageUrl: state.pricingPageUrl,
      subtitle: state.subtitle,
      buttonText: state.buttonText,
    }),
    shallow,
  );

  if (!activeCourse) {
    return null;
  }

  const banner = bannerState.modalImageUrl
    ? bannerState
    : {
        modalImageUrl: "https://s3.rocketlanguages.com/images/front_end/no-promo-banner-v3.png",
        expiresAtEpochSeconds: 0,
        subtitle: "",
        pricingPageUrl: `https://www.rocketlanguages.com/pricing?language=${activeCourse.slug}`,
        buttonText: "UPGRADE NOW",
      };

  return (
    <Modal isOpen onClose={props.onClose} image={banner.modalImageUrl}>
      <div className="my-6 flex flex-col items-center justify-center space-y-6">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <FaCheck />
            The most "bang for buck" language course
          </div>
          <div className="flex items-center gap-2">
            <FaCheck />
            4.7 star rating from 4800+ reviews
          </div>
          <div className="flex items-center gap-2">
            <FaCheck />
            One payment for 24/7 lifetime access
          </div>
          <div className="flex items-center gap-2">
            <FaCheck />
            60-day money back guarantee
          </div>
        </div>
        {banner.expiresAtEpochSeconds > 0 && (
          <div className="text-center">
            Time remaining:
            <div className="my-2 text-rocketred">
              <BannerCountdown endTimeEpochSeconds={banner.expiresAtEpochSeconds} />
            </div>
            {banner.subtitle}
          </div>
        )}
      </div>
      <a href={banner.pricingPageUrl} target="_blank" rel="noreferrer noopener">
        <button className="my-2 w-full rounded-lg bg-rocketred p-2 text-lg font-semibold text-white">
          {banner.buttonText}
        </button>
      </a>
    </Modal>
  );
}

function BannerCountdown({ endTimeEpochSeconds }: { endTimeEpochSeconds: number }) {
  const { isRunning, hoursMinutesSeconds } = useCountdown(endTimeEpochSeconds);

  const units = ["days", "hours", "mins", "secs"];
  if (!isRunning) {
    return null;
  }

  return (
    <div className="flex">
      {units.map((key, i) => (
        <Fragment key={key}>
          <div className="flex w-8">
            <div className="flex w-full flex-col text-center">
              <div className="h-6 text-2xl font-black">
                {`${hoursMinutesSeconds[key as keyof typeof hoursMinutesSeconds]}`.padStart(2, "0")}
              </div>
              <span className="text-md">{key}</span>
            </div>
          </div>
          {i < units.length - 1 && <div className="px-2 pt-0 text-2xl font-black">:</div>}
        </Fragment>
      ))}
    </div>
  );
}
