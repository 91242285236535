import { Button, IconButton } from "@rocket/ui";
import { FaMicrophone } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";

export default function Instructions() {
  return (
    <div className="mx-2 mb-2 sm:mx-8 sm:mb-8">
      <h4 className="mb-2">Role Playing</h4>
      <div className="mb-4">
        <p className="mb-2">
          The Role Playing activity is designed to help you practice having a conversation in a pressure-free
          environment. This will get you ready for when you have to do it in the real world!
        </p>
      </div>
      <h4 className="mb-2">Instructions</h4>
      <div className="mb-4">
        <p className="mb-2">
          <span className="inline-block">
            <Button color="secondary" size="small" className="px-4">
              SELECT
            </Button>
          </span>{" "}
          which character you want to play.
        </p>
        <p className="mb-2">
          <span className="inline-block">
            <IconButton aria-hidden color="error" size="small">
              <FaMicrophone />
            </IconButton>
          </span>{" "}
          RECORD yourself taking part in the conversation.
        </p>
        <p className="mb-2">
          <span className="inline-block">
            <IconButton aria-hidden color="grey" size="small">
              <FiSettings className="!h-[18px] !w-[18px] !min-w-[18px]" />
            </IconButton>
          </span>{" "}
          Use the SETTINGS to make Role Playing more difficult.
        </p>
      </div>
    </div>
  );
}
