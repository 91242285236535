import Button from "@rocket/ui/Button";
import { clsx } from "clsx";

interface NotationButtonProps {
  title: string;
  variant?: "faded";
}

export default function Notation(props: NotationButtonProps) {
  const colors = (() => {
    switch (props.variant) {
      case "faded":
        return "text-white bg-rocketred-extra-light hover:bg-rocketred-extra-light active:bg-rocketred-extra-light";
      default:
        return "text-gray-400 bg-surface2 hover:bg-surface1 active:bg-surface1 border-2 border-gray-300 dark:bg-neutral-700 dark:text-text1 dark:border-neutral-600";
    }
  })();

  return (
    <Button
      size="small"
      use="div"
      tabIndex={-1}
      className={clsx(
        "cursor-initial inline-flex h-6 select-none whitespace-nowrap rounded text-center text-xs focus:ring-0 print:hidden",
        colors,
      )}
    >
      <span>{props.title}</span>
    </Button>
  );
}
