import { Button, FormInput, Modal, OutlineButton } from "@rocket/ui";
import React, { useState } from "react";

import API from "@rocket/shared/res/Api";
import { FaPaperPlane } from "react-icons/fa";
import { FiCheckCircle } from "react-icons/fi";
import { SetStateFunction } from "@rocket/types";
import { getErrorMessage } from "@rocket/shared/utils";
import { toast } from "react-toastify";
import useTranslation from "@rocket/shared/hooks/useTranslation";

export default function FeedbackModal({
  isOpen,
  setIsOpen,
  lessonId,
}: {
  isOpen: boolean;
  setIsOpen: SetStateFunction<boolean>;
  lessonId?: number;
}) {
  const t = useTranslation();

  const [feedback, setFeedback] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  async function onSubmit() {
    setLoading(true);
    await API.post("v2/content-feedback", { lesson_id: lessonId, message: feedback, context: window.location.href })
      .then(() => {
        setSubmitted(true);
      })
      .catch((err) => {
        toast.error(getErrorMessage(err));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      sticky
    >
      <div className="mx-2 sm:mx-8">
        <h4 className="mb-2">{t("feedback")}</h4>
        <div className="mb-4">
          {!submitted ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <FormInput
                use="textarea"
                minLength={10}
                value={feedback}
                required
                onChange={(e) => {
                  setFeedback(e.target.value);
                }}
                label={lessonId ? t("send-feedback-placeholder-text") : t("send-feedback-placeholder-text-non-lesson")}
              />
              <div className="mt-2 flex flex-col justify-end gap-2 sm:flex-row">
                <OutlineButton
                  color="primary"
                  type="button"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {t("cancel").toUpperCase()}
                </OutlineButton>
                <Button left={<FaPaperPlane className="ml-1" />} color="primary" loading={loading} type="submit">
                  {t("send-feedback").toUpperCase()}
                </Button>
              </div>
            </form>
          ) : (
            <div className="my-4 flex flex-col items-center justify-center text-lg text-brand sm:my-6">
              <FiCheckCircle className="mb-2 text-rocketgreen-light" size={32} />
              <p className="">{t("thank-you-for-your-feedback")}</p>
              <div className="my-4 sm:my-6">
                <Button
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {t("close")}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
