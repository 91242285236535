import CongratulationsMessage from "../../RateableTestUI/includes/CongratulationsMessage";
import Button, { OutlineButton } from "@rocket/ui/Button";
import ResetTestButton from "../../RateableTestUI/buttons/ResetTestButton";

interface DrawItCompleteProps {
  completedWithinSession: boolean;
  onReset: VoidFunction;
  numNonEasyComponents: number;
  redoNonEasyCharacters: VoidFunction;
  nextActivityTitle?: string;
  nextActivityHref?: string;
}

export default function DrawItComplete({
  completedWithinSession,
  onReset,
  numNonEasyComponents,
  redoNonEasyCharacters,
  nextActivityTitle,
  nextActivityHref,
}: DrawItCompleteProps) {
  return (
    <div className="flex w-full flex-1 flex-col items-center justify-center">
      {completedWithinSession && (
        <div className="mb-8">
          <CongratulationsMessage />
        </div>
      )}
      <div className="w-min-content flex w-full flex-col items-center justify-center space-y-4 sm:max-w-[400px]">
        {nextActivityTitle && nextActivityHref && (
          <Button
            color="primary"
            use="a"
            // @ts-ignore
            href={nextActivityHref}
            className="w-full uppercase"
          >
            {nextActivityTitle}
          </Button>
        )}
        {numNonEasyComponents > 0 && (
          <div className="w-full">
            <OutlineButton color="primary" onClick={redoNonEasyCharacters} className="w-full">
              REDO {numNonEasyComponents} NON-EASY CHARACTERS
            </OutlineButton>
          </div>
        )}
        <div className="w-full">
          <ResetTestButton onConfirmReset={onReset} testName="Draw It" className="w-full" />
        </div>
      </div>
    </div>
  );
}
