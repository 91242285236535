import { clsx } from "clsx";
import { ReactNode } from "react";

export default function PopupFacelift(props: {
  onClose(value?: number): void;
  className?: string;
  children: ReactNode;
}) {
  return (
    <>
      <div
        className={clsx(
          "absolute top-14 z-20 overflow-auto rounded-lg bg-surface2 shadow-lg transition-all duration-150",
          props.className,
        )}
      >
        {props.children}
      </div>
      <div className="fixed bottom-0 left-0 right-0 top-0 z-10" onClick={() => props.onClose()}></div>
    </>
  );
}
