import { useEffect, useRef } from "react";
import { debounce } from "@rocket/shared/utils/utils";

/** Tracks scroll progress on a page. Only reports higher values than the original */
export default function useScrollProgress(initialValue = 0, onChange: (progress: number) => void) {
  const progressRef = useRef(initialValue);
  const onChangeCallback = useRef(onChange);

  useEffect(() => {
    const handleScroll = debounce(() => {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
      const progress = (() => {
        const n = Math.floor((scrollTop / (scrollHeight - clientHeight)) * 100);
        if (n > 90) {
          return 100;
        }
        return n;
      })();

      // Only report higher values than the original
      if (progressRef.current < progress) {
        progressRef.current = progress;
        onChangeCallback.current(progress);
      }
    }, 2000);

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
      // Cancel any pending debounced calls
      handleScroll.cancel();
    };
  }, []);
}
