import { Button, ModalDeprecated } from "@rocket/ui";
import { useStoreDispatch, useStoreSelector } from "../../../store";

import API from "@rocket/shared/res/Api";
import { FaTimes } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { LinkButton } from "@rocket/ui/Button";
import classes from "./BadgeNotification.module.scss";
import { clsx } from "clsx";
import { getRandomCongratulationsMessage } from "@rocket/shared/utils";
import { RateableTestLocaleCodeArray } from "@rocket/shared/utils/constants";
import { removeRankUpgradeNotification } from "@rocket/shared/store/user/actions";
import { useCallback } from "react";
import usePromise from "@rocket/shared/hooks/usePromise";
import useTranslation from "@rocket/shared/hooks/useTranslation";

export default function BadgeNotification() {
  const dispatch = useStoreDispatch();
  const newRank = useStoreSelector((store) => store.user.stats.newRank);
  const activeCourse = useStoreSelector((store) => store.preferences.activeCourse);
  const activeProduct = useStoreSelector((store) => store.preferences.activeProduct);
  const location = useLocation();

  const getOwnReviewPromise = useCallback(() => {
    return API.getJson(["v2/reviews/my-review/course/{course}", { course: activeCourse?.id || 0 }]);
  }, [activeCourse]);

  const { state } = usePromise(getOwnReviewPromise);

  const isInTest = RateableTestLocaleCodeArray.some((testName) => location.hash.startsWith(`#${testName}`));

  if (!newRank || state?.status === "loading" || isInTest) {
    return null;
  }

  const removeUpgradeNotification = () => dispatch(removeRankUpgradeNotification());

  return (
    <BadgeNotificationView
      onClose={removeUpgradeNotification}
      // 404 error not getting detected here, so if state is empty (no review passed back), treat it as an error
      promptReview={state?.status === "error" || !state?.data}
      productId={activeProduct?.id || 1}
      courseSlug={activeCourse?.slug || ""}
      newRank={newRank}
    />
  );
}

export interface BadgeNotificationViewProps {
  productId: number;
  courseSlug: string;
  onClose(): void;
  promptReview: boolean;
  newRank: {
    rankName: string;
    badgeUrl: string;
  };
}

export function BadgeNotificationView({
  courseSlug,
  productId,
  onClose,
  promptReview,
  newRank,
}: BadgeNotificationViewProps) {
  const t = useTranslation();
  return (
    <ModalDeprecated.Container isOpen bodyClass="flex-col justify-center items-center mt-4">
      <div className={clsx(classes.notificationContainer, "text-text1 dark:text-black")}>
        <h1 className="text-3xl">{getRandomCongratulationsMessage(courseSlug)}</h1>
        <p>{t("achieved-new-badge")}</p>
        <div className={clsx(classes.notificationContainerItem, classes.notificationImageContainer)}>
          <img src={newRank?.badgeUrl} alt="" />
        </div>
        <h2>{newRank?.rankName}</h2>
        <Button className={classes.crossButton} onClick={onClose} color="primary">
          <FaTimes size={28} />
        </Button>
        {promptReview && (
          <div className={classes.reviewContainer}>
            <h2>{t("loving-the-course")}</h2>

            <LinkButton
              use={Link}
              to={`/members/products/${productId}/reviews/write`}
              onClick={onClose}
              className={classes.reviewButton}
              color="error"
              size="large"
            >
              {t("write-a-review")}
            </LinkButton>

            <button className={classes.dismiss} onClick={onClose}>
              Dismiss
            </button>
          </div>
        )}
      </div>
    </ModalDeprecated.Container>
  );
}
