import TranscriptLinePhrasebox, { TranscriptLinePhraseboxRef } from "./TranscriptLinePhrasebox";
import { memo, useRef } from "react";

import type { Phrase } from "@rocket/types";
import { clsx } from "clsx";
import { secondsToMinutesAndSeconds } from "../../../../utils";
import useMedia from "../../../../hooks/useMedia";

interface TranscriptLineProps {
  index: number;
  activeIndex: number;
  subtitle: TextTrackCue;
  showPhrasebox: boolean;
  phrase?: Phrase;
  player?: ReturnType<typeof useMedia>;
  onClick?: () => void;
}

function TranscriptLine(props: TranscriptLineProps) {
  const { index, activeIndex, phrase, subtitle, player, showPhrasebox } = props;

  const transcriptLinePhrasebox = useRef<TranscriptLinePhraseboxRef>(null);
  const isActive = activeIndex === index;
  const isActiveRecordLine = phrase && isActive && showPhrasebox;

  const handleLineClick = () => {
    if (player?.isPlaying) {
      player?.seekAndPlay(subtitle.startTime + 0.1);
    } else {
      player?.seek(subtitle.startTime + 0.1);
    }

    // Restart the record timeout
    if (isActiveRecordLine) {
      transcriptLinePhrasebox.current?.requeueRecord();
    }
  };

  return (
    <div data-index={index}>
      <div
        className={clsx(
          "grid cursor-pointer grid-cols-iac-transcript-line gap-4 px-4 py-1 text-slate-400 hover:text-text1",
          isActive && "font-bold !text-text1",
          //  phrase && player?.phraseMode === "automatic" && "text-red hover:text-rocketred-dark",
        )}
        onClick={props.onClick || handleLineClick}
      >
        <div>{secondsToMinutesAndSeconds(subtitle.startTime)}</div>
        {/* @ts-ignore */}
        <div>{subtitle.text}</div>
      </div>

      {isActiveRecordLine && (
        <div className="grid grid-cols-iac-transcript-phrasebox gap-4 py-0.5 text-slate-400 hover:text-text1">
          <TranscriptLinePhrasebox ref={transcriptLinePhrasebox} player={player} cue={subtitle} phrase={phrase} />
        </div>
      )}
    </div>
  );
}

const MemoizedTranscriptLine = memo(TranscriptLine);

export default MemoizedTranscriptLine;
