import type {
  APISchema,
  AdminImage,
  AuthSuccessPayload,
  LessonEntity,
  LessonQuickEditPayload,
  UserLessonStatusEntity,
} from "@rocket/types";
import Axios, { AxiosRequestConfig, Method, AxiosResponse, AxiosError } from "axios";

import APIFormData from "./APIFormData";
import { config, sleep } from "../utils";
import { create } from "zustand";

const PROD_API_URL = "https://app.rocketlanguages.com/api";
// Next or CRA API Urls
const apiURLEnv = config("API_URL", PROD_API_URL);
const apiModeEnv = config("MODE", "staging") as ApiMode;
type ApiMode = "production" | "staging" | "local";

const apiUrls = {
  production: PROD_API_URL,
  // Production builds can overwrite the API URL (e.g. staff.rl may use "alpha.rl/api")
  // However, if there is no apiURLEnv configured, we'll fall back to PROD_API_URL
  [apiModeEnv]: apiURLEnv || PROD_API_URL,
};

/** State management for managing the API URL */
export const useApiUrl = create<{ mode: ApiMode; toggle(): void }>((set) => {
  const apiMode = (() => {
    if (typeof window !== "undefined" && window?.localStorage) {
      const mode = window.localStorage.getItem("api_mode");
      if (mode) {
        return mode as ApiMode;
      }
    }
    return apiModeEnv;
  })();

  return {
    mode: apiMode in apiUrls ? apiMode : apiModeEnv,
    toggle: () =>
      set((s) => ({
        mode: s.mode === "production" ? apiModeEnv : "production",
      })),
  };
});

useApiUrl.subscribe((newState) => {
  const url = apiUrls[newState.mode];
  if (url) {
    Client.setBaseUrl(url);
  }
  if (window?.localStorage) {
    window.localStorage.setItem("api_mode", newState.mode);
  }
});

export const getApiUrl = () => {
  const { mode } = useApiUrl.getState();
  return apiUrls[mode] || apiURLEnv || apiUrls.production;
};

export type ReplacerUrlTuple<T> = [T, Record<string, string | number>];

function getRequestUrl(urlOrReplacer: string | ReplacerUrlTuple<string>) {
  if (typeof urlOrReplacer === "string") {
    return urlOrReplacer;
  }
  const [url, replacers] = urlOrReplacer;
  let builtUrl: string = url;
  const replacerArray = Object.keys(replacers);
  for (const key of replacerArray) {
    const value = replacers[key];
    builtUrl = builtUrl.replace(`{${key}}`, String(value));
  }
  return builtUrl;
}

function getRequestConfig(
  method: Method,
  payload: [string | ReplacerUrlTuple<string>] | [string | ReplacerUrlTuple<string>, any],
  controller?: AbortController,
): AxiosRequestConfig {
  const [urlOrReplacer, paramsOrData] = payload;

  const config: AxiosRequestConfig = {
    method,
    url: getRequestUrl(urlOrReplacer),
    signal: controller?.signal,
  };

  // Custom multipart/form-data uploads
  // NOTE: this should be method === "POST" (as multipart/form-data only works on POST requests)
  if (paramsOrData instanceof APIFormData) {
    // React Native's APIFormData is a regular object
    if (paramsOrData.formData instanceof FormData) {
      config.headers = { "Content-Type": "multipart/form-data" };
      config.data = paramsOrData.formData;
    } else {
      config.data = paramsOrData.formData;
    }
    return config;
  }

  if (method === "GET") {
    config.params = paramsOrData;
  } else {
    config.data = paramsOrData;
  }

  return config;
}

const fakePayloadResponse = async <T>(fakedPayload: { status?: number; data: T; $delay?: number }) => {
  if (fakedPayload.$delay) {
    // fake loading indefinitely
    await sleep(fakedPayload.$delay);
  }

  const response = {
    data: fakedPayload.data,
    status: fakedPayload.status || 200,
    statusText: "ok, faked",
    headers: {},
    config: {},
  } as AxiosResponse<T>;

  if (response.status >= 300) {
    return Promise.reject(
      new AxiosError(
        `Request failed with status code ${fakedPayload.status}`,
        undefined,
        undefined,
        undefined,
        response,
      ),
    );
  }
  return Promise.resolve(response);
};

export const Client = {
  axios: Axios.create({
    timeout: 60000,
    baseURL: getApiUrl(),
    headers: {
      "Content-Type": "application/json",
    },
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "X-XSRF-TOKEN",
    withCredentials: true,
  }),
  controller: undefined as AbortController | undefined,
  fakeMap: new Map<
    string,
    {
      /** The status code, >= 300 will throw an Axios error */
      status?: number;
      /** An indicator to delay the response */
      $delay?: number;
      data: unknown;
    }
  >(),
  fake<T extends keyof APISchema["GET"]>(
    url: T,
    payload: {
      /** The status code, >= 300 will throw an Axios error */
      status?: number;
      /** An indicator to delay the response */
      $delay?: number;
      data: APISchema["GET"][T]["response"];
    },
  ) {
    this.fakeMap.set(url, payload);
  },
  setBaseUrl(baseURL: string) {
    Client.axios.defaults.baseURL = baseURL;
  },
  setToken(token?: string) {
    Client.axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : "";
  },
  /** Creates a new API client with an abort signal so that subsequent requests can get cancelled */
  abortSignal: () => {
    const controller = new AbortController();
    return { ...Client, controller, abort: controller.abort };
  },
  get: function <T extends keyof APISchema["GET"], TParams extends APISchema["GET"][T]["params"]>(
    ...payload: TParams extends undefined ? [T | ReplacerUrlTuple<T>] : [T | ReplacerUrlTuple<T>, TParams]
  ) {
    const url = typeof payload[0] === "string" ? payload[0] : payload[0][0];
    const fakedPayload = this.fakeMap.get(url) as
      | { status?: number; data: APISchema["GET"][T]["response"] }
      | undefined;
    if (fakedPayload) {
      return fakePayloadResponse(fakedPayload);
    }
    return Client.axios.request<APISchema["GET"][T]["response"]>(getRequestConfig("GET", payload, Client.controller));
  },
  getJson: function <T extends keyof APISchema["GET"], TParams extends APISchema["GET"][T]["params"]>(
    ...payload: TParams extends undefined ? [T | ReplacerUrlTuple<T>] : [T | ReplacerUrlTuple<T>, TParams]
  ): Promise<APISchema["GET"][T]["response"]> {
    const url = typeof payload[0] === "string" ? payload[0] : payload[0][0];
    const fakedPayload = this.fakeMap.get(url) as
      | { status?: number; data: APISchema["GET"][T]["response"] }
      | undefined;
    if (fakedPayload) {
      return fakePayloadResponse(fakedPayload).then((res) => res.data);
    }
    return Client.get(...payload).then((res) => res.data);
  },
  post: function <T extends keyof APISchema["POST"], TParams extends APISchema["POST"][T]["params"]>(
    ...payload: TParams extends undefined ? [T | ReplacerUrlTuple<T>] : [T | ReplacerUrlTuple<T>, TParams]
  ) {
    return Client.axios.request<APISchema["POST"][T]["response"]>(getRequestConfig("POST", payload, Client.controller));
  },
  postJson: function <T extends keyof APISchema["POST"], TParams extends APISchema["POST"][T]["params"]>(
    ...payload: TParams extends undefined ? [T | ReplacerUrlTuple<T>] : [T | ReplacerUrlTuple<T>, TParams]
  ): Promise<APISchema["POST"][T]["response"]> {
    return Client.post(...payload).then((res) => res.data);
  },
  put: function <T extends keyof APISchema["PUT"], TParams extends APISchema["PUT"][T]["params"]>(
    ...payload: TParams extends undefined ? [T | ReplacerUrlTuple<T>] : [T | ReplacerUrlTuple<T>, TParams]
  ) {
    return Client.axios.request<APISchema["PUT"][T]["response"]>(getRequestConfig("PUT", payload, Client.controller));
  },
  putJson: function <T extends keyof APISchema["PUT"], TParams extends APISchema["PUT"][T]["params"]>(
    ...payload: TParams extends undefined ? [T | ReplacerUrlTuple<T>] : [T | ReplacerUrlTuple<T>, TParams]
  ): Promise<APISchema["PUT"][T]["response"]> {
    return Client.put(...payload).then((res) => res.data);
  },
  delete: function <T extends keyof APISchema["DELETE"], TParams extends APISchema["DELETE"][T]["params"]>(
    ...payload: TParams extends undefined ? [T | ReplacerUrlTuple<T>] : [T | ReplacerUrlTuple<T>, TParams]
  ) {
    return Client.axios.request<APISchema["DELETE"][T]["response"]>(
      getRequestConfig("DELETE", payload, Client.controller),
    );
  },
};

export default Client;

export function updateFlashcardCollection(collectionId: string | number, payload: any) {
  return Client.axios.post(`v2/custom-flashcards/${collectionId}/update`, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function createFlashcardCollection(courseId: number, payload: any) {
  return Client.axios.post(`v2/custom-flashcards/course/${courseId}`, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getPDF(lessonId: number) {
  return Client.axios(`lesson/${lessonId}/generatePDF`, {
    method: "GET",
    responseType: "blob",
  });
}

export function downloadCourseVocabCsv(courseId: number) {
  return Client.axios(`v2/vocab/course/${courseId}/export_csv`, {
    method: "GET",
    responseType: "blob",
  });
}

export function approveAccountAdmin(
  accountAdmin: number,
  signature: string,
  payload:
    | {
        approved: 1 | 0;
      }
    | {
        approved: 1 | 0;
        password: string;
      },
) {
  return Client.axios.post<AuthSuccessPayload | undefined>(
    `v2/signed/multiuser-account-admin/${accountAdmin}/approve?signature=${signature}`,
    payload,
  );
}

export function uploadAvatar(payload: FormData) {
  return Client.axios.post("v2/user/upload-avatar", payload, { headers: { "Content-Type": "multipart/form-data" } });
}

export function updateProfile(payload: FormData, userId: number) {
  return Client.axios.post(`v2/user/${userId}/update`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function uploadRocketRecommendsImage(payload: FormData) {
  return Client.axios.post<AdminImage>("v2/admin/recommended-products/upload-image", payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

/** This is used to send through an empty array for the lesson tags
 * (instead of this field being filtered out by the regular API function)
 * in order to overwrite tags in the database */
export function asyncUpdateLessonTags(
  payload: Partial<LessonEntity> & {
    tags?: { id: number; notes?: string }[];
  },
  lessonId: number,
) {
  return Client.axios.put<LessonEntity>(`v2/admin/lesson/${lessonId}`, payload, {
    headers: { "Content-Type": "application/json" },
  });
}

export function asyncUpdateMultipleLessonTags(payload: { lessons: Array<LessonQuickEditPayload & { id: number }> }) {
  return Client.axios.put<LessonEntity[]>(`v2/admin/lessons`, payload, {
    headers: { "Content-Type": "application/json" },
  });
}

export function asyncUpdateLessonProductPermissions(payload: { product_type_ids: number[] }, lessonId: number) {
  return Client.axios.put<{
    product_type_ids: number[];
    user_lesson_status: UserLessonStatusEntity;
  }>(`v2/admin/lesson/${lessonId}/permissions`, payload, {
    headers: { "Content-Type": "application/json" },
  });
}

export function clearApplicationCache() {
  return Client.axios.get(`v2/admin/server/clear-cache`, {
    headers: { "Content-Type": "application/json" },
  });
}
