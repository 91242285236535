import * as authActions from "./actions";
import * as constants from "./constants";
import * as preferencesActions from "../preferences/actions";

import { SagaReturnType, call, put, takeLatest } from "redux-saga/effects";
import authSharedSagas, { handleAuthError } from "@rocket/shared/store/auth/sagas";
import { history, replace } from "@rocket/shared/res/router";

import API from "@rocket/shared/res/Api";
import { AuthSuccessPayload } from "@rocket/types";
import { AxiosResponse } from "axios";
import Courses from "@rocket/shared/res/courses";
import { PromiseCache } from "@rocket/shared/hooks/usePromise";

/**
 * User logs in
 * Triggered from: autologin.tsx
 */
function* autologin(request: () => Promise<AxiosResponse<AuthSuccessPayload>>) {
  try {
    const response: SagaReturnType<typeof request> = yield call(request);

    // if guest, set the active product to the first trial product so that hitting the courses page redirects to the dashboard
    if (response.data.user.isGuest) {
      // get lowest level course in products
      const productMeta = response.data.user.products.trial[0];
      if (productMeta) {
        const { course_id: courseId } = productMeta;
        const course = Courses.find((_course) => _course.id === courseId);
        if (course) {
          // products in course are ordered by their level, unlike in the response data
          const lowestProduct = course.products.find((_product) =>
            response.data.user.products.trial.some((_trial) => _trial.id === _product.id),
          );
          if (lowestProduct) {
            yield put(preferencesActions.setActiveCourseAndProduct(course, lowestProduct));
          }
        }
      }
    }

    // Successful login, set status to "loggedin"
    yield put(
      authActions.success({
        data: response.data,
        source: "login",
      }),
    );

    if (response.data.redirect_url) {
      // Redirect to supplied url
      replace(response.data.redirect_url);
    } else if (response.data.onboarding) {
      const { product_id } = response.data.onboarding;
      //   // disable re-routing for ebook and eigo
      //   const tutorialDisabled = (() => {
      //     //eigo
      //     if (course_id === 19) return true;
      //     //ebook
      //     if (course_id === 33) return true;
      //     return false;
      //   })();

      //   if (tutorialDisabled) {
      //     push("/members/courses");
      //     return;
      replace(`/members/products/${product_id}/dashboard`);
    } else {
      replace("/members/courses");
    }
  } catch (error) {
    yield handleAuthError(error, "login");
  }
}

/**
 * First time login
 */
function* asyncLoginHashRequest(action: ReturnType<typeof authActions.asyncLoginHashRequest>) {
  const request = () => API.get("v2/first-time-login", { h: action.payload.hash });
  yield autologin(request);
}

/**
 * First time login, from rockgeneral_0 list
 */
function* asyncAutoLoginEmailRequest(action: ReturnType<typeof authActions.asyncAutoLoginEmailRequest>) {
  const request = () => API.get(["v2/autologin-email/{user:email}", { "user:email": action.payload.email }]);
  yield autologin(request);
}

/**
 * Login with signed url route
 */
function* asyncAutoLoginRequest(action: ReturnType<typeof authActions.asyncAutoLoginRequest>) {
  // Replace "/api/v2/autologin" => "/v2/autologin" (as our baseURL already contains app.rl.com/api)
  const requestUrl = action.payload.url.replace("/api", "");
  const request = () => API.axios.get(requestUrl);
  yield autologin(request);
}

function onAuthSuccess(action: ReturnType<typeof authActions.success>) {
  // Note: auth token is set in shared sagas
  // Inspectlet
  /**
  if (window.__insp && typeof window.__insp.push === "function") {
    window.__insp.push(["identify", action.payload.data.user.profile.email]);
  }
  */
  if (action.payload.source === "signup") {
    history.push("/members/courses");
  }
}

// Any watchers go in here. They get forked in the Root Saga
const webAuthSagas = [
  ...authSharedSagas,
  takeLatest(constants.ASYNC_AUTO_LOGIN_REQUEST, asyncAutoLoginRequest),
  takeLatest(constants.ASYNC_LOGIN_HASH_REQUEST, asyncLoginHashRequest),
  takeLatest(constants.ASYNC_AUTO_LOGIN_EMAIL_REQUEST, asyncAutoLoginEmailRequest),
  takeLatest(constants.SUCCESS, onAuthSuccess),
  takeLatest(constants.LOGOUT, () => {
    window?.localStorage.removeItem("persist:app");
    PromiseCache.clear(); // Clear any cached data
  }),
];

export default webAuthSagas;
