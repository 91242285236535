export default function WriteItIcon({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_838_5079)">
        <path
          d="M3.39045 20.5356L2.59878 21.6664C2.5467 21.7406 2.50737 21.8228 2.48144 21.91L1.72075 24.4677C1.61704 24.8155 1.74756 25.1908 2.04529 25.3991C2.34302 25.6076 2.74006 25.6021 3.03164 25.3857L5.16496 23.8032C4.12939 23.0702 3.44604 21.8848 3.39045 20.5356Z"
          fill="currentColor"
        />
        <path
          d="M27.9553 12.7695H25.4238V25.4255C25.4238 26.0529 26.0839 26.4609 26.6451 26.1803L28.4578 24.8358C28.6725 24.6767 28.7991 24.4251 28.7991 24.1582V13.6133C28.7991 13.1472 28.4213 12.7695 27.9553 12.7695Z"
          fill="currentColor"
        />
        <path
          d="M16.8421 2.79648C16.46 2.52885 15.9344 2.62157 15.6668 3.00368L14.2148 5.07724L16.9797 7.01325L18.4316 4.93969C18.6993 4.55758 18.6065 4.032 18.2244 3.76437L16.8421 2.79648Z"
          fill="currentColor"
        />
        <path
          d="M6.31055 16.3652C6.71704 16.2345 7.14221 16.1448 7.59156 16.1448H10.5851L16.0111 8.39597L13.2462 6.45996L6.31055 16.3652Z"
          fill="currentColor"
        />
        <path
          d="M6.04377 9.25739C5.57729 9.45997 5.18749 9.79286 4.91481 10.2222L0.264737 17.5439C-0.317979 18.71 0.0955469 20.1066 1.16891 20.7961C1.18847 20.7654 1.19484 20.7291 1.21571 20.6992L10.2867 7.74413C9.94809 7.72369 9.60203 7.74522 9.25332 7.863L6.04377 9.25739Z"
          fill="currentColor"
        />
        <path
          d="M6.74805 24.4976V25.0049C6.74805 25.7039 7.31472 26.2705 8.0139 26.2705C8.71285 26.2705 9.27953 25.7039 9.27953 25.0049V24.583H7.5918C7.30308 24.583 7.02095 24.5531 6.74805 24.4976Z"
          fill="currentColor"
        />
        <path
          d="M10.9668 24.5832V25.0051C10.9668 25.7041 11.5335 26.2707 12.2324 26.2707C12.8516 26.2707 13.3431 25.8174 13.4528 25.2299C13.1779 25.1161 12.8986 25.0132 12.6321 24.8814L12.047 24.583H10.9668V24.5832Z"
          fill="currentColor"
        />
        <path
          d="M23.7369 12.7699H22.1883L16.8263 10.1738L12.6976 16.0706C13.7242 15.9111 14.6947 15.4435 15.4334 14.7046C15.763 14.375 16.2972 14.375 16.6267 14.7046C16.9563 15.0342 16.9563 15.5683 16.6267 15.8979C15.3972 17.1273 13.6947 17.8328 11.956 17.8328C11.0481 17.8328 7.3323 17.8328 7.59202 17.8328C6.24026 17.8328 5.06055 18.9306 5.06055 20.3641C5.06055 21.7651 6.19126 22.8955 7.59202 22.8955H12.4524C12.8576 23.054 15.1119 24.5833 18.5617 24.5833C20.3076 24.5833 22.1634 24.1752 23.7369 23.4185V12.7699Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_838_5079">
          <rect width="28.8" height="28.8" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
