import styles from "./RatingCircle.module.scss";
import { clsx } from "clsx";

/**
 * Gets the rating level [0-3] of a rateable test
 * @param {number} percentage Rating between 0-100
 */
function getRatingLevel(percentage: number) {
  if (percentage >= 90) {
    return 3;
  }
  if (percentage >= 50) {
    return 2;
  }
  if (percentage > 0) {
    return 1;
  }
  return 0;
}

const ratingLabels = ["Todo", "Hard", "Good", "Easy"];

interface RatingCircleProps {
  rating: number;
  useRatingText?: boolean;
}

export default function RatingCircle({ rating, useRatingText }: RatingCircleProps) {
  const ratingText = (() => {
    if (useRatingText) {
      const roundedRating = getRatingLevel(rating);
      if (ratingLabels[roundedRating]) {
        return ratingLabels[roundedRating];
      }
    }
    return rating ? Math.round(rating) : 0;
  })();

  return (
    <div
      className={clsx(
        styles.ratingIcon,
        !rating ? "bg-brand2 text-white dark:bg-brand" : `level-${getRatingLevel(rating)}`,
        useRatingText && styles.ratingSmall,
      )}
    >
      {ratingText}
    </div>
  );
}
