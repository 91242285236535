import {
  FaBook,
  FaBookOpen,
  FaComment,
  FaGlobe,
  FaGlobeAmericas,
  FaHeartbeat,
  FaLanguage,
  FaRegKeyboard,
  FaSuitcase,
  FaVideo,
  FaVolumeUp,
  FaEyeSlash,
} from "react-icons/fa";
import type { LessonTypeCode, LessonTypeShortCode, RateableTestLocaleKey } from "@rocket/types";

import type { IconType } from "react-icons/lib";
import { RateableTestTypeIds } from "./constants";
import { RiNewspaperLine } from "react-icons/ri";
import HearItIcon from "../res/icons/HearItIcon";
import WriteItIcon from "../res/icons/WriteItIcon";
import KnowItIcon from "../res/icons/KnowItIcon";
import QuizIcon from "../res/icons/QuizIcon";
import FlashcardsIcon from "../res/icons/FlashcardsIcon";
import TranslateItIcon from "../res/icons/TranslateItIcon";
import SortItIcon from "../res/icons/SortItIcon";
import DrawItIcon from "../res/icons/DrawItIcon";

export type FilterType = {
  [key in LessonTypeCode]?: {
    type_id: number;
    code: LessonTypeCode;
    shortCode?: LessonTypeShortCode;
    icon: IconType;
    label: string;
    shortLabel: string;
  };
};

export const lessonTypeIcons: { [key in LessonTypeCode | "default"]: IconType } = {
  default: FaVolumeUp,
  "interactive-audio-course": FaVolumeUp,
  "language-and-culture": FaGlobeAmericas,
  "survival-kit": FaSuitcase,
  "sign-language-lessons": FaHeartbeat,
  "video-lesson": FaVideo,
  "esl-conversations-vocab": FaComment,
  "esl-language-lessons": FaLanguage,
  writing: FaRegKeyboard,
  travelogue: FaGlobe,
  "ebook-chapter": FaBook,
  newsletter: RiNewspaperLine,
  onboarding: FaEyeSlash,
  "play-the-part": FaComment,
};

/** Codes to map to translations */
export const filters: FilterType = {
  "interactive-audio-course": {
    type_id: 1,
    code: "interactive-audio-course",
    shortCode: "interactive-audio",
    icon: FaVolumeUp,
    label: "Audio Lessons",
    shortLabel: "Audio Lessons",
  },
  "language-and-culture": {
    type_id: 2,
    code: "language-and-culture",
    shortCode: "language-and-culture-short",
    icon: FaGlobeAmericas,
    label: "Language & Culture Lessons",
    shortLabel: "Language & Culture Lessons",
  },
  writing: {
    type_id: 6,
    code: "writing",
    shortCode: "writing-short",
    icon: FaRegKeyboard,
    label: "Writing Lessons",
    shortLabel: "Writing Lessons",
  },
  "survival-kit": {
    type_id: 3,
    code: "survival-kit",
    icon: FaSuitcase,
    label: "Survival Kit & Review",
    shortLabel: "Survival Kit Lessons",
  },
  travelogue: {
    type_id: 43,
    code: "travelogue",
    shortCode: "travelogue-short",
    icon: FaGlobe,
    label: "Travelogues",
    shortLabel: "Travelogues",
  },
  "sign-language-lessons": {
    type_id: 42,
    code: "sign-language-lessons",
    shortCode: "sign-language",
    icon: FaHeartbeat,
    label: "Sign Language Lessons",
    shortLabel: "Sign Language",
  },
  "video-lesson": {
    type_id: 7,
    code: "video-lesson",
    shortCode: "video",
    icon: FaVideo,
    label: "Video Lessons",
    shortLabel: "Video",
  },
  "esl-conversations-vocab": {
    type_id: 9,
    code: "esl-conversations-vocab",
    shortCode: "esl-conversations",
    icon: FaComment,
    label: "ESL Conversations Lessons",
    shortLabel: "ESL Conversations",
  },
  "esl-language-lessons": {
    type_id: 10,
    code: "esl-language-lessons",
    shortCode: "esl-language",
    icon: FaLanguage,
    label: "ESL Language Lessons",
    shortLabel: "ESL Language",
  },
  "ebook-chapter": {
    type_id: 47,
    code: "ebook-chapter",
    shortCode: "ebook",
    icon: FaBook,
    label: "Learn a Language Extraordinarily Fast",
    shortLabel: "Audio Book Chapters",
  },
  newsletter: {
    type_id: 5,
    code: "newsletter",
    shortCode: "newsletter",
    icon: RiNewspaperLine,
    label: "Free Resource",
    shortLabel: "Free Resource",
  },
  onboarding: {
    type_id: 48,
    code: "onboarding",
    shortCode: "onboarding",
    icon: FaEyeSlash,
    label: "Onboarding",
    shortLabel: "Onboarding",
  },
  "play-the-part": {
    type_id: 49,
    code: "play-the-part",
    shortCode: "play-the-part",
    icon: FaComment,
    label: "Play the Part",
    shortLabel: "Play the Part",
  },
};

export const LessonIconsByTypeId: { [key: number]: IconType } = {
  // IAC
  1: FaVolumeUp,
  // Language&Culture
  2: FaGlobeAmericas,
  // Survival Kit
  3: FaSuitcase,
  // Writing
  6: FaRegKeyboard,
  // Video Lesson
  7: FaVideo,
  // ESL Conversation Vocab
  9: FaComment,
  // ESL Language Lesson
  10: FaLanguage,
  // Sign Language Lessons
  42: FaHeartbeat,
  // Travelogue
  43: FaGlobe,
  // eBook
  47: FaBookOpen,
};

type RateableTestMetadataType = {
  [id: number]: {
    icon: React.FunctionComponent<{ className?: string }>;
    localeKey: RateableTestLocaleKey;
  };
};

/**
 * Metadata about the rateable tests, indexed by rateable test type ID
 */
export const RateableTestMetadata: RateableTestMetadataType = {
  [RateableTestTypeIds.HEAR_IT]: {
    icon: HearItIcon,
    localeKey: "hear-it",
  },
  [RateableTestTypeIds.WRITE_IT]: {
    icon: WriteItIcon,
    localeKey: "write-it",
  },
  [RateableTestTypeIds.WRITE_IT_NATIVE]: {
    icon: WriteItIcon,
    localeKey: "write-it-native",
  },
  [RateableTestTypeIds.KNOW_IT]: {
    icon: KnowItIcon,
    localeKey: "know-it",
  },
  [RateableTestTypeIds.QUIZ]: {
    icon: QuizIcon,
    localeKey: "quiz",
  },
  [RateableTestTypeIds.FLASHCARDS]: {
    icon: FlashcardsIcon,
    localeKey: "flashcard",
  },
  [RateableTestTypeIds.TRANSLATE_IT]: {
    icon: TranslateItIcon,
    localeKey: "translate-it",
  },
  [RateableTestTypeIds.SORT_IT]: {
    icon: SortItIcon,
    localeKey: "sort-it",
  },
  [RateableTestTypeIds.SORT_IT_NATIVE]: {
    icon: SortItIcon,
    localeKey: "sort-it-native",
  },
  [RateableTestTypeIds.DRAW_IT]: {
    icon: DrawItIcon,
    localeKey: "draw-it",
  },
};
