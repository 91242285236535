import { LessonTagEntity } from "@rocket/types";
import dayjs from "dayjs";
import Markdown from "markdown-to-jsx";
import { useState } from "react";
import { FaTimes } from "react-icons/fa";

export default function TagNote(props: { tag: LessonTagEntity }) {
  const [hidden, setHidden] = useState(false);
  if (hidden) {
    return null;
  }
  return (
    <div className={"mb-4 flex flex-col gap-4 rounded-lg bg-rocketgreen-extra-light p-6 text-text2 dark:bg-surface2"}>
      <div className="flex items-center justify-between gap-2">
        <div>
          <strong className="mr-2">Latest lesson update</strong>
          {dayjs(props.tag.created_at).format("MMM D, YYYY")}
        </div>

        <button
          type="button"
          data-tooltip-focus="Close"
          aria-label="Close"
          tabIndex={0}
          className="flex h-8 w-10 items-center justify-center rounded-lg text-slate-500 hover:text-black dark:hover:text-white"
          onClick={() => setHidden(true)}
        >
          <FaTimes aria-hidden />
        </button>
      </div>

      {props.tag.notes ? <Markdown>{props.tag.notes}</Markdown> : null}
    </div>
  );
}
