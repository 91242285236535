import type { APIResource, LessonComponentUnion, UserLessonStatusEntity } from "@rocket/types";
import type { RootState } from "~/store/types";

/**
 * Lesson Screen selector
 * Purpose: prepares data from Redux state to display in the Lesson Screen
 */
import { createSelector } from "reselect";

export type LessonSelectorReturn = APIResource<{
  readonly components: LessonComponentUnion[];
  status: UserLessonStatusEntity;
}>;

function userLessonStatus(state: RootState, props: { lessonId: number }) {
  return state.lesson.entities.user_lesson_status[props.lessonId];
}

function componentsSelector(state: RootState, props: { lessonId: number }) {
  return state.lesson.entities.lesson_components[props.lessonId];
}

function errorSelector(state: RootState, props: { lessonId: number }) {
  return state.lesson.error[props.lessonId];
}

function lessonLoadingSelector(state: RootState, props: { lessonId: number }) {
  return state.lesson.loading[props.lessonId];
}

export default createSelector(
  [errorSelector, lessonLoadingSelector, componentsSelector, userLessonStatus],
  (error, loading, components, status): LessonSelectorReturn => {
    if (error) {
      return {
        status: "error",
        errorText: error.message,
        errorCode: error.code,
      };
    }

    // Either no network request has started yet, or it's still loading
    if (!components || loading || !status) {
      return {
        status: "loading",
      };
    }

    // Get the specific lesson inside the product...
    return {
      status: "loaded",
      data: {
        status,
        components,
      },
    };
  },
);
