import { Component } from "react";
import type { ReactNode } from "react";
import { Button, ErrorMessage } from "@rocket/ui";
import * as Sentry from "@sentry/react";

interface Props {
  /** Required. When changed, React will replace the component with a new instance */
  key: number | string;
  FallbackElement?: ReactNode;
  onError?: (error: any, info: any) => void;
}

interface State {
  error: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
  // [React lifecycle] Update state so the next render will show the fallback UI.
  static getDerivedStateFromError() {
    return { error: true };
  }

  state = {
    error: false,
  };

  // [React lifecycle] a child component throws an error
  componentDidCatch(error: any, info: any) {
    if (import.meta.env.PROD) {
      Sentry.withScope((scope) => {
        scope.setExtra("componentStack", info);
        Sentry.captureException(error);
      });
    }
    // Notify parent component
    if (this.props.onError) {
      this.props.onError(error, info);
    }
  }

  render() {
    if (this.state.error) {
      return this.props.FallbackElement || <DefaultFallbackElement />;
    }

    return this.props.children;
  }
}

export function SentryErrorBoundary(props: { children: ReactNode; fallback?: JSX.Element }) {
  return (
    <Sentry.ErrorBoundary fallback={props.fallback || <DefaultFallbackElement />}>
      {props.children}
    </Sentry.ErrorBoundary>
  );
}

function DefaultFallbackElement() {
  return (
    <ErrorMessage
      title="Whoops!"
      message="There was a problem loading the page. Please try again."
      actions={
        <Button color="primary" onClick={() => window.location.reload()}>
          Reload
        </Button>
      }
    />
  );
}
