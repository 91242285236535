import type { FormEvent } from "react";
import UnderlinedLink from "~/components/ui/UnderlinedLink";
import { Button, FaceliftInput } from "@rocket/ui";
import { asyncLoginRequest } from "@rocket/shared/store/auth/actions";
import { useDispatch } from "react-redux";
import { useStoreSelector } from "../../../store";
import useTranslation from "@rocket/shared/hooks/useTranslation";

export default function LoginForm() {
  const t = useTranslation();
  const dispatch = useDispatch();
  const auth = useStoreSelector((store) => store.auth);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload = new FormData(e.currentTarget);
    dispatch(
      asyncLoginRequest({
        email: payload.get("email") as string,
        password: payload.get("password") as string,
      }),
    );
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col gap-4 rounded-lg bg-rocketorange-light px-6 py-10 dark:bg-neutral-800"
    >
      <FaceliftInput type="email" label={t("email-address")} name="email" required />
      <FaceliftInput
        type="password"
        label={t("password")}
        name="password"
        autoComplete="current-password"
        required
        minLength={3}
        maxLength={255}
      />

      {auth.requester === "login" && auth.errorMessage && (
        <div className="my-3 text-rocketred">{auth.errorMessage}</div>
      )}

      <div className="uppercase">
        <UnderlinedLink to="/forgot-password">{t("forgot-password")}</UnderlinedLink>
      </div>

      <div className="flex justify-center">
        <Button
          className="h-12 w-56 uppercase"
          type="submit"
          loading={auth.requester === "login" && auth.loading}
          color="primary"
        >
          {t("log-in")}
        </Button>
      </div>
    </form>
  );
}
