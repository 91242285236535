import "react-toastify/dist/ReactToastify.css";

import { useEffect, useState, Suspense } from "react";

import AppProvider from "@rocket/shared/components/AppProvider";
import AppRoutes from "./AppRoutes";
import { PersistGate } from "redux-persist/integration/react";
import { Persistor } from "redux-persist";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import configureStore from "./store/store";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Centered, CircularProgress } from "@rocket/ui";
import ColorSchemeListener from "./components/ColorSchemeListener";
import StagingApiToggle from "@rocket/shared/components/StagingApiToggle";
import { ZendeskWidget } from "./components/ZendeskWidget";

// Configure dayjs

dayjs.extend(relativeTime);

const { store, persistor } = configureStore();

/**
 * Library users log in through an iframe on the main site (www)
 * e.g. https://www.rocketlanguages.com/?libID=ZGNlZDhlZ
 *
 * This makes sure that they get redirected to the app when logged in
 */
if (typeof window !== "undefined" && window.self !== window.top && window.top) {
  window.top.location.href = window.self.location.href;
}

export default function App() {
  return (
    <PurgeState persistor={persistor}>
      <ColorSchemeListener />
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ToastContainer position="bottom-right" style={{ zIndex: 9999999 }} autoClose={5000} />
          <ZendeskWidget />
          <Suspense
            fallback={
              <Centered horizontal>
                <CircularProgress />
              </Centered>
            }
          >
            <AppProvider>
              <AppRoutes />
            </AppProvider>
          </Suspense>
          {(!import.meta.env.PROD || window.location.href.includes("vercel.app")) && (
            <div className="hidden sm:flex">
              <StagingApiToggle />
            </div>
          )}
        </PersistGate>
      </Provider>
    </PurgeState>
  );
}

const shouldPurgeState = window.location.href.includes("/autologin") || window.location.href.includes("/redeem");

/** Purges state if the user is on the autologin page */
function PurgeState(props: { children: React.ReactNode; persistor: Persistor }) {
  const [purging, setPurging] = useState(shouldPurgeState);

  useEffect(() => {
    if (purging) {
      // Logout (clear user state)
      store.dispatch({ type: "Auth/LOGOUT", payload: false });
      // Purge localstorage
      persistor.purge().finally(() => setPurging(false));
    }
  }, [purging]);

  if (purging) {
    return <div />;
  }

  return <>{props.children}</>;
}
