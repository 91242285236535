import { useCallback, useRef, useState } from "react";

import API from "../res/Api";
import type { NoteType } from "@rocket/types";
import { getErrorMessage } from "../utils";
import { toast } from "react-toastify";
import useDebounceTruthy from "./useDebounceTruthy";
import useGetter from "./useGetter";
import useTimeout from "./useTimeout";

interface UseNoteEditorProps {
  id: number;
  /** Are we saving the note's id, or the note on a lesson? */
  idType: "note" | "lesson";
  text: string;
  onUpdate(note: NoteType | undefined): void;
  /** Whether we should be doing API requests to save the note */
  allowSave?: boolean;
}

/** Saves the note when the input text changes */
export default function useNoteEditor({ id, idType, text, onUpdate, allowSave = true }: UseNoteEditorProps) {
  const [saving, setSaving] = useState(false);
  const debouncedSaving = useDebounceTruthy(saving, 400);
  const savingTextRef = useRef<string>(text);
  const getOnUpdate = useGetter(onUpdate);
  const hasTextChanged = savingTextRef.current !== text;

  const editNote = useCallback(() => {
    const promise = (() => {
      if (idType === "note") {
        return () => API.put(["v2/notes/{note}", { note: id }], { id, text });
      }
      return () => API.post(["v2/notes/lesson/{lesson}", { lesson: id }], { text });
    })();

    savingTextRef.current = text;

    setSaving(true);

    promise()
      .then((res) => getOnUpdate()(res.data))
      .catch((err) => {
        console.warn(err);
        toast.error(getErrorMessage(err));
      })
      .finally(() => setSaving(false));
  }, [getOnUpdate, id, idType, text]);

  useTimeout(editNote, allowSave && hasTextChanged ? 400 : null);

  return { saving: debouncedSaving };
}
