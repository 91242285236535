import { create } from "zustand";
import { RRStateFlag } from "../../../../hooks/useRocketRecord/types";

export const useSliderProgress = create<{
  [key: `seeking_${number}`]: boolean;
  [key: `id_${number}`]: number;
}>(() => ({}));

interface GlobalTranscriptRocketRecordState {
  flag: RRStateFlag | null;
  isRecording(playerId?: number): boolean;
  activePlayerId: number;
}

/**
 * Represents the rocket record state of transcripts
 */
export const useGlobalTranscriptRocketRecordState = create<GlobalTranscriptRocketRecordState>((set, get) => ({
  activePlayerId: -1,
  flag: null,
  isRecording(playerId) {
    const { flag, activePlayerId } = get();
    const recording = Boolean(flag && !["INACTIVE", "ABORTED", "ERROR"].includes(flag.status));
    if (playerId) {
      return playerId === activePlayerId && recording;
    }
    return recording;
  },
}));
