import { RateableTestTypeIds, RomanizedWritingSystemIds, WritingSystemIds } from "../../../utils/constants";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import usePhraseTest, { usePhraseTestProps } from "../../../hooks/usePhraseTest/usePhraseTest";
import { useSharedDispatch, useSharedSelector } from "../../../store";

import Flashcard from "./includes/Flashcard";
import Instructions from "./includes/Instructions";
import LessonContext from "../../../context/LessonContext";
import RateableTest from "../RateableTest";
import RateableTestContext from "../includes/context";
import RatingButtons from "../RateableTestUI/buttons/RatingButtons";
import Settings from "./includes/Settings";
import { clsx } from "clsx";
import { requestAddPoints } from "../../../store/lesson/actions";
import styles from "./includes/Flashcard.module.scss";
import useTranslation from "../../../hooks/useTranslation";
import useWritingSystemIds from "../../../hooks/useWritingSystemIds";
import RocketMarkdown from "../../Lesson/MarkdownComponent/includes/RocketMarkdown";

export default function Flashcards({
  rateableTestId,
  disableSettings,
}: {
  rateableTestId: number;
  disableSettings?: boolean;
}) {
  const t = useTranslation();
  const dispatch = useSharedDispatch();

  const lesson = useContext(LessonContext);
  const audioEnabled = useSharedSelector((store) => !!Number(store.user.preferences?.flashcard_volume));
  const writingSystemIds = useWritingSystemIds(lesson.id);

  const options: usePhraseTestProps = useMemo(
    () => ({
      testTypeId: RateableTestTypeIds.FLASHCARDS,
      lessonId: lesson.id,
      rateableTestId,
      mode: "unrated_components",
      onComponentRated({ componentId }) {
        // Dispatch add points for flashcard
        dispatch(
          requestAddPoints({
            rewardType: "rateFlashcard",
            data: { lesson: lesson.id, rateableTest: rateableTestId, component: componentId },
          }),
        );
      },
      phraseFilter: (phrase) => {
        return phrase.strings.some(
          (_phraseString) => _phraseString.writing_system_id === WritingSystemIds.english && _phraseString.text,
        );
      },
    }),
    [dispatch, lesson.id, rateableTestId],
  );

  const phraseTest = usePhraseTest(options);

  return (
    <RateableTest
      phraseTest={phraseTest}
      testTypeId={RateableTestTypeIds.FLASHCARDS}
      testName={t("flashcard")}
      testSubheading={t("flashcard-subheading")}
      instructions={<Instructions />}
      settings={
        !disableSettings ? <Settings audioEnabled={audioEnabled} writingSystemIds={writingSystemIds} /> : undefined
      }
    >
      <FlashcardPhraseTest audioEnabled={audioEnabled} />
    </RateableTest>
  );
}

export function FlashcardPhraseTest({ audioEnabled }: { audioEnabled: boolean }) {
  const { phraseTest } = useContext(RateableTestContext);
  const preferences = useSharedSelector((store) => store.user.preferences);
  const activeCourseId = useSharedSelector((store) => store.preferences.activeCourse?.id);
  const hideRomanized = useSharedSelector((store) => !!Number(store.user.preferences?.hide_romanized));
  const hideKana = useSharedSelector((store) => !!Number(store.user.preferences?.hide_kana));

  /** Flipped flashcards (number being the index of the game) */
  const [flipped, setFlipped] = useState(false);
  const phraseRef = useRef<HTMLAudioElement>(null);
  const currentPhrase = phraseTest.components.testPhrases[phraseTest.state.index];
  const hasRevealed = phraseTest.state.revealed.has(phraseTest.state.index);
  const t = useTranslation();

  useEffect(() => {
    const listenerFn = () => setFlipped(false);
    phraseTest.events.registerOnCompleteListener(listenerFn);
    return () => {
      phraseTest.events.unregisterOnCompleteListener(listenerFn);
    };
  }, [phraseTest.events]);

  /**
   * Occurs when tapping "Reveal" from PhraseTestUI
   */
  function onReveal() {
    setFlipped((prevState) => !prevState);
  }

  const flashcard = useMemo(() => {
    if (!currentPhrase) {
      return null;
    }

    /**
     * Occurs on tapping the flashcard
     */
    function flip() {
      // Only flip card if already revealed
      if (hasRevealed) {
        setFlipped((prevState) => !prevState);
      }
    }

    // Flashcard back/front strings
    const { frontStrings, backStrings, notationPhraseString } = (() => {
      const notationIndex = currentPhrase.strings.findIndex((string) => !!string.notations.length);
      const notationPhraseString = notationIndex !== -1 ? currentPhrase.strings[notationIndex] : undefined;

      const availablePhraseStrings = currentPhrase.strings.filter((_string) => {
        if (hideRomanized && RomanizedWritingSystemIds.includes(_string.writing_system_id)) {
          return false;
        }
        if (hideKana && WritingSystemIds.hiragana_katakana === _string.writing_system_id) {
          return false;
        }
        return true;
      });

      // User has no set preferences for writing systems to display on front/back
      if (!activeCourseId || !preferences?.courses?.[activeCourseId]?.flashcard_ws_front?.length) {
        // Translation (English) at the front, foreign language strings at the back
        return {
          frontStrings: availablePhraseStrings.slice(-1),
          backStrings: availablePhraseStrings.slice(0, -1),
          notationPhraseString,
        };
      }

      // Filter front strings by flashcard_ws_front & back
      let flashcardWsFront = preferences.courses[activeCourseId]?.flashcard_ws_front || [];

      // Default to English
      if (!flashcardWsFront?.length) {
        flashcardWsFront = [WritingSystemIds.english];
      }

      let flashcardWsBack = preferences.courses[activeCourseId]?.flashcard_ws_back || [];

      // Default to everything but whatever the front is
      if (!flashcardWsBack?.length) {
        flashcardWsBack = availablePhraseStrings
          .filter((str) => str.writing_system_id !== flashcardWsFront[0])
          .map((str) => str.writing_system_id);
      }

      return {
        frontStrings: availablePhraseStrings.filter((ps) =>
          flashcardWsFront.some((wsId) => wsId === ps.writing_system_id),
        ),
        backStrings: availablePhraseStrings.filter((ps) =>
          flashcardWsBack.some((wsId) => wsId === ps.writing_system_id),
        ),
        notationPhraseString,
      };
    })();

    // Front Flashcard Text
    const frontText = frontStrings.map((line, i) => (
      <FlashcardTextLine className={`ws-${line.writing_system_id}`} key={line.id} bold={i === 0}>
        {line.text}
      </FlashcardTextLine>
    ));

    // Back Flashcard text
    const backText = backStrings.map((line, i) => (
      <FlashcardTextLine
        // Clickup issue: https://app.clickup.com/t/86cuuw96p
        // The screen reader should not read the back text when the card is not flipped
        aria-hidden={!flipped}
        className={`ws-${line.writing_system_id}`}
        key={line.id}
        bold={i === 0}
      >
        {line.text}
      </FlashcardTextLine>
    ));

    return (
      <div>
        {flipped && audioEnabled && (
          <audio ref={phraseRef} preload="metadata" src={currentPhrase.audio_url || undefined} autoPlay />
        )}
        <div className="flex justify-center self-center">
          <Flashcard
            key={currentPhrase.id}
            flipped={flipped}
            onClick={flip}
            notationPhraseString={notationPhraseString}
            literalString={currentPhrase.literal_string}
            literalStringPlacement={
              frontStrings.some((str) => str.writing_system_id === WritingSystemIds.english) ? "front" : "back"
            }
          >
            <>{frontText}</>
            <>{backText}</>
          </Flashcard>
        </div>
      </div>
    );
  }, [activeCourseId, audioEnabled, currentPhrase, flipped, hasRevealed, hideKana, hideRomanized, preferences]);

  return (
    <>
      {flashcard}
      <RatingButtons onReveal={onReveal} onRate={() => setFlipped(false)} revealButtonTitle={t("flip")} />
    </>
  );
}

export function FlashcardTextLine(props: {
  children: string;
  bold?: boolean;
  className?: string;
  "aria-hidden"?: boolean;
}) {
  const childStringContents = props.children.replace(/<[^>]*>/g, "");

  return (
    <div
      className={clsx(
        "text-center font-serif text-brand dark:text-text2",
        props.bold ? clsx("mb-4 font-bold", styles.titlePhrase) : styles.subPhrase,
        props.className,
      )}
      aria-hidden={props["aria-hidden"]}
    >
      <RocketMarkdown
        // Don't use default options
        options={{}}
      >
        {childStringContents}
      </RocketMarkdown>
    </div>
  );
}
