import { useRef, useReducer, useCallback, ReactPortal, useMemo } from "react";
import ReactDOM from "react-dom";
import { Button } from "@rocket/ui";
import { IoIosAdd } from "react-icons/io";
import styles from "./AddToVocabPopover.module.css";
import getVisibleSelectionRect from "./getVisibleSelectionRect";
import useEventListener from "../../../../hooks/useEventListener";
import useSelectionListener from "./useSelectionListener";
import useTranslation from "../../../../hooks/useTranslation";
import { getComponentId } from "./AddToVocabPopover.utils";

interface AddToVocabPopoverProps {
  onConfirmSelect(str: string): void;
}

interface State {
  selection?: string;
  offsets?: {
    top: number;
    left: number;
    right: number;
  };
}

const POPOVER_WIDTH = 74; // 120;
const HALF_POPOVER_WIDTH = 37;
const MAX_CHARACTERS = 400;

function reducer(state: State, action: any): State {
  switch (action.type) {
    case "CLEAR_SELECTION":
      return {
        ...state,
        selection: "",
      };
    case "SET_STATE":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

const initialState = {
  hidePopover: true,
  offsets: undefined,
  lastSelection: undefined,
};

const canAddToVocab = (node: Node | null) => Boolean(getComponentId(node));

export default function AddToVocabPopover(props: AddToVocabPopoverProps): ReactPortal | null {
  const t = useTranslation();
  const selectionTextRef = useRef<string>("");
  const popoverButtonRef = useRef<HTMLButtonElement>(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateDimensions = useCallback(
    (forceUpdate = false) => {
      const currentSelectionText = window.getSelection()?.toString();

      selectionTextRef.current = currentSelectionText || "";

      if (!currentSelectionText || currentSelectionText.length > MAX_CHARACTERS || !currentSelectionText.trim()) {
        return;
      }

      if (!forceUpdate) {
        if (state.selection === currentSelectionText || currentSelectionText.length < 1) {
          return;
        }
      }

      const selection = getVisibleSelectionRect();

      if (!selection) {
        return;
      }

      const { top, left, right } = selection;
      const center = right + left;

      dispatch({
        type: "SET_STATE",
        payload: {
          selection: currentSelectionText,
          offsets: {
            top: top - 40 + window.scrollY,
            right,
            left: center / 2 - HALF_POPOVER_WIDTH,
          },
        },
      });
    },
    [state.selection],
  );

  const hasSelection = Boolean(state.selection);

  // Whenever we resize the window, we need to reposition the "save button"
  if (typeof document !== "undefined") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEventListener("resize", () => updateDimensions(true), document, hasSelection);
  }

  useSelectionListener(
    useMemo(
      () => ({
        onSelectionStart() {
          dispatch({ type: "CLEAR_SELECTION" });
        },
        onSelectionChange(selection) {
          if (!selection) {
            dispatch({ type: "CLEAR_SELECTION" });
          }
        },
        onSelectionEnd(e) {
          const selection = window.getSelection()?.toString() || "";
          if (!selection) {
            dispatch({ type: "CLEAR_SELECTION" });
          }
          if (!e.target) {
            return;
          }
          const selectionNode = window?.getSelection();
          try {
            const container = selectionNode?.rangeCount ? selectionNode.getRangeAt(0).commonAncestorContainer : null;

            if (canAddToVocab(e.target as Node) || (container && canAddToVocab(container as Node))) {
              updateDimensions();
            }
          } catch (e) {
            // Failed to execute 'getRangeAt' on 'Selection': 0 is not a valid index.
            console.error(e);
          }
        },
      }),
      [updateDimensions],
    ),
  );

  if (!state.selection || !state.offsets) {
    return null;
  }

  function handleButtonKeyDown(e: React.KeyboardEvent) {
    if (e.key === "Escape") {
      dispatch({ type: "CLEAR_SELECTION" });
    }
  }

  return ReactDOM.createPortal(
    <div
      className={styles.popover}
      style={{
        top: state.offsets.top,
        left: state.offsets.left,
        justifyContent: "center",
        width: POPOVER_WIDTH,
      }}
    >
      <Button
        size="small"
        color="secondary"
        className={styles.addToVocabButton}
        ref={popoverButtonRef}
        onKeyDown={handleButtonKeyDown}
        onClick={() => {
          dispatch({ type: "CLEAR_SELECTION" });
          props.onConfirmSelect(
            selectionTextRef.current
              .trim()
              // Disallow users to select multiple lines
              .replace(/\n.*/g, ""),
          );
        }}
      >
        <IoIosAdd size={23} />
        <div>{t("save")}</div>
      </Button>
    </div>,
    document.querySelector("body") as HTMLBodyElement,
  );
}
