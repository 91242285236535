import { useCallback, useContext } from "react";

import Markdown from "markdown-to-jsx";
import Button from "@rocket/ui/Button";
import type { Phrase } from "@rocket/types";
import RateableTestContext from "../../includes/context";
import { clsx } from "clsx";
import memoize from "fast-memoize";

const findPhraseById = memoize((phrases: Phrase[], phraseId: number) => {
  return phrases.find((p: Phrase) => p.id === phraseId);
});

export default function NonEasyPhrasesList({
  numNonEasyPhrases,
  redo,
}: {
  numNonEasyPhrases: number;
  redo: () => void;
}) {
  const context = useContext(RateableTestContext);
  const phraseTest = context?.phraseTest;
  const { phrases, videoComponents } = phraseTest.components;
  const { ratings } = phraseTest.computed;

  function getColor(rating: "good" | "hard") {
    switch (rating) {
      case "good":
        return "bg-rocketorange-light dark:bg-neutral-800 border-l-4 border-rocketorange";
      case "hard":
        return "bg-rocketred-extra-light dark:bg-neutral-800 border-l-4 border-rocketred";
      default:
        return "bg-rocketorange-light dark:bg-neutral-800 border-l-4 border-rocketorange";
    }
  }

  const renderTranslateItPhraseItem = useCallback(
    ({ item: id }: { item: number }) => {
      const videoComponent = videoComponents.find((v) => v.id === id);
      if (!videoComponent) {
        return null;
      }
      return (
        <div key={`p.${videoComponent.id}`}>
          <div
            dangerouslySetInnerHTML={{
              // Note: the caption contains html entities
              // This ideally needs to be converted to ascii/utf8
              __html: videoComponent.caption,
            }}
          />
        </div>
      );
    },
    [videoComponents],
  );

  const renderModalPhraseItem = useCallback(
    ({ item: phraseId }: { item: number }) => {
      const phrase = findPhraseById(phrases, phraseId);
      if (!phrase) {
        return null;
      }
      return (
        <div>
          {phrase.strings.map((phraseString, i) => {
            if (phraseString.text.length === 0) {
              return null;
            }
            const markdownText = <Markdown>{phraseString.text}</Markdown>;
            const isTranslation = i === phrase.strings.length - 1;
            return (
              <div key={`p.${phraseString.id}`} className={clsx(i === 0 && "text-lg", "text1 font-serif")}>
                {isTranslation ? <em>{markdownText}</em> : markdownText}
              </div>
            );
          })}
        </div>
      );
    },
    [phrases],
  );

  const sections: Array<{
    title: string;
    key: "good" | "hard";
    data: Array<any>;
  }> = [
    {
      title: "Rated Good",
      key: "good",
      data: Array.from(ratings.good),
    },
    {
      title: "Rated Hard",
      key: "hard",
      data: Array.from(ratings.hard),
    },
  ];

  if (!ratings.easy.size && !ratings.good.size && !ratings.hard.size) {
    return null;
  }

  return (
    <div className="w-full">
      <div className="my-6 flex justify-center">
        <Button color="primary" onClick={redo}>
          REDO {numNonEasyPhrases} NON-EASY PHRASES
        </Button>
      </div>
      {sections.map((section) => {
        if (section.data.length === 0) {
          return null;
        }
        return (
          <div key={section.key} className="mb-4">
            <h4 className="font-serif">{section.title}</h4>
            <div className="flex w-full flex-col">
              {section.data.map((item, i) => (
                <div key={i} className={clsx("my-1 p-4", getColor(section.key))}>
                  {videoComponents.length > 0 ? renderTranslateItPhraseItem({ item }) : renderModalPhraseItem({ item })}
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}
