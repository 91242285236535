import Calendar from "./icons/Calendar";
import { Link } from "react-router-dom";
import Medal from "./icons/Medal";
import Podium from "./icons/Podium";
import { clsx } from "clsx";
import { getOrdinal } from "@rocket/shared/utils";
import useActiveCourse from "@rocket/shared/hooks/useActiveCourse";
import useActiveProduct from "@rocket/shared/hooks/useActiveProduct";
import { useStoreSelector } from "~/store";
import useTranslation from "@rocket/shared/hooks/useTranslation";

function getTodaysPointsText(dailyPoints: number, dailyPointsGoal: string | number | undefined) {
  if (dailyPoints && dailyPoints > 0) {
    return `${dailyPoints} / ${dailyPointsGoal || "-"}`;
  }
  return `0 / ${dailyPointsGoal || "-"}`;
}

export default function ProgressWidget() {
  const t = useTranslation();
  const userPreferences = useStoreSelector((store) => store.user.preferences);

  const stats = useStoreSelector((store) => store.user.stats);
  const activeProductId = useActiveProduct()?.id || 0;
  const dailyPointsGoal = useStoreSelector((store) => Number(store.user.preferences?.daily_points_goal) || 100);
  const todaysPoints = getTodaysPointsText(stats.dailyPoints, dailyPointsGoal);
  const streak = stats.currentStreak || 0;
  const isEbook = useActiveCourse()?.slug === "ebook";

  if (isEbook || Number(userPreferences?.hide_topbar_settings)) {
    return null;
  }

  return (
    <Link
      to={`/members/products/${activeProductId}/tools/leaderboard`}
      className="flex h-14 rounded-lg bg-[rgba(255,_255,_255,_0.2)] p-2 text-sm font-bold text-white print:hidden"
      aria-label={`My Progress. ${stats.position || "No"} leaderboard position. ${
        stats.dailyPoints || 0
      } points today. ${streak} days streak.`}
    >
      <div
        className="flex flex-row items-center gap-2 whitespace-nowrap px-1 sm:px-3 md:w-[120px]"
        title="Leaderboard position"
      >
        <Podium />
        <span>{(stats.position || 0) > 0 ? getOrdinal(stats.position) : "--"}</span>
      </div>

      <div
        className="flex flex-row items-center gap-2 whitespace-nowrap px-1 sm:px-3 md:w-[120px]"
        title="Today's points"
      >
        <Medal />
        <span className={clsx(stats.dailyPoints >= dailyPointsGoal ? "text-rocketorange" : "text-white")}>
          {todaysPoints}
        </span>
      </div>

      <div
        className="hidden flex-row items-center gap-2 whitespace-nowrap px-3 sm:flex md:w-[120px]"
        title="Points streak"
      >
        <Calendar />
        <span>
          {streak} {t("day|days", undefined, streak)}
        </span>
      </div>
    </Link>
  );
}
