import type { SharedRootAction, SharedRootState } from "./types";
import { createDispatchHook, createSelectorHook, createStoreHook } from "react-redux";

import { all } from "redux-saga/effects";
import auth from "./auth/reducer";
import authSagas from "./auth/sagas";
import { combineReducers } from "redux";
import customFlashcards from "./customFlashcards/reducer";
import customFlashcardsSagas from "./customFlashcards/sagas";
import dashboard from "./dashboard/reducer";
import dashboardSagas from "./dashboard/sagas";
import featureimage from "./featureimage/reducer";
import lesson from "./lesson/reducer";
import lessonSagas from "./lesson/sagas";
import moduleTestSagas from "./moduletest/sagas";
import moduletest from "./moduletest/reducer";
import preferences from "./preferences/reducer";
import user from "./user/reducer";
import createUserSagas from "./user/sagas";
import Device from "../utils/Device";

/**
 * Shared reducer. Used for testing purposes only
 */
export const createSharedRootReducer = () => {
  return combineReducers<SharedRootState>({
    auth,
    dashboard,
    user,
    lesson,
    preferences,
    moduletest,
    customFlashcards,
    featureimage,
  });
};

export function createSharedRootSaga() {
  // The root saga is what we actually send to Redux's middleware. In here we fork
  // each saga so that they are all "active" and listening.
  // Sagas are fired once at the start of an app and can be thought of
  // as processes running in the background, watching actions dispatched to the store.
  return function* sharedRootSaga() {
    const allSagas = [
      ...authSagas,
      ...lessonSagas,
      ...dashboardSagas,
      ...createUserSagas({
        syncUser: {
          getDeviceInfo: Device.getDeviceInfo,
          getTimeZone: Device.getTimeZone,
        },
      }),
      ...moduleTestSagas,
      ...customFlashcardsSagas,
    ];
    yield all(allSagas);
  };
}

export const useSharedStore = createStoreHook<SharedRootState, SharedRootAction>();
export const useSharedSelector = createSelectorHook<SharedRootState, SharedRootAction>();
export const useSharedDispatch = createDispatchHook<SharedRootState, SharedRootAction>();
