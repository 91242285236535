export default function BookmarkIcon() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.1673 24.5L14.0007 18.6667L5.83398 24.5V5.83333C5.83398 5.21449 6.07982 4.621 6.5174 4.18342C6.95499 3.74583 7.54848 3.5 8.16732 3.5H19.834C20.4528 3.5 21.0463 3.74583 21.4839 4.18342C21.9215 4.621 22.1673 5.21449 22.1673 5.83333V24.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
