import { LessonComponent, TranscriptComponent } from "@rocket/types";
import { useMemo } from "react";
import { useSharedSelector } from "../store";

export default function usePlayItComponents(lessonId: number) {
  const lessonComponents = useSharedSelector((store) => store.lesson.entities.lesson_components[lessonId || 0]);

  return useMemo(() => {
    const validPlayItComponents = (lessonComponents?.filter(
      (c) => c.type === "transcript" && c.component.lines.length > 3,
    ) || []) as LessonComponent<"transcript", TranscriptComponent>[];

    return validPlayItComponents;
  }, [lessonComponents]);
}
