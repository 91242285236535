import Markdown from "markdown-to-jsx";
import Notation from "../Notation";
import { PhraseStringNotation } from "@rocket/types";
import { Tooltip } from "@rocket/ui";
import useNotations from "../../../hooks/useNotations";

export default function PhraseStringNotationButton(props: {
  phraseStringNotation: PhraseStringNotation;
  phraseText: string;
  ws: number;
  variant?: "faded";
}) {
  const notations = useNotations();
  const activeNotation = notations[props.phraseStringNotation.notation_id] || props.phraseStringNotation.meta;
  const title = activeNotation?.tooltip_label || "";

  function getNotationText() {
    if (props.phraseText) {
      const [lower, upper] = props.phraseStringNotation.mode_value.split("-");
      const notationSelectedText = props.phraseText.substring(Number(lower), Number(upper));
      return (
        activeNotation?.tooltip_description.replace(
          /%s/g,
          `<span class="ws-${props.ws}" style="display:inline-block;">${notationSelectedText}</span>`,
        ) || ""
      );
    }

    return "";
  }

  // No notation found
  if (!title) {
    return null;
  }

  return (
    <Tooltip width={300} label={() => <Markdown>{getNotationText()}</Markdown>}>
      <Notation title={title} variant={props.variant} />
    </Tooltip>
  );
}
