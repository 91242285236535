import { ButtonProps, ColorProps } from "@rocket/ui/Button/Button";
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from "react";

import AudioContext from "../../../ui/Audio/AudioContext";
import { FaVolumeUp } from "react-icons/fa";
import { IconButton } from "@rocket/ui";
import audioWaveformSmall from "./res/audio-waveform-small";
import { requestAddPoints } from "../../../store/lesson/actions";
import useAudioMinified from "../../../hooks/useAudioMinified";
import { useSharedDispatch } from "../../../store";
import { usePreferenceValue } from "../../../hooks/usePreference";
import { isSafari } from "../../../utils/browser";

interface Props {
  title: string;
  /** Audio URL */
  source: string | undefined;
  onPlayStateChange?: (status: "init" | "loading" | "playing" | "paused" | "stopped") => void;
  /** Occurs when playback finishes */
  onPlayFinish?: () => void;
  /** Square button icon size */
  size?: number;
  /** Initializes playback on render */
  playOnMount?: boolean;
  /** If true, button is greyed out */
  disabled?: boolean;
  /** Uses this phrase ID to request to add points */
  phraseId?: number;
  color?: ColorProps;
  /** Id for button to be accessed by things like onboarding */
  buttonId?: string;
  render?: Partial<{
    [key in "playing" | "paused"]?: JSX.Element;
  }>;
}

/**
 * Square button audio player
 *
 * Requires: <LessonContext.Provider value={...} /> as a parent in the tree to get the active lesson id
 */
const AudioButton = forwardRef<ReturnType<typeof useAudioMinified>, Props>((props, ref) => {
  const dispatch = useSharedDispatch();
  const audioRef = useRef<HTMLAudioElement>(null);
  const audioContext = useContext(AudioContext);
  // On Safari 16.1, there seems to be an issue when too many audio elements are on a page
  // would cause no audio to play and indefinitely be in a loading state.
  // This even persists on other websites after closing the tab.
  const preload = isSafari ? "none" : "auto";
  const playbackRate = usePreferenceValue("rocket_record_speed", 1);
  const { source, phraseId } = props;

  const audio = useAudioMinified(audioRef, props.playOnMount, playbackRate);

  useImperativeHandle(ref, () => audio);

  const { status, playAudio, pauseAudio, stopAudio } = audio;

  useEffect(
    function onPlayStateChange() {
      if (status === "init") {
        return;
      }
      // Notify parent
      if (props.onPlayStateChange) {
        props.onPlayStateChange(status);
      }

      if (status === "stopped") {
        // Play It only cares about when playing has finished
        props.onPlayFinish?.();

        // Add points
        /**
         * lesson: lessonId, phraseId: props.phraseId,
         */
        dispatch(
          requestAddPoints({
            rewardType: "phrasePlay",
            data: {},
          }),
        );
      }
    },
    // eslint-disable-next-line
    [phraseId, status],
  );

  const buttonProps = ((): ButtonProps => {
    switch (status) {
      case "playing":
        return {
          children: props.render?.[status] || (
            <div className="flex h-full w-full items-center justify-center">
              <img src={audioWaveformSmall} alt="" />
            </div>
          ),
          onClick: pauseAudio,
        };
      case "paused":
        return {
          children: <FaVolumeUp />,
          // @ts-ignore
          onClick: playAudio,
        };
      case "loading":
        return {
          loading: true,
          disabled: false,
          onClick: stopAudio,
        };
      default:
        return {
          children: <FaVolumeUp />,
          onClick: () => {
            audioContext.activePlayer?.pause();
            playAudio();
          },
        };
    }
  })();

  return (
    <>
      <audio ref={audioRef} src={source} preload={preload} />
      <IconButton
        type="button"
        aria-label={props.title}
        title={props.title}
        disabled={props.disabled || !source}
        {...buttonProps}
        color={props.color ? props.color : "primary"}
        id={props.buttonId}
      />
    </>
  );
});

export default AudioButton;
