import { takeLatest, put } from "redux-saga/effects";
import createUserSharedSagas from "@rocket/shared/store/user/sagas";

// Types
import { push } from "@rocket/shared/res/router";
import { NAVIGATE_TO_DASHBOARD } from "@rocket/shared/store/nav/constants";
import { navigateToDashboard } from "@rocket/shared/store/nav/actions";
import { REHYDRATE } from "redux-persist";
import type { RehydrateAction } from "../../store/types";
import * as userActions from "./actions";
import API from "@rocket/shared/res/Api";
import Device from "@rocket/shared/utils/Device";

/**
 * Resyncs products & user context when the user opens the app again
 */
function* rehydrate(action: RehydrateAction) {
  // Make sure there's a valid payload to rehydrate store
  if (!action.payload || !action.payload.auth || !action.payload.auth.token || !action.payload.user.profile) {
    return;
  }

  /*
  // Inspectlet user context

  // @ts-ignore
  if (window.__insp && typeof window.__insp.push === "function" && action.payload.user.profile.email) {
    // @ts-ignore
    window.__insp.push(["identify", action.payload.user.profile.email]);
  }
  */

  // Make sure that we aren't syncing user details when the user is autologging
  if (!window.location.href.includes("/autologin")) {
    // Update authentication token for every HTTP request
    API.setToken(action.payload.auth.token);
    // Resync user details, and (if active product set, active lesson ID, product info)
    yield put(userActions.sagaSyncUser());
  }
}

// Any watchers go in here. They get forked in the Root Saga
const userWebSagas = [
  ...createUserSharedSagas({
    syncUser: {
      getDeviceInfo: Device.getDeviceInfo,
      getTimeZone: Device.getTimeZone,
    },
  }),
  takeLatest(NAVIGATE_TO_DASHBOARD, (action: ReturnType<typeof navigateToDashboard>) =>
    push(`/members/products/${action.payload.productId}/dashboard`),
  ),
  takeLatest(REHYDRATE, rehydrate),
  // takeLatest(constants.COURSE_SELECTION_ERROR, courseSelectionError),
];

export default userWebSagas;
