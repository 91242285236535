import { createContext, useContext } from "react";
import type { ReactNode } from "react";
import { clsx } from "clsx";
import { IoIosRadioButtonOff, IoIosRadioButtonOn } from "react-icons/io";

type RadioProps = {
  value: string | undefined;
  onValueChange: (value: string) => void;
};

const RadioContext = createContext<RadioProps | null>(null);

interface RadioGroupProps {
  onValueChange: (value: string) => void;
  value: string | undefined;
  children: ReactNode;
}

const Radio = {
  Group({ onValueChange, value, children }: RadioGroupProps) {
    return <RadioContext.Provider value={{ value, onValueChange }}>{children}</RadioContext.Provider>;
  },
  Button(props: {
    label: string;
    disabled?: boolean;
    value: string;
    /** pixel size of radio */
    size?: number;
  }) {
    const context = useContext(RadioContext)!;

    const enabled = context.value === props.value;

    const RadioIcon = enabled ? IoIosRadioButtonOn : IoIosRadioButtonOff;
    return (
      <div className="flex items-center">
        <button
          onClick={() => context.onValueChange(props.value)}
          disabled={props.disabled}
          className={clsx("text-brand dark:text-neutral-100")}
        >
          <RadioIcon size={props.size || 50} />
        </button>
        <p className="ml-2">{props.label}</p>
      </div>
    );
  },
};
export default Radio;
