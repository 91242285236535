import { LessonComponent, Phrase, PhraseboxComponent as PhraseboxComponentType } from "@rocket/types";
import Phrasebox, { TrimmedPhrasebox } from "../../Phrasebox";

import { clsx } from "clsx";
import { isTableRegex } from "../../../utils";
import { useMemo } from "react";
import { useSharedSelector } from "../../../store";

type PhraseboxWrapperFunction = (
  key: string | number,
  phrase: Phrase,
  component: JSX.Element,
  phraseIndex: number,
) => JSX.Element | null;

export type PhraseboxComponentProps = {
  lessonComponent: LessonComponent<"phrasebox", PhraseboxComponentType>;
  /** Set when used in admin area, renders phrasebox within an editable wrapper instead */
  phraseboxWrapper?: PhraseboxWrapperFunction;
};

//const RenderInViewport = (props: any) => <>{props.children}</>;

/**
 * Phrasebox component.
 *
 * Displays a list of phrases that can be played and recorded
 */
export default function PhraseboxComponent(props: PhraseboxComponentProps) {
  const { title_show, title_text, component } = props.lessonComponent;
  const phrases = useSharedSelector((store) => store.lesson.entities.phrases);
  const debugEnabled = useSharedSelector((state) => state.preferences.debugEnabled);

  const isTable = isTableRegex.test(phrases[component.lines[0]?.phrase_id || 0]?.strings?.[0]?.text || "");

  // show draft lines if debug mode enabled or on admin domain
  const filteredLines = useMemo(() => {
    if (debugEnabled || (typeof window !== "undefined" && window.location.hostname === "staff.rocketlanguages.com")) {
      return component.lines;
    }
    return component.lines.filter((_line) => !!_line.status);
  }, [debugEnabled, component.lines]);

  return (
    <div>
      {title_show && title_text ? <h2>{title_text}</h2> : null}
      {filteredLines.map((phraseboxLine, i) => {
        const hasVoiceRecognition = !phrases[phraseboxLine.phrase_id]?.disable_voice_recognition;
        const phrase = phrases[phraseboxLine.phrase_id]!;
        const variant = (() => {
          // if no voice recognition or is the header of a table with no phrase audio
          if (!hasVoiceRecognition || (isTable && i === 0 && !phrase.audio_url)) {
            return "trimmed";
          }
          return "full";
        })();
        const PhraseboxComponent = variant === "trimmed" ? TrimmedPhrasebox : Phrasebox;
        const phrasebox = (
          <PhraseboxComponent
            key={`${phraseboxLine.id}.${phraseboxLine.phrase_id}`}
            phrase={phrase}
            className={clsx((props.phraseboxWrapper || !phraseboxLine.status) && "!bg-transparent")}
          />
        );

        if (props.phraseboxWrapper) {
          return (
            <div key={`${phraseboxLine.id}.${phraseboxLine.phrase_id}`} className="my-4">
              {props.phraseboxWrapper(phraseboxLine.phrase_id, phrase, phrasebox, i)}
            </div>
          );
        }

        return (
          <div key={`${phraseboxLine.id}.${phraseboxLine.phrase_id}`} className="my-4">
            {phrasebox}
          </div>
        );
      })}
    </div>
  );
}
