import { useEffect, useRef, useState } from "react";
import SignaturePad from "signature_pad";

type CanvasConfig = {
  thickness: number;
};

export function useCanvas({ thickness }: CanvasConfig) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const padRef = useRef<SignaturePad>();
  const [isEmpty, setIsEmpty] = useState(true);

  const getBase64Image = () => {
    if (!padRef.current) throw Error("SUBMIT: no pad ref!");
    return padRef.current.toDataURL();
  };

  const undoStroke = () => {
    if (!padRef.current) throw Error("UNDO: no pad ref!");
    const data = padRef.current.toData();
    data.pop();
    padRef.current.fromData(data);
    setIsEmpty(padRef.current.isEmpty());
  };

  const clearCanvas = () => {
    if (!padRef.current) throw Error("CLEAR: no pad ref!");
    padRef.current.clear();
    setIsEmpty(true);
  };

  const setThickness = (thickness: number) => {
    if (!padRef.current) throw Error("THICKNESS: no pad ref!");
    const pad = padRef.current;
    pad.minWidth = thickness;
    pad.maxWidth = thickness * 2;
  };

  // const setSize = (width: number, height: number) => {
  //   if (!canvasRef.current) throw Error("SIZE: no canvas ref!");
  //   if (!padRef.current) throw Error("SIZE: no pad ref!");
  //   canvasRef.current.width = width;
  //   canvasRef.current.height = height;
  //   padRef.current.clear();
  // };

  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    if (!context) throw Error("Could not get context");

    const pad = new SignaturePad(canvas);
    padRef.current = pad;

    pad.minWidth = thickness;
    pad.maxWidth = thickness * 2;

    const callback = () => {
      setIsEmpty(false);
    };

    pad.addEventListener("afterUpdateStroke", callback);

    return () => {
      pad.removeEventListener("afterUpdateStroke", callback);
    };
  }, []);

  return {
    canvasRef,
    getBase64Image,
    undoStroke,
    clearCanvas,
    setThickness,
    isEmpty,
    // setSize,
  };
}
