import { CourseFlagRect } from "@rocket/ui";
import { clsx } from "clsx";

type ReactComponent = (props: any) => JSX.Element;

export interface CourseSelectionButtonProps {
  courseSlug: string;
  dialect?: string | null;
  title: string;
  use?: string | ReactComponent;
  onClick?: () => void;
  href?: string;
  target?: string;
  rel?: string;
  className?: string;
}

export default function CourseSelectionButtonInactive(props: CourseSelectionButtonProps) {
  const { courseSlug, title, use: T = "button", dialect, className, ...rest } = props;
  return (
    <T
      {...rest}
      type={T === "button" ? "button" : undefined}
      className={clsx(
        "flex h-20 items-center rounded-lg bg-surface2 px-4 py-2 font-sans text-sm font-medium text-brand hover:bg-brand2 dark:bg-surface2 dark:text-white",
        className,
      )}
    >
      <div className="mr-3">
        <CourseFlagRect courseSlug={courseSlug} />
      </div>
      <div className="flex flex-1 flex-col text-left">
        {title}
        {dialect && <small className="text-brand-secondary text-xs font-semibold">{dialect}</small>}
      </div>
    </T>
  );
}
