import { ForwardRefExoticComponent, HTMLAttributes, forwardRef, useMemo } from "react";
import CircularProgress from "../CircularProgress";
import { clsx } from "clsx";
import { overrideTailwindClasses } from "tailwind-override";
import type { ColorProps, SizeProps } from "./types";
import { getButtonClasses } from "./getButtonClasses";

export type { ColorProps, SizeProps };

/** Button props */
export type ButtonProps = HTMLAttributes<HTMLButtonElement> & {
  /** Element type to use. Can be "button" or "a" */
  use?: string | ForwardRefExoticComponent<any>;
  /** Color of the button. e.g. "primary" | "secondary" */
  color?: ColorProps;
  /** The sizeo of the button padding */
  size?: SizeProps;
  /** Whether the button will take 100% width */
  fill?: boolean;
  /** Fade whatever color the button is */
  faded?: boolean;
  /** Whether a loading spinner will be rendered */
  loading?: boolean;
  /** Whether the button is disabled */
  disabled?: boolean;
  /** Whether the button is toggled into an active mode */
  active?: boolean;
  /** Add a component on the left side of the button */
  left?: JSX.Element;
  /** Button type, eg: button | submit */
  type?: string;
};

const defaultProps: ButtonProps = {
  color: "primary",
  size: "medium",
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const classes = useMemo(() => getButtonClasses(props), [props]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { use: T = "button", color, children, loading, active, left, tabIndex, disabled, faded, ...rest } = props;

  const clicksDisabled = typeof disabled === "boolean" ? disabled : loading;

  const params = {
    ...rest,
    ref,
    className: overrideTailwindClasses(
      clsx(
        "text-white font-semibold flex items-center justify-center relative rounded-full whitespace-nowrap",
        classes,
      ),
    ),
    disabled: clicksDisabled,
    active: new Boolean(active).toString(),
    "aria-busy": loading,
    tabIndex: tabIndex ?? (clicksDisabled ? undefined : 0),
  };

  return (
    <T {...params}>
      {loading ? (
        <CircularProgress color="#FFF" className="!h-[24px] !w-[24px]" />
      ) : (
        <>
          {left && <span className="absolute inset-0 inline-flex h-full items-center p-2">{left}</span>}
          {children}
        </>
      )}
    </T>
  );
});

Button.defaultProps = defaultProps;

export default Button;
