import { ActionType, action } from "typesafe-actions";
import { RefObject, useEffect, useReducer, useState } from "react";

import { AudioComponent } from "@rocket/types";
import { getTranscriptCueIndex } from "../utils/getTranscriptCueIndex";
import memoizeOne from "memoize-one";
import { noop, secondsToMinutesAndSeconds } from "../utils";
import useEventListener from "./useEventListener";
import { useSharedSelector } from "../store/index";

interface Props {
  mediaRef: RefObject<HTMLAudioElement | null>;
  trackRef: RefObject<HTMLTrackElement | null>;
  component: AudioComponent | null;
  lessonId: number | null;
}

type State = {
  initialPosition: number;
  trackDurationSeconds: number;
  trackDurationSecondsText: string;
  trackSpeed: number;
};

const actions = {
  setTrackDuration(trackDurationSeconds: number) {
    return action("SET_TRACK_DURATION", trackDurationSeconds);
  },
  setTrackSpeed(trackSpeed: number) {
    return action("SET_TRACK_SPEED", trackSpeed);
  },
};

type Actions = ActionType<typeof actions>;

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case "SET_TRACK_DURATION":
      return {
        ...state,
        trackDurationSeconds: action.payload,
        trackDurationSecondsText: secondsToMinutesAndSeconds(action.payload),
      };
    case "SET_TRACK_SPEED":
      return {
        ...state,
        trackSpeed: action.payload,
      };
    default:
      return state;
  }
};

const getInitialState = memoizeOne((component: AudioComponent | null, initialPosition: number) => {
  if (component) {
    return {
      initialPosition,
      trackDurationSeconds: 0,
      trackDurationSecondsText: "",
      //currentPositionText: secondsToMinutesAndSeconds(seekTimeSeconds),
      trackSpeed: 1,
    };
  }

  return {
    initialPosition: 0,
    trackDurationSeconds: 0,
    trackDurationSecondsText: "",
    // currentPositionText: "00:00",
    trackSpeed: 1,
  };
});

function useAudioSeekTime(componentId = 0): number {
  return useSharedSelector((store) => store.lesson.entities.user_audio_component_seek_times?.[componentId]) || 0;
}

function useMedia({ mediaRef, component, lessonId, trackRef }: Props) {
  const [isPlaying, setPlaying] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);
  const seekTimeSeconds = useAudioSeekTime(component?.id);
  const [state, dispatch] = useReducer(reducer, getInitialState(component, seekTimeSeconds));

  /*
  // Hack to show the record button for admins
  // const debugEnabled = useSharedSelector((s) => s.preferences.debugEnabled);
  const [phraseMode, setPhraseMode] = useState<"manual" | "automatic" | null>(
    (component?.has_rocket_record && isVRSupported) || debugEnabled ? "manual" : null,
  );

  useEffect(() => {
    setPhraseMode((component?.has_rocket_record && isVRSupported) || debugEnabled ? "manual" : null);
  }, [component?.has_rocket_record, debugEnabled]);
  */

  useEffect(() => {
    const current = mediaRef.current;
    if (!current) {
      return;
    }

    function renderDuration() {
      if (current) {
        dispatch(actions.setTrackDuration(current.duration));
        // Seek to server's offset of component time in seconds
        if (seekTimeSeconds > 0) {
          current.currentTime = seekTimeSeconds;
          current.dispatchEvent(new Event("timeupdate"));
        }
      }
    }

    const onPlay = () => setPlaying(true);
    const onPause = () => setPlaying(false);

    current.onloadeddata = renderDuration;
    current.onloadedmetadata = renderDuration;
    current.onplay = onPlay;
    current.onpause = onPause;

    return () => {
      current.removeEventListener("loaded", renderDuration);
      current.removeEventListener("loadedmetadata", renderDuration);
      current.removeEventListener("play", onPlay);
      current.removeEventListener("pause", onPause);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component, mediaRef]);

  function play(): void {
    if (!mediaRef.current || !lessonId || !component) {
      console.warn("Cannot play media");
      return;
    }

    if (mediaRef.current.currentTime === mediaRef.current.duration) {
      mediaRef.current.currentTime = 0;
    }

    mediaRef.current.play()?.catch((err) => {
      console.warn(err);
    });
  }

  function seek(seconds: number): void {
    if (mediaRef.current) {
      const roundedSeconds = Number(seconds.toFixed(1));
      if (Number.isFinite(roundedSeconds)) {
        mediaRef.current.currentTime = roundedSeconds;
        // Manually dispatch timeupdate event to avoid jumpy seek
        mediaRef.current.dispatchEvent(new Event("timeupdate"));
      }
    }
  }

  // subsequent seek and play
  // if call seek and play one after the other, seek does not update states in time for play to use the correct value
  function seekAndPlay(seconds: number): void {
    if (mediaRef.current) {
      const roundedSeconds = Number(seconds.toFixed(1));
      if (Number.isFinite(roundedSeconds)) {
        mediaRef.current.currentTime = roundedSeconds;
        // Manually dispatch timeupdate event to avoid jumpy seek
        mediaRef.current.dispatchEvent(new Event("timeupdate"));
        mediaRef.current.play()?.catch((err) => {
          console.warn(err);
        });
      }
    }
  }

  function skip(direction: "ahead" | "back"): void {
    if (!mediaRef.current) {
      return;
    }

    const currentPosition = mediaRef.current.currentTime || 0;

    const seekTime = ((): number => {
      if (direction === "ahead") {
        return Math.min(mediaRef.current.duration, currentPosition + 10);
      }
      return Math.max(0, currentPosition - 10);
    })();

    const flooredSeekTime = Math.floor(seekTime);

    if (Number.isFinite(flooredSeekTime)) {
      mediaRef.current.currentTime = flooredSeekTime;
      // Manually dispatch timeupdate event to avoid jumpy seek
      mediaRef.current.dispatchEvent(new Event("timeupdate"));
    }
  }

  function speed(trackSpeed: number): void {
    if (!mediaRef.current) {
      return;
    }
    mediaRef.current.playbackRate = trackSpeed;
    dispatch(actions.setTrackSpeed(trackSpeed));
  }

  function seekToNextCue() {
    if (!mediaRef.current) {
      return;
    }
    const cues = trackRef.current?.track?.cues;
    // @ts-ignore
    const currentIndex = getTranscriptCueIndex(cues || [], mediaRef.current.currentTime);
    if (currentIndex >= 0) {
      const nextCue = cues?.[currentIndex + 1];
      if (nextCue) {
        mediaRef.current.currentTime = nextCue.startTime;
        mediaRef.current.dispatchEvent(new Event("timeupdate"));
        mediaRef.current.play();
      }
    }
  }

  return {
    id: component?.id,
    isPlaying,
    state,
    play,
    seek,
    seekToNextCue,
    pause: () => {
      mediaRef.current?.pause();
      mediaRef.current?.dispatchEvent(new Event("timeupdate"));
    },
    skip,
    speed,
    ref: mediaRef,
    trackRef,
    phraseMode: null,
    setPhraseMode: noop,
    isSeeking,
    setIsSeeking,
    seekAndPlay,
  };
}

export const useMediaProgress = (ref: RefObject<HTMLAudioElement | HTMLVideoElement | null>) => {
  const [progress, setProgress] = useState(0);

  useEventListener(
    "timeupdate",
    () => {
      if (ref && ref?.current && ref.current.readyState > HTMLMediaElement.HAVE_NOTHING) {
        const currentTime = ref.current?.currentTime || 0;
        setProgress(currentTime);
      }
    },
    ref?.current,
  );

  return progress;
};

export default useMedia;
