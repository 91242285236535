import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from "react";
import TranscriptPhrasebox from "./TranscriptPhrasebox";
import { useGlobalTranscriptRocketRecordState } from "./state";
import type { Phrase } from "@rocket/types";
import useImperativeTimeout from "../../../../hooks/useImperativeTimeout";
import useMedia from "../../../../hooks/useMedia";

interface TranscriptLinePhraseboxProps {
  phrase: Phrase;
  cue: TextTrackCue;
  player?: ReturnType<typeof useMedia>;
}

export interface TranscriptLinePhraseboxRef {
  requeueRecord(): void;
}

const TranscriptLinePhrasebox = forwardRef<TranscriptLinePhraseboxRef, TranscriptLinePhraseboxProps>(
  ({ cue, phrase, player }, ref) => {
    const [startRecording, setStartRecording] = useState(false);

    const { run, clear } = useImperativeTimeout(() => {
      // When the timeout runs, avoid trying to record while recording is ongoing
      const isRecording = useGlobalTranscriptRocketRecordState.getState().isRecording();
      if (!isRecording) {
        player?.pause();
        setStartRecording(true);
      }
    });

    /** Returns the amount of time (in milliseconds) that a record should be queued. 0 means that it should be cancelled */
    const getTimeoutDuration = useCallback(() => {
      if (!player?.isPlaying) {
        return 0;
      }
      const time = player.ref.current?.currentTime;
      if (!time) {
        return 0;
      }
      const timeoutDuration = (cue.endTime - time) * 1000 - 50;
      return timeoutDuration <= 0 ? 0 : timeoutDuration;
    }, [cue.endTime, player?.isPlaying, player?.ref]);

    // Allow parent component to reset the record timeout
    // This is used for when the user clicks on a phrase in the transcript
    useImperativeHandle(ref, () => ({
      requeueRecord: () => {
        const timeoutDuration = getTimeoutDuration();
        if (!timeoutDuration) {
          clear();
        } else {
          run(timeoutDuration);
        }
      },
    }));

    // While the player is playing, run the record timeout
    useEffect(() => {
      const timeoutDuration = getTimeoutDuration();
      if (!timeoutDuration) {
        setStartRecording(false);
      } else {
        run(timeoutDuration);
      }
      return clear;
    }, [run, clear, getTimeoutDuration]);

    return <TranscriptPhrasebox playerId={player?.id || -1} phrase={phrase} recordOnMount={startRecording} />;
  },
);

const MemoizedTranscriptLinePhrasebox = memo(TranscriptLinePhrasebox);

export default MemoizedTranscriptLinePhrasebox;
