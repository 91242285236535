import CongratulationsMessage from "../../RateableTestUI/includes/CongratulationsMessage";
import ResetTestButton from "../../RateableTestUI/buttons/ResetTestButton";
import useTranslation from "../../../../hooks/useTranslation";
import { Button } from "@rocket/ui";

export default function QuizComplete(props: {
  ratingLevel: number;
  numQuestions: number;
  numAnswered: number;
  numCorrect: number;
  onReset: VoidFunction;
  nextActivityTitle?: string;
  nextActivityHref?: string;
}) {
  const { nextActivityTitle, nextActivityHref } = props;
  const t = useTranslation();

  const completedWithinSession = props.numAnswered === props.numQuestions;

  return (
    <div className="flex w-full flex-1 flex-col items-center justify-center">
      <div className="mb-8">
        {completedWithinSession && <CongratulationsMessage />}
        {props.numAnswered === props.numQuestions ? (
          <p className="mb-4 text-center">
            {t("correct-answers")}: {props.numCorrect} out of {props.numQuestions}
          </p>
        ) : null}
      </div>
      <div className="w-min-content flex w-full flex-col items-center justify-center space-y-4 sm:max-w-[400px]">
        {nextActivityTitle && nextActivityHref && (
          <Button
            color="primary"
            use="a"
            // @ts-ignore
            href={nextActivityHref}
            className="w-full uppercase"
          >
            {nextActivityTitle}
          </Button>
        )}
        <ResetTestButton onConfirmReset={props.onReset} testName={t("quiz")} className="w-full" />
      </div>
    </div>
  );
}
