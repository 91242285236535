import Button from "@rocket/ui/Button";
import { getRatingButtonColorProp, getRatingLevel } from "../../../utils";

interface StoreRatingButtonProps {
  percentage: number;
}

export function RatingButton({ percentage }: StoreRatingButtonProps) {
  const ratingLevel = getRatingLevel(percentage);
  return (
    <Button size="small" color={getRatingButtonColorProp(ratingLevel)} className="pointer-events-none w-[48px]">
      {percentage}
    </Button>
  );
}
