import { Button, Modal } from "@rocket/ui";

import { ButtonProps } from "@rocket/ui/Button/Button";
import { OutlineButton } from "@rocket/ui/Button";
import { useState } from "react";
import useTranslation from "../../../../hooks/useTranslation";

export default function ResetTestButton(props: { testName: string; onConfirmReset(): void } & ButtonProps) {
  const { testName, onConfirmReset, ...rest } = props;
  const t = useTranslation();
  const [showConfirmReset, setShowConfirmReset] = useState(false);
  return (
    <>
      <OutlineButton
        {...rest}
        color="primary"
        onClick={() => {
          setShowConfirmReset(true);
        }}
      >
        {t("reset-{rateableTest}", {
          rateableTest: testName,
        }).toUpperCase()}
      </OutlineButton>
      <Modal
        isOpen={showConfirmReset}
        onClose={() => {
          setShowConfirmReset(false);
        }}
      >
        <div className="mx-2 mb-2 sm:mx-8 sm:mb-8">
          <h4 className="mb-2">Confirm Reset</h4>
          <div className="mb-4">
            <p className="mb-2">Are you sure you want to reset your progess?</p>
          </div>
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
            <OutlineButton
              color="primary"
              onClick={() => {
                setShowConfirmReset(false);
              }}
              className="w-full"
            >
              {t("cancel").toUpperCase()}
            </OutlineButton>
            <Button
              color="primary"
              onClick={() => {
                setShowConfirmReset(false);
                onConfirmReset();
              }}
              className="w-full"
            >
              {t("confirm").toUpperCase()}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
