import AudioButton from "./AudioButton";
import { Phrase } from "@rocket/types";

interface UserRecordingPlaybackButtonProps {
  isRecognizing: boolean;
  phrase: Phrase;
  playRecordingOnMount?: boolean;
  blobUrl?: string;
  onPlayFinish?: () => void;
  lessonId?: number;
  initializeWithRating?: boolean;
}

export default function UserRecordingPlaybackButton({
  isRecognizing,
  phrase,
  playRecordingOnMount,
  blobUrl,
  onPlayFinish,
}: UserRecordingPlaybackButtonProps) {
  return (
    <AudioButton
      disabled={isRecognizing}
      title="Play Recording"
      color="secondary"
      playOnMount={playRecordingOnMount}
      source={blobUrl}
      onPlayFinish={onPlayFinish}
      buttonId={`phrase-replay-${phrase.id}`}
    />
  );
}
