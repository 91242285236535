import { ActionType, action } from "typesafe-actions";
import { Dispatch, useMemo, useRef } from "react";
import type { Phrase, RateableTestMode, VideoComponent } from "@rocket/types";
import { asyncResetTest, rateTest, updateComponentRatings } from "../../store/lesson/actions";
import { getRatingValueFromLabel, getUpdatedUserComponentRatings } from "../useRateableTest/utils";
import { useSharedDispatch, useSharedSelector, useSharedStore } from "../../store";
import useTestPhrases, { useCustomTestPhrases } from "./useTestPhrases";

import API from "../../res/Api";
import { RateableTestTypeIds } from "../../utils/constants";
import { getTestRating } from "../../store/lesson/sagas";
import shortid from "shortid";
import { useAllComponents } from "./useAllComponents";
import useGetCurrent from "../useGetter";
import { useRateableTestRatingLevel } from "../useLessonRateableTests";
import useRatingGroups from "./useRatingGroups";
import { useReducer } from "react";

export interface usePhraseTestProps {
  /** Rateable Test ID */
  rateableTestId: number;
  /** Rateable test type ID. Depending on the ID, different properties may be extracted from the store */
  testTypeId: number;
  /** Custom filtering logic */
  phraseFilter?: (phrase: Phrase) => boolean;
  /** Used for reloading tests */
  lessonId: number;
  /** Initial Test Mode */
  mode: RateableTestMode;
  /** Callback when the test is complete */
  onComplete?: (rating: number) => void;
  /** Called after a component has been rated */
  onComponentRated?: (props: { componentId: number; ratingLabel: "easy" | "good" | "hard" }) => void;
}

type PhraseIndex = number;
type SuggestedRatingLevel = number;

interface PhraseTestState {
  /** Session status. `"complete"` means that the phrase test was completed in the _current_ session.
   *
   * For displaying the `"complete"` UI, use `computed.hasCompleted` instead. */
  status: "idle" | "started" | "complete";
  /** Current position */
  index: number;
  /** Phrases the user has revealed in the game */
  revealed: Map<PhraseIndex, SuggestedRatingLevel>;
  /** Suggested rating level for the current phrase */
  currentSuggestedRatingLevel: number;
  /** Updates when the game mode changes. Re-calculates/Re-orders the test phrases when changed */
  gameSessionId: string;
  mode: RateableTestMode;
}

const createInitialState = (mode: RateableTestMode): PhraseTestState => ({
  status: "idle",
  index: 0,
  currentSuggestedRatingLevel: 0,
  revealed: new Map(),
  gameSessionId: shortid.generate(),
  mode,
});

/**
 * React Hook that manages the state of the Phrase Test.
 *
 * Usage:
 *
 * ```js
 * function PhraseTestUI() {
 *  const { state, computed, allComponents, methods } = usePhraseTest({ testTypeId: 1 });
 *  if (state === "loading") {
 *    return "Loading...";
 *  }
 *  return (
 *    <View>
 *     <Text>{`Current index: ${state.index}`}</Text>
 *     <Button onPress={methods.reveal} title="Reveal"/>
 *     <Button onPress={() => methods.rate("hard")} title="Rate hard"/>
 *    </View>
 *  )
 * }
 * ```
 */
export default function usePhraseTest(props: usePhraseTestProps) {
  /** Initialize state with selected mode (get started, redo hard phrases) */
  const reducerFn = useReducer(reducer, createInitialState(props.mode));

  const allComponents = useAllComponents({
    rateableTestId: props.rateableTestId,
    lessonId: props.lessonId,
    testTypeId: props.testTypeId,
    phraseFilter: props.phraseFilter,
  });

  /** Phrases that will be used in the test. Note: use `allComponents.phrases` to get _all_ phrases */
  const testPhrases = useTestPhrases({
    phraseFilter: props.phraseFilter,
    allComponents,
    memoizeKey: `${reducerFn[0].gameSessionId}${props.rateableTestId}`,
    lessonId: props.lessonId,
    rateableTestId: props.rateableTestId,
    rateableTestTypeId: props.testTypeId,
    mode: reducerFn[0].mode,
  });

  //@ts-ignore
  return usePhraseTestWithComponents(reducerFn, props, allComponents, testPhrases, "phrasetest");
}

/** Differs from `usePhraseTest` by sourcing the list of phrases in the second parameter of this function */
export function useCustomPhraseTest(props: usePhraseTestProps, phrases: Phrase[], videoComponents?: VideoComponent[]) {
  /** Initialize state with selected mode (get started, redo hard phrases) */
  const reducerFn = useReducer(reducer, createInitialState(props.mode));

  const testPhrases = useCustomTestPhrases({
    phraseFilter: props.phraseFilter,
    phrases,
    memoizeKey: `${reducerFn[0].gameSessionId}`,
    lessonId: props.lessonId,
    rateableTestId: props.rateableTestId,
    rateableTestTypeId: props.testTypeId,
    mode: reducerFn[0].mode,
  });

  return usePhraseTestWithComponents(
    reducerFn,
    props,
    {
      phrases,
      videoComponents: videoComponents || [],
    },
    testPhrases,
    "groupedtest",
  );
}

type Callback = () => void;

function usePhraseTestWithComponents(
  [state, dispatch]: [PhraseTestState, Dispatch<PhraseTestAction>],
  props: usePhraseTestProps,
  allComponents: ReturnType<typeof useAllComponents>,
  /** Phrases that will be used in the test. Note: use `allComponents.phrases` to get _all_ phrases */
  testPhrases: Phrase[],
  testMode: "phrasetest" | "groupedtest" | "benchmarktest",
) {
  const store = useSharedStore();
  const dispatchStore = useSharedDispatch();
  const onCompleteListeners = useRef<Callback[]>([]);
  const isTranslateIt = props.testTypeId === RateableTestTypeIds.TRANSLATE_IT;
  const languageName = useSharedSelector((store) => store.preferences.activeCourse?.name || "");

  const ratingLevel = useRateableTestRatingLevel(props.rateableTestId);

  /** Fetches the current state. Use this in functions performing async tasks to prevent accessing stale state */
  const getState = useGetCurrent(state);

  const allTestComponents = isTranslateIt ? allComponents.videoComponents : allComponents.phrases;

  /** Visual indicator 10/X */
  const totalComponents = allTestComponents.length;

  /** Visual indicator: X/20 */
  const position = (() => {
    if (state.status === "complete" || state.index === testPhrases.length) {
      return totalComponents;
    }
    if (state.status === "idle") {
      return state.index + totalComponents - testPhrases.length;
    }
    return state.index + totalComponents - testPhrases.length + 1;
  })();

  /** A number between 0-100. Note: for display purposes only. 100% doesn't mean that it's complete */
  const percentCompleteDisplay = (() => {
    if (state.status === "complete" || state.index === testPhrases.length) {
      return 100;
    }

    const arr = isTranslateIt ? allComponents.videoComponents : allComponents.phrases;

    return ((position || 1) / arr.length) * 100 || 0;
  })();

  /**
   * Rates the current phrase and either continues or completes the game
   */
  function rate(ratingLabel: "hard" | "good" | "easy") {
    const ratingValue = getRatingValueFromLabel(ratingLabel);
    const currentIndex = getState().index;
    const currentPhrase = testPhrases[currentIndex];

    if (!currentPhrase) {
      return;
    }

    const componentType = isTranslateIt ? "video" : "phrase";

    // Get updated component ratings
    const componentRatings = getUpdatedUserComponentRatings({
      store: store.getState(),
      componentId: currentPhrase.id,
      componentType,
      rateableTestId: props.rateableTestId,
      rating: ratingValue,
    });

    // Update user_rateable_test_component_ratings to rate the phrase (state.ratings.hard, etc)
    dispatchStore(
      updateComponentRatings({
        rateableTestId: props.rateableTestId,
        componentRatings,
      }),
    );

    const testComplete = allTestComponents.length === componentRatings.length;

    const testRating = testComplete
      ? getTestRating({
          componentRatings,
          totalComponents,
        })
      : 0;

    // Send API request to store the rating
    if (testMode === "phrasetest") {
      if (testRating) {
        dispatchStore(
          rateTest({
            rateableTestId: props.rateableTestId,
            rating: testRating,
          }),
        );
      }

      if (store.getState().auth.status !== "loggedin") {
        console.warn("[usePhraseTest] Not logged in, avoiding API call to rate component");
      } else {
        const testRatingData: { productId?: number; testRating?: number } = testRating
          ? {
              productId: store.getState().preferences.activeProduct!.id,
              testRating,
            }
          : {};

        API.get(
          [
            "v2/rate/component/{rateableComponentType:name}/{componentId}/{rateableTest}/{value}",
            {
              "rateableComponentType:name": componentType,
              componentId: currentPhrase.id,
              rateableTest: props.rateableTestId,
              value: ratingValue,
            },
          ],
          testRatingData,
        ).catch((err) => {
          // TODO: undo "updateComponentRatings" / "rateTest" actions
          console.warn(err);
        });
      }
    }

    // Used for:
    // - flashcards to add points on rating
    // - benchmark tests for 3-strikes
    if (props.onComponentRated) {
      props.onComponentRated({ componentId: currentPhrase.id, ratingLabel });
    }

    if (currentIndex + 1 < testPhrases.length) {
      // Go to next phrase
      dispatch(actions.next());
    } else {
      // Done!

      // Update UI to set game to "Completed". Test Complete screen will display
      if (props.onComplete) {
        // HACK - "dispatch(updateComponentRatings)" may have not been dispatched yet.
        // Users spamming through the test will likely encounter this error
        const rating =
          testRating ||
          getTestRating({
            componentRatings,
            totalComponents,
          });

        props.onComplete(rating);
      }

      // Used when in a nested context. (Flashcards need to know when the test completes)
      onCompleteListeners.current.forEach((cb) => cb());

      // Benchmark tests will "complete" on every phrase
      if (testMode !== "benchmarktest") {
        API.post("v2/events/capture", {
          event: "test end",
          properties: {
            rateableTestId: props.rateableTestId,
          },
        });
      }

      dispatch(actions.finish());
    }
  }

  /**
   * Creates a request and reloads the test phrases from the server
   * Request is handled in redux/lesson/sagas.ts@handleTestsRequest
   */
  function reload() {
    //
  }

  function reset() {
    // Dispatch action to reset rating to 0 for all components on the server and client.
    // screenProps.status will change to 'resetting'.
    dispatchStore(
      asyncResetTest({
        rateableTestId: props.rateableTestId,
        lessonId: props.lessonId,
      }),
    );
    // Reset local state
    dispatch(actions.reset());
  }

  /**
   * Occurs when user presses "Redo X hard phrases" on the test complete screen
   * Resets local state
   */
  function redoNonEasyPhrases() {
    // Reset local state
    dispatch(actions.changeMode("non_easy_components"));
  }

  return {
    state,
    status: useTestStatus(props.rateableTestId),
    methods: {
      rate,
      reset,
      reload,
      redoNonEasyPhrases,
      dispatch,
    },
    events: useMemo(() => {
      return {
        registerOnCompleteListener(cb: Callback) {
          onCompleteListeners.current.push(cb);
        },
        unregisterOnCompleteListener(cb: Callback) {
          onCompleteListeners.current.filter((c) => c !== cb);
        },
      };
    }, []),
    components: {
      phrases: allComponents.phrases,
      videoComponents: allComponents.videoComponents,
      testPhrases,
    },
    computed: {
      ratingLevel,
      ratings: useRatingGroups(isTranslateIt ? "video" : "phrase", props.rateableTestId),
      position,
      percentCompleteDisplay,
      hasCompleted: state.status === "complete" || state.index === testPhrases.length,
      totalComponents,
      languageName,
    },
    rateableTestId: props.rateableTestId,
  };
}

function useTestStatus(rateableTestId: number) {
  const isResetting = useSharedSelector((state) => state.lesson.testResetting[rateableTestId]);
  return isResetting ? "resetting" : "loaded";
}

export const actions = {
  start() {
    return action("START");
  },
  next() {
    return action("NEXT");
  },
  finish() {
    return action("FINISH");
  },
  reveal(suggestedRatingLevel = 0) {
    return action("REVEAL", { suggestedRatingLevel });
  },
  setSuggestedRatingLevel(ratingLevel: number) {
    return action("SET_SUGGESTED_RATING_LEVEL", { ratingLevel });
  },
  reset() {
    return action("RESET");
  },
  /** Occurs when the game mode has changed */
  changeMode(mode: RateableTestMode) {
    return action("CHANGE_MODE", mode);
  },
};

export type PhraseTestAction = ActionType<typeof actions>;

function reducer(state: PhraseTestState, action: PhraseTestAction): PhraseTestState {
  switch (action.type) {
    case "START": {
      return {
        ...state,
        status: "started",
      };
    }
    case "NEXT":
      return {
        ...state,
        index: state.index + 1,
        currentSuggestedRatingLevel: 0,
      };
    case "FINISH":
      return {
        ...state,
        status: "complete",
        revealed: new Map(),
      };
    case "SET_SUGGESTED_RATING_LEVEL": {
      if (state.revealed.has(state.index)) {
        return {
          ...state,
          revealed: getRevealedState(state, action.payload.ratingLevel),
        };
      }
      return {
        ...state,
        currentSuggestedRatingLevel: action.payload.ratingLevel,
      };
    }
    case "RESET":
      return {
        ...createInitialState("unrated_components"),
      };
    case "CHANGE_MODE":
      return {
        ...createInitialState(action.payload),
        status: "started",
      };
    case "REVEAL": {
      return {
        ...state,
        revealed: getRevealedState(state, action.payload.suggestedRatingLevel),
        currentSuggestedRatingLevel: 0,
      };
    }
    default:
      return state;
  }
}

function getRevealedState(state: PhraseTestState, payloadRatingLevel: number) {
  const revealed = new Map(state.revealed);
  // Use either current suggested rating level or payload rating level
  const suggestedRatingLevel =
    payloadRatingLevel > state.currentSuggestedRatingLevel ? payloadRatingLevel : state.currentSuggestedRatingLevel;

  revealed.set(state.index, suggestedRatingLevel);
  return revealed;
}
