import { Button, Centered, FaceliftInput, ModalDeprecated } from "@rocket/ui";

import API from "@rocket/shared/res/Api";
import type { FormEvent } from "react";
import type { LaravelValidationError } from "@rocket/types";
import styles from "./CompleteAccountModal.module.scss";
import { updateProfile } from "@rocket/shared/store/user/actions";
import useActiveCourse from "@rocket/shared/hooks/useActiveCourse";
import { useState } from "react";
import { useStoreDispatch, useStoreSelector } from "../store";
import useTranslation from "@rocket/shared/hooks/useTranslation";

export default function CompleteAccountModal() {
  const t = useTranslation();
  const dispatch = useStoreDispatch();
  const [user] = useStoreSelector((store) => [store.user, store.preferences.activeCourse?.name]);
  const [firstName, setFirstName] = useState(user.profile?.first_name || "");
  const [lastName, setLastName] = useState(user.profile?.last_name || "");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<LaravelValidationError | null>(null);
  const activeCourse = useActiveCourse();
  const isTrial = user.products.paid.length === 0;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (user.hasMissingName) {
      if (firstName === "Rocket") {
        setError({ message: "Please update your first name", errors: {} });
        return;
      }

      if (lastName === "Learner") {
        setError({ message: "Please update your last name", errors: {} });
        return;
      }
    }

    const params: { [key: string]: string } = {
      first_name: firstName,
      last_name: lastName,
    };

    if (user.hasMissingPassword) {
      params.password = password;
    }

    try {
      setLoading(true);
      await API.post("v2/user/update-blank-details", params);
      dispatch(
        updateProfile({
          first_name: firstName,
          last_name: lastName,
        }),
      );
    } catch (e) {
      setError({ message: `${t("unknown-error")} ${t("please-try-again")}`, errors: {} });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalDeprecated.CardContainer verticallyCentered isOpen sticky loading={loading}>
      <div className={styles.container}>
        <Centered>
          <h5 className={"font-sans font-semibold"}>
            {isTrial
              ? t("final-step-trial-{language}", { language: activeCourse?.name ? activeCourse.name : "Languages" })
              : `Rocket ${activeCourse?.name || "Languages"}: ${t("final-step")}`}
          </h5>
        </Centered>
        <hr className={"my-2"} />
        {error && <div className="mb-2 text-rocketred">{error.message}</div>}
        <p>
          {isTrial
            ? t("set-your-account-password-and-get-instant-access-to-rocket-trial-{language}", {
                language: activeCourse?.name || "Languages",
              })
            : t("set-your-account-password-and-get-instant-access-to-rocket-{language}", {
                language: activeCourse?.name || "Languages",
              })}
        </p>
        <form onSubmit={handleSubmit} className={"mb-12 flex flex-col gap-4 pt-4"}>
          {user.hasMissingName && (
            <>
              <FaceliftInput
                type="text"
                name="first-name"
                label={t("first-name")}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
              <FaceliftInput
                type="text"
                name="last-name"
                label={t("last-name")}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </>
          )}
          {user.hasMissingPassword && (
            <>
              <div>
                <FaceliftInput
                  type="password"
                  name="password"
                  label="Set Password"
                  placeholder={t("set-your-account-password")}
                  value={password}
                  minLength={6}
                  maxLength={255}
                  autoComplete="new-password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <p className="mt-1 text-sm lowercase">*{t("minimum-of-six-characters")}</p>
              </div>
            </>
          )}
          <Button fill className={"mt-4"} type="submit" loading={loading} color="error">
            {user.hasMissingPassword ? t("save-password") : t("save-details")}
          </Button>
          <div className={"mt-4"}>
            {isTrial
              ? t("complete-account-email-reminder-trial-{language}", {
                  language: activeCourse?.name || t("your-language-of-choice"),
                })
              : t("complete-account-email-reminder-{language}", {
                  language: activeCourse?.name || t("your-language-of-choice"),
                })}
          </div>
        </form>
      </div>
    </ModalDeprecated.CardContainer>
  );
}
