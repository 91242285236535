import { FiChevronLeft } from "react-icons/fi";

import { Link } from "react-router-dom";
import { ReactNode } from "react";
import ToolIcon from "~/components/icons/ToolIcon";
import classes from "./Sidebar.module.scss";
import { clsx } from "clsx";
import useActiveCourse from "@rocket/shared/hooks/useActiveCourse";
import useActiveProduct from "@rocket/shared/hooks/useActiveProduct";
import { useBannerStore } from "~/layouts/components/Banner/Banner.store";
import useTranslation from "@rocket/shared/hooks/useTranslation";
import { useSidebar } from "./Sidebar.store";

export default function Sidebar(props: { children: ReactNode }) {
  return <aside>{props.children}</aside>;
}

export function StickySidebar(props: { children: ReactNode }) {
  const isBannerVisible = useBannerStore((s) => s.visible);

  return (
    <aside
      className={clsx(
        classes.StickySidebar,
        "fixed w-52 md:sticky md:w-auto",
        isBannerVisible && classes.bannerVisible,
      )}
    >
      <div
        // Padding is needed for focus outline styling
        className={clsx(classes.StickySidebar__content, "tiny-scrollbar flex flex-col overflow-auto p-2")}
      >
        {props.children}
      </div>
    </aside>
  );
}

Sidebar.BackButton = function SidebarbackButton(props: { label: string; to: string }) {
  return (
    <Link
      to={props.to}
      className={
        "flex h-14 w-full items-center justify-start gap-2 rounded-lg bg-surface2 p-4 font-sans text-sm font-medium text-text1 hover:bg-brand2 focus:outline-none focus:ring focus-visible:ring dark:bg-surface2 dark:text-white hover:dark:bg-brand"
      }
    >
      <FiChevronLeft aria-hidden className="h-6 w-6 md:h-4 md:w-4" />
      <span className={"inline-block"}>{props.label}</span>
    </Link>
  );
};

Sidebar.Section = (props: {
  title?: string;
  left?: JSX.Element;
  children: ReactNode;
  className?: string;
  contentClassName?: string;
}) => {
  const { title, left, children, className, contentClassName } = props;
  return (
    <div className={clsx("mb-2", className)}>
      {title && left && (
        <div className="hidden h-12 items-center md:flex">
          {left}
          <h4 className="font-sans text-sm font-bold tracking-wide text-text2">{title}</h4>
        </div>
      )}

      <div className={clsx("rounded-lg bg-surface2", contentClassName)}>{children}</div>
    </div>
  );
};

Sidebar.Anchor = function SidebarAnchor(
  props: {
    href?: string;
    icon?: ReactNode;
    label: string;
    active?: boolean;
    use?: "a" | React.ComponentType<any>;
    to?: string;
    children?: ReactNode;
  } & React.HTMLAttributes<HTMLAnchorElement>,
) {
  const { active, onClick, icon, label, className, use: T = "a", children, ...rest } = props;
  const sidebarExpanded = useSidebar((s) => s.state === "open");
  return (
    <T
      className={clsx(
        active && "bg-brand2 dark:bg-neutral-700 dark:text-white",
        "flex h-12 w-full cursor-pointer items-center gap-2 px-5 text-sm font-medium text-text1 hover:bg-brand2 hover:dark:bg-neutral-700 hover:dark:text-white",
        className,
      )}
      title={label}
      onClick={onClick}
      {...rest}
    >
      {icon && <span className="text-brand">{icon}</span>}
      <div className={clsx("overflow-hidden", classes.anchorLabel, sidebarExpanded && classes.expanded)}>{label}</div>
      {children}
    </T>
  );
};

Sidebar.ToolsLink = function ToolsLink(props: { className?: string }) {
  const activeCourse = useActiveCourse();
  const activeProductId = useActiveProduct()?.id;
  const t = useTranslation();
  // Mackin users & ebook products can't access tools
  if (activeCourse?.slug === "ebook") {
    return null;
  }

  return (
    <Link
      to={`/members/products/${activeProductId}/tools/leaderboard`}
      className={clsx(
        "flex h-14 w-full items-center rounded-md bg-surface2 p-3 font-bold text-brand hover:bg-brand2 dark:text-white dark:hover:bg-brand md:h-16 md:p-5",
        props.className,
      )}
    >
      <ToolIcon />
      <span className="ml-2">{t("tools")}</span>
    </Link>
  );
};
