import { useCallback, useContext } from "react";
import usePhraseTest, { actions as phraseTestActions } from "../../../hooks/usePhraseTest/usePhraseTest";

import Instructions from "./includes/Instructions";
import LessonContext from "../../../context/LessonContext";
import Phrasebox from "../../Phrasebox";
import RateableTest from "../RateableTest";
import RateableTestContext from "../includes/context";
import { RateableTestTypeIds } from "../../../utils/constants";
import { RatedPhrase } from "../../../hooks/useRocketRecord/types";
import RatingButtons from "../RateableTestUI/buttons/RatingButtons";
import useIsFocusedWithin from "../../../hooks/useIsFocusedWithin";
import useTranslation from "../../../hooks/useTranslation";
import { OutlineButton } from "@rocket/ui";

function KnowIt({ rateableTestId }: { rateableTestId: number }) {
  const t = useTranslation();
  const lesson = useContext(LessonContext);

  const phraseTest = usePhraseTest({
    testTypeId: RateableTestTypeIds.KNOW_IT,
    lessonId: lesson.id,
    rateableTestId,
    mode: "unrated_components",
  });

  return (
    <RateableTest
      phraseTest={phraseTest}
      testTypeId={RateableTestTypeIds.KNOW_IT}
      testName={t("know-it")}
      testSubheading={t("know-it-subheading")}
      instructions={<Instructions />}
    >
      <KnowItPhraseTest />
    </RateableTest>
  );
}

export const KnowItPhraseTest = () => {
  const { phraseTest, testContainerRef } = useContext(RateableTestContext);
  const didReveal = phraseTest.state.revealed.has(phraseTest.state.index);
  const currentPhrase = phraseTest.components.testPhrases[phraseTest.state.index];
  const currentTestIsFocused = useIsFocusedWithin(testContainerRef);

  /**
   * Occurs when RR has finished
   */
  const onRecordFinish = useCallback(
    (params: { phraseId: number; result?: RatedPhrase; blobUrl?: string }) => {
      const { result } = params;
      if (!result) {
        return;
      }
      if (result.ratingLevel === 3) {
        phraseTest.methods.dispatch(phraseTestActions.reveal(result.ratingLevel));
      } else if (result.percentage > 10) {
        phraseTest.methods.dispatch(phraseTestActions.setSuggestedRatingLevel(result.ratingLevel));
      }
    },
    [phraseTest.methods],
  );

  function focusTestContainer() {
    testContainerRef.current?.focus({ preventScroll: true });
  }
  return (
    <div className="space-y-6">
      {currentPhrase && (
        <Phrasebox
          key={`${currentPhrase?.id}`}
          phrase={currentPhrase}
          playOnMount={didReveal}
          disablePlayer={!didReveal}
          // Only display the translation while not revealed
          getPhraseStringOptions={(_phraseString, index) => ({
            className:
              !didReveal && index !== currentPhrase.strings.length - 1
                ? "before:content-['...']  [&>span]:invisible"
                : undefined,
            hideNotations: false,
            notationReplacerText: !didReveal ? "This" : undefined,
          })}
          onRecordFinish={onRecordFinish}
          handleKeyEvents={currentTestIsFocused}
        />
      )}

      <RatingButtons RevealButtonComponent={OutlineButton} onReveal={focusTestContainer} onRate={focusTestContainer} />
    </div>
  );
};

export default KnowIt;
