import { Link } from "react-router-dom";
import { clsx } from "clsx";

export default function UnderlinedLink(props: { to: string; children: string; className?: string }) {
  return (
    <Link
      to={props.to}
      className={clsx(
        "inline-block text-sm font-bold uppercase text-brand underline hover:text-brand-dark dark:text-rocketblue",
        props.className,
      )}
    >
      {props.children}
    </Link>
  );
}

export function UnderlinedLinkButton(props: { onClick(): void; children: string; className?: string }) {
  return (
    <button
      className={clsx(
        "inline-block text-sm font-bold uppercase text-brand underline hover:text-brand-dark dark:text-rocketblue",
        props.className,
      )}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
