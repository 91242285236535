import type { ReactNode } from "react";
import useTranslation from "@rocket/shared/hooks/useTranslation";
import { Tooltip } from "@rocket/ui";
import type { RateableTestLocaleKey } from "@rocket/types";
import { RateableTestMetadata } from "@rocket/shared/utils/constants-web";

interface RateableTestTooltipProps {
  ratingLevel: number;
  testTypeId: number;
  children: ReactNode;
  markedAsComplete?: boolean;
}

export default function RateableTestTooltip({
  testTypeId,
  children,
  ratingLevel,
  markedAsComplete,
}: RateableTestTooltipProps) {
  const t = useTranslation();
  const localeKey = RateableTestMetadata[testTypeId]?.localeKey;

  if (!localeKey) {
    console.warn(`No locale key found for rateable test type ${testTypeId}`);
    return <>{children}</>;
  }
  const label = () => <TooltipLabelRenderer localeKey={localeKey} ratingLevel={ratingLevel} />;
  return (
    <Tooltip mode="fixed" label={markedAsComplete ? t("marked-as-complete") : label}>
      {children}
    </Tooltip>
  );
}

function TooltipLabelRenderer(props: { localeKey: RateableTestLocaleKey; ratingLevel: number }) {
  const t = useTranslation();
  const ratingText = [t("todo"), t("hard"), t("good"), t("easy")];

  return (
    <>
      {t(props.localeKey)}
      <br />
      {ratingText[props.ratingLevel] || ratingText[0]}
    </>
  );
}
