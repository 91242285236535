export default function SortItIcon({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_838_2667)">
        <path
          d="M28.2936 8.2508L23.7843 11.7317C22.866 12.4408 21.5474 11.6694 21.7155 10.5162L21.8559 9.54418H21.4853C18.619 9.54418 16.0285 11.2424 14.8862 13.8706L13.6767 16.6524C11.753 21.0772 7.39242 23.9357 2.56828 23.9357H0.479716C0.214673 23.9357 0 23.721 0 23.4559V19.7381C0 19.4731 0.214673 19.2584 0.479716 19.2584H2.72359C5.58929 19.2584 8.17976 17.5602 9.32208 14.932L10.5316 12.1502C12.3563 7.95296 16.5004 4.86695 21.8523 4.86695L21.7155 3.92551C21.5479 2.77563 22.8639 1.99861 23.7843 2.71062L28.2936 6.19096C28.9696 6.71343 28.968 7.73091 28.2936 8.2508ZM28.2936 20.5519L23.7843 17.0709C22.8623 16.359 21.5478 17.1374 21.7155 18.2852L21.8559 19.2584H21.4853C19.1494 19.2584 16.9969 18.1308 15.6622 16.2907C15.442 15.9869 14.9738 16.0436 14.8313 16.3906C14.4913 17.2189 14.0082 18.3761 12.9899 19.7161C12.8463 19.9052 12.8605 20.1723 13.0272 20.3413C16.925 24.2908 21.7386 23.9119 21.8523 23.9357L21.7155 24.8771C21.5469 26.0334 22.8711 26.7975 23.7843 26.092L28.2936 22.611C28.9687 22.0906 28.969 21.0725 28.2936 20.5519ZM2.56828 4.86701H0.479716C0.214793 4.86701 0 5.0818 0 5.34672V9.06452C0 9.32945 0.214793 9.54424 0.479716 9.54424H2.72359C5.05693 9.54424 7.20767 10.6699 8.54254 12.5072C8.76045 12.8071 9.22194 12.7561 9.36975 12.416C9.83544 11.3446 10.2497 10.3617 11.2188 9.08581C11.3641 8.89464 11.3438 8.62648 11.1748 8.45594C8.92056 6.18065 5.82633 4.86701 2.56828 4.86701Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_838_2667">
          <rect width="28.8" height="28.8" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
