import AwardIcon from "~/components/icons/AwardIcon";
import BarChartIcon from "~/components/icons/BarChartIcon";
import BookmarkIcon from "~/components/icons/BookmarkIcon";
import EditIcon from "~/components/icons/EditIcon";
import MessageCircleIcon from "~/components/icons/MessageCircleIcon";
import PocketIcon from "~/components/icons/PocketIcon";
import SearchIcon from "~/components/icons/SearchIcon";
import ThumbsUpIcon from "~/components/icons/ThumbsUpIcon";

export type SidebarItem = {
  localeTitle: string;
  path: string;
  icon?: React.ComponentType<any>;
  hiddenForCourse?: string[];
  hiddenForProductLevel?: number[];
  hiddenForType?: number[];
  lockedForType?: number[];
  hiddenForMackinUsers?: boolean;
};

export const sidebarItems = [
  {
    localeTitle: "leaderboard",
    path: "leaderboard",
    icon: BarChartIcon,
  },
  {
    localeTitle: "benchmark",
    path: "benchmark",
    icon: PocketIcon,
    hiddenForCourse: ["sign-language", "ingles", "english"],
    hiddenForProductLevel: [4],
    hiddenForType: [
      13, // play the part
    ],
  },
  {
    localeTitle: "rocket-certification",
    path: "rocket-certification",
    icon: AwardIcon,
    hiddenForCourse: ["sign-language", "english"],
    hiddenForProductLevel: [
      4, // RF level 2
    ],
    lockedForType: [
      2, // trial
      10, // guest
    ],
    hiddenForType: [
      13, // play the part
    ],
  },
  /*
  {
    localeTitle: "custom-flashcards",
    path: "flashcards",
    hiddenForCourse: new Set(["sign-language", "ingles"]),
  },
  */
  {
    localeTitle: "saved-notes",
    path: "notes",
    icon: EditIcon,
  },
  {
    localeTitle: "saved-vocab",
    path: "vocab",
    hiddenForCourse: ["sign-language"],
    icon: BookmarkIcon,
  },
  {
    localeTitle: "rocket-recommends",
    path: "rocket-recommends",
    icon: ThumbsUpIcon,
    hiddenForCourse: ["ingles"],
    lockedForType: [
      2, // trial
      10, // guest
    ],
    // play the part
    hiddenForType: [13],
    hiddenForMackinUsers: true,
  },
  {
    localeTitle: "forum",
    path: "/members/forum",
    icon: MessageCircleIcon,
  },
  {
    localeTitle: "search",
    path: "search",
    icon: SearchIcon,
  },
];
