import * as constants from "./constants";
import { action } from "typesafe-actions";
import type { ModuleTestPagePayload, ModuleTestsPayload } from "@rocket/types";

//
// Actions that handle the request to /api/v1/product/1/module-page-data
//
export function sagaRequestPageData(payload: { productId: number }) {
  return action(constants.SAGA_REQUEST_PAGE_DATA, payload);
}

export function pageRequestSuccess(payload: { productId: number; data: ModuleTestPagePayload }) {
  return action(constants.PAGE_DATA_REQUEST_SUCCESS, payload);
}

export function pageRequestError(payload: { productId: number; errorText: string }) {
  return action(constants.PAGE_DATA_REQUEST_ERROR, payload);
}

//
// Actions that handle the request to /api/v1/grouped-test/module/97/tests
//
export function sagaRequestModuleTests(payload: { moduleId: number; numComponentsPerTest: number }) {
  return action(constants.SAGA_REQUEST_MODULE_TESTS, payload);
}

export function moduleTestsRequestSuccess(payload: { moduleId: number; data: ModuleTestsPayload }) {
  return action(constants.MODULE_TESTS_REQUEST_SUCCESS, payload);
}

export function moduleTestsRequestError(payload: { moduleId: number; errorText: string }) {
  return action(constants.MODULE_TESTS_REQUEST_ERROR, payload);
}

// Dynamic UI updates

export function resetTestSuccess(payload: { moduleId: number; productId: number }) {
  return action(constants.RESET_TEST_SUCCESS, payload);
}

// Request + does optimistic client modifications
export function sagaRateSubtest(payload: {
  moduleId: number;
  productId: number;
  testTypeId: number;
  rateableTestId: number;
  rating: number;
}) {
  return action(constants.SAGA_RATE_SUBTEST, payload);
}

/**
 * Rates the module test as a whole
 */
export function sagaRateTest(payload: {
  /** Product Id */
  productId: number;
  /** Lesson Id (temp fake one is fine) */
  lessonId: number;
  /** Module ID to fetch the rateable test from */
  moduleId: number;
}) {
  return action(constants.SAGA_RATE_TEST, payload);
}

export function setTestRating(payload: {
  /** Product Id */
  productId: number;
  /** For the dashboard */
  rateableTestId: number;
  /** 0 - 100 */
  rating: number;
}) {
  return action(constants.SET_TEST_RATING, payload);
}
