import { getUniqueNonExcludedLessonPhrases } from "../../../hooks/usePhraseTest/useAllComponents";
import { SharedRootState } from "../../../store/types";
import { getWritingSystemIndex } from "../../../utils";
import { RateableTestTypeIds } from "../../../utils/constants";
import { purgeString } from "../../../utils/stringUtils";
import type { Phrase } from "@rocket/types";
import { createSelector } from "reselect";
import { randomize } from "../utils";
import type {
  RateableTestSelectors,
  UnratedComponentsParams,
  AllComponentsParams,
  NonEasyComponentsParams,
} from "../types";
import getCharacterButtons from "../../../components/RateableTests/SortIt/utils/getCharacterButtons";

export default function createPhraseTestSelectors(): RateableTestSelectors<Phrase> {
  return {
    // Sort by first phrase length
    sort: (phrase) => randomize(phrase.strings[0]?.text.length || 0),
    allComponents: createSelector(
      [
        ({ testTypeId, state }: AllComponentsParams) => phraseFilters[testTypeId]?.(state),
        ({ state, lessonId }: AllComponentsParams) => state.lesson.entities.lesson_phrase_ids[lessonId],
        ({ state }: AllComponentsParams) => state.lesson.entities.phrases,
      ],
      (filterFn, lessonPhraseIds, allPhrases) => {
        // Note: globally memoized so we don't have to re-filter on every phrase test
        const uniquePhrases = getUniqueNonExcludedLessonPhrases(lessonPhraseIds, allPhrases);
        return filterFn ? uniquePhrases.filter(filterFn) : uniquePhrases;
      },
    ),
    unratedComponents: createSelector(
      [
        ({ state, rateableTestId }: UnratedComponentsParams<Phrase>) =>
          state.lesson.entities.user_rateable_test_component_ratings[rateableTestId],
        ({ components }: UnratedComponentsParams<Phrase>) => components,
      ],
      (componentRatings = [], components) => {
        return components.filter((phrase) => !componentRatings.find((c) => c.component_id === phrase.id));
      },
    ),
    nonEasyComponents: createSelector(
      [
        ({ state, rateableTestId }: NonEasyComponentsParams<Phrase>) =>
          state.lesson.entities.user_rateable_test_component_ratings[rateableTestId],
        ({ components }: NonEasyComponentsParams<Phrase>) => components,
      ],
      (componentRatings = [], components) => {
        return components.filter((phrase) =>
          componentRatings.find((c) => c.component_id === phrase.id && c.value < 100),
        );
      },
    ),
  };
}

type ComponentFilterFunction = (item: Phrase) => boolean;
type ComponentFilterItem = (state: SharedRootState) => ComponentFilterFunction;

const phraseFilters: Record<number, ComponentFilterItem | undefined> = {
  [RateableTestTypeIds.WRITE_IT]: (state) => createWriteItPhraseFilter(state, false),
  [RateableTestTypeIds.WRITE_IT_NATIVE]: (state) => createWriteItPhraseFilter(state, true),
  [RateableTestTypeIds.SORT_IT]: (state) => createSortItPhraseFilter(state, false),
  [RateableTestTypeIds.SORT_IT_NATIVE]: (state) => createSortItPhraseFilter(state, true),
};

function createWriteItPhraseFilter(state: SharedRootState, isNative: boolean) {
  const activeCourseSlug = state.preferences.activeCourse?.slug || "";
  return (phrase: Phrase) => {
    const index = getWritingSystemIndex(activeCourseSlug, phrase, isNative);
    const psText = phrase.strings[index]?.text?.trim();
    return Boolean(psText && psText !== "-");
  };
}

function createSortItPhraseFilter(state: SharedRootState, isNative: boolean) {
  const activeCourseSlug = state.preferences.activeCourse?.slug || "";
  return (phrase: Phrase) => {
    const { course_id, strings } = phrase;

    // Filter out duplicate kanji/kana phrases from kanji sort it
    if (course_id === 1 && isNative) {
      if (
        !strings[0] ||
        // Note unusual phrase string in RJ 3.8 with one string entry.
        !strings[1] ||
        purgeString(strings[0].text, { punctuation: true }) === purgeString(strings[1].text, { punctuation: true })
      ) {
        return false;
      }
    }

    const mainWsIndex = getWritingSystemIndex(activeCourseSlug, phrase, isNative);
    const buttons = getCharacterButtons({ phrase, phraseStringIndex: mainWsIndex });
    return buttons.length > 1;
  };
}
