import { FaMicrophone, FaSquare } from "react-icons/fa";
import { ReactNode, useContext, useMemo, useRef } from "react";

import AudioButton from "./AudioButton";
import AudioContext from "../../../ui/Audio/AudioContext";
import { IconButton } from "@rocket/ui/Button";
import { RocketRecordContext } from "../../../context/RocketRecordContext";
import StoreRatingButton from "./StoreRatingButton";
import UserRecordingPlaybackButton from "./UserRecordingPlaybackButton";
import { clsx } from "clsx";
import { shallowEqual } from "react-redux";
import usePhraseBoxHotkey from "../../../hooks/usePhraseBoxHotkey";
import useAudioMinified from "../../../hooks/useAudioMinified";

interface Props {
  /** Shows the rating instead of record button */
  showRating?: boolean;
  /** Hides the record button */
  hideRecordButton?: boolean;
  /** Disables the recording button */
  recordingDisabled?: boolean;
  /** Hide the audio player */
  hidePlayer?: boolean;
  /** Leave player showing, but in disabled state */
  disablePlayer?: boolean;
  /** Whether to initialize with store rating */
  initializeWithRating: boolean;
  // Inline audio player props
  /** Whether the inline player should play when mounted */
  playOnMount?: boolean;
  /** Whether recording should be played on mount */
  playRecordingOnMount?: boolean;
  /** Occurs when inline player playback finishes */
  onPlayFinish?: () => void;
  /** Occurs when record button is clicked */
  onRecordButtonClick?: () => void;
  /** Whether the hotkeys for "P" / "R" should be handled */
  handleKeyEvents?: boolean;
  /** Manually pass in lesson id for phrases accessed outside of the lesson, eg: in Search */
  lessonId?: number;
  /** Switch the mode in which rocket record hides buttons */
  hideMode?: "hidden" | "invisible";
  /** Handle for audio player */
  audioPlayerRef?: React.MutableRefObject<ReturnType<typeof useAudioMinified> | null>;
  /** Show this rating instead of the store rating */
  RatingButton?: ReactNode;
}

/**
 * Displays the Play, Record & Percentage Indicator buttons
 */
export default function RocketRecordControls(props: Props) {
  const context = useContext(RocketRecordContext);
  const audioContext = useContext(AudioContext);
  const audioPlayerRef = useRef<ReturnType<typeof useAudioMinified> | null>();

  const {
    hidePlayer,
    disablePlayer,
    hideRecordButton,
    onPlayFinish,
    playRecordingOnMount,
    handleKeyEvents,
    playOnMount,
    hideMode,
    audioPlayerRef: externalAudioPlayerRef,
    RatingButton,
    onRecordButtonClick,
  } = props;

  const state = context?.useRocketRecordState((state) => {
    const { flag, result, blobUrl } = state;
    return {
      isRecognizing: flag.status === "ACTIVE",
      blobUrl,
      flag,
      recordButtonDisabled: props.recordingDisabled || ["STARTING", "FINISHING"].includes(flag.status),
      recordButtonActive: flag.status === "STARTING" || flag.status === "ACTIVE",
      /**
       * Either the user has a result or it's inactive so they can hear themselves
       **/
      shouldDisplayRecordingPlayback:
        context.computed.canUseAudioRecorder && blobUrl && (!!result || flag.status === "INACTIVE"),
      // && !isAndroidMobile()
    };
  }, shallowEqual);

  usePhraseBoxHotkey({
    callbacks: {
      play() {
        audioPlayerRef.current?.playAudio();
      },
      record: ((): (() => unknown) => {
        if (!context || props.recordingDisabled) {
          return () => undefined;
        }
        if (state?.isRecognizing) {
          return context.methods.abort;
        }
        return context.methods.start;
      })(),
    },
    enabled: handleKeyEvents || false,
  });

  const positioning = useMemo(() => {
    // rows heights are resizing based on box content
    const container = (() => {
      if (hideRecordButton) {
        return "grid grid-cols-phrasebox-2 grid-rows-phrasebox-1 gap-2";
      }
      return "grid grid-cols-phrasebox-2 grid-rows-phrasebox-2 gap-2";
    })();

    const playButton = (() => {
      if (hidePlayer) {
        return hideMode || "invisible";
      }
      // if (isPlayIt) return "sm:col-start-1 col-start-2 sm:col-end-1 col-end-2 row-start-1 row-end-1";
      return "col-start-2 col-end-2 row-start-1 row-end-1";
    })();

    const recordButton = (() => {
      if (hideRecordButton) {
        return hideMode || "invisible";
      }
      return "col-start-2 col-end-2 row-start-2 row-end-2";
    })();

    const recordPlaybackButton = (() => {
      if (hideRecordButton || !state?.shouldDisplayRecordingPlayback) {
        return hideMode || "invisible";
      }
      return "col-start-1 col-end-1 row-start-2 row-end-2";
    })();

    return {
      container,
      playButton,
      recordButton,
      recordPlaybackButton,
    };
  }, [hideMode, hidePlayer, hideRecordButton, state?.shouldDisplayRecordingPlayback]);

  if (!context || !state) {
    return null;
  }

  const { methods, phrase } = context;
  const { start, abort } = methods;
  const { isRecognizing, recordButtonDisabled, recordButtonActive, blobUrl } = state;

  return (
    <div className={clsx("print:hidden", positioning.container)}>
      <div
        className={clsx(
          "col-start-1 col-end-1 row-start-1 row-end-1 flex items-center justify-center",
          // When RatingButton is passed in, visibility is managed by the parent
          !RatingButton && hideRecordButton ? hideMode || "invisible" : "",
        )}
      >
        {RatingButton || (
          <StoreRatingButton lessonId={props.lessonId} initializeWithRating={props.initializeWithRating} />
        )}
      </div>

      <div className={positioning.playButton}>
        {phrase?.audio_url && (
          <AudioButton
            ref={(value) => {
              audioPlayerRef.current = value;
              if (externalAudioPlayerRef) {
                externalAudioPlayerRef.current = value;
              }
            }}
            title="Play Tutor Audio"
            color="secondary"
            phraseId={context.phrase.id}
            disabled={isRecognizing || disablePlayer}
            playOnMount={playOnMount}
            source={phrase.audio_url}
            onPlayFinish={onPlayFinish}
            buttonId={`phrase-play-${phrase.id}`}
          />
        )}
      </div>

      <div className={clsx(positioning.recordButton, "relative")}>
        {recordButtonActive && !recordButtonDisabled && <GlowingRecordCircle />}
        <IconButton
          title="Record Voice"
          color="error"
          disabled={recordButtonDisabled}
          active={recordButtonActive}
          onClick={() => {
            onRecordButtonClick?.();
            audioContext.activePlayer?.pause();
            isRecognizing ? abort() : start();
          }}
          id={`phrase-record-${phrase.id}`}
          className="z-100"
        >
          {isRecognizing ? <FaSquare /> : <FaMicrophone />}
        </IconButton>
      </div>

      <div className={positioning.recordPlaybackButton}>
        <UserRecordingPlaybackButton
          phrase={phrase}
          isRecognizing={isRecognizing}
          playRecordingOnMount={playRecordingOnMount}
          blobUrl={blobUrl}
          onPlayFinish={onPlayFinish}
        />
      </div>
    </div>
  );
}

/**
 * Adds a glowing effect to the record button
 */
function GlowingRecordCircle() {
  return (
    <div className="z-1 absolute top-0 !h-[48px] !w-[48px] animate-pingglow rounded-full bg-rocketred-dark text-rocketred-dark" />
  );
}
