export default function HearItIcon({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_838_2347)">
        <path
          d="M5.50519 14.9189C4.42952 14.9189 3.5577 15.6534 3.5577 16.5594V17.4762H3.52828C1.97562 17.4762 0.716797 18.5366 0.716797 19.8444V23.0206C0.716797 24.3287 1.97556 25.3889 3.52828 25.3889H3.5577V26.3058C3.5577 27.2119 4.42946 27.9462 5.50519 27.9462C6.58069 27.9462 7.45256 27.2119 7.45256 26.3058V16.5594C7.45256 15.6534 6.58069 14.9189 5.50519 14.9189Z"
          fill="currentColor"
        />
        <path
          d="M25.27 17.4762H25.2406V16.5594C25.2406 15.6533 24.3688 14.9189 23.2931 14.9189C22.2176 14.9189 21.3457 15.6534 21.3457 16.5594V26.3058C21.3457 27.2119 22.2176 27.9462 23.2931 27.9462C24.3687 27.9462 25.2406 27.2119 25.2406 26.3058V25.3889H25.27C26.8227 25.3889 28.0815 24.3287 28.0815 23.0206V19.8444C28.0815 18.5365 26.8227 17.4762 25.27 17.4762Z"
          fill="currentColor"
        />
        <path
          d="M14.4 0.854004C6.44709 0.854004 0 7.30099 0 15.2539C0 15.9084 0.0442687 16.5524 0.128813 17.1837C0.23085 17.0774 0.339244 16.9755 0.453994 16.8789C0.881719 16.5186 1.377 16.2412 1.91273 16.0564C2.0367 15.3122 2.43304 14.6211 3.04172 14.1084C3.07226 14.0827 3.10371 14.0581 3.13504 14.0334C3.74389 8.34988 8.55473 3.9234 14.4 3.9234C20.2453 3.9234 25.0561 8.34994 25.665 14.0334C25.6964 14.0581 25.7277 14.0827 25.7583 14.1084C26.367 14.6212 26.7633 15.3123 26.8873 16.0564C27.423 16.2412 27.9183 16.5186 28.346 16.8789C28.4608 16.9755 28.5692 17.0774 28.6712 17.1837C28.7557 16.5524 28.8 15.9084 28.8 15.2539C28.8 7.30105 22.3529 0.854004 14.4 0.854004Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_838_2347">
          <rect width="28.8" height="28.8" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
