/* eslint-disable @typescript-eslint/ban-types */
import { useSharedDispatch, useSharedSelector, useSharedStore } from "../store";
import { CustomFlashcard, RateableTestMode, UserComponentRatingEntity } from "@rocket/types";
import { useMemo, useRef, useReducer, useEffect } from "react";
import shortid from "shortid";
import useGetCurrent from "../hooks/useGetter";
import { getRatingValueFromLabel } from "../hooks/useRateableTest/utils";
import { action, ActionType } from "typesafe-actions";
import useRatingGroups from "../hooks/usePhraseTest/useRatingGroups";
import API from "../res/Api";
import {
  asyncResetTest,
  rateCurrentTest,
  updateCurrentTest,
  updateCurrentTestPosition,
} from "../store/customFlashcards/actions";
import { filterAndSortPhrases } from "../hooks/usePhraseTest/useTestPhrases";
import { shuffleArray } from "../utils";
import { CustomFlashcardReduxRatings } from "../store/customFlashcards/types";

export type usePhraseTestProps = {
  /** Rateable Test ID */
  rateableTestId: number;
  /** Rateable test type ID. Depending on the ID, different properties may be extracted from the store */
  testTypeId: number;
  /** Initial Test Mode */
  mode: RateableTestMode;
  /** Callback when the test is complete */
  onComplete?: (rating: number) => void;
  /** Called after a component has been rated */
  onComponentRated?: (props: { componentId: number; ratingLabel: "easy" | "good" | "hard" }) => void;
};

type PhraseIndex = number;
type SuggestedRatingLevel = number;

type PhraseTestState = {
  /** Session status. `"complete"` means that the phrase test was completed in the _current_ session.
   * For displaying the `"complete"` UI, use `computed.hasCompleted` instead. */
  status: "idle" | "started" | "complete";
  /** Current position */
  index: number;
  /** Phrases the user has revealed in the game */
  revealed: Map<PhraseIndex, SuggestedRatingLevel>;
  /** Suggested rating level for the current phrase */
  currentSuggestedRatingLevel: number;
  /** Updates when the game mode changes. Re-calculates/Re-orders the test phrases when changed */
  gameSessionId: string;
  mode: RateableTestMode;
};

const createInitialState = (mode: RateableTestMode): PhraseTestState => ({
  status: "idle",
  index: 0,
  currentSuggestedRatingLevel: 0,
  revealed: new Map(),
  gameSessionId: shortid.generate(),
  mode,
});

/** Differs from `usePhraseTest` by sourcing the list of phrases in the second parameter of this function */
export default function useCustomFlashcardTest(
  props: usePhraseTestProps,
  flashcards: CustomFlashcard[],
  collectionId: number,
) {
  const collection = useSharedSelector((store) => store.customFlashcards.collections[collectionId]);

  /** Initialize state with selected mode (get started, redo hard phrases) */
  const reducerFn = useReducer(reducer, createInitialState(props.mode));
  const [state, dispatch] = reducerFn;

  const store = useSharedStore();
  const dispatchStore = useSharedDispatch();
  const onCompleteListeners = useRef<Function[]>([]);
  const languageName = useSharedSelector((store) => store.preferences.activeCourse?.name || "");

  /** Phrases that will be used in the test. Note: use `allComponents.phrases` to get _all_ phrases */
  const testPhrases = useCustomFlashcardTestCards({
    flashcards,
    memoizeKey: `${reducerFn[0].gameSessionId}${collection?.flashcards}`,
    rateableTestId: props.rateableTestId,
    rateableTestTypeId: props.testTypeId,
    mode: reducerFn[0].mode,
    collectionId,
  });

  const ratingLevel = testPhrases[state.index]?.rating || 0;

  /** Fetches the current state. Use this in functions performing async tasks to prevent accessing stale state */
  const getState = useGetCurrent(state);

  /** Visual indicator 20/X */
  const totalComponents = flashcards.length;

  /** Total number of rated flashcards for the set - populated from db then updated while playing */
  const ratingsLength = collection ? [...Object.keys(collection.ratings)].length : 0;

  /** Visual indicator: X/20 */
  const position = (() => {
    if (
      state.status === "complete" ||
      state.index === testPhrases.length ||
      (flashcards.length === ratingsLength && state.mode !== "non_easy_components")
    ) {
      return totalComponents;
    }
    if (state.status === "idle") {
      /** If idle and ratings exist, return position at ratings */
      if (ratingsLength) {
        return ratingsLength;
      }
      /** Otherwise return position at total components minus test components */
      return totalComponents - testPhrases.length;
    }
    /** Otherwise test is in progress, use state index to determine position */
    return totalComponents - testPhrases.length + state.index + 1;
  })();

  useEffect(() => {
    dispatchStore(updateCurrentTestPosition({ collectionId, position }));
  }, [collectionId, dispatchStore, position]);

  /**
   * Rates the current phrase and either continues or completes the game
   */
  function rate(ratingLabel: "hard" | "good" | "easy") {
    const ratingValue = getRatingValueFromLabel(ratingLabel);
    const currentIndex = getState().index;
    const currentPhrase = testPhrases[currentIndex];

    if (!currentPhrase) {
      return;
    }

    const componentType = "custom_phrase";

    /** Update rating in store */
    dispatchStore(
      updateCurrentTest({
        collectionId,
        flashcardId: currentPhrase.id,
        rating: {
          component_id: currentPhrase.id,
          component_type_id: 12,
          value: ratingValue,
        },
      }),
    );

    // since test is going through store and store action comes after this, it will always be one behind
    const testComplete = (() => {
      if (!collection || !ratingsLength) return false;
      return flashcards.length - 1 <= ratingsLength;
    })();

    const testRating = testComplete
      ? (() => {
          const totalScore = [...Object.values((collection?.ratings || {}) as CustomFlashcardReduxRatings)].reduce(
            (previousValue, currentRating) => previousValue + (currentRating?.value || 0),
            0,
          );
          const testRating = Math.floor(totalScore / totalComponents);
          return testRating;
        })()
      : 0;

    // Send API request to store the rating
    if (testRating) {
      dispatchStore(
        rateCurrentTest({
          collectionId,
          totalRating: testRating,
        }),
      );

      const testRatingData: { productId?: number; testRating?: number } = testRating
        ? {
            productId: store.getState().preferences.activeProduct?.id || 0,
            testRating,
          }
        : {};

      API.get(
        [
          "v2/rate/component/{rateableComponentType:name}/{componentId}/{rateableTest}/{value}",
          {
            "rateableComponentType:name": componentType,
            componentId: currentPhrase.id,
            rateableTest: props.rateableTestId,
            value: ratingValue,
          },
        ],
        testRatingData,
      ).catch((err) => {
        // TODO: undo "updateComponentRatings" / "rateTest" actions
        console.warn(err);
      });
    } else {
      API.get(
        [
          "v2/rate/component/{rateableComponentType:name}/{componentId}/{rateableTest}/{value}",
          {
            "rateableComponentType:name": componentType,
            componentId: currentPhrase.id,
            rateableTest: props.rateableTestId,
            value: ratingValue,
          },
        ],
        {},
      ).catch((err) => {
        // TODO: undo "updateComponentRatings" / "rateTest" actions
        console.warn(err);
      });
    }

    // Used for:
    // - flashcards to add points on rating
    if (props.onComponentRated) {
      props.onComponentRated({ componentId: currentPhrase.id, ratingLabel });
    }

    if (state.index + 1 < testPhrases.length) {
      // Go to next phrase
      dispatch(actions.next());
    } else {
      // Used when in a nested context. (Flashcards need to know when the test completes)
      onCompleteListeners.current.forEach((cb) => cb());
      dispatch(actions.finish());
    }
  }

  function reload() {
    //
  }

  function reset() {
    // Dispatch action to reset rating to 0 for all components on the server and client.
    // screenProps.status will change to 'resetting'.
    dispatchStore(asyncResetTest({ collectionId, rateableTestId: collection?.rateableTestId || 0 }));
    // Reset local state
    dispatch(actions.reset());
  }

  /**
   * Occurs when user presses "Redo X hard phrases" on the test complete screen
   * Resets local state
   */
  function redoNonEasyPhrases() {
    // Reset local state
    dispatch(actions.changeMode("non_easy_components"));
  }

  const ratings = Object.values(collection?.ratings || {}) as UserComponentRatingEntity[];

  return {
    state: {
      ...state,
    },
    status: useTestStatus(props.rateableTestId),
    methods: {
      rate,
      reset,
      reload,
      redoNonEasyPhrases,
      dispatch,
    },
    events: useMemo(() => {
      return {
        registerOnCompleteListener(cb: Function) {
          onCompleteListeners.current.push(cb);
        },
        unregisterOnCompleteListener(cb: Function) {
          onCompleteListeners.current.filter((_cb) => _cb !== cb);
        },
      };
    }, []),
    components: {
      flashcards: testPhrases,
    },
    computed: {
      ratingLevel,
      ratings: useRatingGroups("custom_flashcard", props.rateableTestId, [...ratings]),
      position,
      hasCompleted:
        state.status === "complete" ||
        state.index === testPhrases.length ||
        (flashcards.length === ratingsLength && state.mode !== "non_easy_components"),
      totalComponents,
      languageName,
    },
    rateableTestId: props.rateableTestId,
  };
}

function useTestStatus(rateableTestId: number) {
  const isResetting = useSharedSelector((state) => state.customFlashcards.testResetting[rateableTestId]);
  return isResetting ? "resetting" : "loaded";
}

export const actions = {
  start(initialIndex = 0) {
    return action("START", { initialIndex });
  },
  next() {
    return action("NEXT");
  },
  finish() {
    return action("FINISH");
  },
  reveal(suggestedRatingLevel = 0) {
    return action("REVEAL", { suggestedRatingLevel });
  },
  setSuggestedRatingLevel(ratingLevel: number) {
    return action("SET_SUGGESTED_RATING_LEVEL", { ratingLevel });
  },
  reset() {
    return action("RESET");
  },
  /** Occurs when the game mode has changed */
  changeMode(mode: RateableTestMode) {
    return action("CHANGE_MODE", mode);
  },
};

export type PhraseTestAction = ActionType<typeof actions>;

function reducer(state: PhraseTestState, action: PhraseTestAction): PhraseTestState {
  switch (action.type) {
    case "START": {
      return {
        ...state,
        index: action.payload.initialIndex,
        status: "started",
      };
    }
    case "NEXT":
      return {
        ...state,
        index: state.index + 1,
        currentSuggestedRatingLevel: 0,
      };
    case "FINISH":
      return {
        ...state,
        status: "complete",
        revealed: new Map(),
      };
    case "SET_SUGGESTED_RATING_LEVEL": {
      if (state.revealed.has(state.index)) {
        return {
          ...state,
          revealed: getRevealedState(state, action.payload.ratingLevel),
        };
      }
      return {
        ...state,
        currentSuggestedRatingLevel: action.payload.ratingLevel,
      };
    }
    case "RESET":
      return {
        ...createInitialState("unrated_components"),
      };
    case "CHANGE_MODE":
      return {
        ...createInitialState(action.payload),
        status: "started",
      };
    case "REVEAL": {
      return {
        ...state,
        revealed: getRevealedState(state, action.payload.suggestedRatingLevel),
        currentSuggestedRatingLevel: 0,
      };
    }
    default:
      return state;
  }
}

function getRevealedState(state: PhraseTestState, payloadRatingLevel: number) {
  const revealed = new Map(state.revealed);
  // Use either current suggested rating level or payload rating level
  const suggestedRatingLevel =
    payloadRatingLevel > state.currentSuggestedRatingLevel ? payloadRatingLevel : state.currentSuggestedRatingLevel;
  revealed.set(state.index, suggestedRatingLevel);
  return revealed;
}

function useCustomFlashcardTestCards(props: {
  flashcards: CustomFlashcard[];
  memoizeKey: string;
  rateableTestId: number;
  rateableTestTypeId: number;
  mode: RateableTestMode;
  collectionId: number;
}) {
  const store = useSharedStore();

  function getTestPhrases() {
    const rootStore = store.getState();
    const onlyNonEasyPhrases = Boolean(props.mode === "non_easy_components");
    const collection = rootStore.customFlashcards.collections[props.collectionId];
    const componentRatings = (() => {
      if (collection) {
        const ratingsArray = [...Object.values(collection.ratings)];
        if (onlyNonEasyPhrases) {
          return ratingsArray;
        }
        /** If all components have been rated, pass empty array so nothing is filtered, resetting test phrases to all flashcards */
        /** This should only happen if mode is unrated_components, as need all rated components for non_easy_components */
        return ratingsArray.length === props.flashcards.length ? [] : ratingsArray;
      }
      return [];
    })() as UserComponentRatingEntity[];

    const filteredPhrases = filterAndSortPhrases({
      arr: props.flashcards,
      onlyNonEasyPhrases,
      componentRatings,
    });

    const filteredShuffledPhrases = shuffleArray<CustomFlashcard>(filteredPhrases);
    return filteredShuffledPhrases;
  }

  // Update only when game session id or status changes
  // eslint-disable-next-line
  return useMemo(getTestPhrases, [props.memoizeKey]);
}
