import styles from "./CourseFlagRect.module.scss";

export interface CourseFlagProps {
  courseSlug: string;
}

const LanguageFlagImageLocationsPercentage: Record<string, number> = {
  spanish: 0,
  french: 44,
  "sign-language": 88,
  portuguese: 132,
  korean: 176,
  italian: 220,
  japanese: 264,
  hindi: 308,
  german: 352,
  russian: 396,
  eigo: 440,
  english: 440,
  esl: 440, // ESL is the course slug from our server
  ingles: 440,
  chinese: 484,
  arabic: 528,
  "arabic-msa": 528,
  ebook: 572,
};

export default function CourseFlagRect(props: CourseFlagProps) {
  const { courseSlug } = props;
  const placement = LanguageFlagImageLocationsPercentage[courseSlug];
  return (
    <div className={styles.courseFlag}>
      <div className={styles.border}>
        <div className={styles.sprite} style={{ backgroundPositionY: `-${placement}px` }} />
      </div>
    </div>
  );
}
