import FaceliftPage from "~/components/FaceliftPage";
import LoginForm from "./includes/form";
import UnderlinedLink from "~/components/ui/UnderlinedLink";
import useTranslation from "@rocket/shared/hooks/useTranslation";

export default function LoginPage() {
  const t = useTranslation();
  return (
    <FaceliftPage title="Members Area Login" className="flex justify-center">
      <div className={"flex w-full max-w-[850px] flex-col self-start rounded-lg bg-surface2"}>
        <div className="border-b-2 border-b-brand2 px-4 py-4 md:px-10">
          <h1>{t("hi-there-good-to-see-you")}</h1>
        </div>
        <div className={"p-4 md:p-10"}>
          <h2 className="font-sans text-base">{t("log-in-and-let-s-get-talking")}</h2>

          <div className="my-2">
            <LoginForm />
          </div>

          <div>
            <span className="mr-2">{t("don't-have-an-account")}</span>
            <UnderlinedLink to="/signup">{t("sign-up-for-a-free-trial")}</UnderlinedLink>
          </div>
        </div>
      </div>
    </FaceliftPage>
  );
}
