import { useState } from "react";
// import useInterval from "../../../../hooks/useInterval";
import useMedia from "../../../../hooks/useMedia";
// import { useGlobalTranscriptRocketRecordState } from "./state";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function usePlayerCounter(player: ReturnType<typeof useMedia> | undefined | null) {
  const counterState = useState(-1);
  return counterState;
  /*
  const [counter, setCounter] = counterState;
  const isPlaying = player?.isPlaying;
  const flag = useGlobalTranscriptRocketRecordState((s) => s.flag);
  // Makes sure that the state refers to this player
  const isActive = useGlobalTranscriptRocketRecordState((s) => s.activePlayerId === player?.id);

  const shouldCountdown =
    isActive &&
    flag &&
    player?.phraseMode === "automatic" &&
    !isPlaying &&
    ["INACTIVE", "ABORTED", "ERROR"].includes(flag?.status);

  useEffect(() => {
    setCounter(shouldCountdown ? 3 : -1);
  }, [shouldCountdown, setCounter]);

  useInterval(() => setCounter((c) => c - 1), counter > 0 ? 1000 : null);

  return counterState;
  */
}
