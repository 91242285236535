import { RateableTestTypeIds } from "@rocket/shared/utils/constants";
import DrawIt from "@rocket/shared/components/RateableTests/DrawIt";
import Flashcards from "@rocket/shared/components/RateableTests/Flashcards";
import HearIt from "@rocket/shared/components/RateableTests/HearIt";
import KnowIt from "@rocket/shared/components/RateableTests/KnowIt";
import Quiz from "@rocket/shared/components/RateableTests/Quiz";
import SortIt from "@rocket/shared/components/RateableTests/SortIt";
import TranslateIt from "@rocket/shared/components/RateableTests/TranslateIt";
import WriteIt from "@rocket/shared/components/RateableTests/WriteIt";

export const TestComponentMap = {
  [RateableTestTypeIds.FLASHCARDS]: Flashcards,
  [RateableTestTypeIds.HEAR_IT]: HearIt,
  [RateableTestTypeIds.QUIZ]: Quiz,
  [RateableTestTypeIds.KNOW_IT]: KnowIt,
  [RateableTestTypeIds.WRITE_IT]: WriteIt,
  [RateableTestTypeIds.TRANSLATE_IT]: TranslateIt,
  [RateableTestTypeIds.SORT_IT]: SortIt,
  [RateableTestTypeIds.DRAW_IT]: DrawIt,
};

export const ExtraTestComponentMap = {
  [RateableTestTypeIds.WRITE_IT]: WriteIt,
  [RateableTestTypeIds.WRITE_IT_NATIVE]: WriteIt,
  [RateableTestTypeIds.SORT_IT]: SortIt,
  [RateableTestTypeIds.SORT_IT_NATIVE]: SortIt,
};

export const AllTestsComponentMap = {
  ...TestComponentMap,
  ...ExtraTestComponentMap,
};
