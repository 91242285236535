import { IconButton, PromiseResolver } from "@rocket/ui";
import { Fragment, ReactNode, useCallback, useEffect } from "react";
import API from "@rocket/shared/res/Api";
import { FaTimes, FaCheck } from "react-icons/fa";
import { getStaticPageQuery } from "@rocket/shared/hooks/usePageQuery";
import { resolveAsyncResource } from "@rocket/shared/utils";
import { showSignupModal } from "../../../store/user/actions";
import useCountdown from "@rocket/shared/hooks/useCountdown";
import { useDispatch } from "react-redux";
import usePromise from "@rocket/shared/hooks/usePromise";
import { useLocalStorageWithTimeout } from "@rocket/shared/hooks/useLocalStorageWithTimeout";
import { useStoreSelector } from "../../../store";
import useIsMackinUser from "@rocket/shared/hooks/useIsMackinUser";
import { useBannerStore } from "./Banner.store";
import { clsx } from "clsx";
import { shallow } from "zustand/shallow";

const pageQuery = getStaticPageQuery();

function getButtonVariant(backgroundColor: string) {
  // Convert the hex color to RGB values
  const red = parseInt(backgroundColor.substring(1, 3), 16);
  const green = parseInt(backgroundColor.substring(3, 5), 16);
  const blue = parseInt(backgroundColor.substring(5, 7), 16);

  // Calculate the relative luminance using the formula for sRGB
  const relativeLuminance = (red * 0.2126 + green * 0.7152 + blue * 0.0722) / 255;

  // Compare the relative luminance to a threshold to determine if the color is light or dark
  if (relativeLuminance > 0.7) {
    return "bg-rocketred text-white";
  }

  return "bg-white text-rocketred";
}

function getBannerData(productId: number) {
  const bannerQueryParams: { banner_id?: number; promo_coupon?: string } = {};

  if (pageQuery.coupon) {
    bannerQueryParams.promo_coupon = String(pageQuery.coupon);
  }

  if (pageQuery.banner_id) {
    bannerQueryParams.banner_id = Number(pageQuery.banner_id);
  }

  /*
  return Promise.resolve({
    id: 1,
    is_promo: true,
    promo_override: false,
    title: "Banner Title",
    subtitle: "Banner subtitle",
    percent_off: 60,
    text_color: "red",
    background_color: "yellow",
    target_url: "https://www.rocketlanguages.com/pricing",
    image_url: null,
    expires_at_epoch_seconds: 1231231231233,
  });
  */
  return API.getJson(["v2/banners/product/{product}", { product: productId }], bannerQueryParams);
}

export default function Banner({ productId }: { productId: number }) {
  const promise = useCallback(() => getBannerData(productId), [productId]);
  const { state } = usePromise(promise, { cacheKey: `banner-${productId}` });
  const isMackinUser = useIsMackinUser();
  const isLibraryUser = useStoreSelector((store) => store.user.isMultiuser);
  const { isBannerVisible, hideBanner } = useBannerStore(
    (s) => ({ isBannerVisible: s.visible, hideBanner: s.hide }),
    shallow,
  );

  const [hideEarlyBirdBanner, setHideEarlyBirdBanner] = useLocalStorageWithTimeout("hideEarlyBirdBanner", {
    timeoutInSeconds: 60 * 60 * 24, // hide for one day
    defaultValue: false,
  });

  const [hidePromoBanner, setHidePromoBanner] = useLocalStorageWithTimeout("hidePromoBanner", {
    timeoutInSeconds: 60 * 60 * 24 * 30, // hide for roughly one month
    defaultValue: false,
  });

  useEffect(() => {
    return () => {
      useBannerStore.setState({ visible: false });
    };
  }, []);

  useEffect(() => {
    const loadedState = resolveAsyncResource(state);
    const store = useBannerStore.getState();

    if (!loadedState) {
      store.reset();
      return;
    }

    // Minimum requirement is to have a banner title.
    const shouldHidePromoBanner = loadedState.is_promo && hidePromoBanner;
    const shouldHideEarlyBirdBanner = !loadedState.is_promo && hideEarlyBirdBanner;
    const visible = !!loadedState.title && !shouldHidePromoBanner && !shouldHideEarlyBirdBanner;

    useBannerStore.setState({
      visible,
      isPromo: loadedState.is_promo,
      pricingPageUrl: loadedState.target_url,
      modalImageUrl: loadedState.modal_image_url,
      expiresAtEpochSeconds: loadedState.expires_at_epoch_seconds,
      subtitle: loadedState.subtitle ?? undefined,
      buttonText: loadedState.button_text,
    });
  }, [state, hideEarlyBirdBanner, hidePromoBanner]);

  if (!isBannerVisible || isMackinUser || isLibraryUser || !productId) {
    return null;
  }

  return (
    <PromiseResolver state={state} renderLoading={false}>
      {(banner) => {
        const imageUrl = banner.image_url;
        const backgroundColor = banner.background_color;
        const textColor = banner.text_color;
        const buttonText = banner.button_text;
        const endTimeEpochSeconds = banner.expires_at_epoch_seconds;
        const subtitle = banner.subtitle;
        return (
          <div
            className="fixed left-0 right-0 top-0 z-10 flex h-[var(--banner-height)] w-full items-center py-4"
            style={{
              backgroundColor: `${backgroundColor}`,
              backgroundSize: "contain",
              color: textColor,
            }}
          >
            <BannerLink href={banner.target_url}>
              <div className="flex w-full items-center justify-between px-4">
                <div className="flex justify-start gap-4">
                  <div className="flex-none">
                    <img src={imageUrl} alt={buttonText} />
                  </div>
                  <div className="hidden items-center gap-4 text-sm xl:flex">
                    <div className="flex flex-col gap-2">
                      <div className="flex items-center">
                        <span className="flex-none">
                          <FaCheck />
                        </span>
                        <span className="ml-2">The most "bang for buck" language course</span>
                      </div>
                      <div className="flex items-center">
                        <span className="flex-none">
                          <FaCheck />
                        </span>
                        <span className="ml-2">One payment for 24/7 lifetime access</span>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="flex items-center">
                        <span className="flex-none">
                          <FaCheck />
                        </span>{" "}
                        <span className="ml-2">4.7 star rating from 4800+ reviews</span>
                      </div>
                      <div className="flex items-center">
                        <span className="flex-none">
                          <FaCheck />
                        </span>{" "}
                        <span className="ml-2">60-day money back guarantee</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-center gap-4">
                    <div className="hidden w-60 flex-none flex-col items-center justify-center sm:flex">
                      {endTimeEpochSeconds ? <BannerCountdown endTimeEpochSeconds={endTimeEpochSeconds} /> : null}
                      {subtitle && <div className="text text-center" dangerouslySetInnerHTML={{ __html: subtitle }} />}
                    </div>
                    <div className="flex-none">
                      <button
                        type="button"
                        className={clsx(
                          "btn h-14 rounded-lg px-4 font-medium uppercase",
                          getButtonVariant(backgroundColor),
                        )}
                      >
                        {buttonText}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </BannerLink>
            <div className="pr-4">
              <IconButton
                aria-label="Close"
                color="transparent"
                style={{
                  color: textColor,
                  boxShadow: `inset 0px 0px 0px 2px ${textColor}`,
                }}
                onClick={() => {
                  hideBanner();
                  if (banner.is_promo) {
                    setHidePromoBanner(true);
                  } else {
                    setHideEarlyBirdBanner(true);
                  }
                }}
              >
                <FaTimes />
              </IconButton>
            </div>
          </div>
        );
      }}
    </PromiseResolver>
  );
}

function BannerLink(props: { children: ReactNode; href: string }) {
  const dispatch = useDispatch();
  const isGuest = useStoreSelector((store) => store.user.isGuest);

  if (isGuest) {
    return <button onClick={() => dispatch(showSignupModal())}>{props.children}</button>;
  }

  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer" className="block w-full">
      {props.children}
    </a>
  );
}

function BannerCountdown({ endTimeEpochSeconds }: { endTimeEpochSeconds: number }) {
  const { isRunning, hoursMinutesSeconds } = useCountdown(endTimeEpochSeconds);

  const units = ["days", "hours", "mins", "secs"];
  if (!isRunning) {
    return null;
  }

  return (
    <div className="flex">
      {units.map((key, i) => (
        <Fragment key={key}>
          <div className="flex w-8">
            <div className="flex w-full flex-col text-center">
              <div className="h-6 text-2xl font-black">
                {`${hoursMinutesSeconds[key as keyof typeof hoursMinutesSeconds]}`.padStart(2, "0")}
              </div>
              <span className="text-xs">{key}</span>
            </div>
          </div>
          {i < units.length - 1 && <div className="px-2 pt-0 text-2xl font-black">:</div>}
        </Fragment>
      ))}
    </div>
  );
}
