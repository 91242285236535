import * as jsdiff from "diff";

import { RateableTestTypeIds, WritingSystemLanguages } from "../../../utils/constants";
import { useContext, useMemo, useRef, useState } from "react";
import usePhraseTest, { usePhraseTestProps } from "../../../hooks/usePhraseTest/usePhraseTest";

import Instructions from "./includes/Instructions";
import LessonContext from "../../../context/LessonContext";
import RateableTest from "../RateableTest";
import RateableTestContext from "../includes/context";
import RatingButtons from "../RateableTestUI/buttons/RatingButtons";
import ToneKeyboard from "./includes/ToneKeyboard";
import VirtualKeyboard from "./includes/VirtualKeyboard";
import { getWritingSystemIndex } from "../../../utils";
import { rateWriting } from "./utils";
import useActiveCourse from "../../../hooks/useActiveCourse";
import { useSharedSelector } from "../../../store";
import useTranslation from "../../../hooks/useTranslation";

export type KeyboardState = {
  submitted: boolean;
  textInput: string;
  writingDiff: jsdiff.Change[];
  selectionStart: number;
  percent: number;
  showDiffAndRating: boolean;
};

interface WriteItProps {
  rateableTestId: number;
  rateableTestTypeId?: number;
}

export default function WriteIt({ rateableTestId, rateableTestTypeId }: WriteItProps) {
  const t = useTranslation();
  const activeCourse = useActiveCourse();
  const isNative = rateableTestTypeId === RateableTestTypeIds.WRITE_IT_NATIVE;
  const lesson = useContext(LessonContext);

  const phraseTestProps = useMemo(
    (): usePhraseTestProps => ({
      testTypeId: rateableTestTypeId || RateableTestTypeIds.WRITE_IT,
      lessonId: lesson.id,
      rateableTestId,
      mode: "unrated_components",
      phraseFilter(phrase) {
        const index = getWritingSystemIndex(activeCourse?.slug || "spanish", phrase, isNative);
        const psText = phrase.strings[index]?.text?.trim() || "";
        return Boolean(psText && psText !== "-");
      },
    }),
    [rateableTestTypeId, lesson.id, rateableTestId, activeCourse, isNative],
  );
  const phraseTest = usePhraseTest(phraseTestProps);

  const currentPhrase = phraseTest.components.testPhrases[phraseTest.state.index];
  const phraseStringIndex = getWritingSystemIndex(activeCourse?.slug || "spanish", currentPhrase, false);

  // Get testName for romanized romantic languages, eg: Pinyin
  const testName = (() => {
    if (!currentPhrase || !phraseStringIndex) {
      return "";
    }
    return WritingSystemLanguages[currentPhrase.strings[phraseStringIndex]?.writing_system_id || 0];
  })();

  return (
    <RateableTest
      phraseTest={phraseTest}
      testName={`${t("write-it")} ${testName}`}
      testSubheading={t("write-it-subheading")}
      testTypeId={phraseTestProps.testTypeId}
      instructions={<Instructions />}
    >
      <WriteItPhraseTest />
    </RateableTest>
  );
}

const baseState: KeyboardState = {
  submitted: false,
  textInput: "",
  writingDiff: [],
  selectionStart: 0,
  percent: 0,
  showDiffAndRating: false,
};

export function WriteItPhraseTest() {
  const { phraseTest } = useContext(RateableTestContext);
  const textRef = useRef<HTMLTextAreaElement>(null);
  const currentPhrase = phraseTest.components.testPhrases[phraseTest.state.index];
  const activeCourseSlug = useSharedSelector((store) => store.preferences.activeCourse?.slug || "spanish");
  const phraseStringIndex = getWritingSystemIndex(activeCourseSlug, currentPhrase, false);
  const [state, setState] = useState(baseState);

  const handleReveal = () => {
    const rating = rateWriting({
      input: textRef.current?.value || "",
      target: currentPhrase?.strings[phraseStringIndex]?.text || "",
      courseSlug: activeCourseSlug,
    });

    setState((prevState) => ({
      ...prevState,
      submitted: true,
      showDiffAndRating: true,
      writingDiff: rating?.writingDiff || prevState.writingDiff,
      percent: rating?.percentageDisplay || 0,
    }));

    if (rating) {
      return rating.ratingLevel;
    }
  };

  const isRussian = activeCourseSlug === "russian";
  const Keyboard = isRussian ? VirtualKeyboard : ToneKeyboard;

  return (
    <div className="space-y-6">
      <div className="rounded-md bg-rocketorange-light p-4 dark:bg-transparent">
        <Keyboard
          writingSystemId={currentPhrase?.strings[phraseStringIndex]?.writing_system_id || 0}
          phraseTest={phraseTest}
          state={state}
          setState={setState}
          textRef={textRef}
        />
      </div>
      <RatingButtons revealButtonTitle="CHECK" onReveal={handleReveal} onRate={() => setState(baseState)} />
    </div>
  );
}
