import type {
  RateableTestSelectors,
  UnratedComponentsParams,
  AllComponentsParams,
  NonEasyComponentsParams,
} from "../types";
import type { VideoComponent } from "@rocket/types";
import { createSelector } from "reselect";

export default function createTranslateItSelectors(): RateableTestSelectors<VideoComponent> {
  const allComponents = createSelector(
    ({ state, lessonId }: AllComponentsParams) => state.lesson.entities.lesson_components[lessonId],
    (components) => {
      return components
        ?.filter((component) => component.type === "video")
        .map((item) => item.component) as VideoComponent[];
    },
  );

  const unratedComponents = createSelector(
    [
      ({ state, rateableTestId }: UnratedComponentsParams<VideoComponent>) =>
        state.lesson.entities.user_rateable_test_component_ratings[rateableTestId] || [],
      ({ components }: UnratedComponentsParams<VideoComponent>) => components,
    ],
    (componentRatings, components) => {
      const unratedComponents = components.filter(
        (component) => !componentRatings.find((item) => item.component_id === component.id),
      );
      return unratedComponents;
    },
  );

  const nonEasyComponents = createSelector(
    [
      ({ state, rateableTestId }: NonEasyComponentsParams<VideoComponent>) =>
        state.lesson.entities.user_rateable_test_component_ratings[rateableTestId] || [],
      ({ components }: NonEasyComponentsParams<VideoComponent>) => components,
    ],
    (componentRatings, components) => {
      const nonEasyRatings = componentRatings.filter((item) => item.value < 100);

      const nonEasyComponents = components.filter((component) =>
        nonEasyRatings.some((item) => item.component_id === component.id),
      );

      return nonEasyComponents;
    },
  );

  return {
    allComponents,
    unratedComponents,
    nonEasyComponents,
  };
}
