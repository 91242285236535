import { useState } from "react";
import CongratulationsMessage from "./includes/CongratulationsMessage";
import NonEasyPhrasesList from "./includes/NonEasyPhrasesList";
import Button, { OutlineButton } from "@rocket/ui/Button";
import ResetTestButton from "./buttons/ResetTestButton";

interface CompleteProps {
  testName: string;
  completedWithinSession: boolean;
  // contextRef: typeof RateableTestContext | typeof CustomFlashcardsContext;
  numNonEasyPhrases: number;
  onReset(): void;
  onRedoNonEasyPhrases(): void;
  /** optionally passed in to replace normal non-easy phrases component, eg: Custom Flashcards */
  NonEasyPhraseList?: (props: { redo(): void; numNonEasyPhrases: number }) => JSX.Element | null;
  nextActivityTitle?: string;
  nextActivityHref?: string;
}

export default function Complete({
  testName,
  numNonEasyPhrases,
  completedWithinSession,
  onReset,
  onRedoNonEasyPhrases,
  NonEasyPhraseList,
  nextActivityTitle,
  nextActivityHref,
}: CompleteProps) {
  const [mode, setMode] = useState<null | "review">(null);

  const ReviewComponent = NonEasyPhraseList ?? NonEasyPhrasesList;

  return (
    <div className="flex w-full flex-1 flex-col items-center justify-center">
      {!mode ? (
        <>
          {completedWithinSession && (
            <div className="mb-8">
              <CongratulationsMessage />
            </div>
          )}
          <div className="w-min-content flex w-full flex-col items-center justify-center space-y-4 sm:max-w-[400px]">
            {nextActivityTitle && nextActivityHref && (
              <div className="w-full">
                <Button
                  color="primary"
                  use="a"
                  // @ts-ignore
                  href={nextActivityHref}
                  className="w-full uppercase"
                >
                  {nextActivityTitle}
                </Button>
              </div>
            )}
            {numNonEasyPhrases > 0 && (
              <div className="w-full">
                <OutlineButton color="primary" className="w-full" onClick={() => setMode("review")}>
                  REVIEW {numNonEasyPhrases} NON-EASY PHRASES
                </OutlineButton>
              </div>
            )}
            <div className="w-full">
              <ResetTestButton testName={testName} onConfirmReset={onReset} className="w-full" />
            </div>
          </div>
        </>
      ) : (
        <ReviewComponent redo={onRedoNonEasyPhrases} numNonEasyPhrases={numNonEasyPhrases} />
      )}
    </div>
  );
}
