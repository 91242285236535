import { AnchorHTMLAttributes, forwardRef } from "react";
import { Link } from "react-router-dom";

interface DynamicLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  path: string;
  newTab?: boolean;
}

/** Uses a regular anchor tag if the path starts with "http", otherwise uses a react-router link */
export default forwardRef(function DynamicLink(props: DynamicLinkProps, ref: any) {
  const { path, newTab, ...rest } = props;
  if (path.startsWith("http")) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={path}
        ref={ref}
        rel={newTab ? "noopener noreferrer" : ""}
        target={newTab ? "_blank" : undefined}
        {...rest}
      />
    );
  }
  return <Link ref={ref} to={path} {...rest} />;
});
