import { BsCircle, BsCircleFill, BsCircleHalf } from "react-icons/bs";
import { Button, Card, Centered, CircularProgress, ErrorMessage, PromiseResolver, Select } from "@rocket/ui";
import { FiChevronDown, FiChevronRight, FiX } from "react-icons/fi";
import { Fragment, ReactNode, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { LessonCodesByTypeId, PRODUCT_LEVEL_LABEL_BY_ID } from "@rocket/shared/utils/constants";
import { LessonEntity, LessonTypeCode } from "@rocket/types";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { RootAction, RootState } from "~/store/types";
import Sidebar, { StickySidebar } from "~/components/ui/Sidebar";
import dashboardPageSelector, { DashboardSelectedData, ModuleData } from "./includes/utils/selector";
import { getModuleLabel, scrollIntoView, slugify } from "@rocket/shared/utils";
import { useStoreDispatch, useStoreSelector, useStoreTyped } from "../../../store";
import FaceliftModuleItem from "~/pages/members/dashboard/includes/components/FaceliftModuleItem";
import FaceliftPage from "~/components/FaceliftPage";
import { Navigate } from "react-router-dom";
import { Store } from "redux";
import { asyncDashboardRequest } from "~/store/dashboard/actions";
import { create } from "zustand";
import { logout } from "@rocket/shared/store/auth/actions";
import useActiveCourse from "@rocket/shared/hooks/useActiveCourse";
import useActiveProduct from "@rocket/shared/hooks/useActiveProduct";
import useElementObserver from "@rocket/shared/hooks/useElementObserver";
import useProductFromCourseParams from "~/hooks/useProductFromCourseParams";
import useSidebarProducts from "./includes/useSidebarProducts";
import useTranslation from "@rocket/shared/hooks/useTranslation";
import useHasPermission from "@rocket/shared/hooks/useHasPermission";
import RocketMarkdown from "@rocket/shared/components/Lesson/MarkdownComponent/includes/RocketMarkdown";
import { clsx } from "clsx";
import { useSyncedBooleanPreference } from "@rocket/shared/hooks/usePreference";

/** Route: members/course/:courseId/level/:levelId/dashboard */
export function RedirectToDashboardPage() {
  const product = useProductFromCourseParams();
  if (product) {
    return <Navigate to={`/members/products/${product.id}/dashboard`} replace />;
  }
  return <Navigate to="/members/courses" replace />;
}

export default function DashboardPage() {
  const t = useTranslation();
  const dispatch = useStoreDispatch();
  const productId = Number(useParams().productId);
  const dashboard = useStoreSelector(dashboardPageSelector);

  useEffect(() => {
    if (!dashboard) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      dispatch(asyncDashboardRequest({ productId, timezone }));
    }
  }, [dashboard, dispatch, productId]);

  return (
    <FaceliftPage title={t("dashboard<title>")}>
      <FaceliftPage.TwoColumns>
        <DashboardSidebar data={dashboard?.status === "loaded" ? dashboard.data : null} />
        <PromiseResolver
          state={dashboard}
          loading={
            <Centered className="py-20">
              <CircularProgress />
            </Centered>
          }
          error={
            <Card variant="rounded" className="flex justify-center">
              <div className="py-10">
                <ErrorMessage
                  title={PromiseResolver.getErrorText(dashboard, "Network Error")}
                  message="If this error persists, try logging in and out again"
                  actions={
                    <>
                      <Button color="primary" onClick={() => window.location.reload()}>
                        Reload
                      </Button>
                      <Button color="primary" onClick={() => dispatch(logout())}>
                        Log out
                      </Button>
                    </>
                  }
                />
              </div>
            </Card>
          }
        >
          {LoadedDashboardPage}
        </PromiseResolver>
      </FaceliftPage.TwoColumns>
    </FaceliftPage>
  );
}

function LoadedDashboardPage(props: DashboardSelectedData) {
  const { modules, suggestedLessonId } = props;
  const levelId = useActiveProduct()?.level_id || 0;
  const canEditContent = useHasPermission("edit_content");
  const canOnlyViewDrafts = useHasPermission("only_view_draft_lessons");
  const debugEnabled = useStoreSelector((store) => store.preferences.debugEnabled);
  const [searchParams, setSearchParams] = useSearchParams();
  const activeCourse = useActiveCourse();
  const activeProduct = useStoreSelector((store) => store.preferences.activeProduct);
  const isEbook = activeCourse?.slug === "ebook";
  const isTravelogue = activeProduct?.level_id === 4;
  const isPlayThePart = activeProduct?.level_id === 10;
  const lessonTypeFilter = searchParams.get("filter") as LessonTypeCode | undefined;
  const showSectionTitles = !isEbook && !isTravelogue && !isPlayThePart;
  const firstName = useStoreSelector((store) => store.user.profile?.first_name || "");
  const [hideDashboardIntro, setHideDashboardIntro] = useSyncedBooleanPreference("hide_dashboard_intro", false);

  useLayoutEffect(() => {
    setTimeout(() => {
      const selector = searchParams.has("module") ? searchParams.get("module") || "" : `lesson.${suggestedLessonId}`;
      const element = document.getElementById(selector);
      element?.focus();
      if (element) {
        scrollIntoView(element, { instant: true, offset: 100 }); // focus: true,
      }
    }, 16);
    // only want this to run on initial page
    // eslint-disable-next-line
  }, [suggestedLessonId]);

  const lessonTypeCodes = useMemo(() => {
    const ids = new Set<LessonTypeCode>();
    for (const m of modules) {
      for (const section of m.data) {
        for (const lesson of section.lessons) {
          const lessonCode = LessonCodesByTypeId[lesson.lesson_type_id];
          if (lesson.status === "published" && lessonCode) {
            ids.add(lessonCode);
          }
        }
      }
    }
    return Array.from(ids);
  }, [modules]);

  const shouldShowLesson = useCallback(
    (lesson: LessonEntity) => {
      // Users with "only_view_draft_lessons" should only show draft lessons
      if (canOnlyViewDrafts && !canEditContent) {
        return lesson.status === "draft";
      }

      // Show published lessons in all cases
      if (lesson.status === "published") {
        return true;
      }
      // Show draft/hidden lessons if admin debug is enabled
      return canEditContent && debugEnabled;
    },
    [canOnlyViewDrafts, debugEnabled, canEditContent],
  );

  const lessonTypeCodeToCount = useMemo(() => {
    const lessonTypeCodeToCount: [string, number][] = lessonTypeCodes.map((code) => [code, 0]);

    if (lessonTypeCodeToCount.length === 0) {
      return lessonTypeCodeToCount;
    }

    for (const module of modules) {
      for (const section of module.data) {
        const typeToCountPair = lessonTypeCodeToCount.find(([type]) => type === section.code);
        if (!typeToCountPair) {
          continue;
        }

        for (const lesson of section.lessons) {
          if (shouldShowLesson(lesson)) {
            typeToCountPair[1] += 1;
          }
        }
      }
    }

    return lessonTypeCodeToCount;
  }, [modules, lessonTypeCodes, shouldShowLesson]);

  return (
    <div className="mx-2 md:mx-0">
      <DashboardModules>
        {modules
          .map((module, index) => {
            return {
              id: module.id,
              index: index,
              title: module.title,
              description: module.description?.replace(/{{first_name}}/g, firstName),
              suggestedLessonId,
              shouldShowLesson,
              showSectionTitles,
              label: getModuleLabel({ title: module.title, levelId }),
              subsections: module.data.filter(
                (subsection) => !lessonTypeFilter || lessonTypeFilter === subsection.code,
              ),
            };
          })
          .filter((module) => module.subsections.length > 0)
          .map((module, itemIndex) => {
            return (
              <DashboardModuleContainer
                key={`module.${module.id}`}
                id={slugify(module.label)}
                moduleIndex={module.index}
              >
                {module.index === 0 && module.description && !hideDashboardIntro && (
                  <div className="relative mb-10 rounded-lg bg-brand2 p-6 dark:bg-surface2">
                    <Button
                      size="small"
                      type="button"
                      color="transparent"
                      className="absolute right-6 top-4 m-0 h-10 w-10 cursor-pointer rounded-full p-0 text-text2 hover:bg-white dark:hover:bg-gray-700"
                      onClick={() => setHideDashboardIntro(true)}
                    >
                      <FiX className="h-full w-full" />
                    </Button>
                    <RocketMarkdown
                      className="space-y-4 text-brand dark:text-text2"
                      options={{
                        overrides: {
                          h4: {
                            component: ({ children }: React.PropsWithChildren<unknown>) => (
                              <h4 className="text-2xl">👋 {children}</h4>
                            ),
                          },
                          a: ({ children, href, target, rel }) => (
                            <a
                              href={href}
                              target={target}
                              rel={rel}
                              className="cursor-pointer text-[var(--color-link)] underline"
                            >
                              {children}
                            </a>
                          ),
                        },
                      }}
                    >
                      {module.description}
                    </RocketMarkdown>
                  </div>
                )}
                <div className="flex flex-col-reverse items-center justify-between gap-4 lg:flex-row">
                  <h3 className="w-full font-sans text-2xl">{module.label}</h3>
                  {itemIndex === 0 && lessonTypeCodes.length > 1 && (
                    <LessonTypeFilterDropdown
                      productLevelId={activeProduct?.level_id || 1}
                      lessonTypeCodeToCount={lessonTypeCodeToCount}
                      value={(searchParams.get("filter") || "all_lessons") as LessonTypeCode | "all_lessons"}
                      lessonTypeCodes={lessonTypeCodes}
                      onChange={(value) => {
                        if (value === "all_lessons") {
                          searchParams.delete("filter");
                        } else {
                          searchParams.set("filter", value);
                        }
                        setSearchParams(searchParams);
                      }}
                    />
                  )}
                </div>

                <FaceliftModuleItem
                  subsections={module.subsections}
                  suggestedLessonId={module.suggestedLessonId}
                  shouldShowLesson={module.shouldShowLesson}
                  showSectionTitles={module.showSectionTitles}
                />
              </DashboardModuleContainer>
            );
          })}
      </DashboardModules>
    </div>
  );
}

function DashboardModules(props: { children: ReactNode }) {
  useEffect(() => {
    // Clear all visible module elements when unmounting (e.g. adding type filters)
    return () => {
      useVisibleModuleElements.setState({ visible: [] });
    };
  });
  return <div className="flex flex-col gap-8">{props.children}</div>;
}

function LessonTypeFilterDropdown(props: {
  productLevelId: number;
  lessonTypeCodeToCount: [string, number][];
  lessonTypeCodes: LessonTypeCode[];
  value: LessonTypeCode | "all_lessons";
  onChange(value: LessonTypeCode | "all_lessons"): void;
}) {
  const t = useTranslation();
  const { productLevelId, lessonTypeCodes, lessonTypeCodeToCount } = props;

  const numberOfLessons = lessonTypeCodeToCount.reduce((acc, [, count]) => acc + count, 0);
  const products = useActiveCourse()?.products || [];
  const hasToShowLevelInLabel = products.some((product, i) => {
    if (i === 0) {
      return false;
    }

    return product.level_id !== products.at(0)?.level_id;
  });

  const options = useMemo(() => {
    const label =
      productLevelId in PRODUCT_LEVEL_LABEL_BY_ID
        ? PRODUCT_LEVEL_LABEL_BY_ID[productLevelId as keyof typeof PRODUCT_LEVEL_LABEL_BY_ID]
        : `Level ${productLevelId}`;

    return [
      {
        label: hasToShowLevelInLabel ? `All ${label} Lessons (${numberOfLessons})` : `All Lessons (${numberOfLessons})`,
        value: "all_lessons",
      },
      ...lessonTypeCodes.map((code) => {
        const lessonTypeCount = lessonTypeCodeToCount.find(([type]) => type === code)?.[1] || 0;
        return {
          label: `${t(code)} (${lessonTypeCount})`,
          value: code,
        };
      }),
    ];
  }, [lessonTypeCodes, t, lessonTypeCodeToCount, numberOfLessons, productLevelId, hasToShowLevelInLabel]);

  return (
    <label className="flex w-full items-center gap-2">
      <span>Show</span>
      <Select
        fullWidth
        options={options}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value as LessonTypeCode | "all_lessons")}
      />
    </label>
  );
}

const useVisibleModuleElements = create<{ visible: boolean[]; setVisible(index: number, visible: boolean): void }>(
  (set) => {
    return {
      visible: [],
      setVisible: (index, visible) => {
        set((state) => {
          const visibleCopy = [...state.visible];
          visibleCopy[index] = visible;
          return { visible: visibleCopy };
        });
      },
    };
  },
);

type DashboardModuleContainerProps = {
  id: string;
  children: ReactNode;
  moduleIndex: number;
};

const headingObserverOptions = { threshold: 0.3 };

function DashboardModuleContainer({ id, children, moduleIndex }: DashboardModuleContainerProps) {
  const ref = useElementObserver<HTMLHeadingElement>((visible) => {
    setTimeout(() => {
      useVisibleModuleElements.getState().setVisible(moduleIndex, visible);
    }, 32);
  }, headingObserverOptions);

  return (
    <div
      ref={ref}
      id={id}
      className={
        // Add extra top margin on the first module to display the "Show" dropdown
        clsx(moduleIndex === 0 ? "scroll-mt-36" : `scroll-mt-28`)
      }
    >
      {children}
    </div>
  );
}

interface DashboardSidebarProps {
  data: DashboardSelectedData | null;
}

function DashboardSidebar(props: DashboardSidebarProps) {
  const t = useTranslation();
  const sidebarProducts = useSidebarProducts();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <StickySidebar>
      <div className="mb-4">
        <Sidebar.BackButton label={t("courses-and-levels")} to="/members/courses" />
      </div>
      <div className="flex flex-1 flex-col">
        <Sidebar.Section>
          <div className="py-4">
            {sidebarProducts.map((sidebarProduct) => {
              if (!sidebarProduct.isActive) {
                return (
                  <Sidebar.Anchor
                    key={`product.${sidebarProduct.product.id}`}
                    use={Link}
                    to={`/members/products/${sidebarProduct.product.id}/dashboard`}
                    className="justify-between !text-base !font-bold"
                    label={sidebarProduct.label}
                  >
                    <FiChevronRight size={24} />
                  </Sidebar.Anchor>
                );
              }

              if (!isOpen) {
                return (
                  <Sidebar.Anchor
                    key={`product.${sidebarProduct.product.id}`}
                    use={(p) => <div {...p} />}
                    className="justify-between !text-base !font-bold"
                    label={sidebarProduct.label}
                    onClick={() => setIsOpen(true)}
                  >
                    <FiChevronRight size={24} />
                  </Sidebar.Anchor>
                );
              }

              return (
                <Fragment key={`product.${sidebarProduct.product.id}`}>
                  <Sidebar.Anchor
                    use={(p) => <div {...p} />}
                    className="justify-between !text-base !font-bold"
                    label={sidebarProduct.label}
                    onClick={() => setIsOpen(false)}
                  >
                    <FiChevronDown size={24} />
                  </Sidebar.Anchor>
                  <div>
                    {!props.data ? (
                      <ModuleSelectionSkeleton />
                    ) : (
                      props.data.modules.map((m, index) => (
                        <ModuleAnchor
                          key={`anchor.${m.id}`}
                          index={index}
                          moduleData={m}
                          productId={sidebarProduct.product.id}
                        />
                      ))
                    )}
                  </div>
                </Fragment>
              );
            })}
          </div>
        </Sidebar.Section>

        <Sidebar.ToolsLink className="mt-2" />
      </div>
    </StickySidebar>
  );
}

interface ModuleAnchorProps {
  moduleData: ModuleData;
  index: number;
  productId: number;
}

function ModuleAnchor({ moduleData, index, productId }: ModuleAnchorProps) {
  const store = useStoreTyped();
  const activeProduct = useActiveProduct();
  const levelId = activeProduct?.level_id || 1;
  const visibleModules = useVisibleModuleElements((s) => s.visible);
  const [searchParams] = useSearchParams();
  const hasFilter = searchParams.has("filter");
  const Icon = getModuleIcon(moduleData, store);
  const label = getModuleLabel({ title: moduleData.title, levelId });
  const domId = slugify(label);
  const isActive = !hasFilter && visibleModules[index] && !visibleModules[index + 1];

  return (
    <Sidebar.Anchor
      use={Link}
      to={`/members/products/${productId}/dashboard?module=${domId}${
        hasFilter ? `&filter=${searchParams.get("filter")}` : ""
      }`}
      onClick={() => {
        setTimeout(() => {
          scrollIntoView(domId, {
            instant: true,
            // First module should also show the "Show" dropdown
            offset: index === 0 ? 100 : undefined,
          });
        });
      }}
      label={label}
      // aria-current={domId === activeModuleDomId ? "location" : undefined}
      icon={<Icon color={"var(--color-rocketgreen)"} size={24} />}
      active={isActive}
      className={"pl-8"}
    />
  );
}

function ModuleSelectionSkeleton() {
  return (
    <div className="flex flex-col pl-8">
      <div className="flex h-12 items-center">
        <div className="h-6 w-28 animate-pulse bg-slate-300"></div>
      </div>
      <div className="flex h-12 items-center">
        <div className="h-6 w-28 animate-pulse bg-slate-300"></div>
      </div>
      <div className="flex h-12 items-center">
        <div className="h-6 w-28 animate-pulse bg-slate-300"></div>
      </div>
      <div className="flex h-12 items-center">
        <div className="h-6 w-28 animate-pulse bg-slate-300"></div>
      </div>
      <div className="flex h-12 items-center">
        <div className="h-6 w-28 animate-pulse bg-slate-300"></div>
      </div>
      <div className="flex h-12 items-center">
        <div className="h-6 w-28 animate-pulse bg-slate-300"></div>
      </div>
      <div className="flex h-12 items-center">
        <div className="h-6 w-28 animate-pulse bg-slate-300"></div>
      </div>
      <div className="flex h-12 items-center">
        <div className="h-6 w-28 animate-pulse bg-slate-300"></div>
      </div>
    </div>
  );
}

function getModuleIcon(m: ModuleData, store: Store<RootState, RootAction>) {
  const IconStatus = {
    default: BsCircle,
    completed: BsCircleFill,
    inProgress: BsCircleHalf,
  };

  const state = store.getState();
  const userLessonStatus = state.lesson.entities.user_lesson_status;
  const userRateableTestRatings = state.lesson.entities.user_rateable_test_ratings;
  const lessonRateableTestIds = state.lesson.entities.lesson_rateable_test_ids;
  const rateableTests = state.lesson.entities.rateable_tests;

  const status = ((): keyof typeof IconStatus => {
    let lessonsInProgress = 0;
    let lessonsCompleted = 0;
    let numLessons = 0;

    for (const section of m.data) {
      const visibleLessons = section.lessons.filter((l) => l.status === "published");
      numLessons += visibleLessons.length;
      for (const lesson of visibleLessons) {
        if (userLessonStatus[lesson.id]?.is_done) {
          lessonsCompleted += 1;
          continue;
        }
        const rateableTestIds = lessonRateableTestIds[lesson.id]?.filter((id) => !rateableTests[id]?.is_extra);
        if (rateableTestIds && rateableTestIds.length > 0) {
          let ratebleTestsCompleted = 0;
          for (const rateableTestId of rateableTestIds) {
            if (userRateableTestRatings[rateableTestId]) {
              ratebleTestsCompleted += 1;
            }
          }
          if (ratebleTestsCompleted === rateableTestIds.length) {
            lessonsCompleted += 1;
          } else if (ratebleTestsCompleted > 0) {
            lessonsInProgress += 1;
            break;
          }
        }
      }
      if (lessonsInProgress > 0) {
        break;
      }
    }

    if (lessonsInProgress > 0) {
      return "inProgress";
    }
    if (!lessonsCompleted) {
      return "default";
    }
    if (lessonsCompleted !== numLessons) {
      return "inProgress";
    }
    return "completed";
  })();

  return IconStatus[status];
}
