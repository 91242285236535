export default {
  // Dashboard - Page
  "dashboard<title>": "Dashboard",

  /** Translations File */

  // Navigation
  pricing: "Pricing",
  "sign-up": "Sign Up",
  "about-us": "About Us",
  support: "Support",
  profile: "Profile",
  help: "Help",
  "log-out": "Log Out",
  "point|points": "Point|Points",
  search: "Search",
  "search-results": "Search results",
  settings: "Settings",
  easy: "Easy",
  medium: "Medium",
  hard: "Hard",
  tools: "Tools",
  forum: "Forum",
  reviews: "Reviews",
  back: "Back",
  "logged-out": "Logged out",
  "maybe-later": "Maybe Later",
  confirm: "Confirm",
  "back-to-all-courses": "Back to all courses",
  "back-to-dashboard": "Back to dashboard",
  "toggle-more": "Toggle More",
  "courses-and-levels": "Courses and Levels",
  feedback: "Feedback",
  "got-feedback": "Got feedback?",
  dictionary: "Dictionary",
  appearance: "Appearance",
  dark: "Dark",
  light: "Light",
  device: "Device",
  "appearance-use-device-theme": "Use device theme",
  "appearance-dark-mode": "Dark mode",
  "appearance-light-mode": "Light mode",

  // Login/Logout
  "log-in": "Log In",
  "hi-there-good-to-see-you": "Hi there. Good to see you!",
  "log-in-and-let-s-get-talking": "Log in and let's get talking...",
  "welcome-to-rocket-languages": "Welcome to Rocket Languages",
  password: "Password",
  "set-your-account-password": "Set your account password",
  "minimum-of-six-characters": "Minimum of 6 characters",
  "forgot-password": "Forgot password?",
  "don't-have-an-account": "Don't have an account?",
  "sign-up-for-a-free-trial": "Sign up for a free trial",
  "unsubscribe-from-all-{language}": "Unsubscribe from all Rocket {language} emails",

  // Forgot Password
  "forgot-your-password": "Forgot your password?",
  "forgot-your-password-description":
    "Fill in the email address associated with your account and we will email a password reset link to you.",
  "email-address": "Email Address",
  "email-me-the-password-reset-link": "Email me the password reset link",
  "back-to-login": "Back to Login",

  // Sign up
  "sign-up-for-a-free-trial-here": "Sign up for a free trial here!",
  "sign-up-for-a-free-trial-here-description":
    "Once you sign up you will get free and instant access to selected lessons from within the course. Not only that, you will also get exclusive discounts, tips and tricks to help your language learning, and more.",
  "try-it-for-free": "Try it for free!",
  trial: "Trial",
  "complete-your-account": "Complete Your Account",
  "final-step": "Final Step!",
  "final-step-trial-{language}": "FREE Rocket {language} Trial: Final Step!",
  "set-your-account-password-and-get-instant-access-to-rocket-{language}":
    "Set your account password and get instant access to your Rocket {language} course.",
  "set-your-account-password-and-get-instant-access-to-rocket-trial-{language}":
    "Set your account password and get instant access to your Rocket {language} FREE trial.",
  "save-password": "Save Password",
  "start-trial": "Start Trial",
  "complete-account-email-reminder-{language}":
    "Check your inbox for your account details, getting started, and top tips on learning {language}. You can unsubscribe at any time!",
  "complete-account-email-reminder-trial-{language}":
    "Check your inbox for your account details, exclusive discounts, and our top tips on learning {language}. You can unsubscribe at any time!",
  "your-language-of-choice": "your language of choice",

  // Password Reset
  "password-reset": "Password Reset",
  "reset-password": "Reset Password",
  "confirm-password": "Confirm Password",
  "save-details": "Save Details",

  // Help
  "help-center": "Help Center",
  "welcome-to-the-rocket-languages-help-center": "Welcome to the Rocket Languages Help Center!",
  "welcome-to-the-rocket-languages-help-center-description-1":
    "Check out our Top Questions, search, or browse for questions listed under the category headings.",
  "welcome-to-the-rocket-languages-help-center-description-2-{link}":
    "If you can't find what you are looking for then {link} and we'll try our best to help!",
  "top-questions": "Top Questions",
  "search-the-help-center": "Search the Help Center",
  "can't-find-what-you're-looking-for": "Can't find what you're looking for?",

  // Contact Us
  send: "Send",
  "go-to-help-center": "Go to help center",
  "go-to-forum": "Go to Forum",
  "contact-us": "Contact Us",
  "{language}-question": "{language} Question?",
  "{language}-question-description-{link}":
    "Have you got a question about {language}? The best place to ask questions about the language is on the community forum! {link}",
  "click-here": "Click here!",
  "technical-problem": "Technical Problem?",
  "technical-problem-description-{link}":
    "Have you got a technical issue? Check out the FAQ's {link} or if that doesn't help then send us an email using the form below.",
  here: "here",
  "lesson-feedback": "Lesson Feedback?",
  "lesson-feedback-description":
    "Do you want to provide feedback on the course or a lesson? We love to hear what our members have to say, just send us an email using the form below.",
  subject: "Subject",
  message: "Message",
  "send-message": "Send Message",
  thanks: "Thanks!",
  "thanks-for-your-email": "Thanks for your email. Please allow 2-4 working days for a response.",
  dashboard: "Dashboard",
  "email-me-a-copy-of-my-message": "Email me a copy of my message",

  // All Courses
  "hello-{name}": "Hello, {name}!",
  "free-trials": "Free Trials",
  "free-trials-description": "Just click on the language you wish to learn to see sample lessons from the full course",
  "getting-course-ready": "Getting your course dashboard ready",
  "error-navigating-to-course": "Looks like an error occurred while navigating to your course dashboard.",

  // Dashboard
  "rocket-{language}-dashboard": "Rocket Inglés Lessons",
  "module-{module-number}": "Module {module-number}",
  audio: "Audio",
  language: "Language",
  "all-courses": "All Courses",
  "start-lesson": "Start Lesson",
  "continue-lesson": "Continue Lesson",
  todo: "To do",
  "take-the-tutorial": "Find out how everything works!",
  "buy-to-access": "Buy To Access",
  "interactive-audio-course": "Audio Lessons",
  "play-the-part": "Play the Part Lessons",
  "interactive-audio": "Audio Lessons",
  "survival-kit": "Survival Kit Lessons",
  "language-and-culture": "Language & Culture Lessons",
  "language-and-culture-short": "Language & Culture Lessons",
  onboarding: "Onboarding",
  complete: "Complete",
  incomplete: "Incomplete",

  // Rocket Certification
  information: "Information",
  "rocket-certification-header-{firstname}-{greeting}":
    "{greeting}, {firstname}. Once you complete all the lessons within a module, you can take the Rocket Certification tests.",
  "rocket-certification-paragraph-1":
    "A Rocket Certification takes around 30 minutes to complete, and must be completed for your score to count.",
  "rocket-certification-paragraph-2":
    "You will be tested on random phrases and quizzes from the lessons in each module.",
  "rocket-certification-paragraph-3": "You need to get 80% or more to pass the Certification",
  "rocket-certification-paragraph-4-{language}":
    "Once you have passed all of the Rocket Certifications, you will receive a printable Rocket {language} Certificate of Achievement!",
  "good-luck": "Good luck!",
  "rocket-certification-certificate": "Certificate of Achievement",
  "rocket-certification-certificate-print": "Print Certificate",
  "module-{module-number}-certification": "Module {module-number} Certification",
  "start-module-{module-number}-certification": "Start Module {module-number} Certification",
  "continue-module-{module-number}-certification": "Continue Module {module-number} Certification",
  "retake-module-{module-number}-certification": "Retake Module {module-number} Certification",
  "are-you-sure": "Are you sure?",
  "this-will-reset-the-test": "This will reset the test",
  "are-you-sure-progress-is-only-saved-until-last-reinforcement-activity":
    "Are you sure? Progress is only saved up until the last completed reinforcement activity.",

  // Reviews
  "out-of-5-based-on-{total-reviews}-reviews": "out of 5, based on {total-reviews} reviews",
  "your-review": "Your Review",
  "tell-us-what-you-think-about-rocket-{language}":
    "If you haven't already, tell us what you think about Rocket {language}!",
  "write-your-rocket-{langauge}-review": "Write Your Rocket {language} Review",
  "{total-reviews}-customer-reviews": "{total-reviews} Customer Reviews",
  "customer-reviews": "Customer Reviews",
  "review-criteria": "Review Criteria",
  "how-satisfied-are-you-with-your-experience?": "How satisfied are you with your experience?",
  "how-likely-are-you-to-buy-again?": "How likely are you to buy again?",
  "are-you-happy-with-the-price?": "Are you happy with the price?",
  "would-you-recommend-it-to-a-friend?": "Would you recommend it to a friend?",
  "how-was-the-customer-service?": "How was the Customer Service?",
  "overall-rating": "Overall Rating",
  overall: "Overall",
  "{average-rating}-out-of-5-stars": "{average-rating} out of 5 stars",
  stars: "Stars",
  "5-stars": "5 Stars",
  "4-stars": "4 Stars",
  "3-stars": "3 Stars",
  "2-stars": "2 Stars",
  "1-star": "1 Star",
  "order-by": "Order By",
  "most-recent": "Most Recent",
  "most-helpful": "Most Helpful",
  submitted: "Submitted",
  "submitted-{number}-{months}-ago": "Submitted: {number} {months} ago",
  "month|months": "months|month",
  "read-more": "Read More",
  "show-less": "Show Less",
  "{number}-users-found-this-review-helpful": "{number} users found this review helpful",
  helpful: "Helpful",
  first: "First",
  previous: "Previous",
  next: "Next",
  last: "Last",
  "review-waiting-approval": "Review waiting approval",

  // Create Review
  "create-review": "Create Review",
  "create-review-header-{language}": "Tell us what you think about Rocket {language}!",
  "create-review-description-1-{language}":
    "Reviews are a great way for new {language} learners, just like you, to get outside opinions on what we do. Plus, your feedback helps us make Rocket {language} even better!",
  "create-review-description-2-{link}":
    "Please note that we don't allow any inappropriate comments. If you have any technical issues or need help, please {link}.",
  "required-field": "* Indicates a required field",
  "city/state": "City / State",
  country: "Country",
  "write-your-review-description": "Write something awesome about Rocket Languages here",
  "minimum-50-characters": "Minimum 50 characters",
  "rate-the-following": "Rate the following",
  "confirm-your-details": "Confirm your details",
  "see-example-reviews": "See example reviews",
  "write-your-review": "Write your review",
  "preview-your-review": "Preview your review",
  "terms-and-conditions": "Terms and Conditions",
  "terms-and-conditions-description-{link}":
    "Once you've submitted your review you can view it in Reviews under your profile. To make any changes just visit that page and follow the instructions. By submitting your review you give Rocket Languages full permission to use it at their discretion. To find out more check out our {link}.",
  "terms-of-use": "Terms of Use",
  "accept-terms-and-conditions": "I Accept the Terms and Conditions",
  "submit-review": "Submit Review",
  "thank-you-for-submitting-your-review":
    "Thank you for submitting your review! It will appear on our website once it's been approved.",
  "amazon-prompt":
    "If you are a fan of Amazon and have purchased from them within the last 12 months please submit a review for our products",
  "we-appreciate-your-help": "We appreciate your help!",
  "post-review-on-amazon": "Post review on Amazon",
  "continue-back-to-reviews": "Continue back to Reviews",

  // Search
  lessons: "Lessons",
  forums: "Forums",
  phrases: "Phrases",
  "search-for-description-{language}":
    "Search for {language} words and phrases, lesson content and forum posts from within Rocket {language}.",
  "no-results-found-in-this-category": "No results found in this category",
  "show-more": "Show More",
  "{categories-results}-results-in-{categories-number}-categories":
    "{categories-results} results in {categories-number} categories",
  "search-in-rocket-{language}": "Search in Rocket {language}",
  "search-no-results-{query}": "We couldn't find anything for '{query}' - some suggestions:",
  "modify-search": "Modify Search",
  "search-no-results-description-1":
    "Check spelling, expand any abbreviations, use fewer or more general keywords, etc.",
  "search-no-results-description-2-{link}": "Write us an e-mail at {link}",

  // Lesson Components
  "listen-to-the-full-lesson": "Listen to the full lesson",
  review: "Review",
  "the-conversation": "The Conversation",
  "rocket-reinforcement-activities": "Rocket Reinforcement activities",
  "rocket-reinforcement-activities-description":
    "Reinforce your learning from this lesson with the Rocket Reinforcement tools! Improve your knowledge and retention of Inglés and earn points for your badges along the way!",

  // Lesson
  lesson: "Lesson",
  "scroll-to-top": "Scroll to top",
  notes: "Notes",
  "add-a-note-to-this-lesson": "Add A Note To This Lesson",
  show: "Show",
  "notes-placeholder-text": "Save your notes about this lesson here.",
  "spot-a-problem": "Spot a problem?",
  "lesson-{number}": "Lesson {number}",
  "send-feedback": "Send feedback",
  "send-feedback-placeholder-text": "Have you spotted a bug or some other issue in this lesson? Let us know!",
  "send-feedback-placeholder-text-non-lesson": "Have you spotted a bug or some other issue on this page? Let us know!",
  "thank-you-for-your-feedback": "Thank you for your feedback",
  "error-submitting-feedback": "Error submitting feedback. Please try again.",
  "minimum-character-{number}-text": "Minimum of {number} characters",
  save: "Save",
  "all-lessons": "All Lessons",
  saving: "Saving",
  "lesson-notes": "Lesson notes",

  // Play It (Role Playing)
  "play-it": "Role Playing",
  "play-it-subheading": "Role play as one of the characters in the conversation",
  playing: "Playing",
  "playing-as": "Playing as",
  display: "Display",
  "voice-recognition": "Voice Recognition",
  english: "English",
  "every-second-word-character": "Every second word/character",
  spanish: "Spanish",
  "enable-voice-recognition-ratings": "Enable Voice Recognition Ratings",
  stop: "Stop",
  "play-conversation": "Play conversation",
  "play-as-{hostname}": "Play as {hostname}",
  "extra-vocab": "Extra Vocab",
  "extra-audio": "Extra Audio",
  replay: "Replay",

  // Flashcards
  flashcard: "Flashcards",
  "flashcard-subheading": "Reinforce your memory using Flashcards",
  flashcards: "Flashcards",
  "get-started": "Get Started",
  continue: "Continue",
  "front-of-card": "Front of card",
  "back-of-card": "Back of card",
  sound: "Sound",
  "play-audio-on-reveal": "Play audio on reveal",
  flip: "Flip",
  reveal: "Reveal",
  good: "Good",
  "flashcard-settings-tooltip-1": "Select what you want to appear on the FRONT of the Flashcards.",
  "flashcard-settings-tooltip-2": "Note that you can not select all, as something must show on the reverse side.",

  // Hear It! (Listening)
  "hear-it": "Listening",
  "hear-it-say-it": "Listening",
  "hear-it-say-it-subheading": "Listen to the phrase, then record yourself saying the phrase",
  hotkeys: "Hotkeys",
  "play-tutor-audio": "Play Tutor Audio",
  "record-yourself": "Record Yourself",
  "reveals-phrase": "Reveals Phrase",
  "rate-as-easy-good-or-hard": "Rate as Easy, Good or Hard",
  "close-help": "Close Help",

  // Write It! (Writing)
  "write-it": "Writing",
  "write-it-subheading": "Listen to the phrase, then type what you heard",
  "write-it-in-{language}-name-here": "Write it in {language} here",

  // Know It! (Speaking)
  "know-it": "Speaking",
  "know-it-subheading": "Record yourself saying the phrase out loud",

  // Quiz
  quiz: "Quiz",
  "quiz-subheading": "Answer the multi-choice questions to reinforce your understanding",
  "try-again": "Try Again",
  "correct-answers": "Correct Answers",
  "{numCorrect}-out-of-{numQuestions}": "{numCorrect} out of {numQuestions}",

  // Rateable Tests
  "marked-as-complete": "Marked as Complete",
  "mark-as-complete": "Mark as Complete",
  "mark-as-incomplete": "Mark as Incomplete",
  "click-to-mark-{testName}-as-complete": "Click to mark {testName} as complete.",
  "click-to-reset-{testName}-ratings": "Click to reset {testName} ratings.",
  "are-you-sure-you-would-like-to-reset-your-rating": "Are you sure that you would like to reset your rating?",
  yes: "Yes",
  "mark-as-complete-instructions": "Click the activity icon to mark as complete",
  "reset-{rateableTest}": "Reset {rateableTest}",
  "review/redo-{number}-non-easy-phrases": "Review / Redo {number} Non-Easy Phrases",
  "review-{number}-non-easy-phrases": "Review {number} Non-Easy Phrases",
  "redo-{number}-non-easy-phrases": "Redo {number} Non-Easy Phrases",
  "rated-{ratingLevel}": "Rated {ratingLevel}",
  "are-you-sure-you-would-like-to-reset-your-progress": "Are you sure that you would like to reset your progress?",

  // Rank up
  "achieved-new-badge": "You've just achieved a new badge!",
  "loving-the-course": "Loving the course?",
  "write-a-review": "Write a review",

  // Tools
  "tools-description":
    "These tools give you a whole bunch of features designed to aid your learning. Make sure you check them all out so that you make the most of your learning time!",
  progress: "Progress",
  "progress-description":
    "Check on your Leaderboard position, how many points you have earned, and your streak status here.",
  certification: "Certification",
  "rocket-certification": "Rocket Certification",
  "rocket-certification-description":
    "Once you complete all the lessons within a module, you can take the Rocket Certification tests.",
  benchmark: "Benchmark",
  "benchmark-description-{language}":
    "Use the Benchmark tool to find your current {language} level. Redo the test regularly to track your improvement!",
  "custom-flashcards": "Custom Flashcards",
  "custom-flashcards-description": "Create your own Flashcard sets with this handy tool.",
  "saved-notes": "Saved Notes",
  "saved-notes-description": "In each lesson you can add notes using the Notes icon. See them all in one place here!",
  "saved-vocab": "Saved Vocab",
  "saved-vocab-description-{language}":
    "Save the vocabulary you want to remember from within the lessons, or save your own custom vocabulary.",
  "rocket-recommends": "Rocket Recommends",
  "rocket-recommends-description-{language}": "Check out the latest deals on {language}-related products!",

  "show-10-entries": "Show 10 entries",
  "show-20-entries": "Show 20 entries",
  "show-50-entries": "Show 50 entries",
  "show-100-entries": "Show 100 entries",

  // Profile
  "profile-information": "Profile Information",
  "profile-description-{link}":
    "Your profile information is used for customer support services only and is not viewable by other members. The exception being your 'username' which is used when you post in the forum, and displayed on the Leaderboard. Read our {link} to learn more.",
  "privacy-policy": "Privacy Policy",
  name: "Name",
  "first-name": "First Name",
  "last-name": "Last Name",
  "email-login": "Email/Login",
  username: "Username",
  "username-tooltip": "Your 'username' is used when you post in the forum, and is displayed on the Leaderboard.",
  photo: "Photo",
  "upload-photo": "Upload Photo",
  "maximum-size-of-{size}-jpeg-or-png": "Maximum size of {size}mb. JPEG or PNG.",
  "forum-notifications": "Forum Notifications",
  "forum-notifications-description": "Email me when someone replies to a forum topic I have commented on.",
  "forum-notifications-description-note":
    "This will only change the default setting for all new posts. Any previous posts that you have asked to be notified will still result in a notification email.",
  "leaderboard-and-points": "Leaderboard and Points Widget",
  "leaderboard-and-points-description": "Hide the Leaderboard and Points Widget.",
  "update-profile": "Update Profile",
  "change-your-password": "Change Your Password",
  "current-password": "Current Password",
  "new-password": "New Password",
  "re-enter-password": "Re-enter Password",
  "update-password": "Update Password",
  "transaction-history": "Transaction History",
  "passwords-are-not-the-same": "Passwords are not the same",
  "confirm-settings": "Confirm Settings",

  // Forum
  "forum-description-1-{language}":
    "The forum is your place to ask any tricky questions about {language}. Let our tutors and other Rocket {language} members help you out!",
  "forum-description-2":
    "You can browse the topics, do a search in the top right corner of this page, or start a new conversation. Don’t be shy!",
  "start-a-new-conversation": "Start a new conversation",
  "most-recent-comment": "Most Recent Comment",
  new: "New",
  conversations: "Conversations",
  comments: "Comment|Comments",
  "{replies}-replies": "{replies} replies",
  announcements: "Announcement|Announcements",
  "discard-all": "Discard all",
  "your-conversations": "Your Conversations",
  "your-conversations-description":
    "Post a conversation or make a comment on an existing conversation and you'll see them here!",
  "rocket-{language}-categories-{categories}": "Rocket {language} {categories}",
  categories: "Category|Categories",
  "{language}-grammar": "{language} Grammar",
  "{language}-culture-and-travel": "{language} Culture and Travel",
  "{language}-vocab": "{language} Vocab",
  "conversation-in-{language}": "Conversation in {language}",
  "{language}-feedback-and-comments": "{language} Feedback and Comments",
  "general-categories": "General Categories",
  "general-general": "General",
  "general-feedback-and-comments": "Feedback and Comments",
  "general-introduce-yourself": "Introduce Yourself!",
  "general-rocket-languages-blog": "Rocket Languages Blog",
  "last-comment-by": "last comment by",
  "latest-activity": "Latest Activity",
  "newest-conversation": "Newest Conversation",
  "mark-all-as-read": "Mark All As Read",
  "no-threads-found": "No threads found",
  "post-a-reply-to-this-thread": "Post a reply to this thread",
  "no-replies-yet": "No replies yet",
  "report-spam": "Report spam",
  reported: "Reported",
  "post-a-reply": "Post a reply",
  "post-my-reply": "Post my reply",
  "email-me-when-someone-replies": "Email me when someone replies",
  "comment-posted": "Comment posted",
  "comment-deleted": "Comment deleted",
  "comment-updated": "Comment updated",
  "thread-deleted": "Thread deleted",
  "thread-updated": "Thread updated",
  "are-you-sure-you-want-to-delete-your-comment": "Are you sure you want to delete this comment?",
  "this-will-delete-the-whole-conversation": "This will delete the whole conversation.",

  // Create Thread
  "create-thread": "Create Thread",
  "new-conversation": "New Conversation",
  "start-a-new-conversation-description":
    "Start a new conversation post here! Just make sure that you include all relevant details.",
  "conversation-title": "Conversation title",
  "conversation-title-tooltip": "What's your question about?",
  "forum-tooltip": "Select a forum to see available categories",
  "forum-category": "Forum category",
  "forum-category-tooltip": "Select the category you want your conversation to appear in",
  "conversation-content": "Conversation content",
  notifications: "Notifications",
  "notifications-description": "Email me when someone replies to a forum topic I have commented on.",
  "alert-users-of-new-conversation": "Alert users of new conversation",
  "post-your-conversation": "Post your conversation",

  // Saved Notes
  "saved-notes-header":
    "This page displays all of your notes that have been saved. You can click on the lesson name below to go straight back to the lesson.",
  "recently-added": "Recently Added",
  "recently-added-desc": "Recently Added First",
  "recently-added-asc": "Recently Added Last",
  "lesson-number": "Lesson Number",
  "lesson-number-desc": "Lesson Number ↓",
  "lesson-number-asc": "Lesson Number ↑",
  "no-notes-found": "No saved notes! To save notes click on the Notes icon within each lesson.",
  "successfully-deleted-note": "Successfully deleted note",
  "are-you-sure-you-would-like-to-delete-this-note": "Are you sure that you would like to delete this note?",

  // Saved Vocab
  "saved-vocab-title": "Your Saved Vocab",
  "saved-vocab-header":
    "All words in your Saved Vocab will be underlined wherever they appear in the course. Just click on a highlighted word to get the translation and your notes!",
  "saved-vocab-paragraph":
    "To save to Saved Vocab double click on the word/phrase of your choice in any lesson. Then just click Save in the pop up.",
  "saved-vocab-search-input-placeholder": "Search your Saved Vocab",
  "download-all-as-csv": "Download all as CSV",
  "custom-vocab": "Custom Vocab",
  edit: "Edit",
  delete: "Delete",
  remove: "Remove",
  "edit-vocab": "Edit Vocab",
  "add-{vocab}-to-your-vocab": 'Add "{vocab}" to your vocab',
  "notes-about-this-phrase": "Notes about this phrase",
  "add-to-saved-vocab": "Add to Saved Vocab",
  vocab: "Vocab",
  meaning: "Meaning",
  "similar-phrases": "Similar Phrases",
  cancel: "Cancel",
  add: "Add",
  "vocab-saved": "Vocab Saved",
  "vocab-removed": "Vocab Removed",
  "confirm-delete": "Confirm Delete",
  "are-you-sure-you-would-like-to-delete-this-vocab": "Are you sure that you would like to delete this vocab?",
  "no-translation": "No Translation",

  // Progress
  "progress-header":
    "Keep track of the amount of points you have earned, your Leaderboard position, and your streaks all in one place!",
  "progress-paragraph-{language}":
    "Everything you do in your Rocket {language} course earns you points and helps push you up the Leaderboard. Check in here to see how you compare to other learners!",
  "points-and-streak": "Points and Streaks",
  points: "Points",
  streak: "Streak",
  today: "Today",
  total: "Total",
  current: "Current",
  longest: "Longest",
  "day|days": "day|days",
  days: "Days",
  "daily-points-goal": "Daily Points Goal",
  "set-daily-points-goal": "Set daily points goal:",
  "points-history": "Points History",
  "last-week": "Last Week",
  "last-month": "Last Month",
  "last-year": "Last Year",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  "daily-points": "Daily Points",
  "points-goal": "Points Goal",
  leaderboard: "Leaderboard",
  "leaderboard-position": "Leaderboard Position",
  "all-languages": "All Languages",
  "current-leaderboard": "Current Leaderboard",
  "last-24-hours": "Last 24 hours",
  "last-7-days": "Last 7 days",
  "last-30-days": "Last 30 days",
  "last-12-months": "Last 12 months",
  "all-time": "All Time",
  position: "Position",
  user: "User",

  // Benchmark
  "level-{number}": "Level {number}",
  "benchmark-header-{language}":
    "Use Benchmarks to benchmark your current {language} ability. Then, as you go through Rocket {language}, redo the benchmark test regularly to keep track of your improvement!",
  "benchmark-description-2":
    "IMPORTANT! Once you get 3 answers either incorrect or rated as not 'Easy' then the benchmark will stop.",
  "benchmark-description-3": "You can retake your benchmark test at any time.",
  "there-was-a-problem-loading-the-benchmark-test": "There was a problem loading the benchmark test.",
  "benchmark-test": "Benchmark Test",

  // Misc
  reload: "Reload",
  print: "Print",
  retry: "Retry",
  "network-error": "Network Error",
  "no-results": "No Results",
  error: "Error",
  lit: "Lit.",
  literally: "Literally",
  "unknown-error": "Unknown error.",
  "please-try-again": "Please try again.",
  success: "Success!",
  close: "Close",
  whoops: "Whoops!",

  /** No Translations */

  // Dashboard
  "sign-language-lessons": "Sign Language Lessons",
  "sign-language": "Sign Language",
  "video-lesson": "Video Lessons",
  video: "Video",
  travelogue: "Travelogues",
  "travelogue-short": "Travelogues",
  "esl-conversations-vocab": "ESL Conversations Lessons",
  "esl-conversations": "ESL Conversations",
  "esl-language-lessons": "ESL Language Lessons",
  "esl-language": "ESL Language",
  writing: "Writing Lessons",
  "writing-short": "Writing",
  "ebook-chapter": "Learn a Language Extraordinarily Fast",
  ebook: "Audio Book Chapters",
  newsletter: "Free Resource",

  // Rateable Tests
  "draw-it": "Drawing",
  "draw-it-subheading": "Draw the character using the correct stroke order",
  "write-it-native": "Writing",
  "translate-it": "Translation",
  "sort-it": "Composing",
  "sort-it-subheading": "Listen to the phrase, then build the sentence",
  "sort-it-native": "Composing",
};
