import { useMemo } from "react";
import { create } from "zustand";
import type { RRStateFlag, RatedPhrase } from "./types";
import type { Phrase, TimeoutType } from "@rocket/types";

export default function useRocketRecordStore(phrase?: Phrase, initialResult?: RatedPhrase, initialBlobUrl?: string) {
  return useMemo(
    () => createRRStore({ phrase, initialResult, initialBlobUrl }),
    [phrase, initialResult, initialBlobUrl],
  );
}

/**
 * Creates an individual instance of an RR store
 */
export function createRRStore(params: { phrase?: Phrase; initialResult?: RatedPhrase; initialBlobUrl?: string }) {
  const { phrase, initialResult, initialBlobUrl } = params;
  return create<RocketRecordState>((set, get) => ({
    flag: { status: "INACTIVE" },
    startedAt: 0,
    result: initialResult,
    ratingVisible: true,
    debugLog: [],
    phrase,
    blobUrl: initialBlobUrl,
    errorTally: 0,
    non100PctTally: 0,
    timeouts: {
      startRecognition: undefined,
      speechStart: undefined,
      speechRecognition: undefined,
      hitRatingLevel: undefined,
    },
    actions: {
      clearTimeouts(...keys) {
        const currentState = get();
        const timeoutKeys = keys.length > 0 ? keys : (Object.keys(currentState.timeouts) as typeof keys);
        const updatedTimeouts = { ...currentState.timeouts };
        for (const timeoutKey of timeoutKeys) {
          const timeout = currentState.timeouts[timeoutKey];
          if (timeout) {
            clearTimeout(timeout);
            updatedTimeouts[timeoutKey] = undefined;
          }
        }
        set({
          timeouts: updatedTimeouts,
        });
      },
      setTimeouts(timeouts) {
        // Clear any currently-running timeouts
        const keys = Object.keys(timeouts) as Array<keyof RocketRecordState["timeouts"]>;
        get().actions.clearTimeouts(...keys);
        // Update timeout state
        set((state) => ({
          timeouts: {
            ...state.timeouts,
            ...timeouts,
          },
        }));
      },
      setActive() {
        set({
          flag: {
            status: "ACTIVE",
          },
          result: undefined,
          ratingVisible: undefined,
        });
      },
      finish({ flag, ratingVisible, blobUrl }) {
        const prevBlobUrl = get().blobUrl;
        set((state) => ({
          ratingVisible,
          blobUrl,
          flag,
          // Errors without any recognized speech. When this hits 3, it will prompt "Having problems? Move on or check the FAQ!"
          errorTally: flag.status === "ERROR" && !state.result?.diffResult ? state.errorTally + 1 : 0,
          // Non-100% ratings. When this hits 3, it will prompt "Still can't get it? Report this phrase"
          non100PctTally: state.result?.rawTranscription && state.result.ratingLevel < 3 ? state.non100PctTally + 1 : 0,
        }));
        // Clear old blob URL to prevent memory leaks
        if (prevBlobUrl) {
          window.URL.revokeObjectURL(prevBlobUrl);
        }
      },
      addDebugMessage(message) {
        set((state) => ({
          debugLog: [
            ...state.debugLog,
            {
              time: new Date().getTime(),
              message,
            },
          ],
        }));
      },
    },
  }));
}

export type RocketRecordState = {
  /** Phrase to be recorded/recognized */
  phrase: Phrase | undefined;
  /** Use to figure out the current RR state (e.g. "INACTIVE", "STARTING", "ACTIVE", "ABORTED") */
  flag: RRStateFlag;
  /**
   * Time in which the recording is started.
   * This is used to calculate the duration of the recording.
   * The value should be the result of `new Date().getTime()`.
   * */
  startedAt: number;
  /** Current speech result and rating */
  result?: RatedPhrase;
  /** Whether rating should be visible. Calculated using a timeout. */
  ratingVisible?: boolean;
  /** Debug log */
  debugLog: Array<{ time: number; message: string }>;
  /** Recording blob url */
  blobUrl: string | undefined;
  /** Number of consecutive errors */
  errorTally: number;
  /** Number of consecutive times the user has attempted to record a phrase and not gotten 100% */
  non100PctTally: number;
  /** Speech timeouts to make sure that RR doesn't go on indefinitely */
  timeouts: {
    startRecognition: TimeoutType | undefined;
    speechStart: TimeoutType | undefined;
    speechRecognition: TimeoutType | undefined;
    hitRatingLevel: TimeoutType | undefined;
  };
  actions: {
    /** If no keys given, clears all timeouts */
    clearTimeouts(...keys: Array<keyof RocketRecordState["timeouts"]>): void;
    setTimeouts(timeouts: Partial<{ [key in keyof RocketRecordState["timeouts"]]: TimeoutType }>): void;
    setActive(): void;
    addDebugMessage(message: string): void;
    finish(payload: { flag: RRStateFlag; ratingVisible: boolean; blobUrl: string | undefined }): void;
  };
};
