interface PodiumProps {
  size?: number;
}

export default function Podium({ size = 32 }: PodiumProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_233_9492)">
        <path
          d="M31.0625 22.5625H21.625C21.1068 22.5625 20.6875 22.9818 20.6875 23.5V32H31.0625C31.5807 32 32 31.5807 32 31.0625V23.5C32 22.9818 31.5807 22.5625 31.0625 22.5625Z"
          fill="#FE9923"
        />
        <path
          d="M10.375 20.6875H0.9375C0.419312 20.6875 0 21.1068 0 21.625V31.0625C0 31.5807 0.419312 32 0.9375 32H11.3125V21.625C11.3125 21.1068 10.8932 20.6875 10.375 20.6875Z"
          fill="#FEA832"
        />
        <path
          d="M22.5625 16V32H9.4375V16C9.4375 15.4749 9.84994 15.0625 10.375 15.0625H21.625C22.1501 15.0625 22.5625 15.4749 22.5625 16Z"
          fill="#FEDB41"
        />
        <path d="M22.5625 16V32H16V15.0625H21.625C22.1501 15.0625 22.5625 15.4749 22.5625 16Z" fill="#FFCC33" />
        <path
          d="M22.0192 4.59995L18.4566 4.03745L16.9003 0.544826C16.7504 0.226013 16.3754 -0.0177368 16.0004 0.00101317C15.7004 0.00101317 15.4191 0.151076 15.2129 0.526076L13.5817 3.96245L10.0191 4.4312C9.25045 4.54376 8.91282 5.48126 9.47545 6.02501L12.0254 8.57502L11.3693 12.1188C11.2192 12.8875 12.0254 13.4875 12.7192 13.1312L15.9255 11.5L16.0004 11.5375L19.0942 13.2063C19.7692 13.5812 20.5942 13.0187 20.4629 12.25L19.9004 8.68745L22.5066 6.19364C23.0879 5.66876 22.7879 4.71245 22.0192 4.59995Z"
          fill="#FEDB41"
        />
        <path
          d="M16.9375 19.75V25.375C16.9375 25.8999 16.5251 26.3125 16 26.3125C15.4749 26.3125 15.0625 25.8999 15.0625 25.375V22.0187L14.7812 22.2812C14.4249 22.6562 13.8251 22.6562 13.4688 22.2812C13.0938 21.9249 13.0938 21.3249 13.4688 20.9687L15.3438 19.0937C15.5312 18.9062 15.7562 18.8125 16 18.8125C16.4688 18.8125 16.9375 19.1875 16.9375 19.75Z"
          fill="#0D70B2"
        />
        <path
          d="M16.9375 19.75V25.375C16.9375 25.8999 16.5251 26.3125 16 26.3125V18.8125C16.4688 18.8125 16.9375 19.1875 16.9375 19.75Z"
          fill="#095C92"
        />
        <path
          d="M22.5062 6.1937L19.9 8.68752L20.4625 12.2501C20.5937 13.0188 19.7687 13.5813 19.0938 13.2063L16 11.5375V0.00101317C16.375 -0.0177368 16.7501 0.226013 16.8999 0.544826L18.4562 4.03751L22.0188 4.60001C22.7875 4.71245 23.0875 5.66876 22.5062 6.1937Z"
          fill="#FFCC33"
        />
      </g>
      <defs>
        <clipPath id="clip0_233_9492">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
