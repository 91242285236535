import { ReactNode } from "react";
import CircularProgress from "../CircularProgress";
import ErrorMessage from "../ErrorMessage";
import styles from "./PromiseResolver.module.scss";

const genericErrorText = "Sorry! We've run into an error. Please try again.";

type APIRefreshOptions = {
  /** Whether the data is being re-fetched while still available */
  value: boolean;
  /** Whether the UI should display any feedback */
  silent: boolean;
};

type APIResource<T> =
  | {
      status: "loading";
    }
  | {
      status: "loaded";
      refreshing?: APIRefreshOptions;
      /** Whether you'd want to refetch data based on time fetched */
      dateFetched?: Date;
      data: T;
    }
  | {
      status: "error";
      errorText?: string;
      statusCode?: number;
      errorCode?: string | number;
    };

type State<T> = APIResource<T> | undefined | null;

interface PromiseResolverProps<T> {
  state: State<T>;
  error?: JSX.Element | null;
  renderLoading?: boolean;
  /** Replace the circular progress with a custom skeleton layout (or null) */
  loading?: JSX.Element | null;
  children: ReactNode | ((param: T) => JSX.Element | null);
}

function PromiseResolver<T>({ state, error, renderLoading, loading, children }: PromiseResolverProps<T>) {
  if (!state) {
    return null;
  }

  if (renderLoading || state.status === "loading") {
    return typeof loading !== "undefined" ? (
      loading
    ) : (
      <div className={styles.resolver} aria-label="Content loading">
        <CircularProgress />
      </div>
    );
  }

  if (state.status === "error") {
    return error || <ErrorMessage title="Error" message={PromiseResolver.getErrorText(state)} />;
  }

  if (typeof children === "function") {
    return children(state.data);
  }

  return <>{children}</>;
}

PromiseResolver.getErrorText = function <T>(resource: State<T>, fallback = genericErrorText) {
  if (resource?.status === "error") {
    if (resource.statusCode === 404 || resource.errorCode === 404) {
      return "Not Found";
    }
    return resource.errorText || fallback;
  }
  return "";
};

export default PromiseResolver;
