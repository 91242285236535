import * as RR from "./utils";

import type {
  AudioRecorderInterface,
  CallbackSubscriptions,
  RRStateErrorCode,
  RRStateFlag,
  RatedPhrase,
  VoiceInterface,
} from "./types";
import type { APIParams, DebugRecordPayload, Phrase } from "@rocket/types";
import { omit, tryCatch } from "../../utils";
import { requestAddPoints, setPhraseRatingDisplay } from "../../store/lesson/actions";
import { useContext, useEffect } from "react";
import { useSharedDispatch, useSharedSelector, useSharedStore } from "../../store";
import LessonContext from "../../context/LessonContext";
import type { SharedRootState } from "../../store/types";
import { detect } from "detect-browser";
import { isAndroidMobile, isLegacySafariDesktop } from "../../utils/browser";
import { shallowEqual } from "react-redux";
import useRocketRecordStore from "./useRocketRecordStore";
import APIFormData from "../../res/APIFormData";
import API from "../../res/Api";

export interface UseRocketRecordProps {
  phrase: Phrase;
  /** Whether on mount, the component should start speech recognition */
  recordOnMount?: boolean;
  /** Initial result of the Rocket Record element */
  initialResult?: RatedPhrase;
  /** [PlayIt]: disables revoking blobURL when the component unmounts */
  persistBlobs?: boolean;
  /** For playback */
  initialBlobUrl?: string;
  /** Triggered when speech recognition finishes */
  onRecordFinish?: (params: { phraseId: number; result?: RatedPhrase; blobUrl?: string }) => void;
  /** Whether voice recognition is disabled (relying only on recording) */
  disableVoiceRecognition?: boolean;
  /** Manually pass in lesson id for phrases accessed outside of the lesson, eg: in Search */
  lessonId?: number;
  /** Endpoint for speech transcription to be sent to, e.g. https://website.com/api/transcribe */
  transcribeEndpoint?: string;

  // platform-specific stuff
  /** Whether we should send over transcripts to our Node speech api for further processing */
  shouldUseNodeSpeechApi: boolean;
  Voice: VoiceInterface;
  AudioRecorder?: AudioRecorderInterface;
  locale: string;
  platform: "web" | "ios-app" | "android-app";
}

const voiceRecognitionDifficultySelector = ({ user }: SharedRootState) => {
  return user.preferences ? user.preferences.voice_recognition_difficulty : "beginner";
};

/**
 * useRocketRecord is an utility hook for all functionality & state management for Rocket Record
 *
 * Usage:
 *
 * ```js
 * function RocketRecordUI() {
 *   const { methods, useRocketRecordState } = useRocketRecord({...});
 *   const flag = useRocketRecordState(state => state.flag);
 *   return (
 *     <View>
 *       <Text>State flag: {flag}</Text>
 *       <Text>Is Speech Active: {RocketRecord.computed.isRecognizing}</Text>
 *       <Button title="Start recognition" onPress={RocketRecord.methods.start} />
 *       <Button title="Stop recognition" onPress={RocketRecord.methods.abort} />
 *     </View>
 *   )
 * }
 * ```
 */
export default function useRocketRecord(props: UseRocketRecordProps) {
  /**
   * Used for adding points.
   * Lesson ID is either derived from lesson this phrase is in, or a provided lesson id (if the phrasebox is on a different page)
   */
  const lessonId = useContext(LessonContext)?.id || props.lessonId;
  const RRStore = useRocketRecordStore(props.phrase, props.initialResult, props.initialBlobUrl);
  const actions = RRStore((state) => state.actions, shallowEqual);
  const store = useSharedStore();
  const voiceRecognitionDifficulty = useSharedSelector(voiceRecognitionDifficultySelector);

  useTracking(RRStore);

  const debugEnabled = useSharedSelector((store) => store.preferences.debugEnabled);
  /** This preference is added to clients by support to debug VR issues */
  const isVerboseSpeechLogging = Number(store.getState().user.preferences?.verbose_speech_logging) === 1;
  // We dispatch to the store whenever we want to add points to the user
  const dispatchStore = useSharedDispatch();

  const { AudioRecorder, Voice } = props;

  /** Whether the user disabled, or browser supports voice recognition */
  const getCanUseSpeechRecognitionAPI = () =>
    !props.disableVoiceRecognition && Voice.hasNativeAPISupport() && Voice.shouldRecognizeForPhrase(props.phrase);

  /** Note: Android chrome can't record audio and use speech recognition at the same time */
  const getCanUseAudioRecorder = () => !getCanUseSpeechRecognitionAPI() || Boolean(AudioRecorder?.canRecord());

  /**
   * Returns a blob and a blob uri (if applicable)
   *
   * Note: on React Native environments, the blob is a string uri
   */
  const stopAudioAndVoice = async (options: {
    shouldSaveRecording: boolean;
    shouldImmediatelyStopRecognizing: boolean;
  }): Promise<[Blob | string | undefined, string | undefined]> => {
    if (!AudioRecorder) {
      await Voice.abort();
      return [undefined, undefined];
    }

    const [err, res] = await tryCatch(
      Promise.all([
        // Gracefully stop, otherwise abort
        options.shouldImmediatelyStopRecognizing ? Voice.abort() : Voice.stop(),
        AudioRecorder.finishRecording(options.shouldSaveRecording),
      ]),
    );

    if (err) {
      console.warn(err);
      actions.addDebugMessage(`Finish: error destroying voice: ${JSON.stringify(err)}`);
    }

    const { blob, uri } = res?.[1] || {};
    try {
      return [blob, uri];
    } catch (e) {
      actions.addDebugMessage(`[stopAudioAndVoice] failed to create object url: ${e}`);
      return [blob, undefined];
    }
  };

  /** @throws "ERROR_NO_MATCH", "ERROR_TRANSCRIPTION" */
  const transcribeAndRateBlob = async (blobOrUri: Blob | string) => {
    const formData = new FormData();

    if (blobOrUri instanceof Blob) {
      formData.append("file", blobOrUri);
    } else {
      // @ts-expect-error - React Native environments need to pass a file uri instead of a blob
      formData.append("file", {
        name: "audio.wav",
        type: "audio/wav",
        uri: blobOrUri, // "file://path/to/file.wav"
      });
    }

    formData.append("type", AudioRecorder?.getMimeType?.() || "");
    const token = store.getState().auth.token;

    const params = new URLSearchParams({
      v: "2",
      locale: props.locale,
      sanctumToken: token,
      encoding: AudioRecorder?.getEncoding?.() || "",
      phrases: JSON.stringify(props.phrase.strings.map((s) => s.text).slice(0, -1)),
    });

    const endpoint = props.transcribeEndpoint || "https://fns.rocketlanguages.com/api/transcribe";

    const res = await fetch(`${endpoint}?${params}`, {
      method: "POST",
      body: formData,
      headers:
        // on web, setting multipart/form-data headers will cause a mutliparty form parse error
        // with `BadRequestError: content-type missing boundary`
        props.platform !== "web" ? { Accept: "application/json", "Content-Type": "multipart/form-data" } : undefined,
    });

    const response = await res.json();

    const transcripts: string[] = response.results;

    actions.addDebugMessage("[transcribeAndRateBlob] response: ".concat(JSON.stringify(transcripts)));

    // Can come back as a 200 response with "{err: "bad config", error: true}"
    if (!transcripts || !Array.isArray(transcripts)) {
      throw new Error("ERROR_TRANSCRIPTION");
    }

    const bestResult = RR.getBestResultFromTranscripts({
      transcripts,
      currentFlag: RRStore.getState().flag,
      phrase: props.phrase,
      locale: props.locale,
      difficulty: voiceRecognitionDifficulty,
    });

    if (!bestResult) {
      throw new Error("ERROR_NO_MATCH");
    }

    // Success! Update result
    RRStore.setState({ result: bestResult });
  };

  /** Flag to finish after recording / speech recognition stops */
  const finish = async (flag: RRStateFlag = { status: "INACTIVE" }) => {
    // Make sure that we're only calling this finish function once
    // (Async partial & final results will likely trigger this function)
    if (RRStore.getState().flag.status === "FINISHING") {
      return;
    }

    // Clear speech timeouts
    actions.clearTimeouts();

    const shouldFetchTranscript =
      flag.status !== "ABORTED" &&
      props.shouldUseNodeSpeechApi &&
      !props.disableVoiceRecognition &&
      RRStore.getState().result?.ratingLevel !== 3;

    // Ignore error states from speech recognition if we're fetching the transcript
    // The flag is also set to inactive if speech recognition timed out within hitting the rating level threshold
    let flagToSet: RRStateFlag =
      shouldFetchTranscript || RRStore.getState().timeouts.hitRatingLevel ? { status: "INACTIVE" } : flag;

    const shouldSaveRecording = (() => {
      if (shouldFetchTranscript && flagToSet.status !== "ABORTED") {
        return true;
      }
      // No match, still save in case
      if (flagToSet.status === "ERROR" && flagToSet.errorCode === "ERROR_NO_MATCH") {
        return true;
      }
      if (["ERROR", "ABORTED"].includes(flagToSet.status)) {
        return false;
      }
      // const hasRatingLevel = Boolean(RRStore.getState()?.result?.ratingLevel);
      // return !getCanUseSpeechRecognitionAPI() || hasRatingLevel;
      return true;
    })();

    // Update state flag to "finishing"
    RRStore.setState({
      flag: {
        status: "FINISHING",
        fetchingTranscript: shouldFetchTranscript,
        shouldStillProcessFinalResult:
          flagToSet.status !== "ERROR" || (flagToSet.status === "ERROR" && flagToSet.errorCode === "ERROR_NO_MATCH"),
      },
    });

    const shouldImmediatelyStopRecognizing = (() => {
      // If we've aborted, already got 100%
      if (flagToSet.status === "ABORTED" || RRStore.getState().result?.ratingLevel === 3) {
        return true;
      }
      if (flagToSet.status === "ERROR") {
        // Immediately stop for all errors besides ERROR_NO_MATCH
        // This likely came from a timeout, try to process the end result
        return flagToSet.errorCode !== "ERROR_NO_MATCH";
      }
      // Idle, non 100% - we may want to process a final result to possibly get 100%
      return false;
    })();

    const [blobOrUri, blobUrl] = await stopAudioAndVoice({
      shouldSaveRecording,
      shouldImmediatelyStopRecognizing,
    });

    // NOTE: during the above call, a final "result" event will emit if Voice.stop() is called (At least for Android & iOS)
    // In the case this function has been entered with the timeout flag: "NO_MATCH", change it to INACTIVE instead if there's a result
    if (
      flagToSet.status === "ERROR" &&
      flagToSet.errorCode === "ERROR_NO_MATCH" &&
      RRStore.getState().result?.ratingLevel
    ) {
      flagToSet = { status: "INACTIVE" };
    }

    // Transcribe and rate blob, if applicable
    // This applies to browsers that don't support speech recognition (e.g. Safari)
    // and browsers that have opted out of using speech recognition (e.g. permission denied errors)
    if (shouldFetchTranscript && blobOrUri && blobUrl) {
      try {
        await transcribeAndRateBlob(blobOrUri);
      } catch (err) {
        if (typeof err !== "object" || !err || !("message" in err) || typeof err.message !== "string") {
          console.warn("[transcribeAndRateBlob] unknown error:", err);
          return;
        }
        actions.addDebugMessage(`[finish] error transcribing audio: ${err.message}`);
        // Check if there are any results before setting error state
        // This may happen when speech recognition is enabled with fallback to remote API
        // We still want to show *some* results, even if the remote API fails
        if (!RRStore.getState().result?.ratingLevel) {
          actions.finish({
            ratingVisible: false,
            blobUrl,
            flag: {
              status: "ERROR",
              errorCode: err.message as RRStateErrorCode, // "ERROR_TRANSCRIPTION" | "ERROR_NO_MATCH"
            },
          });
          return;
        }
      }
    }

    // After transcription, the rating level may change
    const currentResult = RRStore.getState().result;
    const shouldDisplayRating = flagToSet.status !== "ABORTED" && Boolean(currentResult?.ratingLevel);

    console.warn(
      "[finish]",
      JSON.stringify({
        flagToSet,
        shouldSaveRecording,
        currentResult,
        canUseSpeechRecognitionAPI: getCanUseSpeechRecognitionAPI(),
        shouldDisplayRating,
        blobUrl,
      }),
    );

    actions.finish({
      ratingVisible: shouldDisplayRating,
      flag: flagToSet,
      blobUrl,
    });

    // Send debug record to API
    if (isVerboseSpeechLogging && !debugEnabled) {
      const browser = detect();

      const data = new APIFormData<DebugRecordPayload>({
        device_name: browser?.name || "unknown",
        device_version: browser?.version || "",
        percentage: currentResult?.percentage ? currentResult?.percentage : 0,
        best_result: currentResult?.rawTranscription ? currentResult?.rawTranscription.toString() : "",
      });

      const { debugLog } = RRStore.getState();
      const formattedTimedLog = debugLog.map((log, i) => {
        const timestamp = i === 0 ? "0.00" : ((log.time - (debugLog[0]?.time || 0)) / 1000).toFixed(2);
        return `[${timestamp}]${log.message}`;
      });
      data.set("log", JSON.stringify(formattedTimedLog));
      if (!props.disableVoiceRecognition && blobOrUri && blobUrl) {
        data.set("recording_blob", blobOrUri);
      }

      API.post(["v2/phrase/{phrase}/debug-record", { phrase: props.phrase.id }], data);
    }

    // Notify parent
    props.onRecordFinish?.({
      phraseId: props.phrase.id,
      result: currentResult,
      blobUrl,
    });

    // Make sure that a lesson is set before attempting to add points
    if (!lessonId || lessonId <= 0) {
      return;
    }

    // Add points, update rating display

    const payload = {
      lesson: lessonId,
      phraseId: props.phrase.id,
      ratingPercentage: currentResult?.percentageDisplay || 0,
    };

    if (shouldDisplayRating) {
      dispatchStore(setPhraseRatingDisplay(payload));
      dispatchStore(
        requestAddPoints({
          rewardType: "phraseRecord",
          data: payload,
        }),
      );
    } else if (!getCanUseSpeechRecognitionAPI() && flagToSet.status !== "ERROR") {
      // Other browsers that can only record should also earn points
      // Remove rating percentage from payload
      dispatchStore(
        requestAddPoints({
          rewardType: "phraseRecord",
          data: omit(payload, "ratingPercentage"),
        }),
      );
    }
  };

  /** Whether the transcript result exceeds state result */
  function shouldUpdateBestResult({ ratedPhrase, isFinal }: { ratedPhrase: RatedPhrase; isFinal: boolean }) {
    const { result } = RRStore.getState();
    if (!result || ratedPhrase.percentage > result.percentage) {
      return true;
    }
    if (ratedPhrase.percentage === result.percentage) {
      // If the transcript is final, it's likely to be a better match (despite the same percentage)
      if (isFinal) {
        return true;
      }
      // Else, prefer the transcript that is longer
      return (ratedPhrase.rawTranscription || "").length > (result.rawTranscription || "").length;
    }
    return false;
  }

  /**
   * Starts recording & (if enabled, speech recognition)
   */
  async function start() {
    RRStore.setState({
      startedAt: new Date().getTime(),
    });

    if (Voice.recognizing || AudioRecorder?.recording) {
      finish({ status: "ERROR", errorCode: "ERROR_BUSY" });
      return;
    }

    if (["STARTING", "ACTIVE", "FINISHING"].includes(RRStore.getState().flag.status)) {
      actions.addDebugMessage("[start]: Still starting..");
      return;
    }

    actions.clearTimeouts();

    RRStore.setState({
      debugLog: [],
      flag: { status: "STARTING" },
    });

    const recordingTimeoutDuration = RR.getRecordingTimeoutDuration(props.phrase);

    if (debugEnabled) {
      actions.addDebugMessage(
        `[start] - Voice.hasNativeAPISupport: ${Voice.hasNativeAPISupport()} 
      - AudioRecorder.canRecord: ${AudioRecorder?.canRecord()} 
      - phraseDuration: ${props.phrase.duration}
      - recordingTimeoutDuration: ${recordingTimeoutDuration}
      - canUseSpeechRecognitionAPI: ${getCanUseSpeechRecognitionAPI()} 
      - isLegacySafariDesktop: ${isLegacySafariDesktop}
      - shouldUseNodeSpeechApi: ${props.shouldUseNodeSpeechApi}
      - disableVoiceRecognition: ${props.disableVoiceRecognition}
      - detect()?.name: ${detect()?.name}
      - detect()?.version: ${detect()?.version}
      - detect()?.os: ${detect()?.os}`,
      );
    }

    try {
      // Note: both AudioRecorder.start and Voice.start can throw errors
      if (AudioRecorder && getCanUseAudioRecorder()) {
        await AudioRecorder.start({ mono: props.shouldUseNodeSpeechApi });
      }

      if (getCanUseSpeechRecognitionAPI()) {
        await startVoiceRecognition(recordingTimeoutDuration);
      } else {
        // Audio should only be recording (on Safari, users that opted for disabling VR)
        actions.setActive();
        // Call finish() after recordingTimeoutDuration
        actions.setTimeouts({
          speechRecognition: setTimeout(() => finish(), recordingTimeoutDuration),
        });
      }
    } catch (error) {
      const flagToSet = ((): RRStateFlag => {
        if (!error || typeof error !== "object") {
          return {
            status: "ERROR",
            errorCode: "ERROR_UNKNOWN",
          };
        }
        // Check if error class name is a known record error
        if ("name" in error && typeof error.name === "string" && error.name in RR.RecordErrorFlags) {
          return {
            status: "ERROR",
            errorCode: RR.RecordErrorFlags[error.name as keyof typeof RR.RecordErrorFlags] as RRStateErrorCode,
          };
        }

        // Message can be a code itself (e.g. reject("ERROR_AUDIO"))
        if ("message" in error && typeof error.message === "string") {
          if (error.message === "ABORTED") {
            return { status: "ABORTED" };
          }
          return {
            status: "ERROR",
            errorCode: error.message as RRStateErrorCode,
          };
        }

        // Something unexpected happened
        return { status: "ERROR", errorCode: "ERROR_UNKNOWN" };
      })();

      // Call finish() with the error flag
      finish(flagToSet);
    }
  }

  const handleSpeechResults = ({ transcripts, isFinal }: { transcripts: string[]; isFinal: boolean }) => {
    // Clear the speech start timeout (if active)
    actions.clearTimeouts("speechStart", "startRecognition");

    const bestTranscriptResult = RR.getBestResultFromTranscripts({
      transcripts,
      phrase: props.phrase,
      currentFlag: RRStore.getState().flag,
      difficulty: voiceRecognitionDifficulty,
      locale: props.locale,
    });

    if (bestTranscriptResult) {
      if (shouldUpdateBestResult({ ratedPhrase: bestTranscriptResult, isFinal })) {
        // Update result
        RRStore.setState((currentState) => ({
          result: {
            ...bestTranscriptResult,
            // Preserve raw transcription (in case "ratingLevel === 3" and we're finishing in 2 seconds)
            // This avoids hiding the current raw transcript for 2 seconds before showing 100%
            rawTranscription: bestTranscriptResult.rawTranscription || currentState.result?.rawTranscription,
          },
        }));
      }
      const isFinishing = RRStore.getState().flag.status === "FINISHING";

      if (!isFinishing && bestTranscriptResult.ratingLevel === 3) {
        // Finish two seconds later if it's partial, otherwise instantly
        // We want to make sure that there's a bigger chance for a "final" result to prevent cut-offs
        if (isFinal) {
          finish();
        } else {
          const timeoutDuration = bestTranscriptResult.percentage === 100 ? 500 : 2000;
          actions.addDebugMessage(`Hit 100%, finishing in ${timeoutDuration}ms..`);
          // if percentage is 100, finish in 500ms, otherwise 2 secs
          actions.setTimeouts({
            hitRatingLevel: setTimeout(finish, timeoutDuration),
          });
        }
      }
    }

    if (isVerboseSpeechLogging || debugEnabled) {
      const percentage = bestTranscriptResult?.percentageDisplay || 0;
      const transcriptLines = transcripts?.join("\n - ");
      actions.addDebugMessage(
        `[${percentage}%][${isFinal ? "final" : "partial"}-speech] \n\n - ${transcriptLines}\n\n Best Transcript: ${
          bestTranscriptResult?.rawTranscription
        }`,
      );
    }
  };

  /**
   * Initializes speech recognizer & registers voice recognition callbacks
   *
   * Note: Voice.start may throw `{ message: RRStateErrorCode }`
   */
  function startVoiceRecognition(recordingTimeoutDuration: number) {
    actions.addDebugMessage(`[startVoiceRecognition][${props.locale}] starting...`);

    const callbacks: CallbackSubscriptions = {
      onSpeechError(e) {
        actions.addDebugMessage(`[onSpeechError] code: ${e}`);
        const hasResult = Boolean(RRStore.getState().result);

        // On Android, this will throw when speech recognition has finished
        // but while interim results may come up there's no "final" matches
        if (isAndroidMobile() && e === "ERROR_NO_MATCH" && hasResult) {
          finish({ status: "INACTIVE" });
        } else {
          finish({
            status: "ERROR",
            errorCode: e,
          });
        }
      },
      onSpeechStart() {
        // Speech should now be recognizing
        actions.clearTimeouts();
        actions.setActive();
        actions.setTimeouts({
          speechRecognition: setTimeout(() => {
            actions.addDebugMessage(
              `[speechRecognition] timed out after ${recordingTimeoutDuration}ms. Current state: ${
                RRStore.getState().flag?.status
              }`,
            );
            console.warn(`Speech timeout after ${recordingTimeoutDuration}ms. Current state:`, RRStore.getState().flag);
            const result = RRStore.getState().result;
            if (!result || !result.rawTranscription) {
              finish({
                status: "ERROR",
                errorCode: "ERROR_NO_MATCH",
              });
            } else {
              finish({ status: "INACTIVE" });
            }
          }, recordingTimeoutDuration),
        });
      },
      onSpeechResults(transcripts) {
        handleSpeechResults({
          transcripts,
          isFinal: true,
        });
      },
      onSpeechPartialResults(transcripts) {
        handleSpeechResults({
          transcripts,
          isFinal: false,
        });
      },
    };

    actions.setTimeouts({
      // Set a 5 second timeout to start recognition (Voice recognition may never start)
      startRecognition: setTimeout(() => {
        actions.addDebugMessage("[startRecognition]: timed out after 5s");
        finish({
          status: "ERROR",
          errorCode: "ERROR_START_RECOGNITION_TIMEOUT",
        });
      }, 5000),
      // Checks whether onSpeechStart emitted within the timeout period. Finishes if user never talked.
      speechStart: setTimeout(() => {
        finish({
          status: "ERROR",
          errorCode: "ERROR_SPEECH_START_TIMEOUT",
        });
      }, recordingTimeoutDuration),
    });

    return Voice.start({
      locale: props.locale,
      callbacks,
      phrase: props.phrase,
      isUsingRemoteSpeechAPI: props.shouldUseNodeSpeechApi,
    });
  }

  // Start recognition when recordOnMount is set to true
  useEffect(() => {
    if (props.recordOnMount) {
      start();
    }
    // eslint-disable-next-line
  }, [props.recordOnMount]);

  useEffect(() => {
    return () => {
      // Clear blob url on unmount to prevent memory leaks
      if (!props.persistBlobs) {
        // PlayIt persists blobs in order to be able to play recordings later
        const { blobUrl } = RRStore.getState();
        if (blobUrl) {
          window.URL.revokeObjectURL(blobUrl);
        }
      }
    };
  }, [RRStore, props.persistBlobs]);

  useEffect(() => {
    // Cleanup when this component unmounts
    return () => {
      const { actions, flag } = RRStore.getState();
      if (["ACTIVE", "STARTING", "FINISHING"].includes(flag.status)) {
        // Clear speech timeouts
        actions.clearTimeouts();
        AudioRecorder?.finishRecording(false);
        Voice.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    useRocketRecordState: RRStore,
    methods: {
      start,
      abort() {
        if (RRStore.getState().flag.status === "ACTIVE") {
          actions.addDebugMessage("[abort]: pressed");
          finish({ status: "ABORTED" });
        }
      },
    },
    computed: {
      canUseAudioRecorder: getCanUseAudioRecorder(),
    },
    phrase: props.phrase,
  };
}

function useTracking(RRStore: ReturnType<typeof useRocketRecordStore>) {
  useEffect(() => {
    return RRStore.subscribe((state, prevState) => {
      const desiredStatuses = ["ERROR", "INACTIVE"];
      const currentStatusIsDesired = desiredStatuses.includes(state.flag.status);
      const prevStatusIsDesired = desiredStatuses.includes(prevState.flag.status);

      // const sampleRate = 0.1;
      // const random = Math.random();
      const shouldTakeSample = true; // random < sampleRate;

      if (!currentStatusIsDesired || prevStatusIsDesired || !shouldTakeSample) {
        return;
      }

      const status = state.flag.status === "ERROR" ? "ERROR" : "SUCCESS";
      const reason = state.flag.status === "ERROR" ? state.flag.errorCode : undefined;
      const resultPercentage = status === "SUCCESS" ? (state.result?.percentage ?? 0) : undefined;
      const resultRawTranscription = status === "SUCCESS" ? (state.result?.rawTranscription ?? "") : undefined;

      const payload = {
        event: "rocket-record result",
        properties: {
          phraseId: state.phrase?.id ?? 0,
          recordingDurationMs: new Date().getTime() - state.startedAt,
          status,
          reason,
          resultPercentage,
          resultRawTranscription,
        },
      } satisfies APIParams<"POST", "v2/events/capture">;

      void API.post("v2/events/capture", payload);
    });
  }, [RRStore]);
}

/*
const buildGrammarList = (phrase: Phrase, locale: string) => {
  // Build strings from phrase
  const strings: string[] = [];

  for (const ps of phrase.strings) {
    if (ps.writing_system_id === WritingSystemIds.english && locale !== "en-US") {
      continue;
    }
    const text = purgeString(ps.text, {
      html: true,
      markdown: true,
      punctuation: true,
    });

    if (text) {
      strings.push(text);
    }
  }

  // Add alternative strings
  if (phrase.answer_alt) {
    try {
      JSON.parse(phrase.answer_alt).forEach((answer: string) => {
        const text = purgeString(answer, { html: true, markdown: true, punctuation: true });
        if (text) {
          strings.push(text.toLowerCase());
        }
      });
    } catch (err) {
      //
    }
  }
  return strings;
};
*/
