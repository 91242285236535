import { LessonComponent, TextComponent as TextComponentType } from "@rocket/types";
import styles from "./LessonComponent.module.scss";

export type Props = {
  lessonComponent: LessonComponent<"text", TextComponentType>;
  /** (Used for Travelogue lessons on non-english components) */
  italicize?: boolean;
};

const styleRegex = /<style([\S\s]*?)>([\S\s]*?)<\/style>/gi;
const metaRegex = /<meta([\S\s]*?) \/>/gi;

/**
 * @deprecated Legacy HTML text component
 *
 * @param props
 */
export default function TextComponent(props: Props) {
  const { lessonComponent } = props;
  return (
    <div>
      {lessonComponent.title_show && lessonComponent.title_text ? <h2>{lessonComponent.title_text}</h2> : null}
      <div
        style={props.italicize ? { fontStyle: "italic" } : {}}
        className={styles.overrides}
        dangerouslySetInnerHTML={{
          __html: lessonComponent.component.text.replace(styleRegex, "").replace(metaRegex, ""),
        }}
      />
    </div>
  );
}
