import { Button, RatingCircle } from "@rocket/ui";
import { Character, TranscriptRatings } from "@rocket/types";
import { PlayItContext } from "./includes/context";
import { actions as playItActions } from "./includes/usePlayIt";
import { useContext, useEffect, useMemo, useState } from "react";

import AudioContext from "../../../../ui/Audio/AudioContext";
import { OutlineButton } from "@rocket/ui/Button";
import { clsx } from "clsx";
import usePlayItRatings from "../../../../hooks/usePlayItRatings";
import useTranslation from "../../../../hooks/useTranslation";
import { useLocation, useNavigate } from "react-router-dom";
import usePlayItComponents from "../../../../hooks/usePlayItComponents";
import LessonContext from "../../../../context/LessonContext";

interface Props {
  characters: Character[];
  numLines: number;
  componentId: number;
}

export default function PlaybackButtons({ characters, numLines, componentId }: Props) {
  const t = useTranslation();
  const { dispatch, state } = useContext(PlayItContext)!;
  const audioContext = useContext(AudioContext);
  const lessonId = useContext(LessonContext).id;
  const location = useLocation();
  const navigate = useNavigate();

  const playItComponents = usePlayItComponents(lessonId);

  const playItId = useMemo(() => {
    if (playItComponents.length <= 1) {
      return "#role-playing";
    }
    // Travelogue components may have multiple play it components
    const playItNumber = playItComponents.findIndex((lc) => lc.component.id === componentId) + 1;
    return `#role-playing-#${playItNumber}`;
  }, [playItComponents, componentId]);

  const playItRatings = usePlayItRatings(componentId);

  /** Shows or hides character buttons */
  const [displayCharacterButtons, setDisplayCharacterButtons] = useState(hasRatings(playItRatings));

  // Effect to show the character buttons if transcript ratings are updated
  useEffect(() => {
    if (!displayCharacterButtons && hasRatings(playItRatings)) {
      setDisplayCharacterButtons(true);
    }
  }, [displayCharacterButtons, playItRatings]);

  useEffect(() => {
    if (state.status === "active") {
      audioContext.activePlayer?.pause();
    } else if (state.status === "finished" || state.status === "aborted") {
      // this is to re-enable the popups while doing this activity
      navigate(location.pathname, { replace: true, preventScrollReset: true });
    }
  }, [audioContext.activePlayer, state.status, location.pathname, navigate]);

  /**
   * Dispatches a `start` action to the PlayIt context
   *
   * @see PlayIt.tsx for state reducer updates
   */
  function startPlayIt(characterId: number) {
    // play-it-${lessonComponent.component.id}
    // this is to be able to block the popup while doing this activity
    navigate(playItId, { replace: true, relative: "path", preventScrollReset: true });
    dispatch(
      playItActions.start({
        componentId,
        characterId,
        numLines,
      }),
    );
  }

  const canPlaybackThroughConversation =
    !(state.status === "active" && state.mode === "test") && state.ratingsPerLine.size > 0;

  return (
    <div className="flex flex-1 flex-col print:hidden">
      <div
        className="grid-rows-auto flex flex-col gap-2 sm:grid"
        style={{ gridTemplateColumns: `repeat(${characters.length + 1}, minmax(125px, 1fr))` }}
      >
        <div className="grid grid-rows-[40px_50px_auto] items-center gap-2">
          <div />
          <Button
            color="primary"
            className={clsx(
              "flex w-full min-w-[125px] items-center justify-center whitespace-nowrap px-4 text-white",
              state.status === "active" && state.mode !== "listen" && "opacity-50",
            )}
            onClick={() => {
              if (state.mode === "listen" && state.status === "active") {
                dispatch({ type: "ABORT" });
              } else {
                // this is to be able to block the popup while doing this activity
                navigate(playItId, { replace: true, relative: "path" });
                dispatch(
                  playItActions.listenConversation({
                    componentId,
                    numLines,
                  }),
                );
              }
            }}
          >
            <div className="inline-block max-w-full overflow-hidden text-ellipsis uppercase">
              {state.mode === "listen" && state.status === "active" ? t("stop") : t("play-conversation")}
            </div>
          </Button>
          <div />
        </div>
        {characters.map((char) => {
          const rating = playItRatings[char.id];
          const replayIsActive =
            canPlaybackThroughConversation &&
            state.status === "active" &&
            state.characterId === char.id &&
            state.mode === "playthrough";

          return (
            <div key={`char.${char.id}`} className="grid grid-rows-[40px_50px_auto] items-center gap-2">
              <div className="flex flex-1 justify-center">
                <RatingCircle rating={rating || 0} />
              </div>
              <OutlineButton
                color="primary"
                className={clsx(
                  "flex w-full min-w-[125px] items-center justify-center whitespace-nowrap px-4 uppercase dark:border-text1 dark:text-text1",
                  state.mode === "listen" && state.status === "active" && "opacity-50",
                  state.status === "active" && (state.characterId !== char.id || state.mode !== "test") && "opacity-50",
                )}
                onClick={() => {
                  audioContext.activePlayer?.pause();
                  if (state.status === "active" && state.characterId === char.id) {
                    dispatch({ type: "ABORT" });
                  } else {
                    startPlayIt(char.id);
                  }
                }}
              >
                <div className="inline-block max-w-full overflow-hidden text-ellipsis uppercase">
                  {state.status === "active" && state.characterId === char.id && state.mode === "test"
                    ? t("stop")
                    : t("play-as-{hostname}", { hostname: char.name })}
                </div>
              </OutlineButton>

              {canPlaybackThroughConversation && state.characterId === char.id ? (
                <OutlineButton
                  color="primary"
                  className="flex w-full min-w-[125px] items-center justify-center overflow-hidden text-ellipsis whitespace-nowrap uppercase text-brand"
                  onClick={() => {
                    if (replayIsActive) {
                      dispatch({ type: "ABORT" });
                    } else {
                      navigate(playItId, { replace: true, relative: "path" });
                      dispatch(
                        playItActions.playbackConversation({
                          componentId,
                        }),
                      );
                    }
                  }}
                >
                  {replayIsActive ? t("stop") : t("replay")}
                </OutlineButton>
              ) : (
                <div />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function hasRatings(ratings?: TranscriptRatings) {
  if (!ratings || Array.isArray(ratings) || typeof ratings !== "object") {
    return false;
  }

  return Object.keys(ratings).length > 0;
}
