import * as constants from "./constants";
import * as prefActions from "../preferences/actions";
import * as userActions from "./actions";
import * as uiActions from "../ui/actions";

import API, { Client, updateProfile, uploadAvatar } from "../../res/Api";
import type {
  BasicProduct,
  Course,
  CoursePreferences,
  DeviceObject,
  Product,
  SyncUserAndProductPayload,
  UserProducts,
} from "@rocket/types";
import { SagaReturnType, call, put, select, takeLatest } from "redux-saga/effects";
import {
  preferencesSelector,
  userPreferencesSelector,
  userProductsSelector,
  userProfileSelector,
  userSelector,
} from "../selectors";

import { ActionType } from "typesafe-actions";
import Courses from "../../res/courses";
import { SharedRootState } from "../types";
import { getErrorMessage } from "../../utils";
import handleRequestError from "../../utils/handleRequestError";
import { navigateToDashboard } from "../nav/actions";
import { PromiseCache } from "../../hooks/usePromise";

interface SyncUserParams {
  /** Required for device-specific details */
  getDeviceInfo(userId?: number): Promise<DeviceObject>;
  getTimeZone(): string;
}

function createSyncUserGenerator(params: SyncUserParams) {
  return function* syncUser() {
    const preferences: ReturnType<typeof preferencesSelector> = yield select(preferencesSelector);
    // Resync active lesson ID, product info & user
    if (preferences.activeProduct) {
      const user: ReturnType<typeof userSelector> = yield select(userSelector);
      const device: SagaReturnType<typeof params.getDeviceInfo> = yield call(params.getDeviceInfo, user.profile?.id);

      // Get selected product and verify whether product should be synced
      yield call(startSyncUserAndProduct, {
        product: preferences.activeProduct,
        timezone: params.getTimeZone(),
        device,
      });
    } else {
      // Just resync the user details
      try {
        const syncUserRequest = () => API.get("v2/syncUser");
        const response: SagaReturnType<typeof syncUserRequest> = yield call(syncUserRequest);
        yield put(userActions.syncBasicUser(response.data));
      } catch (error) {
        console.warn(error);
      }
    }
  };
}

export function getLowestLevelProductFromCourse(userProducts: UserProducts, course: Course) {
  const lowestLevelProduct = (products: BasicProduct[]) => {
    let activeProduct;

    for (const product of products) {
      if (product.course_id !== course.id) {
        continue;
      }
      const courseProduct = course.products.find((p) => p.id === product.id);
      if (!courseProduct) {
        continue;
      }
      if (!activeProduct || courseProduct.level_id < activeProduct.level_id) {
        activeProduct = courseProduct;
      }
    }
    return activeProduct;
  };

  return lowestLevelProduct(userProducts.paid) || lowestLevelProduct(userProducts.trial);
}

const hasAuthTokenSelector = (state: SharedRootState) => Boolean(state.auth.token);

/**
 * Adds a trial course on the server
 */
function* asyncCourseSignup(courseId: number) {
  try {
    yield put(userActions.startLoading({ key: "courseSignup" }));
    const addTrialCourseRequest = () => API.get(["v2/user/addTrialCourse/{course}", { course: courseId }]);
    const response: SagaReturnType<typeof addTrialCourseRequest> = yield call(addTrialCourseRequest);
    yield put(userActions.stopLoading({ key: "courseSignup" }));
    // Update user and products
    if (response.data) {
      yield put(userActions.syncBasicUser(response.data));
    }
  } catch (err: any) {
    yield put(userActions.errorLoading({ key: "courseSignup" }));
    yield handleRequestError(err);
    console.warn("[asyncCourseSignup]", err);
  }
}

/**
 * Updates the daily points goal on the server side.
 * See reducer for client side modifications on this same action
 */
function* updateDailyPointsGoal(action: ActionType<typeof userActions.updateDailyPointsGoal>) {
  const user: ReturnType<typeof userSelector> = yield select(userSelector);
  if (!user.profile || !user.profile.hash) {
    // TODO: yield navigate, display logged out
    return;
  }

  try {
    const request = () =>
      API.post("v2/user/update-preferences", {
        daily_points_goal: action.payload.dailyPointsGoal,
      });
    yield call(request);
  } catch (error) {
    console.warn("updateDailyPointsGoal", error);
    uiActions.toast({ type: "error", message: getErrorMessage(error) });
  }
}

const isLoggedInSelector = (state: SharedRootState) => Boolean(state.auth.status === "loggedin");

/**
 * Optimistically update preferences
 */
function* asyncUpdatePreference(action: ActionType<typeof userActions.asyncUpdatePreference>) {
  const { preferenceKey, preference } = action.payload;
  const { preferences }: ReturnType<typeof userSelector> = yield select(userSelector);
  const isLoggedIn: ReturnType<typeof isLoggedInSelector> = yield select(isLoggedInSelector);

  try {
    yield put(userActions.updatePreferences({ ...preferences, [preferenceKey]: preference }));

    if (!preferences || !isLoggedIn) {
      console.warn("[asyncUpdatePreference]", "Not logged in");
      return;
    }

    yield call(() => API.post("v2/user/update-preferences", { [preferenceKey]: preference }));
  } catch (e) {
    console.warn(e);
    yield put(uiActions.toast({ type: "error", message: getErrorMessage(e) }));
    if (preferences) {
      yield put(userActions.updatePreferences(preferences));
    }
  }
}

/**
 * Update multiple preferences in one call
 */
function* asyncUpdatePreferences(action: ActionType<typeof userActions.asyncUpdatePreferences>) {
  const preferences = action.payload;
  const { preferences: fallbackPreferences }: ReturnType<typeof userSelector> = yield select(userSelector);
  const isLoggedIn: ReturnType<typeof isLoggedInSelector> = yield select(isLoggedInSelector);

  yield put(
    userActions.startLoading({
      key: "account",
    }),
  );
  try {
    yield put(userActions.updatePreferences(preferences));
    if (isLoggedIn) {
      yield call(() => API.post("v2/user/update-preferences", preferences));
    } else {
      console.warn("[user/sagas@asyncUpdatePreferences] User is not logged in");
    }
    yield put(userActions.stopLoading({ key: "account" }));
    yield put(uiActions.toast({ type: "success", message: "Updated preferences" }));
  } catch (e) {
    console.warn(e);
    yield put(uiActions.toast({ type: "error", message: getErrorMessage(e) }));
    if (fallbackPreferences) {
      yield put(userActions.updatePreferences(fallbackPreferences));
    }
    yield put(
      userActions.errorLoading({
        key: "account",
      }),
    );
  }
}

/**
 * Optimistically updates the user's flashcard settings preferences on the server side.
 * See reducer for client side modifications on this same action
 */
function* updateFlashcardPreferences(action: ActionType<typeof userActions.updateFlashcardPreferences>) {
  const user: ReturnType<typeof userSelector> = yield select(userSelector);
  const isLoggedIn: ReturnType<typeof isLoggedInSelector> = yield select(isLoggedInSelector);

  if (!user.profile || !user.profile.hash || !isLoggedIn) {
    console.warn("[user/sagas@updateFlashcardPreferences] User is not logged in");
    return;
  }

  try {
    const updateFlaschardPreferencesRequest = () =>
      API.post("v2/user/update-preferences", {
        flashcard_ws_front: {
          value: JSON.stringify(action.payload.flashcardPreferences.flashcard_ws_front),
          course: action.payload.courseId,
        },
        flashcard_ws_back: {
          value: JSON.stringify(action.payload.flashcardPreferences.flashcard_ws_back),
          course: action.payload.courseId,
        },
      });
    yield call(updateFlaschardPreferencesRequest);
  } catch (error) {
    console.warn("API.updateFlashcardPreferences", error);
    yield put(uiActions.toast({ type: "error", message: getErrorMessage(error) }));
  }
}

/**
 * Optimistically updates the user's flashcard settings preferences on the server side.
 * See reducer for client side modifications on this same action
 */
function* updateCoursePreferences(action: ActionType<typeof userActions.asyncUpdateCoursePreferences>) {
  const user: ReturnType<typeof userSelector> = yield select(userSelector);
  const isLoggedIn: ReturnType<typeof isLoggedInSelector> = yield select(isLoggedInSelector);

  if (!user.profile || !user.profile.hash || !isLoggedIn) {
    console.warn("[user/sagas@updateCoursePreferences] User is not logged in");
    return;
  }

  try {
    const payload: { [key in keyof CoursePreferences]: { value: string; course: number } } = {};
    for (const key in action.payload.preferences) {
      payload[key as keyof CoursePreferences] = {
        value: JSON.stringify(action.payload.preferences[key as keyof CoursePreferences]),
        course: action.payload.courseId,
      };
    }
    const updateCoursePreferencesRequest = () => API.post("v2/user/update-preferences", payload);
    yield call(updateCoursePreferencesRequest);
  } catch (error) {
    console.warn("API.updateCoursePreferencesRequest", error);
    yield put(uiActions.toast({ type: "error", message: getErrorMessage(error) }));
  }
}

/**
 * Updates the voice recognition difficulty the server side.
 * See reducer for client side modifications on this same action
 */
function* updateRRDifficulty(action: ActionType<typeof userActions.updateRRDifficulty>) {
  const user: ReturnType<typeof userSelector> = yield select(userSelector);
  if (!user.profile || !user.profile.hash) {
    // TODO: yield navigate, display logged out
    return;
  }

  try {
    yield call(() =>
      API.post("v2/user/update-preferences", { voice_recognition_difficulty: action.payload.difficulty }),
    );
  } catch (error) {
    console.warn("[updateRRDifficulty]", error);
    yield put(uiActions.toast({ type: "error", message: getErrorMessage(error) }));
  }
}

/**
 * Synchronises User and product
 * @param {number} productId - Active product the user has (affects leaderboard position, points earned & suggested lesson ID)
 */
export function* startSyncUserAndProduct(params: { product: Product; timezone: string; device?: DeviceObject }) {
  const { product, timezone, device } = params;
  // Get selected product and verify whether product should be synced
  if (!product) {
    console.warn("[syncUserAndProduct]: No product found");
    return;
  }

  // Sync products, user profile, stats & suggested lesson id
  try {
    const syncStateRequest = () => {
      //return API.get(["v2/product/{product}/sync", { product: product.id }], params);
      function syncState(productId: number) {
        let stringifiedParams = `timezone=${timezone.replace("/", "%2F")}`;
        if (device) {
          Object.keys(device).forEach((key) => {
            // @ts-ignore
            stringifiedParams += `&device%5B${key}%5D=${device[key]}`;
          });
        }

        return Client.axios.get<SyncUserAndProductPayload>(`/v2/product/${productId}/sync?${stringifiedParams}`);
      }
      return syncState(product.id);
    };
    const response: SagaReturnType<typeof syncStateRequest> = yield call(syncStateRequest);

    // Sanity check data fields exist
    if (!response.data || !response.data.user || !response.data.user.products) {
      throw new Error("[SYNC STATE] Unknown response");
    }

    yield put(userActions.syncUserAndProduct(product.id, response.data));

    // Verify that active product still exists in user's products
    const { paid, trial } = response.data.user.products;

    const productExists = (p: BasicProduct) => p.id === product.id;
    if (paid.find(productExists) || trial.find(productExists)) {
      return;
    }

    // Product doesn't exist. Check for any products with the same course.
    const courseId = product.course_id;
    const hasProductWithSameCourse = (p: BasicProduct) => p.course_id === courseId;

    // No products with the same course. Go back to course selection screen and unset active product
    if (!paid.find(hasProductWithSameCourse) && !trial.find(hasProductWithSameCourse)) {
      // Unset active product
      yield put(prefActions.setActiveProduct());
      // (Saga watcher for SET_ACTIVE_PRODUCT will navigate back to the CourseSelectionScreen)
      return;
    }

    // User has products with the same course
    const foundCourse = Courses.find((c) => c.id === courseId);
    if (foundCourse) {
      yield put(userActions.asyncSelectCourse(foundCourse, false));
    }
  } catch (err: any) {
    // Logs the user out if their token expired
    yield handleRequestError(err);
    console.warn("[syncState]", err);
  }
}

/**
 * Sets the active course and product from a selected course
 * Effects:
 * - User may get signed up for a trial course, recursive call to this function
 * - User dispatches an action to select a course and product
 */
function* asyncSelectCourse(action: ActionType<typeof userActions.asyncSelectCourse>, attempts = 1): any {
  const { course, shouldNavigate, shouldForceUpdateProduct } = action.payload;
  const products: ReturnType<typeof userProductsSelector> = yield select(userProductsSelector);

  const { activeProduct }: ReturnType<typeof preferencesSelector> = yield select(preferencesSelector);

  // Course or product hasn't changed. Navigate back to the dashboard.
  if (!shouldForceUpdateProduct && shouldNavigate && activeProduct && activeProduct.course_id === course.id) {
    // push(`/members/products/${activeProduct.id}/dashboard`);
    yield put(navigateToDashboard(activeProduct.id));
  }

  // Product did change, find the lowest level product of the course that the user owns
  const newActiveProduct = getLowestLevelProductFromCourse(products, course);

  // Sign the user up for a trial
  if (!newActiveProduct) {
    // Try to sign up for course.
    yield asyncCourseSignup(course.id);

    // Check if user has been logged out
    const hasAuthToken: ReturnType<typeof hasAuthTokenSelector> = yield select(hasAuthTokenSelector);
    if (!hasAuthToken) {
      return;
    }

    // Recursively call this function again and try to set an active course.
    if (attempts === 1) {
      yield asyncSelectCourse(action, attempts + 1);
      return;
    }

    // User either:
    // - attempted to sign up for the same trial twice.
    // - has a network issue
    // - or the product doesn't exist on the app (this would need to be added in courses.tsx)
    yield put(userActions.courseSelectionError());
    return;
  }

  // User selected product. Set active product.
  yield put(prefActions.setActiveCourseAndProduct(course, newActiveProduct));

  if (shouldNavigate) {
    yield put(navigateToDashboard(newActiveProduct.id));
    PromiseCache.delete("v2/courses");
  }

  // Update user with new product
  // Prevents issues when changing courses back-and-forth
  yield put(userActions.sagaSyncUser());
}

function* asyncSelectProduct(action: ActionType<typeof userActions.asyncSelectProduct>) {
  const { product, course, shouldNavigate } = action.payload;

  yield put(prefActions.setActiveCourseAndProduct(course, product));

  if (shouldNavigate) {
    yield put(navigateToDashboard(product.id));
  }

  // Update user with new product
  // Prevents issues when changing courses back-and-forth
  yield put(userActions.sagaSyncUser());
}

function* getNotifications(action: ActionType<typeof userActions.asyncGetNotifications>) {
  const activeCourseId = action.payload;
  try {
    const request = () => API.get(["v2/course/{course}/user-meta-data", { course: activeCourseId }]);
    const { data: notifications }: SagaReturnType<typeof request> = yield call(request);
    yield put(userActions.updateNotifications(notifications));
  } catch (e) {
    console.warn(e);
  }
}

function* asyncUpdateAccount(action: ActionType<typeof userActions.asyncUpdateAccount>) {
  const currentUserProfile: ReturnType<typeof userProfileSelector> = yield select(userProfileSelector);
  const formData = action.payload.data;

  yield put(userActions.startLoading({ key: "account" }));
  try {
    if (currentUserProfile) {
      const { data } = yield call(updateProfile, formData, currentUserProfile.id);

      yield put(
        userActions.updateProfile({
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          username: data.username,
        }),
      );

      if (typeof data !== "string") {
        yield put(userActions.updateAvatar(data.avatar_url));
      }

      if (data.preferences) {
        yield put(
          userActions.updatePreferences({
            hide_topbar_settings: data.preferences.hide_topbar_settings,
            send_forum_notifications: data.preferences.send_forum_notifications,
          }),
        );
      }

      yield put(userActions.stopLoading({ key: "account" }));

      yield put(uiActions.toast({ type: "success", message: "Updated account" }));
    }
  } catch (e: any) {
    console.warn(e);
    if (currentUserProfile) {
      yield put(userActions.updateProfile(currentUserProfile));
    }
    yield put(userActions.errorLoading({ key: "account" }));
    uiActions.toast({ type: "error", message: getErrorMessage(e) });
  }
}

function* asyncUpdateForumNotificationsPreferences(
  action: ActionType<typeof userActions.asyncUpdateForumNotificationsPreferences>,
) {
  const currentPreferences: ReturnType<typeof userPreferencesSelector> = yield select(userPreferencesSelector);
  yield put(
    userActions.startLoading({
      key: "notificationPreferences",
    }),
  );
  try {
    const request = () => API.post("v2/user/update-preferences", action.payload.preferences);
    const { data } = yield call(request);
    const notificationPreference = data.send_forum_notifications.toString();
    yield put(userActions.updateForumNotificationsPreferences(notificationPreference));
    yield put(
      userActions.stopLoading({
        key: "notificationPreferences",
      }),
    );
  } catch (e) {
    console.warn(e);
    if (currentPreferences?.send_forum_notifications) {
      yield put(userActions.updateForumNotificationsPreferences(currentPreferences.send_forum_notifications));
    }
    yield put(
      userActions.errorLoading({
        key: "notificationPreferences",
      }),
    );
  }
}

function* asyncUpdateAvatar(action: ActionType<typeof userActions.asyncUpdateAvatar>) {
  const currentUser: ReturnType<typeof userSelector> = yield select(userSelector);
  yield put(
    userActions.startLoading({
      key: "avatar",
    }),
  );
  try {
    const { data } = yield call(uploadAvatar, action.payload);
    yield put(userActions.updateAvatar(data.avatarUrl));
    yield put(
      userActions.stopLoading({
        key: "avatar",
      }),
    );
  } catch (e) {
    console.warn(e);
    yield put(userActions.updateAvatar(currentUser.avatarUrl));
    yield put(
      userActions.errorLoading({
        key: "avatar",
      }),
    );
  }
}

export default function createUserSagas(params: { syncUser: SyncUserParams }) {
  return [
    takeLatest(constants.ASYNC_SELECT_COURSE, asyncSelectCourse),
    takeLatest(constants.ASYNC_SELECT_PRODUCT, asyncSelectProduct),
    takeLatest(constants.UPDATE_DAILY_POINTS_GOAL, updateDailyPointsGoal),
    takeLatest(constants.UPDATE_RR_DIFFICULTY, updateRRDifficulty),
    takeLatest(constants.UPDATE_FLASHCARD_PREFERENCE, updateFlashcardPreferences),
    takeLatest(constants.ASYNC_GET_NOTIFICATIONS, getNotifications),
    takeLatest(constants.ASYNC_UPDATE_ACCOUNT, asyncUpdateAccount),
    takeLatest(constants.ASYNC_UPDATE_FORUM_NOTIFICATIONS_PREFERENCES, asyncUpdateForumNotificationsPreferences),
    takeLatest(constants.ASYNC_UPDATE_AVATAR, asyncUpdateAvatar),
    takeLatest(constants.SAGA_SYNC_USER, createSyncUserGenerator(params.syncUser)),
    takeLatest(constants.ASYNC_UPDATE_PREFERENCE, asyncUpdatePreference),
    takeLatest(constants.ASYNC_UPDATE_PREFERENCES, asyncUpdatePreferences),
    takeLatest(constants.ASYNC_UPDATE_COURSE_PREFERENCES, updateCoursePreferences),
  ];
}
