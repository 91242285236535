import * as PlayItUtils from "./includes/utils";

import type { Character, TranscriptLine } from "@rocket/types";
import { PlayItContext } from "./includes/context";
import { actions as playItActions } from "./includes/usePlayIt";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import ComponentStatusWrapper from "../../includes/ComponentStatusWrapper";
import Phrasebox from "../../../Phrasebox";
import { RatedPhrase } from "../../../../hooks/useRocketRecord/types";
import Voice from "../../../Phrasebox/includes/RocketRecord/Voice";
import { clsx } from "clsx";
import { isMobileDevice } from "../../../../utils/browser";
import useActiveCourse from "../../../../hooks/useActiveCourse";
import useGetter from "../../../../hooks/useGetter";
import { useSharedSelector } from "../../../../store";

interface Props {
  lines: TranscriptLine[];
  activeCourseSlug: string;
}

/** DOM ID applied to the container of a phrase line. Used for height calculation & scrolling */
const getPhraseLineDomId = (lineId: number) => `playit-phrase-${lineId}`;

export default function PlayItLines(props: Props) {
  const PlayIt = useContext(PlayItContext)!;
  const getCurrentPlayIt = useGetter(PlayIt);
  const phrases = useSharedSelector((store) => store.lesson.entities.phrases);
  const { filteredWritingSystems, hideWords } = PlayIt;
  const { lines, activeCourseSlug } = props;
  const isArabic = useActiveCourse()?.slug === "arabic";

  const active = PlayIt.state.status === "active" || PlayIt.state.status === "countdown";

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const phraseStringOptions = useCallback(
    PlayItUtils.createPhraseStringDisplaySettings({
      applyStringTransformer: hideWords,
      courseSlug: activeCourseSlug,
      filterWs: filteredWritingSystems,
    }),
    [hideWords, activeCourseSlug, filteredWritingSystems, PlayIt.state.index],
  );

  const getCharacterName = useCallback(
    (character: Character | null | undefined, index: number) => {
      return character?.name && lines?.[index - 1]?.character?.name !== character.name ? character.name : undefined;
    },
    [lines],
  );

  /** Play It has started on this component */
  const isStarted = PlayIt.state.status === "active";

  const shouldScrollIntoView = isStarted || PlayIt.state.status === "countdown";

  const alternativeCharacterStyles = useMemo(() => {
    const characterColors = new Map<
      number,
      {
        phraseBoxStyles: string;
        activeStyles: string;
        containerStyles: string;
      }
    >();
    lines.forEach((_line) => {
      if (_line.character) {
        if (!characterColors.has(_line.character.id)) {
          characterColors.set(
            _line.character.id,
            characterColors.size % 2 === 0
              ? {
                  phraseBoxStyles: "!bg-rocketpurple-extra-light dark:!bg-slate-700",
                  activeStyles: "border-2 border-rocketred",
                  containerStyles: isArabic ? "md:ml-[10%]" : "md:mr-[10%]",
                }
              : {
                  phraseBoxStyles: "!bg-surface-blue dark:!bg-zinc-700",
                  activeStyles: "border-2  border-rocketblue",
                  containerStyles: isArabic ? "md:mr-[10%]" : "md:ml-[10%]",
                },
          );
        }
      }
    });
    return characterColors;
  }, [isArabic, lines]);

  useEffect(() => {
    // Height from the top is a bit too much on mobile
    if (shouldScrollIntoView && !isMobileDevice()) {
      const playItElement = document.getElementById(`play-it-${PlayIt.state.componentId}`);
      if (playItElement) {
        const scrollPosition = playItElement.getBoundingClientRect().top + window.pageYOffset - 80;
        window.scrollTo({ top: scrollPosition, behavior: "smooth" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldScrollIntoView]);

  const onPlayFinish = useCallback(
    (line: TranscriptLine, index: number) => {
      return () => {
        const currentPlayIt = getCurrentPlayIt();
        const { characterId, mode, status, index: stateIndex } = currentPlayIt.state;

        const isCurrentLineActive = status === "active" && index === stateIndex;
        const isRecordLineOnTest = Boolean(line.character?.id === characterId) && mode === "test";

        if (!isCurrentLineActive || isRecordLineOnTest) {
          return;
        }

        if (mode === "listen") {
          const timeoutDuration = line.character?.id === lines[(index || 1) - 1]?.character?.id ? 750 : 250;
          setTimeout(() => {
            currentPlayIt.dispatch(playItActions.next());
          }, timeoutDuration);
        } else {
          setTimeout(() => {
            currentPlayIt.dispatch(playItActions.next());
          }, 750);
        }
      };
    },
    [getCurrentPlayIt, lines],
  );

  const onRecordFinish = useCallback(
    (line: TranscriptLine, index: number) => {
      return (params: { phraseId: number; result?: RatedPhrase; blobUrl?: string }) => {
        const { result } = params;

        const currentPlayIt = getCurrentPlayIt();

        const { disableVoiceRecognition, characterId, mode, status, index: stateIndex } = currentPlayIt.state;

        if (
          Voice.isAnyKindOfRecognitionSupported() &&
          !disableVoiceRecognition &&
          (!result || result.ratingLevel === 0)
        ) {
          return;
        }

        /** Play It has started on this component */
        const isRecordLine = Boolean(line.character && line.character.id === characterId && mode !== "listen");
        const isLineActive = status === "active" && index === stateIndex;
        if (isRecordLine && isLineActive) {
          currentPlayIt.dispatch(playItActions.recordFinish(params));
          setTimeout(() => {
            currentPlayIt.dispatch(playItActions.next());
          }, 750);
        }
      };
    },
    [getCurrentPlayIt],
  );

  /**
   * The visible section of the transcript lines
   *
   * Uses the `startIndexOffset` to offset the transcript lines
   */
  // const visibleLines = useMemo(() => {
  //   return lines.slice(startIndexOffset);
  // }, [lines, startIndexOffset]);

  /** Renders a single Play It Line  */
  function renderPlayItLine(line: TranscriptLine, index: number) {
    const phrase = phrases[line.phrase_id];

    if (!phrase) {
      return null;
    }

    const isCurrentLineActive = isStarted && index === PlayIt.state.index;
    const isRecordLine = Boolean(
      line.character && line.character.id === PlayIt.state.characterId && PlayIt.state.mode !== "listen",
    );

    /** Whether the component will play or record */
    const playOnMount = !isRecordLine;
    const recordOnMount = PlayIt.state.mode === "test" && isRecordLine;
    const playRecordingOnMount = PlayIt.state.mode === "playthrough" && isRecordLine;

    // Fetch the initial result in case the component re-mounts and loses state
    const initialResult = PlayIt.state.ratingsPerLine && PlayIt.state.ratingsPerLine.get(line.phrase_id);
    const isTest = PlayIt.state.mode === "test";
    const isListeningConversation = PlayIt.state.mode === "listen";

    const PhraseboxElement = (
      <Phrasebox
        key={`tp.${line.phrase_id}.${isStarted}`}
        persistBlobs={true}
        initialResult={initialResult?.result}
        initialBlobUrl={initialResult?.blobUrl}
        phrase={phrase}
        getPhraseStringOptions={phraseStringOptions}
        disableVoiceRecognition={PlayIt.state.disableVoiceRecognition}
        hidePlayer={active && isTest && isRecordLine}
        disablePlayer={
          active &&
          ((isTest && !isRecordLine && !isCurrentLineActive) || (isListeningConversation && !isCurrentLineActive))
        }
        recordButtonHidden={active && ((isTest && !isRecordLine) || isListeningConversation)}
        recordingDisabled={active && isTest && isRecordLine && !isCurrentLineActive}
        playOnMount={isCurrentLineActive && playOnMount}
        recordOnMount={isCurrentLineActive && recordOnMount}
        onPlayFinish={
          isCurrentLineActive && (playOnMount || playRecordingOnMount) ? onPlayFinish(line, index) : undefined
        }
        playRecordingOnMount={isCurrentLineActive && playRecordingOnMount}
        onRecordFinish={isCurrentLineActive && isRecordLine && isTest ? onRecordFinish(line, index) : undefined}
        className={clsx(
          line.character?.id && alternativeCharacterStyles.get(line.character.id)?.phraseBoxStyles,
          line.character?.id && isCurrentLineActive && alternativeCharacterStyles.get(line.character.id)?.activeStyles,
          !isCurrentLineActive && "border-2 border-transparent",
          (PlayIt.phraseboxWrapper || !line.status) && "bg-transparent",
        )}
      />
    );

    const characterName = getCharacterName(line.character, index);

    return (
      <div
        key={line.id}
        id={getPhraseLineDomId(line.id)}
        className={clsx(
          "mt-2 break-inside-avoid pt-2 last-of-type:pb-2",
          line.character?.id && alternativeCharacterStyles.get(line.character.id)?.containerStyles,
        )}
      >
        {characterName && <h4 className="pb-2 text-brand dark:text-white">{characterName}</h4>}
        {PlayIt.phraseboxWrapper ? (
          PlayIt.phraseboxWrapper(line.phrase_id, phrases[line.phrase_id]!, PhraseboxElement, index)
        ) : (
          <ComponentStatusWrapper draft={!line.status}>{PhraseboxElement}</ComponentStatusWrapper>
        )}
      </div>
    );
  }

  return (
    <div className="overflow-hidden">
      <PlayItScroller lines={lines} index={PlayIt.state.index} isStarted={isStarted}>
        {lines.map(renderPlayItLine)}
      </PlayItScroller>
    </div>
  );
}

/** Sets a margin-top offset based off the current index & phrase rect height */
function PlayItScroller(props: {
  lines: TranscriptLine[];
  index: number;
  isStarted: boolean;
  children: React.ReactNode;
}) {
  const { lines, index, isStarted, children } = props;

  const [translateY, setTranslateY] = useState(0);

  useEffect(
    function onIndexChange() {
      if (isStarted && index > 0) {
        const previousLine = lines[index - 1];
        if (!previousLine) {
          return;
        }
        const phraseHeight = document.getElementById(getPhraseLineDomId(previousLine.id))?.scrollHeight;
        if (phraseHeight) {
          // scrollHeight does not take in consideration the margin of the component.
          // Therefore, 8 seems to be the magic number that translates all the element properly.
          setTranslateY((currentOffset) => currentOffset - phraseHeight - 8);
        }
      } else {
        // Reset offset
        setTranslateY(0);
      }
    },
    [index, isStarted, lines],
  );

  return (
    <div
      className="transition-[transform] duration-[350ms]"
      style={translateY ? { transform: `translateY(${translateY}px)` } : undefined}
    >
      {children}
    </div>
  );
}
