import en from "./en";

export const translationSets = {
  general: {
    en: () => import("./en").then((res) => res.default),
    es: () => import("./es").then((res) => res.default),
    ja: () => import("./ja").then((res) => res.default),
  },
  // lesson: {
  //   en: () => import("./lesson/en").then((res) => res.default),
  //   es: () => import("./lesson/es").then((res) => res.default),
  // },
};

export type UnPromisify<T> = T extends Promise<infer U> ? U : T;
export type TranslationSetKeys = keyof typeof translationSets;
export type Locale = "es" | "en" | "ja";

export type Locales = {
  [localeKey in Locale]: {
    [key in TranslationSetKeys]?: UnPromisify<ReturnType<(typeof translationSets)[key][localeKey]>>;
  };
};

export const locales: Locales = {
  en: {
    general: en,
  },
  es: {},
  ja: {},
};
