import { QuizAnswer, VideoComponent } from "@rocket/types";
import { useContext, useState } from "react";
import usePhraseTest, { actions as phraseTestActions } from "../../../hooks/usePhraseTest/usePhraseTest";

import LessonContext from "../../../context/LessonContext";
import QuizOption from "../Quiz/includes/QuizOption";
import RateableTest from "../RateableTest";
import RateableTestContext from "../includes/context";
import { RateableTestTypeIds } from "../../../utils/constants";
import { RateableTestUIContext } from "../RateableTestUI/includes/context";
import RevealContinueButtons from "../RateableTestUI/buttons/RevealContinueButtons";
import TranslateItVideoPlayer from "./includes/TranslateItVideoPlayer";
import useTranslateItQuestions from "../../../hooks/useTranslateItQuestions";
import useTranslation from "../../../hooks/useTranslation";

export default function TranslateIt({ rateableTestId }: { rateableTestId: number }) {
  const t = useTranslation();
  const lesson = useContext(LessonContext)!;

  const phraseTest = usePhraseTest({
    testTypeId: RateableTestTypeIds.TRANSLATE_IT,
    rateableTestId,
    lessonId: lesson.id,
    mode: "unrated_components",
  });

  return (
    <RateableTest
      testName={t("translate-it")}
      testSubheading="Watch the video and choose the correct translation"
      testTypeId={RateableTestTypeIds.TRANSLATE_IT}
      phraseTest={phraseTest}
    >
      <TranslateItTest />
    </RateableTest>
  );
}

export function TranslateItTest() {
  const { phraseTest } = useContext(RateableTestContext)!;
  const [selectedQuizAnswer, setSelectedQuizAnswer] = useState<QuizAnswer>();
  const uiContext = useContext(RateableTestUIContext);

  const { videoComponents, testPhrases } = phraseTest.components;
  //@ts-ignore
  const questions = useTranslateItQuestions(videoComponents, testPhrases as VideoComponent[]);

  const currentQuestion = questions[phraseTest.state.index];
  const hasRevealed = phraseTest.state.revealed.has(phraseTest.state.index);

  /* override component type - avoids type issues in all other rateable tests */
  //@ts-ignore
  const currentPhrase: VideoComponent = phraseTest.components.testPhrases[phraseTest.state.index];

  return (
    <>
      <div className="rounded-md bg-rocketorange-light p-4 dark:bg-transparent">
        <TranslateItVideoPlayer
          src={currentPhrase.file}
          poster={currentPhrase.thumbnail}
          preload={false}
          videoId={currentPhrase.id}
          autoPlay={!uiContext?.instructionsVisible}
          muted
        />
        <div key={phraseTest.state.index} className="mt-4">
          {currentQuestion?.map((answer) => {
            return (
              <div key={answer.id} className="mt-2">
                <QuizOption
                  key={answer.id}
                  answer={answer}
                  answered={hasRevealed}
                  selected={selectedQuizAnswer?.id === answer.id}
                  onClick={() => setSelectedQuizAnswer(answer)}
                />
              </div>
            );
          })}
        </div>
      </div>
      <RevealContinueButtons
        state={hasRevealed ? "continue" : "reveal"}
        onContinue={() => {
          phraseTest.methods.rate(selectedQuizAnswer?.is_correct ? "easy" : "hard");
          setSelectedQuizAnswer(undefined);
        }}
        onReveal={() => {
          const revealAction = phraseTestActions.reveal(selectedQuizAnswer?.is_correct ? 3 : 1);
          phraseTest.methods.dispatch(revealAction);
        }}
        revealDisabled={typeof selectedQuizAnswer === "undefined"}
      />
    </>
  );
}
