import { Button, Centered, CircularProgress, ErrorMessage } from "@rocket/ui";
import { useContext, useEffect, useState } from "react";
import useQuizTest, { actions as quizActions } from "../../../hooks/useQuizTest";
import AudioContext from "../../../ui/Audio/AudioContext";
import LessonContext from "../../../context/LessonContext";
import type { QuizAnswer } from "@rocket/types";
import QuizComplete from "./includes/QuizComplete";
import QuizOption from "./includes/QuizOption";
import { RateableTestTypeIds, RateableTestTypes } from "../../../utils/constants";
import RateableTestUI from "../RateableTestUI";
import { ReinforcementContext } from "../../../context/ReinforcementContext";
import RevealContinueButtons from "../RateableTestUI/buttons/RevealContinueButtons";
import { requestRateTest } from "../../../store/lesson/actions";
import { useDispatch } from "react-redux";
import { useSharedSelector, useSharedStore } from "../../../store";
import useTranslation from "../../../hooks/useTranslation";
import API from "../../../res/Api";
import { getNextRateableTest } from "../../../utils";

export default function Quiz(props: { rateableTestId: number }) {
  const t = useTranslation();
  const dispatch = useDispatch();
  const lesson = useContext(LessonContext);
  const store = useSharedStore();

  const test = useQuizTest({
    lessonId: lesson.id,
    rateableTestId: props.rateableTestId,
    onComplete: () => {
      API.post("v2/events/capture", {
        event: "test end",
        properties: {
          rateableTestId: props.rateableTestId,
        },
      });
    },
  });

  const { activePhraseTest, setActivePhraseTest } = useContext(ReinforcementContext);
  const audioContext = useContext(AudioContext);
  const activeProduct = useSharedSelector((store) => store.preferences.activeProduct);

  const [quizStarted, setQuizStarted] = useState(activePhraseTest === props.rateableTestId);

  // disable audio player when a test is started
  useEffect(() => {
    if (quizStarted) {
      audioContext.activePlayer?.pause();
    }
    //eslint-disable-next-line
  }, [quizStarted]);

  useEffect(() => {
    if (activePhraseTest !== props.rateableTestId) {
      setQuizStarted(false);
    }
    //eslint-disable-next-line
  }, [activePhraseTest, props.rateableTestId]);

  // update state on quiz test reset to return to 'get started'
  useEffect(() => {
    setQuizStarted(activePhraseTest === props.rateableTestId);
  }, [activePhraseTest, props.rateableTestId]);

  const numQuestions = test.computed.quizQuestions.length;

  const position = (() => {
    if (test.state.isComplete) {
      return numQuestions;
    }
    if (!quizStarted) {
      return 0;
    }
    return Math.min(numQuestions, test.state.index + 1);
  })();

  function renderBody() {
    // Test has completed
    if (test.state.isComplete) {
      const nextRateableTest = getNextRateableTest({
        rateableTestId: props.rateableTestId,
        lessonId: lesson.id,
        store,
      });
      const localeKey = nextRateableTest ? RateableTestTypes[nextRateableTest.rateable_test_type_id]?.code : null;
      const nextTestName = localeKey ? t(localeKey) : null;

      return (
        <QuizComplete
          ratingLevel={test.computed.ratingLevel}
          numAnswered={test.state.answered.length}
          numCorrect={test.state.answered.reduce((prev, curr) => prev + (curr.isCorrect ? 1 : 0), 0)}
          numQuestions={test.computed.quizQuestions.length}
          onReset={test.methods.reset}
          nextActivityTitle={nextTestName ? `Next Activity: ${nextTestName}` : undefined}
          nextActivityHref={localeKey ? `#${localeKey}` : undefined}
        />
      );
    }

    if (!quizStarted) {
      return (
        <Centered style={{ width: "100%" }}>
          <Button
            color="primary"
            onClick={() => {
              if (activeProduct) {
                dispatch(
                  requestRateTest({
                    productId: activeProduct.id,
                    rateableTestId: props.rateableTestId,
                    rating: 0,
                    markComplete: false,
                  }),
                );
                setActivePhraseTest(props.rateableTestId);
                setQuizStarted(true);
                API.post("v2/events/capture", {
                  event: "test start",
                  properties: {
                    rateableTestId: props.rateableTestId,
                  },
                });
              }
            }}
          >
            {t("get-started").toUpperCase()}
          </Button>
        </Centered>
      );
    }

    return <QuizTest test={test} />;
  }

  return (
    <RateableTestUI.Container rateableTestTypeId={RateableTestTypeIds.QUIZ}>
      <RateableTestUI.Header
        testName={t("quiz")}
        testSubheading={t("quiz-subheading")}
        rateableTestId={props.rateableTestId}
        rateableTestTypeId={RateableTestTypeIds.QUIZ}
        ratingLevel={test.computed.ratingLevel}
        position={position}
        total={numQuestions}
      />
      <RateableTestUI.Body>{renderBody()}</RateableTestUI.Body>
    </RateableTestUI.Container>
  );
}

export function QuizTest(props: { test: ReturnType<typeof useQuizTest> }) {
  const t = useTranslation();
  const { status, state, dispatch, methods, computed } = props.test;

  // While the request is still ongoing
  if (status === "loading") {
    return (
      <Centered>
        <CircularProgress />
      </Centered>
    );
  }

  if (status === "error") {
    return (
      <ErrorMessage
        title="Network Error"
        message="Looks like there was a problem loading the quiz set."
        actions={
          <Button color="primary" onClick={methods.reset}>
            {t("try-again")}
          </Button>
        }
      />
    );
  }

  if (!computed.currentSection) {
    return null;
  }

  const question = computed.quizQuestions[state.index];
  const hasAnswered = state.answered.length - 1 === state.index;
  const answerId = state.answered[state.index]?.answerId;
  const selectedId = state.selected[state.index];

  if (!question) {
    console.warn("Quiz question not found on index", state.index, computed.quizQuestions);
    return null;
  }

  return (
    <div className="flex w-full flex-1 flex-col items-center justify-between">
      <div className="w-full rounded-md bg-rocketorange-light p-4 dark:bg-neutral-800">
        <div
          className="m-4 font-serif text-lg font-bold sm:text-2xl"
          dangerouslySetInnerHTML={{
            // Note: this contains entities, so we need to use dangerouslySetInnerHTML
            __html: question.text,
          }}
        />
        {question.answers.map((answer: QuizAnswer) => {
          return (
            <div key={answer.id} className="my-2">
              <QuizOption
                answered={hasAnswered}
                answer={answer}
                selected={answer.id === (hasAnswered ? answerId : selectedId)}
                onClick={() => dispatch(quizActions.selectAnswer(answer.id))}
              />
            </div>
          );
        })}
      </div>
      <RevealContinueButtons
        state={state.answered.length - 1 === state.index ? "continue" : "reveal"}
        onContinue={() => dispatch(quizActions.next())}
        onReveal={methods.confirmAnswer}
        revealDisabled={state.selected.length - 1 !== state.index}
      />
    </div>
  );
}
