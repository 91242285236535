import { ComponentType, useCallback, useContext } from "react";

import { Button } from "@rocket/ui";
import CustomFlashcardsContext from "../../../../context/CustomFlashcardsContext";
import RateableTestContext from "../../includes/context";
import { ReinforcementContext } from "../../../../context/ReinforcementContext";
import { actions as customFlashcardsActions } from "../../../../hooks/useCustomFlashcardTest";
import { getRatingButtonColorProp } from "../../../../utils";
import { actions as phraseTestActions } from "../../../../hooks/usePhraseTest/usePhraseTest";
import usePhraseHotkeys from "../../../../hooks/usePhraseHotkeys";
import useTranslation from "../../../../hooks/useTranslation";
import { clsx } from "clsx";

const ratingLabels = ["easy", "good", "hard"] as const;

type Props = {
  /** Focuses on the test container after revealing & rating */
  focusContainer?: boolean;
  onReveal?: () => number | void;
  onRate?: (ratingLabel: (typeof ratingLabels)[number]) => void;
  revealButtonTitle?: string;
  RevealButtonComponent?: ComponentType<unknown>;
};

/** Note: Requires a `<PhraseTestContext.Provider />` parent  */
export default function RatingButtons(props: Props) {
  const t = useTranslation();
  const context = useContext(RateableTestContext);
  const customFlashcardsContext = useContext(CustomFlashcardsContext);
  const { phraseTest, testContainerRef } = customFlashcardsContext.testTypeId ? customFlashcardsContext : context;
  const { state, computed } = phraseTest;
  const { dispatch, rate } = phraseTest.methods;
  const suggestedRating = state.revealed.get(state.index);
  const revealed = state.revealed.has(state.index);

  /** In benchmark tests, phrase tests only have one component each */
  const ratings: [number, number, number] = [
    computed.ratings.easy.size,
    computed.ratings.good.size,
    computed.ratings.hard.size,
  ];

  const focusContainer = useCallback(() => {
    if (props.focusContainer ?? true) {
      testContainerRef.current?.focus({ preventScroll: true });
    }
  }, [props.focusContainer, testContainerRef]);

  const onReveal = useCallback(
    () => {
      dispatch(
        customFlashcardsContext
          ? customFlashcardsActions.reveal(props.onReveal?.() || 0)
          : phraseTestActions.reveal(props.onReveal?.() || 0),
      );
      focusContainer();
    },
    // eslint-disable-next-line
    [dispatch, props.onReveal, focusContainer],
  );

  const onRate = useCallback(
    (ratingLabel: "hard" | "good" | "easy") => {
      // Call parent screen component function, if exists
      if (props.onRate) {
        props.onRate(ratingLabel);
      }
      rate(ratingLabel);
      focusContainer();
    },
    [props, rate, focusContainer],
  );

  // Hooks keybinds to 1,2,3 and enter while the test container is focused
  usePhraseHotkeys({ phraseTestRef: testContainerRef, revealed, onReveal, onRate });

  const ButtonComponent = props.RevealButtonComponent || Button;

  return (
    <div className="flex w-full flex-col items-center justify-center space-y-2">
      <span
        className={clsx("mb-2 block w-full text-center text-sm font-medium dark:text-white", !revealed && "invisible")}
      >
        How hard did you find this phrase?
      </span>
      {!revealed ? (
        <ButtonComponent onClick={onReveal}>
          <span className="uppercase">{props.revealButtonTitle || t("reveal")}</span>
        </ButtonComponent>
      ) : (
        <RatingButtonGroup ratings={ratings} onRate={onRate} suggestedRating={suggestedRating} />
      )}
    </div>
  );
}

interface RatingButtonGroupProps {
  ratings: [number, number, number];
  onRate(ratingLabel: "easy" | "good" | "hard"): void;
  suggestedRating: number | undefined;
}

export function RatingButtonGroup({ ratings, onRate, suggestedRating }: RatingButtonGroupProps) {
  const t = useTranslation();
  const reinforcementContext = useContext(ReinforcementContext);

  return (
    <div className="grid w-full grid-cols-3 gap-4">
      {ratingLabels.map((label, index) => {
        const rating = ratings[index] || 0;
        return (
          <Button
            key={label}
            faded={!!suggestedRating && suggestedRating !== Math.abs(index - 3)}
            color={getRatingButtonColorProp(Math.abs(index - 3))}
            onClick={() => onRate(label)}
          >
            <div className="flex items-center">
              <span className="uppercase">{t(label)}</span>
              {reinforcementContext?.mode !== "benchmark" &&
                reinforcementContext?.mode !== "certification" &&
                rating > 0 && <span className="ml-2">({rating})</span>}
            </div>
          </Button>
        );
      })}
    </div>
  );
}
