import useEventListener from "./useEventListener";

interface PhraseBoxHotkeyProps {
  callbacks: {
    play: () => void;
    record: () => void;
  };
  enabled: boolean;
}

export default function usePhraseBoxHotkey({ callbacks, enabled }: PhraseBoxHotkeyProps) {
  if (typeof document === "undefined") {
    return;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEventListener(
    "keydown",
    (e) => {
      if (e.code === "KeyP") {
        callbacks.play();
      } else if (e.code === "KeyR") {
        callbacks.record();
      }
    },
    document,
    enabled,
  );
}
