import { PhraseResource, PhraseString } from "@rocket/types";
import { getSpeechRecognitionLocale, useShouldUseNodeSpeechApi } from "./includes/RocketRecord/utils";
import AudioRecorder from "./includes/RocketRecord/AudioRecorder";
import DebugPanel from "./includes/DebugPanel";
import ErrorText from "./includes/ErrorText";
import Phrase from "./includes/Phrase";
import { PhraseStringOptions } from "./includes/PhraseString";
import { RateableTestUIContext } from "../RateableTests/RateableTestUI/includes/context";
import { RatedPhrase } from "../../hooks/useRocketRecord/types";
import RocketRecordControls from "./includes/RocketRecordControls";
import { RocketRecordProvider, RocketRecordWithValueProvider } from "../../context/RocketRecordContext";
import RocketRecordSpeech from "./includes/RocketRecordSpeech";
import Voice from "./includes/RocketRecord/Voice";
import { clsx } from "clsx";
import { isRTL } from "../../utils";
import useActiveCourse from "../../hooks/useActiveCourse";
import { Fragment, ReactNode, useContext, useState } from "react";
import { useSharedSelector } from "../../store";
import { useMutation } from "../../hooks/usePromise";
import { Button, OutlineButton } from "@rocket/ui";
import IconOutlineButton from "@rocket/ui/Button/variants/IconOutlineButton";
import { FiCornerUpLeft, FiEdit3, FiTrash } from "react-icons/fi";
import { useCanvas } from "@rocketlanguages/writeit-canvas";
import API from "../../res/Api";
import { CSSTransition } from "react-transition-group";
import styles from "./Phrasebox.module.scss";
import { FaCheck, FaSpinner } from "react-icons/fa";
import { LiteralStringNotationButton } from "../Notation";
import PhraseStringNotationButtons from "./includes/PhraseStringNotationButtons";
import { WritingSystemIds } from "../../utils/constants";
import PhraseTestContext from "../RateableTests/includes/context";
import useAudioMinified from "../../hooks/useAudioMinified";
import { BreakpointComponent } from "../BreakpointComponent";
import { useRocketRecord } from "../../hooks/useRocketRecord";

export type PhraseboxVariant = "phrasetest" | "trimmed";

export type PhraseboxProps = {
  phrase: PhraseResource;
  /** Custom visibility/notation/display options per phrase string, applied based on index it's rendered */
  getPhraseStringOptions?: (phraseString: PhraseString, index: number) => PhraseStringOptions | undefined;
  /** Plays audio when the component is mounted */
  playOnMount?: boolean;
  /** Plays recording when the component is mounted */
  playRecordingOnMount?: boolean;
  recordOnMount?: boolean;
  hidePlayer?: boolean;
  /** Leave player showing, but in disabled state */
  disablePlayer?: boolean;
  /** Hides all phrases */
  hidePhrases?: boolean;
  /** [PlayIt] Disables clearing of blob resource (revokeObjectURL) when the component unmounts */
  persistBlobs?: boolean;
  /** When initially rendered, displays the given result. */
  initialResult?: RatedPhrase;
  /** When initially rendered, uses the given blob url for playback */
  initialBlobUrl?: string;
  recordingDisabled?: boolean;
  recordButtonHidden?: boolean;
  onPlayFinish?: () => void;
  onRecordFinish?: (params: { phraseId: number; result?: RatedPhrase; blobUrl?: string }) => void;
  onRecordButtonClick?: () => void;
  handleKeyEvents?: boolean;
  /** Whether RR voice recognition is enabled (Recording will still work) */
  disableVoiceRecognition?: boolean;
  /** Manually pass in lesson id for phrases accessed outside of the lesson, eg: in Search */
  lessonId?: number;
  /** Switch the mode in which rocket record hides buttons */
  hideMode?: "hidden" | "invisible";
  /** Audio player handle */
  audioPlayerRef?: React.MutableRefObject<ReturnType<typeof useAudioMinified> | null>;
  /** Optional styles to add to the phrasebox container */
  className?: string;
  /** Optional children, placed within the RocketRecordProvider */
  children?: ReactNode;
  /** Placement for TextDiff element */
  Diff?: ReactNode;
  /** Overrides rating display from store */
  RatingButton?: ReactNode;
  /** Allows you to pass in a single useRocketRecord value for external use */
  rocketRecord?: ReturnType<typeof useRocketRecord>;
};

type CanvasButtonProps = {
  state: "loading" | "loaded" | "error" | "success" | undefined;
  resetMutation: () => void;
  handleClick: () => void;
  disabled: boolean;
};

export default function Phrasebox(props: PhraseboxProps) {
  const {
    playOnMount,
    recordOnMount,
    phrase,
    getPhraseStringOptions,
    hidePlayer,
    disablePlayer,
    hidePhrases,
    recordingDisabled,
    initialResult,
    onPlayFinish,
    onRecordFinish,
    onRecordButtonClick,
    playRecordingOnMount,
    initialBlobUrl,
    persistBlobs,
    handleKeyEvents,
    disableVoiceRecognition,
    lessonId,
    recordButtonHidden,
    hideMode,
    className,
    audioPlayerRef,
    Diff,
    RatingButton,
    rocketRecord,
  } = props;
  const debugEnabled = useSharedSelector((state) => state.preferences.debugEnabled);
  const activeCourse = useActiveCourse();

  // const isInPhraseTest = useContext(PhraseTestContext).testTypeId !== 0;
  const isInRateableTest = Boolean(useContext(RateableTestUIContext));
  const shouldUseNodeSpeechApi = useShouldUseNodeSpeechApi(phrase);
  const [isExpanded, setIsExpanded] = useState(false);

  if (!phrase) {
    return null;
  }

  /**
   * `recordButtonHidden` may come as a prop from tests that intentionally hide the recording button (e.g. write it)
   *
   * Otherwise falls back to `disable_voice_recognition` on the phrase where some phrases are difficult to
   * properly recognize
   */
  const isRecordButtonHidden = recordButtonHidden || phrase.disable_voice_recognition === 1;

  const isRtl = isRTL(props.phrase.course_id);

  const rocketRecordChildren = (
    <>
      <PhraseboxNotations phrase={phrase} getPhraseStringOptions={getPhraseStringOptions} />
      <RocketRecordControls
        audioPlayerRef={audioPlayerRef}
        // Inline audio player
        playOnMount={playOnMount}
        playRecordingOnMount={playRecordingOnMount}
        // Buttons
        hidePlayer={hidePlayer || !phrase.audio_url || phrase.audio_url.length === 0}
        disablePlayer={disablePlayer}
        initializeWithRating={!isInRateableTest}
        hideRecordButton={isRecordButtonHidden}
        recordingDisabled={recordingDisabled}
        onPlayFinish={onPlayFinish}
        onRecordButtonClick={onRecordButtonClick}
        handleKeyEvents={handleKeyEvents}
        lessonId={lessonId}
        hideMode={hideMode}
        RatingButton={RatingButton}
      />
      <div className={clsx("ml-4 flex-1 break-words", isRtl && "flex-end align-right mr-4 flex flex-col")}>
        <div data-phraseid={phrase.id}>
          {!hidePhrases && <Phrase phrase={phrase} getPhraseStringOptions={getPhraseStringOptions} />}
        </div>
        <div className={clsx("flex flex-col", isRtl && "items-end")}>
          {/* Speech from Rocket Record */}
          <RocketRecordSpeech />
          {/* Speech Error Text */}
          <ErrorText />
          {/* Text Diff from non-speech sources (e.g. write it) */}
          {Diff}
        </div>
        {/* Visible debug log */}
        {debugEnabled && <DebugPanel />}
      </div>
      {props.children}
    </>
  );

  return (
    <div>
      <div
        className={clsx("relative rounded-lg bg-rocketorange-light p-4 dark:bg-neutral-800 print:bg-white", className)}
      >
        <div className={clsx("flex break-inside-avoid rounded-md print:p-0", isRtl && "flex-row-reverse")}>
          {rocketRecord ? (
            <RocketRecordWithValueProvider rocketRecord={rocketRecord}>
              {rocketRecordChildren}
            </RocketRecordWithValueProvider>
          ) : (
            <RocketRecordProvider
              rocketRecord={{
                phrase,
                Voice,
                AudioRecorder,
                shouldUseNodeSpeechApi,
                locale: getSpeechRecognitionLocale({ phrase, course: activeCourse }),
                recordOnMount,
                initialResult,
                initialBlobUrl,
                persistBlobs,
                onRecordFinish,
                disableVoiceRecognition,
                lessonId,
                platform: "web",
              }}
            >
              {rocketRecordChildren}
            </RocketRecordProvider>
          )}
        </div>
        <div className="flex items-center justify-center">
          {Boolean(phrase.writeit_enabled) && !isInRateableTest && (
            <BreakpointComponent mediaQuery="(min-width: 1000px)">
              <OutlineButton
                title="Practice"
                type="button"
                onClick={() => {
                  setIsExpanded((b) => !b);
                  API.post("v2/events/capture", {
                    event: "writeit-practice-button clicked",
                    properties: {
                      isOpen: !isExpanded,
                      phraseId: phrase.id,
                    },
                  });
                }}
                className="space-x-2"
              >
                <FiEdit3 />
                <p>Practice your writing</p>
              </OutlineButton>
            </BreakpointComponent>
          )}
        </div>
      </div>
      {Boolean(phrase.writeit_enabled) && !isInRateableTest && (
        <BreakpointComponent mediaQuery="(min-width: 1000px)">
          <div className="overflow-hidden">
            <CSSTransition
              in={isExpanded}
              unmountOnExit
              timeout={300}
              classNames={{
                enter: styles.enter,
                enterActive: styles.enterActive,
                exitActive: styles.exitActive,
              }}
            >
              <CanvasComponent phraseId={phrase.id} character={phrase.strings[0]?.text} />
            </CSSTransition>
          </div>
        </BreakpointComponent>
      )}
    </div>
  );
}

function PhraseboxNotations(props: {
  phrase: PhraseResource;
  getPhraseStringOptions?: (phraseString: PhraseString, index: number) => PhraseStringOptions | undefined;
}) {
  const { phrase, getPhraseStringOptions } = props;
  const isInPhraseTest = useContext(PhraseTestContext).testTypeId !== 0;
  const debugEnabled = useSharedSelector((state) => state.preferences.debugEnabled);

  const userNotationsVisible = isInPhraseTest || phrase.show_notations;
  const notationsVisible = userNotationsVisible || debugEnabled;

  const hasLiteralNotation =
    phrase.literal_string &&
    phrase.strings.some(
      (ps, i) =>
        ps.writing_system_id === WritingSystemIds.english &&
        !getPhraseStringOptions?.(ps, i)?.hideLiteralStringNotation,
    );

  // If there's nothing to display, don't render anything
  if (!notationsVisible && !hasLiteralNotation) {
    return null;
  }

  const isRtl = isRTL(props.phrase.course_id);

  return (
    <div className={clsx("absolute -top-2", isRtl ? "left-2" : "right-2")}>
      <span>
        {phrase.strings.map((phraseString, i) => {
          /*
          if (
            !phraseString.text ||
            (romanizationHidden && isRomanizedString(phraseString)) ||
            (kanaHidden && isKanaString(phraseString))
          ) {
            return null;
          }
      */
          const options = getPhraseStringOptions?.(phraseString, i);

          return (
            <Fragment key={phraseString.id}>
              {!options?.hideLiteralStringNotation &&
              phrase.literal_string &&
              phraseString.writing_system_id === WritingSystemIds.english ? (
                <span className="mx-0.5">
                  <LiteralStringNotationButton literalString={phrase.literal_string} />
                </span>
              ) : null}
              {notationsVisible && !options?.hideNotations && phraseString.notations.length > 0 && (
                <PhraseStringNotationButtons
                  phraseString={phraseString}
                  variant={!userNotationsVisible ? "faded" : undefined}
                  hiddenNotationIds={Array.isArray(options?.hideNotations) ? options?.hideNotations : undefined}
                />
              )}
            </Fragment>
          );
        })}
      </span>
    </div>
  );
}

const CanvasComponent = ({ phraseId, character }: { phraseId: number; character?: string }) => {
  const { canvasRef, undoStroke, clearCanvas, getBase64Image, isEmpty } = useCanvas({ thickness: 3 });

  const getWriteitVisionResult = async (base64Image: string) => {
    const result = await API.postJson(["v2/writeit-vision/detect-text/phrase/{phraseId}", { phraseId }], {
      base64Image,
    });

    return {
      ...result,
      succeeded: result.scores.find(([, score]) => score === 100) !== undefined,
    };
  };

  const writeitMutation = useMutation({ mutationFn: getWriteitVisionResult });

  const status =
    writeitMutation.state?.status === "loaded" && writeitMutation.state.data.succeeded
      ? "success"
      : writeitMutation.state?.status;

  return (
    <div className="rounded-b-md bg-gray-50 py-6 dark:bg-inherit">
      <div className="flex flex-col items-center justify-center">
        {(status === "error" || status === "loaded") && (
          <p>Oops that doesn't look right. Try to match the character(s) exactly.</p>
        )}
        {status === "success" && <p>Great job!</p>}
        {status === undefined && <p>Write '{character}' in the space below.</p>}
        {status === "loading" && <p className="invisible">loading...</p>}
      </div>
      <div className="my-4" />
      <div className="flex justify-center">
        <div className="flex w-16 flex-col items-center space-y-2">
          <div className="group">
            <IconOutlineButton
              title="Undo"
              type="button"
              onClick={() => {
                if (status === "success" || status === "loaded") {
                  writeitMutation.reset();
                }
                undoStroke();
              }}
            >
              <FiCornerUpLeft className="text-brand group-hover:text-white dark:text-white dark:group-hover:text-white" />
            </IconOutlineButton>
          </div>
          <div className="group">
            <IconOutlineButton
              title="Clear Canvas"
              type="button"
              onClick={() => {
                if (status === "success" || status === "loaded") {
                  writeitMutation.reset();
                }
                clearCanvas();
              }}
            >
              <FiTrash className="text-brand group-hover:text-white dark:text-white dark:group-hover:text-white" />
            </IconOutlineButton>
          </div>
        </div>
        <canvas ref={canvasRef} width={600} height={400} className="rounded-lg border border-black bg-white" />
        <div className="w-16" />
      </div>
      <div className="my-4" />
      <div className="flex items-center justify-center">
        <CanvasButton
          state={status}
          resetMutation={() => {
            clearCanvas();
            writeitMutation.reset();
          }}
          disabled={isEmpty}
          handleClick={() => {
            writeitMutation.mutate(getBase64Image());
            API.post("v2/events/capture", {
              event: "writeit-check-button clicked",
              properties: {
                phraseId: phraseId,
              },
            });
          }}
        />
      </div>
    </div>
  );
};

const CanvasButton = ({ state, resetMutation, handleClick, disabled }: CanvasButtonProps) => {
  if (state === "loading") {
    return (
      <Button type="button" disabled className="gap-4">
        <FaSpinner className="animate-spin" />
        Checking
      </Button>
    );
  }

  if (state === "success") {
    return (
      <Button type="button" onClick={resetMutation} color="success" className="gap-4">
        <FaCheck />
        Correct
      </Button>
    );
  }

  if (state === "loaded") {
    return (
      <Button type="button" onClick={resetMutation} className="gap-4">
        Try again!
      </Button>
    );
  }

  return (
    <Button type="button" disabled={disabled} onClick={handleClick} className="gap-4">
      Check
    </Button>
  );
};
