import { Phrase } from "@rocket/types";
import { WritingSystemIds } from "../../../../utils/constants";
import { purgeString } from "../../../../utils/stringUtils";

const characterSplits = {
  // primary split character for the specific language - removes the split character
  default: " ",
  [WritingSystemIds.hiragana_katakana]: " ",
  [WritingSystemIds.kanji]: "",
  [WritingSystemIds.hanzi]: "",
};

/**
 * Take a split character string specific for a language and return a scrambled or shuffled array
 */
export default function getCharacterButtons({
  phrase,
  phraseStringIndex,
}: {
  /** the current phrase for the current rateable test phrase */
  phrase: Phrase;
  /**
   * The index of the specific string to take from a phrases phrase string list,
   *
   * eg: in Japanese kana is phrase.strings[0] vs kanji as phrase.strings[1]
   * */
  phraseStringIndex: number;
}) {
  const phraseString = phrase.strings[phraseStringIndex];
  if (!phraseString) {
    return [];
  }
  const splitCharacter = characterSplits[phraseString.writing_system_id] ?? characterSplits.default;

  // const punctuationRegex = /(»|«|…|‘|’|"|'|“|”|\||\.|／|～|〜|~|¿|,|،|！|、|!|。|؟|？|，|¡|\?|\*|!|-)/;
  // eslint-disable-next-line no-irregular-whitespace
  const punctuationRegex = new RegExp(`[${splitCharacter}»«…‘’'“”/।|.／～〜~¿,،！、!。؟？，¡?*!　「」]`, "gi");

  const phraseStringText = purgeString(phraseString.text, { html: true, markdown: true });
  // Remove html / markdown

  const phraseArray = phraseStringText
    .split(splitCharacter)
    .map((el, i) => {
      if (
        el === "[" &&
        phraseString.text.split(splitCharacter)[i + 1] === "." &&
        phraseString.text.split(splitCharacter)[i + 2] === "." &&
        phraseString.text.split(splitCharacter)[i + 3] === "." &&
        phraseString.text.split(splitCharacter)[i + 4] === "]"
      ) {
        return "[...]";
      }
      if (el === " [...] " || el === "[...]") {
        return el;
      }
      return el.split(punctuationRegex).filter((el) => {
        if (el === " [" || el === "[" || el === "] " || el === "]" || el === "." || el === " ") {
          return false;
        }
        return !!el;
      });
    })
    .flat();

  return phraseArray;
}

// kanji split code wip

/**
 *
 * const phraseString = phrase.strings[phraseStringIndex].text;
  console.log("original", phraseString);
  const kanjiRegex = /[\u4E00-\u9FFF]/;
  const phraseStringWithPunctuationRemoved = phraseString.split(allPunctuationRegexGlobal).filter((el) => !!el);
  console.log("no punctuation", phraseStringWithPunctuationRemoved);
  const phraseArray = phraseStringWithPunctuationRemoved
    .map((phraseString) => {
      console.log("phrase string", phraseString, phraseString.length);
      // get kanji in the phrase string
      const kanji = [...phraseString.matchAll(new RegExp(kanjiRegex, "g"))];
      console.log("kanji", kanji);
      const phraseStringArray = phraseString.split("").filter((el) => !!el);
      console.log(phraseStringArray);
      // destructable array edited per operation to keep a reference for what has and hasnt been used already
      const destructablePhraseStringArray = [...phraseStringArray];

      const newArray: string[][] = [];
      kanji.forEach((char) => {
        let localCharacters: string[][] = [];
        // for each kanji in the phrase string, split where the kanji is
        const el = phraseStringArray.slice(0, char.index ? char.index : char.index! + 1);
        // if changes have been made to the destructable array, remove what has been removed there
        if (phraseStringArray.length !== destructablePhraseStringArray.length) {
          console.log(el);
          console.log(phraseStringArray.length - destructablePhraseStringArray.length);
          el.splice(0, phraseStringArray.length - destructablePhraseStringArray.length);
          console.log(el);
        }

        console.log("el", el);
        // then split the resultant array into any prior kana, and the kanji
        if (el.length > 1) {
          const kanjiChar = el.pop();
          console.log(kanjiChar);
          console.log(el);
          localCharacters = [el, [kanjiChar!]];
        } else {
          localCharacters = [el];
        }
        console.log(localCharacters);
        // edit the destructable array
        destructablePhraseStringArray.splice(0, char.index ? char.index : char.index! + 1);
        console.log("destructable phrase string array", destructablePhraseStringArray);
        newArray.push(...localCharacters);
      });
      console.log("new array", newArray);
      newArray.push(phraseStringArray);
      // const kana = phraseString.split(new RegExp(kanjiRegex, "g"));
      // console.log("kana", kana);
      // const merged = kana.map((el, i) => (!el ? kanji![i] : el)).filter((el) => !!el);
      // console.log("merged", merged);
      // return merged;
      return newArray;
    })
    .flat();
  console.log("final array", phraseArray);
 *
 */
