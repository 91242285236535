import { memo, useEffect, useMemo, useState } from "react";
import classes from "./Slider.module.scss";
import { secondsToMinutesAndSeconds } from "../../../../utils";
import useMedia, { useMediaProgress } from "../../../../hooks/useMedia";
import RocketSlider from "../../../../ui/Slider/Slider";
import { useSliderProgress } from "./state";

type Mark = {
  value: number;
  label: React.ReactNode;
};

interface SliderProps {
  player: ReturnType<typeof useMedia>;
  disabled?: boolean;
  syncProgress?: boolean;
}

const Slider = memo(({ player, disabled, syncProgress }: SliderProps) => {
  const currentPosition = useMediaProgress(player.ref);
  const [seekValue, setSeekValue] = useState<number>(currentPosition);
  const seekingKey = `seeking_${player?.id || 0}` as const;
  const [seeking, setSeeking] = useState(false);
  const value = seeking ? seekValue : currentPosition;

  useEffect(() => {
    if (syncProgress) {
      const key = `id_${player?.id || 0}` as const;
      useSliderProgress.setState({
        [key as any]: value,
      });
    }
  }, [value, player?.id, seeking, syncProgress]);

  const positionFormatted = secondsToMinutesAndSeconds(value);

  const marks: Mark[] = useMemo(() => {
    if (!player || !player.state.trackDurationSeconds) {
      return [];
    }

    return [
      {
        value: 0,
        label: (
          <div className={classes.marks}>
            <div className={classes.marksFirstContainer}>
              <div className={classes.marksFirstPositioner}>{positionFormatted}</div>
            </div>
          </div>
        ),
      },
      {
        value: player.state.trackDurationSeconds,
        label: (
          <div className={classes.marks}>
            <div className={classes.marksLastContainer}>
              <div className={classes.marksLastPositioner}>{player.state.trackDurationSecondsText}</div>
            </div>
          </div>
        ),
      },
    ];
  }, [player, positionFormatted]);

  return (
    <RocketSlider
      role="slider"
      aria-label="Audio Slider"
      value={value}
      marks={marks}
      disabled={disabled}
      min={0}
      max={player.state.trackDurationSeconds}
      className={classes.seekbar}
      onChange={(e, value) => {
        useSliderProgress.setState({
          [seekingKey as any]: true,
        });
        setSeeking(true);
        setSeekValue(value as number);
      }}
      onChangeCommitted={(e, value) => {
        player.seek(value as number);
        useSliderProgress.setState({ [seekingKey as any]: false });
        setSeeking(false);
      }}
    />
  );
});

export default Slider;
