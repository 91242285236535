import { MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from "react";
import usePhraseTest, { actions as phraseTestActions } from "../../../hooks/usePhraseTest/usePhraseTest";

import Instructions from "./includes/Instructions";
import LessonContext from "../../../context/LessonContext";
import PhraseTestContext from "../includes/context";
import Phrasebox from "../../Phrasebox";
import RateableTest from "../RateableTest";
import { RateableTestTypeIds } from "../../../utils/constants";
import { RatedPhrase } from "../../../hooks/useRocketRecord/types";
import RatingButtons from "../RateableTestUI/buttons/RatingButtons";
import useIsFocusedWithin from "../../../hooks/useIsFocusedWithin";
import useTranslation from "../../../hooks/useTranslation";
import useAudioMinified from "../../../hooks/useAudioMinified";
import { noop } from "../../../utils";
import { Button, Checkbox, OutlineButton } from "@rocket/ui";
import { useRocketRecord } from "../../../hooks/useRocketRecord";
import { getSpeechRecognitionLocale, useShouldUseNodeSpeechApi } from "../../Phrasebox/includes/RocketRecord/utils";
import Voice from "../../Phrasebox/includes/RocketRecord/Voice";
import AudioRecorder from "../../Phrasebox/includes/RocketRecord/AudioRecorder";
import useActiveCourse from "../../../hooks/useActiveCourse";
import useCurrentRef from "../../../hooks/useCurrentRef";
import { useSyncedBooleanPreference } from "../../../hooks/usePreference";
import { useSharedStore } from "../../../store";
import { RateableTestUIContext } from "../RateableTestUI/includes/context";
import { Phrase } from "@rocket/types";

export default function HearIt({ rateableTestId }: { rateableTestId: number }) {
  const t = useTranslation();
  const lesson = useContext(LessonContext);
  const phraseTest = usePhraseTest({
    testTypeId: RateableTestTypeIds.HEAR_IT,
    lessonId: lesson.id,
    rateableTestId,
    mode: "unrated_components",
    phraseFilter(phrase) {
      return !!phrase.audio_url;
    },
  });

  return (
    <RateableTest
      phraseTest={phraseTest}
      testTypeId={RateableTestTypeIds.HEAR_IT}
      testName={t("hear-it-say-it")}
      testSubheading={t("hear-it-say-it-subheading")}
      instructions={<Instructions />}
      settings={<HearItSettings />}
    >
      <HearItPhraseTest />
    </RateableTest>
  );
}

function HearItSettings() {
  const [autoRecord, setAutoRecord] = useSyncedBooleanPreference("listening_auto_record", false);
  const uiContext = useContext(RateableTestUIContext);

  return (
    <div>
      <h4 className="mb-2">Options</h4>
      <div className="mb-4">
        <Checkbox
          checked={autoRecord}
          onChange={() => {
            setAutoRecord(!autoRecord);
          }}
          label={"Record automatically after tutor audio plays"}
        />
      </div>
      <div className="my-4 flex justify-center sm:my-6">
        <Button
          color="primary"
          onClick={() => {
            uiContext?.toggleSettings();
          }}
        >
          CONFIRM
        </Button>
      </div>{" "}
    </div>
  );
}

export function HearItPhraseTest() {
  const { testContainerRef, phraseTest } = useContext(PhraseTestContext);
  const currentTestIsFocused = useIsFocusedWithin(testContainerRef);
  const focusTestContainer = () => testContainerRef.current?.focus({ preventScroll: true });
  const currentPhrase = phraseTest.components.testPhrases[phraseTest.state.index];

  return (
    <div className="space-y-4">
      {currentPhrase && (
        <HearItPhrasebox phrase={currentPhrase} phraseTest={phraseTest} handleKeyEvents={currentTestIsFocused} />
      )}
      <RatingButtons RevealButtonComponent={OutlineButton} onReveal={focusTestContainer} onRate={focusTestContainer} />
    </div>
  );
}

interface HearItPhraseboxProps {
  phrase: Phrase;
  phraseTest: ReturnType<typeof usePhraseTest>;
  handleKeyEvents: boolean;
}

export function HearItPhrasebox({ phrase, phraseTest, handleKeyEvents }: HearItPhraseboxProps) {
  const didReveal = phraseTest.state.revealed.has(phraseTest.state.index);
  /**
   * Occurs when RR has finished
   */
  const onRecordFinish = useCallback(
    (params: { phraseId: number; result?: RatedPhrase; blobUrl?: string }) => {
      const { result } = params;
      if (!result) {
        return;
      }

      // Reveal if rating level hits 3
      if (result.ratingLevel === 3) {
        phraseTest.methods.dispatch(phraseTestActions.reveal(result.ratingLevel));
        return;
      }

      // Otherwise, update the suggested rating level
      if (result.percentage > 10) {
        phraseTest.methods.dispatch(phraseTestActions.setSuggestedRatingLevel(result.ratingLevel));
      }
    },
    [phraseTest.methods],
  );

  const audioPlayerRef = useRef<ReturnType<typeof useAudioMinified> | null>(null);
  const shouldUseNodeSpeechApi = useShouldUseNodeSpeechApi(phrase);
  const activeCourse = useActiveCourse();

  const rocketRecord = useRocketRecord({
    phrase,
    Voice,
    AudioRecorder,
    shouldUseNodeSpeechApi,
    locale: getSpeechRecognitionLocale({ phrase, course: activeCourse }),
    recordOnMount: false,
    initialResult: undefined,
    initialBlobUrl: undefined,
    persistBlobs: false,
    onRecordFinish,
    disableVoiceRecognition: undefined,
    lessonId: undefined,
    platform: "web",
  });

  useEffect(() => {
    // Cleanup when this component unmounts
    return () => {
      const { actions, flag } = rocketRecord.useRocketRecordState.getState();
      if (["ACTIVE", "STARTING", "FINISHING"].includes(flag.status)) {
        // Clear speech timeouts
        actions.clearTimeouts();
        AudioRecorder?.finishRecording(false);
        Voice.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phraseTest.state.index]);

  const playing = useHearItPlayerAndRecorder({
    audioRef: audioPlayerRef,
    index: phraseTest.state.index,
    startRecording: rocketRecord.methods.start,
  });

  return (
    <Phrasebox
      key={`${phrase.id}`}
      rocketRecord={rocketRecord}
      phrase={phrase}
      audioPlayerRef={audioPlayerRef}
      // Blur every phrase string while not revealed
      getPhraseStringOptions={() => ({
        className: !didReveal ? "before:content-['...']  [&>span]:invisible" : undefined,
        hideNotations: !didReveal,
        hideLiteralStringNotation: !didReveal,
      })}
      onRecordFinish={onRecordFinish}
      handleKeyEvents={handleKeyEvents}
      recordingDisabled={playing && !didReveal}
    />
  );
}

/**
 * Plays audio when the index changes
 *
 * Keeps track of play state
 */
const useHearItPlayerAndRecorder = (params: {
  audioRef: MutableRefObject<ReturnType<typeof useAudioMinified> | null>;
  index: number;
  startRecording: () => Promise<void>;
}) => {
  const { audioRef, index, startRecording } = params;
  const [playing, setPlaying] = useState(true);
  const startRecordingRef = useCurrentRef(startRecording);
  const store = useSharedStore();

  const indexRecordedAtRef = useRef<number | null>(null);

  useEffect(() => {
    // Register listener for when audio finishes
    const audio = audioRef?.current?.audioRef.current;
    if (!audio) {
      setPlaying(false);
      return noop;
    }
    // Set a timeout for ~10 seconds to enable the user to record
    const timeout = setTimeout(() => {
      setPlaying(false);
    }, 15000);

    let recordTimeoutBuffer: ReturnType<typeof setTimeout> | null = null;

    const handleEnded = () => {
      clearTimeout(timeout);
      if (indexRecordedAtRef.current !== index) {
        indexRecordedAtRef.current = index;

        const shouldAutoRecord = Number(store.getState().user.preferences?.listening_auto_record) === 1;

        if (!shouldAutoRecord) {
          setPlaying(false);
          return;
        }

        recordTimeoutBuffer = setTimeout(() => {
          const fn = startRecordingRef.current;
          if (fn) {
            fn().finally(() => {
              setPlaying(false);
            });
          } else {
            setPlaying(false);
          }
        }, 500);
      } else {
        setPlaying(false);
      }
    };
    audio.addEventListener("ended", handleEnded);

    const player = audioRef.current;

    // Play the audio
    const playTimeoutBuffer = setTimeout(() => {
      setPlaying(true);
      player?.playAudio({ ignoreToast: true, fromStart: true });
    }, 250);

    return () => {
      audio.removeEventListener("ended", handleEnded);
      player?.stopAudio();

      clearTimeout(timeout);
      clearTimeout(playTimeoutBuffer);
      if (recordTimeoutBuffer) {
        clearTimeout(recordTimeoutBuffer);
      }
    };
  }, [audioRef, index, startRecordingRef, store]);

  return playing;
};
