export default function TranslateItIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="23 7 16 12 23 17 23 7" />
      <rect x="1" y="5" width="15" height="14" rx="2" ry="2" />
      <polygon xmlns="http://www.w3.org/2000/svg" points="23 7 16 12 23 17 23 7" />
      <rect xmlns="http://www.w3.org/2000/svg" x="1" y="5" width="15" height="14" rx="2" ry="2" />
    </svg>
  );
}
