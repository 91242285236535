import useLocalStorage from "@rocket/shared/hooks/useLocalStorage";
import { noop } from "@rocket/shared/utils";
import { useEffect } from "react";
import { ColorScheme } from "~/hooks/useColorScheme";

/** Listens for changes to the color scheme (if the user's preference is "device") and updates the site if it changes */
export default function ColorSchemeListener() {
  const [scheme] = useLocalStorage<ColorScheme>("theme", "device");

  useEffect(() => {
    if (!scheme || scheme !== "device" || typeof window === "undefined" || typeof window.matchMedia === "undefined") {
      return noop;
    }

    const darkQuery = window.matchMedia("(prefers-color-scheme: dark)");

    if (!darkQuery || !darkQuery.addEventListener) {
      return noop;
    }

    const setTheme = (matches: boolean) => {
      const htmlElement = document.querySelector("html");
      if (htmlElement) {
        htmlElement.classList.remove("dark", "light");
        htmlElement.classList.add(matches ? "dark" : "light");
      }
    };

    const handleDarkModeChange = (e: MediaQueryListEvent) => setTheme(e.matches);

    setTheme(darkQuery.matches);

    darkQuery.addEventListener("change", handleDarkModeChange);
    return () => {
      darkQuery.removeEventListener("change", handleDarkModeChange);
    };
  }, [scheme]);

  return null;
}
