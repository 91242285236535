import { getOrdinal } from "../../../../utils";
import useRandomCongratulationsMessage from "../../../../hooks/useRandomCongratulationsMessage";
import { useSharedSelector } from "../../../../store";

export default function CongratulationsMessage() {
  const leaderboardPosition = useSharedSelector((store) => store.user.stats.position);
  const congratsMessage = useRandomCongratulationsMessage();

  return (
    <div className={"my-4 space-y-2 text-center"}>
      <div className="text-6xl leading-[initial]">👏👏</div>
      <div className="mb-2 font-serif text-2xl text-brand dark:text-white">{congratsMessage}</div>
      {!!leaderboardPosition && (
        <p className="text-sm font-medium dark:text-white">
          You are now {getOrdinal(leaderboardPosition)} on the Rocket Languages leaderboard
        </p>
      )}
    </div>
  );
}
