import { Button, FaceliftInput, ModalDeprecated, TestIcon } from "@rocket/ui";
import { FaChartLine, FaCheck, FaDollarSign, FaMobileAlt, FaRegEnvelope } from "react-icons/fa";

import { APISchema } from "@rocket/types";
import { IconType } from "react-icons/lib";
import { OutlineButton } from "@rocket/ui/Button";
import { asyncGuestSignup } from "../../../store/auth/actions";
import classes from "./SignupModal.module.scss";
import { hideSignupModal } from "../../../store/user/actions";
import { useDispatch } from "react-redux";
import { useStoreSelector } from "../../../store";
import useTranslation from "@rocket/shared/hooks/useTranslation";
import { FormEvent } from "react";

export default function SignupModal() {
  const dispatch = useDispatch();
  const t = useTranslation();

  const isOpen = useStoreSelector((store) => store.user.showSignupModal);
  const activeCourse = useStoreSelector((store) => store.preferences.activeCourse);
  const auth = useStoreSelector((store) => store.auth);

  const handleSubmit = (ev: FormEvent) => {
    ev.preventDefault();
    if (activeCourse) {
      const formData = new FormData(ev.currentTarget as HTMLFormElement);
      const payload: APISchema["POST"]["v2/user/signup-course/{course}/{source}"]["params"] = {
        name: formData.get("name") as string,
        email: formData.get("email") as string,
        password: formData.get("password") as string,
        courseId: activeCourse.id,
        emailSubscription: true,
      };
      dispatch(asyncGuestSignup(payload, "browser-app"));
    }
  };

  return (
    <ModalDeprecated.CardContainer isOpen={isOpen && !!activeCourse} onClose={() => dispatch(hideSignupModal())}>
      <ModalDeprecated.Title>Immerse yourself further for free!</ModalDeprecated.Title>
      <form onSubmit={handleSubmit}>
        <ModalDeprecated.Body>
          <div className={classes.container}>
            <div>
              <ListItem icon={FaCheck} text={`Access more free ${activeCourse?.name} lessons`} />
              <ListItem icon={FaChartLine} text={`Have your progress saved`} />
              <ListItem icon={FaDollarSign} text={`Get exclusive discounts`} />
              <ListItem icon={FaMobileAlt} text={`Use your account on the apps`} />
              <ListItem
                icon={FaRegEnvelope}
                text={`Receive our 7 day "Learn ${activeCourse?.name}" email course`}
                subText="Unsubscribe at any time"
              />
              <div className="flex flex-col gap-2">
                <FaceliftInput type="text" label={t("name")} name="name" required />
                <FaceliftInput type="email" label={t("email-address")} name="email" required />
                <FaceliftInput type="password" label={t("password")} name="password" min={3} required />
                {auth.requester === "guest-signup" && auth.errorMessage && (
                  <div className={`mt-3 text-rocketred`}>{auth.errorMessage}</div>
                )}
              </div>
            </div>
          </div>
        </ModalDeprecated.Body>
        <ModalDeprecated.Actions
          left={
            <OutlineButton type="button" color="secondary" onClick={() => dispatch(hideSignupModal())}>
              {t("maybe-later")}
            </OutlineButton>
          }
        >
          <Button type="submit" color="primary" loading={auth.requester === "guest-signup" && auth.loading}>
            {t("get-started")}
          </Button>
        </ModalDeprecated.Actions>
      </form>
    </ModalDeprecated.CardContainer>
  );
}

function ListItem({ text, subText, icon }: { text: string; subText?: string; icon: IconType }) {
  return (
    <div className={classes.listItem}>
      <div className={classes.listItemIcon}>
        <TestIcon icon={icon} ratingLevel={0} shape="circle" size="large" faded />
      </div>
      <div>
        <p>{text}</p>
        {subText && <small>{subText}</small>}
      </div>
    </div>
  );
}
