import { ActiveProductTopbar, AvatarCircle, Topbar } from "~/layouts/components/TopBar/TopBar";
import { FiHelpCircle, FiLogOut, FiMail, FiStar, FiUser } from "react-icons/fi";
import { Link, Outlet, useLocation } from "react-router-dom";
import Sidebar, { StickySidebar } from "~/components/ui/Sidebar";

import FaceliftPage from "~/components/FaceliftPage";
import MembersFaceliftLayout from "~/layouts/MembersLayout/MembersFaceliftLayout";
import PageLoader from "@rocket/shared/ui/PageLoader";
import { Suspense } from "react";
import { logout } from "@rocket/shared/store/auth/actions";
import { toast } from "react-toastify";
import useActiveCourse from "@rocket/shared/hooks/useActiveCourse";
import useActiveProduct from "@rocket/shared/hooks/useActiveProduct";
import { useDispatch } from "react-redux";
import { useStoreSelector } from "~/store";
import useTranslation from "@rocket/shared/hooks/useTranslation";
import useCanContactSupport from "@rocket/shared/hooks/useCanContactSupport";
import useIsMackinUser from "@rocket/shared/hooks/useIsMackinUser";

export default function UtilsLayout() {
  const activeProduct = useStoreSelector((store) => store.preferences.activeProduct);

  return (
    <>
      {activeProduct ? (
        <ActiveProductTopbar />
      ) : (
        <Topbar>
          <Topbar.LogoLink hasSidebar use={Link} path={"/members/courses"} title="Rocket Languages" />
          <AvatarCircle />
        </Topbar>
      )}
      <MembersFaceliftLayout>
        <UtilsPage />
      </MembersFaceliftLayout>
    </>
  );
}

function UtilsPage() {
  const t = useTranslation();
  const activeProductId = useActiveProduct()?.id;
  return (
    <FaceliftPage.WithoutTitle className="mb-4">
      <FaceliftPage.TwoColumns>
        <StickySidebar>
          <div className="mb-4">
            {activeProductId ? (
              <Sidebar.BackButton to={`/members/products/${activeProductId}/dashboard`} label={t("dashboard")} />
            ) : (
              <Sidebar.BackButton to={`/members/courses`} label="Back to Courses" />
            )}
          </div>
          <UtilsSidebar />
        </StickySidebar>
        <div>
          <Suspense fallback={<PageLoader />}>
            <Outlet />
          </Suspense>
        </div>
      </FaceliftPage.TwoColumns>
    </FaceliftPage.WithoutTitle>
  );
}

function UtilsSidebar() {
  const t = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const product = useActiveProduct();
  const course = useActiveCourse();
  const canContactSupport = useCanContactSupport();
  const isMackinUser = useIsMackinUser();

  return (
    <>
      <Sidebar.Section className="mt-2 md:mt-0">
        <div className="py-4">
          {!isMackinUser && (
            <Sidebar.Anchor
              use={Link}
              to={"/members/profile"}
              label={t("profile")}
              active={location.pathname === "/members/profile"}
              icon={<FiUser className="h-7 w-7 text-brand dark:text-text1" />}
            />
          )}
          {canContactSupport && product && course?.slug !== "ebook" && (
            <Sidebar.Anchor
              use={Link}
              to={`/members/products/${product.id}/reviews`}
              label={t("reviews")}
              active={location.pathname === `/members/products/${product.id}/reviews`}
              icon={<FiStar className="h-7 w-7 text-brand dark:text-text1" />}
            />
          )}
          <Sidebar.Anchor
            use={Link}
            to={"/members/help"}
            label={t("help")}
            active={location.pathname === "/members/help"}
            icon={<FiHelpCircle className="h-7 w-7 text-brand dark:text-text1" />}
          />
          {canContactSupport && (
            <Sidebar.Anchor
              use={Link}
              to={"/members/contact-us"}
              label={t("contact-us")}
              active={location.pathname === "/members/contact-us"}
              icon={<FiMail className="h-7 w-7 text-brand dark:text-text1" />}
            />
          )}
          <Sidebar.Anchor
            label={t("log-out")}
            icon={<FiLogOut className="h-7 w-7 text-brand dark:text-text1" />}
            onClick={() => {
              dispatch(logout(true));
              toast.success(t("logged-out"));
            }}
          />
        </div>
      </Sidebar.Section>
    </>
  );
}
