import { useEffect } from "react";
import usePageTitle from "../../hooks/usePageTitle";
import { useStoreSelector, useStoreDispatch } from "../../store";
import usePageQuery from "@rocket/shared/hooks/usePageQuery";
import styles from "./autologin.module.scss";
import Rocket from "./includes/Rocket";
import { useNavigate } from "react-router-dom";
import { asyncAutoLoginRequest, asyncLoginHashRequest, asyncAutoLoginEmailRequest } from "../../store/auth/actions";

/** Note: relies on either "?url=xxxxx", "?h=xxxx" or "?email=xxxx" query params, otherwise redirects to login */
export default function AutoLoginPage() {
  usePageTitle("Logging in...");
  const navigate = useNavigate();
  const pageQuery = usePageQuery();
  const auth = useStoreSelector((state) => state.auth);

  const dispatch = useStoreDispatch();

  useEffect(() => {
    if (typeof pageQuery.url === "string") {
      dispatch(asyncAutoLoginRequest({ url: pageQuery.url as any }));
    } else if (typeof pageQuery.h === "string") {
      dispatch(asyncLoginHashRequest({ hash: pageQuery.h }));
    } else if (typeof pageQuery.email === "string") {
      dispatch(asyncAutoLoginEmailRequest({ email: pageQuery.email }));
    } else {
      // Go back to login page if there's no hash
      navigate("/login");
    }
  }, [navigate, dispatch, pageQuery]);

  useEffect(() => {
    if (auth.errorMessage) {
      navigate("/login");
    }
  }, [navigate, auth.errorMessage]);

  return (
    <div className={styles.container}>
      <div>
        <Rocket />
      </div>
    </div>
  );
}
