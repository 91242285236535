import { RefObject, useEffect, useRef } from "react";
import type { AudioComponent } from "@rocket/types";
import TranscriptLine from "./TranscriptLine";
import VirtualizedList from "./VirtualizedList";
import classes from "./TranscriptList.module.scss";
import { clsx } from "clsx";
import useActiveCourse from "../../../../hooks/useActiveCourse";
import useActiveCueIndex from "./useActiveCueIndex";
import useMedia from "../../../../hooks/useMedia";
import { useSliderProgress } from "./state";
import { detected } from "../../../../utils/browser";

interface TranscriptListProps {
  subtitles: TextTrackCue[];
  height: number;
  component: AudioComponent;
  player: ReturnType<typeof useMedia>;
}

/** Identifies the shell Id */
const getFooterListId = (componentId: number) => `transcript-list-${componentId}`;

/**
 * FooterTranscriptList contains a shell of the transcript list.
 *
 * The Transcript component will portal to this shell if the sticky player is visible
 */
export function FooterTranscriptList(props: { componentId: number }) {
  const isHindi = useActiveCourse()?.slug === "hindi";
  return (
    <div className={clsx(classes.transcriptContent, isHindi && classes.transcriptContentSerif)}>
      <div id={getFooterListId(props.componentId)} />
    </div>
  );
}

export default function TranscriptList(props: TranscriptListProps) {
  const { height, component, player, subtitles } = props;
  const listRef = useRef<HTMLDivElement>(null);
  const isStickyPlayerVisible = false; //!useContext(AudioContext)?.activeTrack?.isVisible;
  // const showPhrasebox = player.phraseMode === "automatic";
  const activeIndex = useActiveCueIndex(player?.id, subtitles);

  const TranscriptList = (() => {
    if (component.audio_transcript && subtitles.length > 0) {
      return (
        <VirtualizedList
          ref={listRef}
          data={subtitles}
          index={activeIndex}
          height={height}
          windowSize={7}
          className={clsx(classes.list, "dark:before:shadow-none")}
          renderItem={(item, index) => (
            <TranscriptLine
              key={`t.${component.id}.${index}`}
              index={index}
              activeIndex={activeIndex}
              subtitle={item as TextTrackCue}
              showPhrasebox={false}
              player={player}
              phrase={component.phrases?.[(item as TextTrackCue).startTime]}
            />
          )}
        />
      );
    }
    return null;
  })();

  useScrollToActiveLine({ activeIndex, listRef, playerId: player?.id }, [subtitles, isStickyPlayerVisible]);

  /*
  // When the sticky footer player becomes visible, we need to portal the transcript list to the footer
  if (isStickyPlayerVisible) {
    const element = document.getElementById(getFooterListId(component.id));
    // There could be multiple transcript components on the page
    if (element) {
      return createPortal(TranscriptList, element);
    }
  }
  */

  return TranscriptList;
}

interface UseScrollToActiveLineParams {
  activeIndex: number;
  playerId: any;
  listRef: RefObject<HTMLDivElement>;
}

const useScrollToActiveLine = ({ activeIndex, listRef, playerId }: UseScrollToActiveLineParams, deps: any[]) => {
  // @ts-ignore
  const seeking = useSliderProgress((s) => s[`seeking_${playerId || 0}`]);
  useEffect(() => {
    if (listRef.current) {
      const index = Math.max(activeIndex, 0);
      scrollToElement({
        selector: `div[data-index="${index}"]`,
        parent: listRef.current,
        // Note: Safari 15.4 has a bug where scroll offsets seem to not work for smooth scroll
        instant: seeking || detected?.name === "safari",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, listRef, seeking]);

  // When settings change (subtitles, phrase mode), instantly scroll to the active cue
  useEffect(() => {
    if (listRef.current) {
      const index = Math.max(activeIndex, 0);
      scrollToElement({
        selector: `div[data-index="${index}"]`,
        parent: listRef.current,
        instant: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

function scrollToElement(options: { selector: string; parent: HTMLElement; instant: boolean }) {
  const { selector, parent, instant } = options;
  const element = parent.querySelector<HTMLDivElement>(selector);
  if (element) {
    const top = element.offsetTop;
    // Setting inline style of scroll-behavior to 'auto' temporarily
    parent.scrollTo({ top, behavior: !instant ? "smooth" : undefined });
  }
}
