import { CourseFlagRect } from "@rocket/ui";
import { FiChevronRight } from "react-icons/fi";
import { clsx } from "clsx";

type ReactComponent = (props: any) => JSX.Element;

export interface CourseSelectionButtonProps {
  selected: boolean;
  courseSlug: string;
  title: string;
  onClick?: () => void;
  use?: string | ReactComponent;
  dialect?: string | null;
}

export default function CourseSelectionButton(props: CourseSelectionButtonProps) {
  const { selected, courseSlug, title, use: T = "button", dialect, ...rest } = props;
  return (
    <T
      {...rest}
      type={T === "button" ? "button" : undefined}
      className={clsx(
        "flex h-16 w-full items-center rounded-lg p-2 font-sans text-sm font-medium text-brand hover:bg-brand2 focus:outline-none focus:ring dark:text-white hover:dark:bg-brand hover:dark:bg-neutral-700",
        selected ? "bg-brand2 dark:bg-neutral-700" : "bg-white dark:bg-surface2",
      )}
      // for puppeteer
      id={`course-selection-${courseSlug}`}
      aria-label={title}
    >
      <div className="scale-75 md:mr-1" aria-hidden>
        <CourseFlagRect courseSlug={courseSlug} />
      </div>
      <div className="flex flex-1 items-center">
        <div className="flex flex-1 flex-col text-left">
          {title}
          {dialect && <small className="text-brand-secondary text-xs font-semibold">{dialect}</small>}
        </div>
        <FiChevronRight
          className={clsx("ml-2 h-6 w-6", selected && "rotate-90 md:rotate-0", !selected && "md:hidden")}
        />
      </div>
    </T>
  );
}
