import type { LessonComponent, VerbBoxComponent as VerbBoxComponentType } from "@rocket/types";

export interface VerbBoxComponentProps {
  lessonComponent: LessonComponent<"verb_box", VerbBoxComponentType>;
}

export default function VerbBoxComponent(props: VerbBoxComponentProps) {
  return (
    <div>
      <h2>VerbBox Component</h2>
      <div>{JSON.stringify(props)}</div>
    </div>
  );
}
