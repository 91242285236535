interface StickyNoteIconProps {
  size?: number;
}
export default function StickyNoteIcon({ size = 28 }: StickyNoteIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_658_11694)">
        <path
          d="M18.1562 8.25782V2.51563C18.1562 2.06259 17.789 1.69531 17.3359 1.69531C16.8829 1.69531 16.5156 2.06259 16.5156 2.51563V8.25782H18.1562Z"
          fill="currentColor"
        />
        <path
          d="M20.6172 24.664C21.2746 24.664 21.8925 24.4081 22.3574 23.9432C24.8161 21.4845 26.7466 18.5194 28 15.2441V25.4843C28 25.9371 27.6325 26.3046 27.1797 26.3046H7.49226C7.03945 26.3046 6.67195 25.9371 6.67195 25.4843V24.664H20.6172Z"
          fill="currentColor"
        />
        <path
          d="M0.240181 21.6228C4.32751 17.5355 6.67195 11.9722 6.67195 6.3596V5.79687C6.67195 5.34406 7.03945 4.97656 7.49226 4.97656H14.875V9.07811C14.875 9.53092 15.2425 9.89842 15.6954 9.89842H18.9766C19.4294 9.89842 19.7969 9.53092 19.7969 9.07811V4.97656H27.1797C27.6325 4.97656 28 5.34406 28 5.79687V6.3596C28 12.6804 25.4423 18.5385 21.1974 22.7833C21.0432 22.937 20.8349 23.0234 20.6172 23.0234H0.820412C0.488461 23.0234 0.189322 22.8238 0.0624466 22.517C-0.0644264 22.2107 0.00557327 21.8574 0.240181 21.6228ZM17.336 11.539H10.7735C10.3207 11.539 9.95319 11.9065 9.95319 12.3593C9.95319 12.8122 10.3207 13.1797 10.7735 13.1797H17.336C17.7888 13.1797 18.1563 12.8122 18.1563 12.3593C18.1563 11.9065 17.7888 11.539 17.336 11.539ZM10.7735 19.7421H18.9766C19.4294 19.7421 19.7969 19.3746 19.7969 18.9218C19.7969 18.469 19.4294 18.1015 18.9766 18.1015H10.7735C10.3207 18.1015 9.95319 18.469 9.95319 18.9218C9.95319 19.3746 10.3207 19.7421 10.7735 19.7421ZM9.13288 16.4609H20.6172C21.07 16.4609 21.4375 16.0934 21.4375 15.6406C21.4375 15.1878 21.07 14.8203 20.6172 14.8203H9.13288C8.68007 14.8203 8.31257 15.1878 8.31257 15.6406C8.31257 16.0934 8.68007 16.4609 9.13288 16.4609Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_658_11694">
          <rect width="28.8" height="28.8" fill="white" transform="matrix(-1 0 0 1 28 0)" />
        </clipPath>
      </defs>
    </svg>
  );
}
