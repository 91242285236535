export default function FlashcardsIcon({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.2046 21.1553L21.0799 23.0357V9.75383C21.0799 7.81407 19.5044 6.23854 17.5646 6.23854H12.2832V5.61171L19.5255 2.95618C20.9063 2.44795 22.4437 3.15948 22.9477 4.54018L23.8964 7.12371L24.5317 8.85171L27.7886 17.7331C28.2968 19.1138 27.5853 20.6513 26.2046 21.1553Z"
        fill="currentColor"
      />
      <path
        d="M17.5644 7.08569H12.2829V8.16146C12.2829 9.50404 11.1902 10.5967 9.85189 10.5967C8.48774 10.5967 7.41659 9.48681 7.41659 8.16146V7.08569H3.51589C2.04201 7.08569 0.847656 8.28005 0.847656 9.75393V23.8024C0.847656 25.2763 2.04201 26.4706 3.51589 26.4706H17.5644C19.0382 26.4706 20.2326 25.2763 20.2326 23.8024V9.75393C20.2326 8.28005 19.0382 7.08569 17.5644 7.08569ZM16.563 23.4002H4.51584C4.28197 23.4002 4.09231 23.2108 4.09231 22.9767C4.09231 22.7426 4.28197 22.5532 4.51584 22.5532H16.563C16.7969 22.5532 16.9866 22.7426 16.9866 22.9767C16.9866 23.2108 16.797 23.4002 16.563 23.4002ZM16.563 19.9247H4.51584C4.28197 19.9247 4.09231 19.7353 4.09231 19.5012C4.09231 19.2671 4.28197 19.0777 4.51584 19.0777H16.563C16.7969 19.0777 16.9866 19.2671 16.9866 19.5012C16.9866 19.7353 16.797 19.9247 16.563 19.9247ZM16.563 16.4488H4.51584C4.28197 16.4488 4.09231 16.2594 4.09231 16.0253C4.09231 15.7912 4.28197 15.6018 4.51584 15.6018H16.563C16.7969 15.6018 16.9866 15.7912 16.9866 16.0253C16.9866 16.2594 16.797 16.4488 16.563 16.4488Z"
        fill="currentColor"
      />
      <path
        d="M11.4357 5.81372V8.16304C11.4357 9.03975 10.7276 9.74789 9.8509 9.74789C8.97923 9.74789 8.26562 9.04098 8.26562 8.16304V3.91462C8.26562 3.03749 8.97758 2.32935 9.8509 2.32935C10.7276 2.32935 11.4357 3.03749 11.4357 3.91462V5.81372Z"
        fill="currentColor"
      />
    </svg>
  );
}
