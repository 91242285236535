import { FaGraduationCap, FaPlane, FaStar } from "react-icons/fa";
import { LessonComponent, MarkdownComponent as MarkdownComponentType, MarkdownTipType } from "@rocket/types";
import { memo, useContext } from "react";

import { IconType } from "react-icons";
import LessonContext from "../../../context/LessonContext";
import RocketMarkdown from "./includes/RocketMarkdown";
import TravelogueMarkdownComponent from "./includes/TravelogueMarkdown";
import { clsx } from "clsx";
import styles from "./MarkdownComponent.module.scss";
import useCustomMarkdownTitleBodyParser from "./useCustomMarkdownTitleBodyParser";

export type Props = {
  lessonComponent: LessonComponent<"markdown", MarkdownComponentType>;
  /** (Used for Travelogue lessons on non-english components) */
  translation?: boolean;
};

const MarkdownComponent = memo((props: Props) => {
  const { lessonComponent, translation } = props;
  const { component } = lessonComponent;

  // Custom markdown types include an icon, a title & body parser with custom layout rules
  if (component.type !== "default") {
    return (
      <CustomMarkdownTip isTranslation={translation} componentType={component.type}>
        {component.content}
      </CustomMarkdownTip>
    );
  }

  return (
    <MarkdownBody isTranslation={translation} componentType={component.type}>
      {component.content}
    </MarkdownBody>
  );
});

interface CustomMarkdownTipProps {
  children: string;
  componentType: MarkdownTipType;
  isTranslation?: boolean;
}

function CustomMarkdownTip({ children, isTranslation, componentType }: CustomMarkdownTipProps) {
  const markdown = useCustomMarkdownTitleBodyParser(children);
  const Icon = iconMap[componentType];

  // If the markdown has no heading, and is a tip, show the icon on the top left
  const showTopLeftIcon = !markdown.title && Icon && componentType === "tip";

  return (
    <>
      {markdown.title && (
        <div
          className={clsx(
            styles.markdown,
            "mb-4 flex items-center font-serif text-text1",
            componentType === "tip" && `${styles["markdown-title-tip"]} bg-rocketpurple-extra-light dark:bg-slate-800`,
          )}
        >
          {Icon && (
            <div className="mr-4 flex h-[48px] w-[48px] min-w-[48px] items-center justify-center rounded-full bg-brand text-white print:hidden">
              <Icon size={28} />
            </div>
          )}
          <RocketMarkdown>{markdown.title}</RocketMarkdown>
        </div>
      )}
      {markdown.body && (
        <MarkdownBody
          componentType={componentType}
          isTranslation={isTranslation}
          topLeftIcon={showTopLeftIcon ? Icon : null}
        >
          {markdown.body}
        </MarkdownBody>
      )}
    </>
  );
}

interface MarkdownBodyProps {
  componentType: MarkdownTipType;
  isTranslation?: boolean;
  children: string;
  /** Displays a top-left icon, relevant for markdown tips with no headings */
  topLeftIcon?: IconType | null;
}

function MarkdownBody({ componentType, isTranslation, children, topLeftIcon: TopLeftIcon }: MarkdownBodyProps) {
  const isTravelogue = useContext(LessonContext).isTravelogue;
  return (
    <div
      className={clsx(
        "relative font-serif text-text1",
        styles.markdown,
        componentType !== "default" && [styles[`markdown-${componentType}`], "p-6 dark:bg-slate-800 print:p-0"],
        isTranslation && styles.translation,
      )}
      // Used by Add to Vocab for selection DOM identification
      data-markdown-travelogue={isTravelogue ? true : undefined}
    >
      {TopLeftIcon && (
        <div className="absolute -left-2 -top-2 mr-4 flex h-[28px] w-[28px] min-w-[28px] items-center justify-center rounded-full bg-brand text-white print:hidden">
          <TopLeftIcon size={18} />
        </div>
      )}
      {isTravelogue ? (
        <TravelogueMarkdownComponent baseText={children} isTranslation={isTranslation} />
      ) : (
        <RocketMarkdown>{children}</RocketMarkdown>
      )}
    </div>
  );
}

const iconMap = {
  default: null,
  tip: FaStar,
  replay: FaStar,
  culture: FaPlane,
  alt: FaGraduationCap,
};

export default MarkdownComponent;
