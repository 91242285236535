import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { rootReducer, rootSaga } from "./index";
import configureStore, { defaultPersistOptions } from "@rocket/shared/store/configureStore";

export default function createStoreInstance() {
  return configureStore({
    rootReducer,
    rootSaga,
    persistOptions: { ...defaultPersistOptions, storage },
  });
}
