import Markdown from "markdown-to-jsx";
import Notation from "../Notation";
import { Tooltip } from "@rocket/ui";
import useTranslation from "../../../hooks/useTranslation";

interface LiteralStringNotationButtonProps {
  literalString: string;
}

export default function LiteralStringNotationButton(props: LiteralStringNotationButtonProps) {
  const t = useTranslation();
  return (
    <Tooltip width={300} label={<Markdown>{`${t("literally")}: ${props.literalString}`}</Markdown>}>
      <Notation title={t("lit")} />
    </Tooltip>
  );
}
