/**
 * Purpose of selectors:
 * Import these selectors to your mapStateToProps/mapDispatchToProps inside your Redux-connected components
 * These selectors are designed to filter out only what is needed for the specific component
 * So that there won't be any re-rendering on small state changes
 */
// Types
import { RootState } from "./types";

export const userSelector = (state: RootState) => state.user;
export const userProductsSelector = (state: RootState) => state.user.products;
export const userPaidProductsSelector = (state: RootState) => state.user.products.paid;
export const authSelector = (state: RootState) => state.auth;
export const lessonSelector = (state: RootState) => state.lesson;
export const dashboardSelector = (state: RootState) => state.dashboard;
export const preferencesSelector = (state: RootState) => state.preferences;
export const activeCourseSelector = (state: RootState) => state.preferences.activeCourse;
export const activeProductSelector = (state: RootState) => state.preferences.activeProduct;
export const dashboardProductsSelector = (state: RootState) => state.dashboard.products;
export const hasSyncedThisSessionSelector = (state: RootState) => state.user.hasSyncedThisSession;
