import type { PhraseResource, PhraseString as PhraseStringType } from "@rocket/types";
import PhraseString, { PhraseStringOptions } from "./PhraseString";
import { RomanizedWritingSystemIds, WritingSystemIds } from "../../../utils/constants";
import { memo } from "react";
import { clsx } from "clsx";
import { isRTL } from "../../../utils";
import { useSharedSelector } from "../../../store";

interface PhraseProps {
  phrase: PhraseResource;
  getPhraseStringOptions?: (phraseString: PhraseStringType, index: number) => PhraseStringOptions | undefined;
}

/**
 * Renders each string (in separate rows) associated with a specific phrase:
 *
 * e.g. `["ellos", "they (masculine + plural)"]`
 */
function Phrase({ phrase, getPhraseStringOptions }: PhraseProps) {
  const rtl = isRTL(phrase.course_id);
  const romanizationHidden = useSharedSelector((state) => !!Number(state.user.preferences?.hide_romanized));
  const kanaHidden = useSharedSelector((store) => !!Number(store.user.preferences?.hide_kana));

  const phraseStrings = phrase.strings.map((phraseString, i) => {
    if (
      !phraseString.text ||
      (romanizationHidden && isRomanizedString(phraseString)) ||
      (kanaHidden && isKanaString(phraseString))
    ) {
      return null;
    }

    const options = getPhraseStringOptions?.(phraseString, i);

    return (
      <div
        className={clsx("mb-1 flex flex-1", i === 0 && "text-lg", rtl && "flex-row-reverse text-right")}
        key={phraseString.id}
      >
        <PhraseString key={phraseString.id} phraseString={phraseString} options={options} notation={null} />
      </div>
    );
  });

  return <>{phraseStrings}</>;
}

function isRomanizedString(phraseString: PhraseStringType) {
  return RomanizedWritingSystemIds.includes(phraseString.writing_system_id);
}

function isKanaString(phraseString: PhraseStringType) {
  return WritingSystemIds.hiragana_katakana === phraseString.writing_system_id;
}

// non-memoized version for deeply nested admin area manipulations
export function AdminPhrase(props: PhraseProps) {
  return <Phrase {...props} />;
}

const MemoizedPhrase = memo(Phrase);

export default MemoizedPhrase;
