import { Phrase, TranscriptLine } from "@rocket/types";

import ComponentStatusWrapper from "../../includes/ComponentStatusWrapper";
import Phrasebox from "../../../Phrasebox";
import { clsx } from "clsx";
import { useSharedSelector } from "../../../../store";

interface TranscriptLinesProps {
  /** Component ID used for keying */
  componentId: number;
  lines: TranscriptLine[];
  /** Set when used in admin area, renders phrasebox within an editable wrapper instead */
  phraseboxWrapper?: (
    key: string | number,
    phrase: Phrase,
    component: JSX.Element,
    phraseIndex: number,
  ) => JSX.Element | null;
}

/**
 * Normal transcript lines. Only used when is not valid Play It (i.e. less than 4 lines)
 */
export default function TranscriptLines(props: TranscriptLinesProps) {
  const phrases = useSharedSelector((store) => store.lesson.entities.phrases);
  let lastCharacter = "";

  const Lines = props.lines.map((line, i) => {
    const phrase = phrases[line.phrase_id];
    if (!phrase) {
      return null;
    }

    const phraseBox = props.phraseboxWrapper ? (
      props.phraseboxWrapper(line.id, phrase, <Phrasebox phrase={phrase} className="bg-transparent" />, i)
    ) : (
      <ComponentStatusWrapper key={`wrapper.${phrase.id}`} draft={!line.status}>
        <Phrasebox phrase={phrase} />
      </ComponentStatusWrapper>
    );

    if (!line.character || lastCharacter === line.character.name) {
      return phraseBox;
    }
    lastCharacter = line.character.name;
    return (
      <div key={`t.${props.componentId}.${line.id}`}>
        <h4 className={clsx("pb-1 text-brand dark:text-white")}>{line.character.name}</h4>
        {phraseBox}
      </div>
    );
  });

  return <div>{Lines}</div>;
}
