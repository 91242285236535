import { LessonComponent, TranscriptComponent } from "@rocket/types";
import { useContext, useMemo, useState } from "react";

import Instructions from "./includes/Instructions";
import { PlayItContext } from "./includes/context";
import PlayItHeader from "./PlayItHeader";
import PlayItLines from "./PlayItLines";
import PlaybackButtons from "./PlaybackButtons";
import RateableTestUI from "../../../RateableTests/RateableTestUI";
import Settings from "./includes/Settings";
import { getCharacters } from "./includes/utils";
import useActiveCourse from "../../../../hooks/useActiveCourse";
import { useSharedSelector } from "../../../../store";

interface Props {
  lessonComponent: LessonComponent<"transcript", TranscriptComponent>;
}

export default function PlayIt(props: Props) {
  const { lessonComponent } = props;
  const { state } = useContext(PlayItContext)!;
  const activeCourse = useActiveCourse();

  /** If true, displays a list of settings to show/hide writing systems */
  const [displaySettings, setDisplaySettings] = useState(false);

  const debugEnabled = useSharedSelector((state) => state.preferences.debugEnabled);

  // show draft lines if debug mode enabled or on admin domain
  const filteredLines = useMemo(() => {
    if (debugEnabled || (typeof window !== "undefined" && window.location.hostname === "staff.rocketlanguages.com"))
      return lessonComponent.component.lines;
    return lessonComponent.component.lines.filter((_line) => !!_line.status);
  }, [debugEnabled, lessonComponent.component.lines]);

  const activeCourseSlug = activeCourse ? activeCourse.slug : "";

  const characters = useMemo(() => getCharacters(filteredLines), [filteredLines]);

  function getCharacterNameById(id: number) {
    const char = characters.find((c) => c.id === id);
    if (char) {
      return char.name;
    }
    return "Unknown";
  }

  return (
    <div className="rounded-md border-2 border-slate-300 p-4 dark:border-gray-600 sm:p-6">
      <RateableTestUI.Container
        id={`role-playing-${lessonComponent.component.id}`}
        instructions={<Instructions />}
        settings={<Settings lessonComponent={lessonComponent} />}
      >
        <PlayItHeader
          characterName={getCharacterNameById(Number(state.characterId))}
          componentId={lessonComponent.component.id}
          onToggleDisplaySettings={() => setDisplaySettings((state) => !state)}
          displaySettings={displaySettings}
        />
        <RateableTestUI.Body>
          <div>
            {state.secondsCounter ? (
              <div className="absolute inset-0 z-10 bg-black bg-opacity-50 text-center text-white">
                <div className="mt-8 text-6xl">{state.secondsCounter}</div>
              </div>
            ) : null}
            <div className="flex justify-center">
              <PlaybackButtons
                characters={characters}
                numLines={filteredLines.length}
                componentId={lessonComponent.component.id}
              />
            </div>
            <PlayItLines lines={filteredLines} activeCourseSlug={activeCourseSlug} />
          </div>
        </RateableTestUI.Body>
      </RateableTestUI.Container>
    </div>
  );
}
