import { Button, Centered } from "@rocket/ui";
import DrawItContextProvider, { DrawItContext } from "./includes/context";
import ScriptWriter, { ScriptWriterProps } from "./includes/HanziWriter/ScriptWriter";
import { useContext, useEffect, useState } from "react";
import useDrawIt, { useDrawItPosition } from "../../../hooks/useDrawIt";

import DrawItComplete from "./includes/DrawItComplete";
import Instructions from "./includes/Instructions";
import LessonContext from "../../../context/LessonContext";
import { RateableTestTypeIds, RateableTestTypes } from "../../../utils/constants";
import RateableTestUI from "../RateableTestUI";
import { RatingButtonGroup } from "../RateableTestUI/buttons/RatingButtons";
import { ReinforcementContext } from "../../../context/ReinforcementContext";
import Settings from "./includes/Settings";
import { getNextRateableTest, getRatingLevel } from "../../../utils";
import { shallowEqual } from "react-redux";
import useNonEasyComponentRatings from "../../../hooks/useNonEasyComponentRatings";
import { useRateableTestRatingLevel } from "../../../hooks/useLessonRateableTests";
import useTranslation from "../../../hooks/useTranslation";
import { useSharedStore } from "../../../store";

export default function DrawIt({ rateableTestId }: { rateableTestId: number }) {
  const t = useTranslation();
  const ratingLevel = useRateableTestRatingLevel(rateableTestId);

  const lessonId = useContext(LessonContext).id;
  const drawItStore = useDrawIt({ rateableTestId, lessonId, mode: "unrated_components" });
  const position = useDrawItPosition(drawItStore);

  const { numComponents } = drawItStore(
    (state) => ({
      numComponents: state.allComponents.length,
    }),
    shallowEqual,
  );

  return (
    <DrawItContextProvider>
      <RateableTestUI.Container
        rateableTestTypeId={RateableTestTypeIds.DRAW_IT}
        instructions={<Instructions />}
        settings={<Settings />}
      >
        <RateableTestUI.Header
          testName={t("draw-it")}
          testSubheading={t("draw-it-subheading")}
          rateableTestId={rateableTestId}
          rateableTestTypeId={RateableTestTypeIds.DRAW_IT}
          ratingLevel={ratingLevel}
          position={position}
          total={numComponents}
        />
        <RateableTestUI.Body>
          <DrawItTest rateableTestId={rateableTestId} store={drawItStore} />
        </RateableTestUI.Body>
      </RateableTestUI.Container>
    </DrawItContextProvider>
  );
}

export function DrawItTest({ rateableTestId, store }: { rateableTestId: number; store: ReturnType<typeof useDrawIt> }) {
  const t = useTranslation();
  const context = useContext(DrawItContext);
  const { setActivePhraseTest } = useContext(ReinforcementContext);
  const [state, setState] = useState<"idle" | "started" | "revealed">("idle");
  const nonEasyComponents = useNonEasyComponentRatings(rateableTestId);
  const lessonId = useContext(LessonContext).id;
  const reduxStore = useSharedStore();

  const { status, actions, currentComponent } = store(
    (state) => ({
      actions: state.actions,
      status: state.status,
      currentComponent: state.testComponents[state.index],
    }),
    shallowEqual,
  );

  useEffect(
    function onComponentChange() {
      setState("idle");
    },
    [currentComponent],
  );

  useEffect(
    function onStateChange() {
      context?.setQuizState(state);
    },
    // eslint-disable-next-line
    [state],
  );

  if (status === "complete") {
    const nextRateableTest = getNextRateableTest({ rateableTestId, lessonId, store: reduxStore });
    const localeKey = nextRateableTest ? RateableTestTypes[nextRateableTest.rateable_test_type_id]?.code : null;
    const nextTestName = localeKey ? t(localeKey) : null;

    return (
      <DrawItComplete
        nextActivityTitle={nextTestName ? `Next Activity: ${nextTestName}` : undefined}
        nextActivityHref={localeKey ? `#${localeKey}` : undefined}
        completedWithinSession={status === "complete"}
        onReset={() => {
          actions.reset();
        }}
        numNonEasyComponents={nonEasyComponents.length}
        redoNonEasyCharacters={() => {
          actions.redoNonEasyCharacters();
        }}
      />
    );
  }

  if (status === "idle") {
    return (
      <Centered>
        <Button
          color="primary"
          className="uppercase"
          onClick={() => {
            setActivePhraseTest(rateableTestId);
            actions.start();
          }}
        >
          {t("get-started")}
        </Button>
      </Centered>
    );
  }

  function renderControlButtons({ quiz, startQuiz, stopQuiz, rating }: Parameters<ScriptWriterProps["controls"]>[0]) {
    if (state === "revealed") {
      return (
        <RatingButtonGroup
          ratings={[0, 0, 0]}
          suggestedRating={rating ? getRatingLevel(rating) : undefined}
          onRate={actions.rate}
        />
      );
    }

    const isQuizActive = quiz?.active;

    return (
      <Centered horizontal>
        {isQuizActive ? (
          <Button
            color="secondary"
            onClick={() => {
              stopQuiz();
              setState("revealed");
            }}
          >
            {t("reveal")}
          </Button>
        ) : (
          <Button
            color="secondary"
            onClick={() => {
              startQuiz();
              setState("started");
            }}
          >
            DRAW
          </Button>
        )}
      </Centered>
    );
  }

  if (!currentComponent) {
    return null;
  }

  return (
    <ScriptWriter
      showCharacter
      // todo: set "1000" to false when bug fixed in hanzi-writer repo
      showHintAfterMisses={context?.enableHints ? 2 : 1000}
      showOutline={context?.showShadow}
      showGridlines={context?.showGrid}
      component={currentComponent}
      onCompleteQuiz={() => setState("revealed")}
      controls={renderControlButtons}
    />
  );
}
