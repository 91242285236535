import { getRatingLevel } from "../utils/utils";
import { useSharedSelector } from "../store";
import { RateableTestEntity } from "@rocket/types";
import { createSelector } from "reselect";
import { SharedRootState } from "../store/types";
import { shallowEqual } from "react-redux";

/** Returns 0-100 (defaults to 0 if incomplete) */
export function useRateableTestRating(rateableTestId: number): number {
  return useSharedSelector((store) => store.lesson.entities.user_rateable_test_ratings[rateableTestId]?.value || 0);
}

/** Returns `{value: 0-100, marked_complete: bool}` (or undefined) */
export function useRateableTestUserRating(rateableTestId: number) {
  return useSharedSelector((store) => store.lesson.entities.user_rateable_test_ratings[rateableTestId]);
}

/**
 * Gets the rating level of a specified test
 */
export function useRateableTestRatingLevel(rateableTestId: number): 0 | 1 | 2 | 3 {
  return getRatingLevel(useRateableTestRating(rateableTestId));
}

type UseLessonRateableTestOptions = {
  lessonId: number | undefined;
  omitExtraTests?: boolean;
  includeDisabled?: boolean;
};

const lessonRateableTestSelector = createSelector(
  [
    (store: SharedRootState, options: UseLessonRateableTestOptions) =>
      store.lesson.entities.lesson_rateable_test_ids[options.lessonId || 0],
    (store: SharedRootState) => store.lesson.entities.rateable_tests,
    (_store, options: UseLessonRateableTestOptions) => options,
  ],
  (testIds, rateableTests, options) => {
    if (!testIds) {
      return [];
    }

    const tests: RateableTestEntity[] = [];

    for (const testId of testIds) {
      const resolvedTest = rateableTests[testId];
      if (!resolvedTest) {
        continue;
      }
      if (options.omitExtraTests && resolvedTest.is_extra) {
        continue;
      }
      if (resolvedTest.status !== 1 && !options.includeDisabled) {
        continue;
      }
      tests.push(resolvedTest);
    }
    return tests;
  },
);

/**
 * Fetches all enabled rateable tests for the specified lesson id
 */
export default function useLessonRateableTests(options: UseLessonRateableTestOptions) {
  return useSharedSelector((state) => lessonRateableTestSelector(state, options), shallowEqual);
}
