import { RocketRecordProvider, RocketRecordContext } from "../../../../context/RocketRecordContext";
import { useContext, useEffect } from "react";

import AudioRecorder from "../../../Phrasebox/includes/RocketRecord/AudioRecorder";
import ErrorText from "../../../Phrasebox/includes/ErrorText";
import LessonContext from "../../../../context/LessonContext";
import { PhraseboxProps } from "../../../Phrasebox/Phrasebox";
import RocketRecordControls from "../../../Phrasebox/includes/RocketRecordControls";
import RocketRecordSpeech from "../../../Phrasebox/includes/RocketRecordSpeech";
import RocketRecordVisualizer from "../../../Phrasebox/includes/RocketRecordVisualizer";
import Voice from "../../../Phrasebox/includes/RocketRecord/Voice";
import { getSpeechRecognitionLocale, useShouldUseNodeSpeechApi } from "../../../Phrasebox/includes/RocketRecord/utils";
import useActiveCourse from "../../../../hooks/useActiveCourse";
import { useGlobalTranscriptRocketRecordState } from "./state";

export default function TranscriptPhrasebox(props: Omit<PhraseboxProps, "variant"> & { playerId: number }) {
  const {
    recordOnMount,
    phrase,
    initialResult,
    onRecordFinish,
    initialBlobUrl,
    persistBlobs,
    disableVoiceRecognition,
  } = props;

  const activeCourse = useActiveCourse();
  const lessonId = useContext(LessonContext)?.id;
  const shouldUseNodeSpeechApi = useShouldUseNodeSpeechApi(phrase);

  if (!phrase) {
    return null;
  }

  return (
    <RocketRecordProvider
      rocketRecord={{
        phrase,
        Voice,
        AudioRecorder,
        shouldUseNodeSpeechApi,
        locale: getSpeechRecognitionLocale({ phrase, course: activeCourse }),
        recordOnMount,
        initialResult,
        initialBlobUrl,
        persistBlobs,
        onRecordFinish,
        disableVoiceRecognition,
        lessonId,
        platform: "web",
      }}
    >
      <Content {...props} />
    </RocketRecordProvider>
  );
}

function Content(props: Omit<PhraseboxProps, "variant"> & { playerId: number }) {
  const { useRocketRecordState } = useContext(RocketRecordContext)!;

  // Sync current rocket record state with the transcript Play/Pause button.
  // This allows the Play/Pause button to be disabled when the user is recording
  useEffect(() => {
    const unsubscribe = useRocketRecordState.subscribe((state) => {
      useGlobalTranscriptRocketRecordState.setState({
        activePlayerId: props.playerId,
        flag: state.flag,
      });
    });
    return () => {
      useGlobalTranscriptRocketRecordState.setState({ flag: null });
      unsubscribe();
    };
  }, [props.playerId, useRocketRecordState]);

  const { onPlayFinish, lessonId } = props;

  return (
    <>
      <div style={{ gridColumn: "span 2" }}>
        <RocketRecordControls
          hidePlayer={true}
          initializeWithRating={false}
          onPlayFinish={onPlayFinish}
          lessonId={lessonId}
        />
      </div>

      <div className="flex flex-col">
        <RocketRecordVisualizer />
        {/* Speech from Rocket Record */}
        <strong>
          <RocketRecordSpeech />
        </strong>
        {/* Speech Error Text */}
        <ErrorText />
      </div>
    </>
  );
}
