import { Slider, withStyles } from "@material-ui/core";

const RocketSlider = withStyles({
  root: {
    opacity: 0.9,
    height: 8,
    marginTop: 13,
  },
  thumb: {
    color: "#FFF",
    marginTop: -4,
    "&:hover": {
      boxShadow: "0px 0px 0px 8px rgb(255 255 255 / 16%)",
    },
    "&:focus, &active": {
      boxShadow: "0px 0px 0px 14px rgb(255 255 255 / 16%)",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    color: "#FFF",
    height: 4,
    borderRadius: 4,
  },
  rail: {
    color: "#FFF",
    height: 4,
    borderRadius: 4,
  },
  markLabel: {
    color: "#FFF",
    top: 13,
  },
  mark: {
    color: "transparent",
    backgroundColor: "transparent",
  },
  marked: {
    marginBottom: 0,
    // marginTop: 13,
    padding: 0,
  },
})(Slider);

export default RocketSlider;
