import { LessonComponent, LessonComponentMap as LessonComponentMapType, LessonComponentUnion } from "@rocket/types";
import { useContext, useMemo } from "react";

import AddToVocab from "@rocket/shared/components/Lesson/Vocab/AddToVocab";
import ComponentStatusWrapper from "@rocket/shared/components/Lesson/includes/ComponentStatusWrapper";
import FileComponent from "@rocket/shared/components/Lesson/LessonComponents/FileComponent";
import ImageComponent from "@rocket/shared/components/Lesson/ImageComponent";
import InteractiveAudioComponent from "@rocket/shared/components/Lesson/InteractiveAudioComponent";
import LessonContext from "@rocket/shared/context/LessonContext";
import MarkdownComponent from "@rocket/shared/components/Lesson/MarkdownComponent";
import PhraseboxComponent from "@rocket/shared/components/Lesson/PhraseboxComponent";
import ScriptWriterComponent from "@rocket/shared/components/Lesson/ScriptWriterComponent/ScriptWriterComponent";
import TextComponent from "@rocket/shared/components/Lesson/LessonComponents/TextComponent";
import TranscriptComponent from "@rocket/shared/components/Lesson/TranscriptComponent";
import VerbBoxComponent from "@rocket/shared/components/Lesson/LessonComponents/VerbBoxComponent";
import VideoComponent from "@rocket/shared/components/Lesson/VideoComponent";
import { useStoreSelector } from "~/store";
import LessonScrollProgressTracker from "../LessonScrollProgressTracker";
import { LessonTypeId } from "@rocket/shared/utils/constants";

const LessonComponentMap: {
  [key in keyof LessonComponentMapType]: (props: {
    lessonComponent: LessonComponent<key, LessonComponentMapType[key]>;
  }) => JSX.Element | null;
} = {
  markdown: MarkdownComponent,
  text: TextComponent,
  file: FileComponent,
  image: ImageComponent,
  video: VideoComponent,
  // audio: AudioComponent,
  audio: InteractiveAudioComponent,
  transcript: TranscriptComponent,
  phrasebox: PhraseboxComponent,
  verb_box: VerbBoxComponent,
  script_writer: ScriptWriterComponent,
  onboarding_step: () => null,
  section_start: () => null,
  section_end: () => null,
};

export default function LessonComponents({ components }: { components: LessonComponentUnion[] | undefined }) {
  const lessonContext = useContext(LessonContext);

  const filterLanguageId = useStoreSelector((state) => {
    const { activeProduct, productOptions } = state.preferences;
    if (!activeProduct || !productOptions) {
      return "all";
    }
    const options = productOptions[activeProduct.id];
    if (!options || !options.filterLanguageId) {
      return "all";
    }
    return options.filterLanguageId;
  });

  const LessonComponentElements = useMemo(() => {
    if (!components) {
      return null;
    }

    if (!Array.isArray(components)) {
      return <h1>You do not have access to this lesson.</h1>;
    }

    return components.map((lessonComponent) => {
      const key = `${lessonComponent.component.id}${lessonComponent.type}`;
      const isDraft = lessonComponent.status === "draft";

      if (lessonContext?.isTravelogue) {
        const languageId = lessonComponent.language_id || 15; // Default to 15 (English) if not set
        const isLanguageEnglish = languageId === 15;
        // Render component if language matches filter
        const isTextOrMarkdown = Boolean(lessonComponent.type === "text" || lessonComponent.type === "markdown");
        const isNotFiltered = Boolean(filterLanguageId === "all" || languageId === filterLanguageId);
        const shouldRender = Boolean(!isTextOrMarkdown || isNotFiltered);

        if (!shouldRender) {
          return null;
        }

        if (!isLanguageEnglish) {
          if (lessonComponent.type === "markdown") {
            return (
              <ComponentStatusWrapper key={key} draft={isDraft}>
                <MarkdownComponent lessonComponent={lessonComponent} translation />
              </ComponentStatusWrapper>
            );
          }
          if (lessonComponent.type === "text") {
            return (
              <ComponentStatusWrapper key={key} draft={isDraft}>
                <TextComponent lessonComponent={lessonComponent} italicize />
              </ComponentStatusWrapper>
            );
          }
        }
      }

      const LessonComponentFn = LessonComponentMap[lessonComponent.type];

      if (!LessonComponentFn) {
        return null;
      }

      return (
        <ComponentStatusWrapper key={key} draft={isDraft}>
          <div className="my-4">
            <LessonComponentFn
              key={key}
              // @ts-ignore
              lessonComponent={lessonComponent}
            />
          </div>
        </ComponentStatusWrapper>
      );
    });
  }, [components, filterLanguageId, lessonContext?.isTravelogue]);

  return (
    <AddToVocab>
      {/* Non-IAC lessons have their scroll progress tracked */}
      {lessonContext.lesson_type_id !== LessonTypeId.IAC && <LessonScrollProgressTracker lessonId={lessonContext.id} />}
      <div>{LessonComponentElements}</div>
    </AddToVocab>
  );
}
