import { CSSProperties, useRef, useState, useEffect, HTMLAttributes } from "react";
import styles from "./TooltipPositioner.module.scss";
import { clsx } from "clsx";

type Props = {
  left: number;
  top: number;
  width?: number;
  parentWidth: number;
  children: React.ReactNode;
  orientation?: "above" | "beneath";
  mode?: "fixed" | "absolute";
} & HTMLAttributes<HTMLSpanElement>;

const settings = {
  width: 120,
  height: 0,
};

export default function TooltipPositioner(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { left, top, width: manualWidth, parentWidth, children, orientation, mode, className, ...rest } = props;
  const [state, setState] = useState(settings);
  const containerRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const containerElement = containerRef.current!;
    setState({
      height: containerElement.offsetHeight,
      width: containerElement.offsetWidth,
    });
  }, [containerRef]);

  const { height, width } = state;

  const [leftOffset, triangleOffset] = ((): [number, number] => {
    const fromLeftOffset = Math.max((left || 0) - (width - parentWidth) / 2, 0);
    if (window.innerWidth < fromLeftOffset + width + 20) {
      const newOffset = window.innerWidth - width - 20; // -20 for scrollbar
      const newTriangleOffset = width / 2 - 5 + (fromLeftOffset - newOffset);
      return [newOffset, newTriangleOffset];
    }
    return [fromLeftOffset, fromLeftOffset === 0 ? left + parentWidth / 2 - 5 : width / 2 - 5];
  })();

  const style = ((): CSSProperties => {
    if (height === 0) {
      return {
        left: 0,
        top: 0,
        width: manualWidth || settings.width,
        visibility: "hidden",
      };
    }
    return {
      position: props.mode || "absolute",
      left: leftOffset,
      top: props.orientation === "beneath" ? (top || 0) + height / 2 : (top || 0) - height,
      width: manualWidth || settings.width,
    };
  })();

  return (
    <span role="tooltip" className={clsx(styles.container, className)} style={style} ref={containerRef} {...rest}>
      {props.orientation === "beneath" && <span className={styles.triangleAbove} style={{ left: triangleOffset }} />}
      {props.children}
      {props.orientation !== "beneath" && <span className={styles.triangle} style={{ left: triangleOffset }} />}
    </span>
  );
}
