import * as authActions from "../store/auth/actions";
import * as uiActions from "../store/ui/actions";
import { push } from "../res/router";
import { put } from "redux-saga/effects";

type AxiosRequestError = {
  response?: {
    data: ApiError | undefined;
  };
};

type ApiError = {
  code: "license_expired" | "account_disabled" | "lesson_access";
  message: string;
};

/**
 * General handler for Axios errors in Sagas
 *
 * Logs the user out if the "error" is "token_expired"
 */
export default function* handleRequestError(e: AxiosRequestError) {
  if (!e.response?.data?.code) {
    return;
  }
  // Toast error message
  yield put(uiActions.toast({ message: e.response.data.message, type: "error" }));

  switch (e.response.data.code) {
    // Navigate to course selection
    case "license_expired":
      push("/members/courses");
      break;
    case "account_disabled":
      yield put(authActions.logout());
      break;
  }
}
