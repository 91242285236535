interface Props {
  size?: number;
}

export default function EditIcon({ size = 28 }: Props) {
  return (
    <svg width={size} height={size} viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_911_13418)">
        <path
          d="M22 12C21.447 12 21 12.448 21 13V21C21 21.551 20.552 22 20 22H6C5.448 22 5 21.551 5 21V7C5 6.449 5.448 6 6 6H14C14.553 6 15 5.552 15 5C15 4.448 14.553 4 14 4H6C4.346 4 3 5.346 3 7V21C3 22.654 4.346 24 6 24H20C21.654 24 23 22.654 23 21V13C23 12.447 22.553 12 22 12Z"
          fill="currentColor"
        />
        <path
          d="M12.3752 11.0878C12.3052 11.1578 12.2582 11.2468 12.2382 11.3428L11.5312 14.8788C11.4982 15.0428 11.5502 15.2118 11.6682 15.3308C11.7632 15.4258 11.8912 15.4768 12.0222 15.4768C12.0542 15.4768 12.0872 15.4738 12.1202 15.4668L15.6552 14.7598C15.7532 14.7398 15.8422 14.6928 15.9112 14.6228L23.8232 6.71078L20.2882 3.17578L12.3752 11.0878Z"
          fill="currentColor"
        />
        <path
          d="M26.2686 0.73125C25.2936 -0.24375 23.7076 -0.24375 22.7336 0.73125L21.3496 2.11525L24.8846 5.65025L26.2686 4.26625C26.7406 3.79525 27.0006 3.16725 27.0006 2.49925C27.0006 1.83125 26.7406 1.20325 26.2686 0.73125Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_911_13418">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
