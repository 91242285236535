import * as authConstants from "../auth/constants";
import * as dashboardConstants from "../dashboard/constants";
import * as lessonConstants from "../lesson/constants";
import * as userConstants from "./constants";

import { REHYDRATE } from "redux-persist/lib/constants";
import { SharedRootAction } from "../types";
import { UserState } from "./types";
import { getCourseFromProductId } from "../../utils";
import { omit } from "../../utils";

export const initialState: UserState = {
  isAdmin: false,
  isMultiuser: false,
  isGuest: false,
  hasMissingName: false,
  hasMissingPassword: false,
  created_at: null,
  profile: undefined,
  products: {
    paid: [],
    trial: [],
  },
  stats: {
    dailyPoints: 0,
    totalPoints: 0,
    longestStreak: 0,
    currentStreak: 0,
    position: 0,
    totalPositions: 0,
  },
  avatarUrl: "",
  // require("../../res/images/avatar.png"),
  badgeUrl: "",
  // require("../../res/images/avatar.png"),
  preferences: {}, // Preferences
  requestStatus: {},
  hasSyncedThisSession: false,
  notifications: null,
  lastAccessedCourses: [],
  permission_ids: [],
  roles: [],
  showSignupModal: true,
};

/**
 * User Reducer
 */
export default function userReducer(state: UserState = initialState, action: SharedRootAction): UserState {
  switch (action.type) {
    // State is rehydrated, reset errors
    case REHYDRATE:
      if (action.payload?.user) {
        // Return state
        return {
          ...action.payload.user,
          requestStatus: {},
          hasSyncedThisSession: false,
        };
      }
      return state;
    // User logs out, reset state
    case authConstants.LOGOUT:
      return { ...initialState };
    // User logs in
    case authConstants.SUCCESS: {
      const response = action.payload;
      return {
        ...state,
        ...response.data.user,
      };
    }
    // Updates profile stats from a successful dashboard request
    case dashboardConstants.DASHBOARD_REQUEST_SUCCESS: {
      const { user } = action.payload.payload;
      return {
        ...state,
        badgeUrl: user.badge_url,
        stats: user.stats,
      };
    }
    case userConstants.UPDATE_LEADERBOARD_POSITION:
      return {
        ...state,
        stats: {
          ...state.stats,
          position: action.payload.position,
          totalPositions: action.payload.totalPositions,
        },
      };
    case userConstants.UPDATE_STREAK:
      return {
        ...state,
        stats: {
          ...state.stats,
          currentStreak: action.payload.currentStreak,
          longestStreak: action.payload.longestStreak,
        },
      };
    case userConstants.UPDATE_DAILY_POINTS:
      return {
        ...state,
        stats: {
          ...state.stats,
          dailyPoints: action.payload.dailyPoints,
        },
      };
    case userConstants.UPDATE_DAILY_POINTS_GOAL:
      if (!state.preferences) {
        return state;
      }
      return {
        ...state,
        preferences: {
          ...state.preferences,
          daily_points_goal: action.payload.dailyPointsGoal,
        },
      };
    // case userConstants.UPDATE_PREFERENCE:
    //   if (state.preferences) {
    //     return {
    //       ...state,
    //       preferences: {
    //         ...state.preferences,
    //         [action.payload.preferenceKey]: action.payload.preference,
    //       },
    //     };
    //   } else {
    //     return { ...state };
    //   }
    case userConstants.UPDATE_PREFERENCES:
      return {
        ...state,
        //@ts-ignore
        preferences: {
          ...(state.preferences || {}),
          ...action.payload,
        },
      };
    case userConstants.UPDATE_FLASHCARD_PREFERENCE:
      if (!state.preferences) {
        return state;
      }
      return {
        ...state,
        preferences: {
          ...state.preferences,
          courses: {
            ...(state.preferences.courses || {}),
            [action.payload.courseId]: {
              ...(state.preferences.courses?.[action.payload.courseId] || {}),
              flashcard_ws_front: action.payload.flashcardPreferences.flashcard_ws_front,
              flashcard_ws_back: action.payload.flashcardPreferences.flashcard_ws_back,
            },
          },
        },
      };
    case userConstants.ASYNC_UPDATE_COURSE_PREFERENCES:
      if (!state.preferences) {
        return state;
      }
      return {
        ...state,
        preferences: {
          ...state.preferences,
          courses: {
            ...(state.preferences.courses || {}),
            [action.payload.courseId]: {
              ...(state.preferences.courses?.[action.payload.courseId] || {}),
              ...action.payload.preferences,
            },
          },
        },
      };
    case userConstants.UPDATE_RR_DIFFICULTY:
      if (!state.preferences) {
        return state;
      }
      return {
        ...state,
        preferences: {
          ...state.preferences,
          voice_recognition_difficulty: action.payload.difficulty,
        },
      };
    case userConstants.UPDATE_STATS:
      return {
        ...state,
        stats: {
          ...state.stats,
          ...action.payload.stats,
        },
      };
    case userConstants.SYNC_BASIC_USER:
      return {
        ...state,
        ...action.payload.user,
      };
    case userConstants.SYNC_USER_AND_PRODUCT:
      return {
        ...state,
        ...action.payload.payload.user,
        hasSyncedThisSession: true,
      };
    case userConstants.COURSE_SELECTION_ERROR:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          courseSelection: "error",
        },
      };
    case userConstants.SHOW_RANK_NOTIFICATION:
      return {
        ...state,
        stats: {
          ...state.stats,
          newRank: action.payload,
        },
        badgeUrl: action.payload.badgeUrl,
      };
    case userConstants.REMOVE_RANK_NOTIFICATION:
      return {
        ...state,
        stats: {
          dailyPoints: state.stats.dailyPoints,
          totalPoints: state.stats.totalPoints,
          longestStreak: state.stats.longestStreak,
          currentStreak: state.stats.currentStreak,
          position: state.stats.position,
          totalPositions: state.stats.totalPositions,
        },
      };
    case userConstants.UPDATE_PROFILE: {
      if (!state.profile) {
        return state;
      }
      return {
        ...state,
        hasMissingName: false,
        hasMissingPassword: false,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      };
    }
    case userConstants.UPDATE_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case userConstants.UPDATE_FORUM_NOTIFICATIONS_PREFERENCES:
      return {
        ...state,
        preferences: {
          ...(state.preferences || {}),
          courses: state.preferences?.courses || {},
          send_forum_notifications: action.payload,
        },
      };
    case userConstants.UPDATE_AVATAR:
      return {
        ...state,
        avatarUrl: action.payload,
      };
    case userConstants.START_LOADING:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          [action.payload.key]: "loading",
        },
      };
    case userConstants.STOP_LOADING: {
      return {
        ...state,
        // Remove key from request status
        requestStatus: omit(state.requestStatus, action.payload.key),
      };
    }
    case userConstants.ERROR_LOADING:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          [action.payload.key]: "error",
        },
      };
    case userConstants.SHOW_SIGNUP_MODAL: {
      return {
        ...state,
        showSignupModal: true,
      };
    }
    case userConstants.HIDE_SIGNUP_MODAL: {
      return {
        ...state,
        showSignupModal: false,
      };
    }
    case userConstants.PUT: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case lessonConstants.VISIT: {
      const course = getCourseFromProductId(action.payload.productId);

      if (!course) {
        return state;
      }

      return {
        ...state,
        lastAccessedCourses: (() => {
          const newAccessedCourses = [...(state.lastAccessedCourses || [])]
            // Remove any matching course
            .filter((c) => c.course_id !== course.id);

          // Unshift course/product to first item
          newAccessedCourses.unshift({
            course_id: course.id,
            product_id: action.payload.productId,
          });
          return newAccessedCourses;
        })(),
      };
    }
    default:
      return state;
  }
}
