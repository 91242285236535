import { ButtonProps } from "@rocket/ui/Button/Button";
import { SquareButton } from "@rocket/ui";
import { clsx } from "clsx";

export default function KeyButton(props: ButtonProps) {
  return (
    <SquareButton
      {...props}
      color="grey"
      className={clsx(
        "border-2 text-xl font-semibold dark:border-text1 dark:text-text1",
        props.disabled ? "border-brand-light text-brand-light" : "border-brand text-brand",
        props.className,
      )}
    >
      {props.children}
    </SquareButton>
  );
}
