import Body from "./Body";
import Complete from "./Complete";
import Container from "./Container";
import Header from "./Header";
import Position from "./Position";

const RateableTestUI = {
  Header,
  Container,
  Position,
  Body,
  Complete,
};

export default RateableTestUI;
