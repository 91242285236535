import InstructionsHotkeys from "../../RateableTestUI/includes/InstructionsHotkeys";
import { RateableTestTypeIds } from "../../../../utils/constants";

export default function Instructions() {
  return (
    <div className="mx-2 mb-2 sm:mx-8 sm:mb-8">
      <InstructionsHotkeys rateableTestTypeId={RateableTestTypeIds.HEAR_IT} play record reveal ratings icon />
    </div>
  );
}
