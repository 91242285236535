import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { getNewTextValue } from "../utils";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import writeItTones, { WriteItTone } from "../utils/tones";

import { FiChevronDown } from "react-icons/fi";
import KeyButton from "./KeyButton";
import { KeyboardState } from "../WriteIt";
import Phrasebox from "../../../Phrasebox";
import { RateableTestUIContext } from "../../RateableTestUI/includes/context";
import type { SetStateFunction } from "@rocket/types";
import { clsx } from "clsx";
import useActiveCourse from "../../../../hooks/useActiveCourse";
import usePhraseTest from "../../../../hooks/usePhraseTest/usePhraseTest";
import useTranslation from "../../../../hooks/useTranslation";
import { RatingButton } from "../../../Phrasebox/includes/RatingButton";
import { WriteItTextDiff } from "../../../../ui/TextDiff";
import { isRTL } from "../../../../utils";
import PhraseString from "../../../Phrasebox/includes/PhraseString";

interface ToneKeyboardProps {
  phraseTest: ReturnType<typeof usePhraseTest>;
  state: KeyboardState;
  setState: SetStateFunction<KeyboardState>;
  writingSystemId: number;
  textRef: React.RefObject<HTMLTextAreaElement>;
}

function ToneKeyboard({ textRef, phraseTest, state, setState, writingSystemId }: ToneKeyboardProps) {
  const t = useTranslation();
  const activeCourse = useActiveCourse();
  const [upperCase, setUpperCase] = useState(false);
  const uiContext = useContext(RateableTestUIContext);

  const didReveal = phraseTest.state.revealed.has(phraseTest.state.index);
  const currentPhrase = phraseTest.components.testPhrases[phraseTest.state.index];

  useEffect(
    // Occurs after clicking on a WriteItToneButton
    function refocusTextInput() {
      if (textRef.current && state.selectionStart > 0) {
        textRef.current.focus();
        setTimeout(() => {
          textRef.current?.setSelectionRange(state.selectionStart, state.selectionStart);
        }, 0);
      }
    },
    [state.selectionStart, state.textInput, textRef],
  );

  const tone = (() => {
    if (
      (activeCourse?.name && writeItTones[activeCourse?.name]) ||
      (activeCourse?.slug === "chinese" && activeCourse?.name)
    ) {
      if (activeCourse?.slug === "chinese") {
        return writeItTones["Pinyin"];
      }
      return writeItTones[activeCourse?.name];
    }
    return null;
  })();

  useLayoutEffect(() => {
    if (!didReveal) {
      setToneVisibility((tone ? Object.keys(tone)[0] : "") || "");
      setTimeout(() => {
        textRef.current?.focus();
      }, 50);
    }
    if (didReveal) {
      setToneVisibility("");
    }
  }, [didReveal, textRef, tone]);

  const [toneVisibility, setToneVisibility] = useState<string>((tone ? Object.keys(tone)[0] : "") || "");

  const isCollapsible = activeCourse?.slug === "chinese";

  const renderedTones = (() => {
    if (!tone) {
      return [];
    }
    return Object.keys(tone).map((vowel, i) => {
      const WriteItButton = isCollapsible ? WriteItToneButtonCollapsible : WriteItToneButton;
      return (
        <WriteItButton
          tone={tone}
          vowel={vowel}
          upperCase={upperCase}
          onClick={(char: string) => {
            // Don't update text if revealed
            if (didReveal) {
              return;
            }
            setState((currentState) => {
              const { value, selectionStart } = getNewTextValue({
                textRef,
                textInput: currentState.textInput,
                value: char,
              });
              return {
                ...currentState,
                textInput: value,
                selectionStart,
              };
            });
          }}
          toneVisibility={toneVisibility}
          setToneVisibility={setToneVisibility}
          key={vowel}
          disabled={didReveal}
          vowelOrder={i}
        />
      );
    });
  })();

  const textDiffElement = (() => {
    if (!state.submitted) {
      return undefined;
    }

    if (state.percent !== 100) {
      return (
        <span className={`font-serif ws-${writingSystemId}`}>
          <WriteItTextDiff writingDiff={state.writingDiff} isRTL={isRTL(currentPhrase?.course_id)} />
        </span>
      );
    }
    const phraseString = currentPhrase?.strings.find((ps) => ps.writing_system_id === writingSystemId);

    if (!phraseString) {
      return undefined;
    }

    return <PhraseString phraseString={phraseString} options={{ className: "!text-rocketgreen" }} />;
  })();

  return (
    <>
      <div className="space-between flex">
        {currentPhrase ? (
          <>
            <div className="w-full">
              <Phrasebox
                key={`${currentPhrase.id}.${didReveal}`}
                playOnMount={!uiContext?.instructionsVisible}
                getPhraseStringOptions={() => ({
                  hideLiteralStringNotation: !didReveal,
                })}
                hidePhrases={!didReveal}
                onRecordButtonClick={() => {
                  setState((s) => ({
                    ...s,
                    showDiffAndRating: false,
                  }));
                }}
                recordButtonHidden={!didReveal}
                phrase={currentPhrase}
                Diff={state.showDiffAndRating && textDiffElement}
                RatingButton={
                  state.showDiffAndRating && state.submitted && state.percent > 0 ? (
                    <RatingButton percentage={state.percent} />
                  ) : null
                }
              />
            </div>
          </>
        ) : null}
      </div>
      <textarea
        className="mt-4 min-h-[115px] w-full resize-y rounded-md border border-rocketgreen-alt p-4 font-serif text-lg text-text1 dark:bg-neutral-900 dark:focus:outline-none dark:focus:ring-2"
        ref={textRef}
        name="Write It Textbox"
        placeholder={(() => {
          if (activeCourse?.slug === "chinese") {
            return `Write it in Pinyin here`;
          }
          return `${t("write-it-in-{language}-name-here", { language: activeCourse?.name || "" })}`;
        })()}
        value={state.textInput}
        onKeyDown={(e) => {
          if (e.key !== "Enter") {
            e.nativeEvent.stopImmediatePropagation();
          }
        }}
        onChange={(e) => {
          const { value } = e.target;
          setState((prevState) => ({ ...prevState, selectionStart: 0, textInput: value }));
        }}
        disabled={state.submitted}
        spellCheck={false}
      />
      {!state.submitted && (
        <>
          <span className={clsx("my-4 block w-full text-center text-sm font-medium dark:text-white")}>
            Use these special characters if required.
          </span>
          <div
            className={clsx("mb-2 flex items-center justify-center whitespace-nowrap", !isCollapsible && "flex-wrap")}
          >
            {activeCourse?.slug === "chinese" && <p className="mb-2 mr-2 hidden sm:block">Add Tones</p>}
            {renderedTones}
            {renderedTones.length > 0 ? (
              <KeyButton
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.nativeEvent.stopImmediatePropagation();
                  }
                }}
                onClick={(e) => {
                  e.preventDefault();
                  if (textRef.current) {
                    textRef.current.focus();
                  }
                  setUpperCase(!upperCase);
                }}
                disabled={didReveal}
                className="mx-1 mb-2"
              >
                {!upperCase ? <MdArrowDropUp size={24} /> : <MdArrowDropDown size={24} />}
              </KeyButton>
            ) : null}
          </div>
        </>
      )}
      {/** Because writeit buttons are absolutely positioned, not adding a height will overflow */}
      {activeCourse?.slug === "chinese" && !didReveal ? <div className="h-12"></div> : null}
    </>
  );
}

interface ToneButtonProps {
  tone: WriteItTone;
  vowel: string;
  upperCase: boolean;
  onClick: (key: string) => void;
  toneVisibility: string;
  setToneVisibility: (arg: string) => void;
  disabled?: boolean;
  vowelOrder?: number;
  active?: boolean;
}

function WriteItToneButton({ tone, vowel, upperCase, onClick, disabled, active }: ToneButtonProps) {
  // @ts-ignore
  const toneArr = upperCase ? tone[vowel].upperCase : tone[vowel].lowerCase;

  return (
    <div className="mb-2 flex justify-center self-center" key={vowel}>
      {toneArr.map((_tone: any) => (
        <KeyButton
          key={_tone}
          disabled={disabled}
          onClick={() => onClick(_tone)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.nativeEvent.stopImmediatePropagation();
            }
          }}
          active={active}
          className="mx-1"
        >
          {_tone}
        </KeyButton>
      ))}
    </div>
  );
}

function WriteItToneButtonCollapsible({
  tone,
  vowel,
  upperCase,
  onClick,
  toneVisibility,
  setToneVisibility,
  disabled,
  vowelOrder,
}: ToneButtonProps) {
  return (
    <div className="relative mb-2 flex flex-col justify-center self-center" key={vowel}>
      <div className="relative">
        <KeyButton
          onClick={() => {
            if (toneVisibility === vowel) {
              setToneVisibility("");
            } else {
              setToneVisibility(vowel);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.nativeEvent.stopImmediatePropagation();
            }
          }}
          className="mx-1"
          active={toneVisibility === vowel}
          disabled={disabled}
        >
          {upperCase ? vowel.toUpperCase() : vowel}
        </KeyButton>
        <span className="pointer-events-none absolute top-[32px] flex w-full justify-center">
          {toneVisibility === vowel && <FiChevronDown className="!h-[12px] !w-[12px] text-brand dark:text-text1" />}
        </span>
      </div>
      <div
        className={clsx("absolute bottom-0 right-0 top-[80px] mt-1 flex", !(toneVisibility === vowel) && "hidden")}
        style={{
          left: `${vowelOrder ? -(100 * vowelOrder - 50) : 50}%`,
        }}
      >
        <WriteItToneButton
          tone={tone}
          vowel={vowel}
          upperCase={upperCase}
          onClick={onClick}
          toneVisibility={toneVisibility}
          setToneVisibility={setToneVisibility}
          key={vowel}
          disabled={disabled}
          active={true}
        />
      </div>
    </div>
  );
}

export default ToneKeyboard;
