import { AudioComponent, LessonComponent, SetStateFunction } from "@rocket/types";
import { FaPause, FaPlay } from "react-icons/fa";
import { FiDownload, FiSearch, FiX } from "react-icons/fi";
import { GrBackTen, GrForwardTen } from "react-icons/gr";
import { IconButton, IconOutlineButton } from "@rocket/ui/Button";
import { useContext, useEffect, useRef, useState } from "react";

import AudioContext from "../../../ui/Audio/AudioContext";
import LessonContext from "../../../context/LessonContext";
import { PlaybackSpeedDialog } from "@rocket/ui";
import SearchResultsOverlay from "./includes/SearchResultsOverlay";
import Slider from "./includes/Slider";
import TranscriptList from "./includes/TranscriptList";
import classes from "./InteractiveAudioComponent.module.scss";
import { clsx } from "clsx";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import useActiveCourse from "../../../hooks/useActiveCourse";
import { useGlobalTranscriptRocketRecordState } from "./includes/state";
import useMedia from "../../../hooks/useMedia";
import useObserver from "../../../hooks/useObserver";
import { usePlayerCounter } from "./includes/usePlayerCounter";
import { useSharedSelector } from "../../../store";
import useSubtitles from "./includes/useSubtitles";
import useTranslation from "../../../hooks/useTranslation";

dayjs.extend(duration);

/** Retrieved from the DB */
const AudioTypeId = {
  IAC: 1,
  REVIEW: 2,
  ROLE_PLAY: 3,
  CONVERSATION: 4,
};

export type Props = {
  lessonComponent: LessonComponent<"audio", AudioComponent>;
};

function InteractiveAudioComponent(props: Props) {
  const { component } = props.lessonComponent;
  const lesson = useContext(LessonContext);
  const activeProduct = useSharedSelector((store) => store.preferences.activeProduct);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { contentVisibility } = useObserver(containerRef);
  const context = useContext(AudioContext);
  const player = context.players[component.id];
  const [query, setQuery] = useState("");
  const audioTitle = useAudioTitle(props.lessonComponent);

  const isTravelogue = activeProduct?.level_id === 4;

  useEffect(() => {
    if (activeProduct) {
      context.setProductId(activeProduct.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProduct]);

  useEffect(() => {
    if (lesson) {
      context.registerComponent({
        component: props.lessonComponent.component,
        lessonId: lesson.id,
        title: `${lesson.number} ${audioTitle}`,
        lessonTitle: `${lesson.number} ${lesson.title}`,
        isVisible: contentVisibility,
      });
      return () => {
        context.unregisterComponent(props.lessonComponent.component.id);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentVisibility, lesson, props.lessonComponent.component, audioTitle]);

  const hasTranscript = !!component.audio_transcript;

  return (
    <>
      {isTravelogue && props.lessonComponent.title_show && <h3 className="mb-2">{props.lessonComponent.title_text}</h3>}
      <div className="relative w-full rounded-md bg-brand text-white print:hidden" ref={containerRef}>
        {hasTranscript && (
          <TranscriptListContainer
            player={player}
            component={component}
            query={query}
            clearSearch={() => setQuery("")}
          />
        )}
        <PlayerControls player={player} component={component} query={query} setQuery={setQuery} />
      </div>
    </>
  );
}

const useAudioTitle = (lessonComponent: LessonComponent<"audio", AudioComponent>) => {
  const t = useTranslation();
  const lesson = useContext(LessonContext);
  return (() => {
    const { title_text, title_show, component } = lessonComponent;
    if (title_text && title_show) {
      return title_text;
    }
    const isReviewOrConversations = lesson.title === "All Conversations" || lesson.title === "All Reviews";
    // TODO: check this logic??
    if (lesson.isTravelogue || isReviewOrConversations) {
      return title_text || "";
    }

    const isIAC = component.audio_type_id === AudioTypeId.IAC;

    if (isIAC) {
      return lesson.title;
    }

    switch (component.audio_type_id) {
      case AudioTypeId.ROLE_PLAY: {
        const { character } = component;
        if (typeof character === "object" && character !== null && typeof character.name === "string") {
          return `Role Play - ${character.name}`;
        }
        return "Role Play";
      }
      case AudioTypeId.CONVERSATION:
        return t("the-conversation");
      case AudioTypeId.REVIEW:
        return t("review");
    }

    return "";
  })();
};

interface HeaderSearchProps {
  query: string;
  onChange(input: string): void;
}

function HeaderSearch({ query, onChange }: HeaderSearchProps) {
  const [searchIsOpen, setSearchIsOpen] = useState(false);

  const blur = () => {
    onChange("");
    setSearchIsOpen(false);
  };

  return (
    <div className="invisible relative sm:visible">
      {searchIsOpen ? (
        <div>
          <input
            autoFocus
            placeholder="Search subtitles"
            value={query}
            onChange={(e) => onChange(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                blur();
              }
            }}
            className="h-[48px] w-full rounded-full border-2 border-white bg-transparent px-6 outline-none"
          />
          <div className="absolute right-0 top-0 z-10">
            <IconOutlineButton aria-label="Close" color="white" onClick={blur} title="Close Search">
              <FiX />
            </IconOutlineButton>
          </div>
        </div>
      ) : (
        <IconOutlineButton
          aria-label="Search"
          onClick={() => {
            onChange("");
            setSearchIsOpen(true);
          }}
          color="white"
          title="Search"
        >
          <FiSearch />
        </IconOutlineButton>
      )}
    </div>
  );
}

interface TranscriptListContainerProps {
  player: ReturnType<typeof useMedia> | undefined;
  component: AudioComponent;
  query: string;
  clearSearch: () => void;
}

/** Parses the subtitles and renders the list */
function TranscriptListContainer({ player, component, query, clearSearch }: TranscriptListContainerProps) {
  const isHindi = useActiveCourse()?.slug === "hindi";
  const subtitles = useSubtitles(player?.trackRef);

  return (
    <div className="rounded-t-md border-2 border-b-0 border-l-slate-300 border-r-slate-300 border-t-slate-300 bg-white pt-2 text-text2 dark:border-0 dark:bg-neutral-800">
      <div className={clsx("relative h-[200px] overflow-hidden", isHindi && "font-serif")}>
        {player && (
          <>
            {component.audio_transcript && !!subtitles.length && query && (
              <SearchResultsOverlay
                subtitles={subtitles}
                query={query}
                height={200}
                player={player}
                clearSearch={clearSearch}
              />
            )}
            <TranscriptList subtitles={subtitles} height={200} component={component} player={player} />
          </>
        )}
      </div>
    </div>
  );
}

interface PlayerProps {
  player?: ReturnType<typeof useMedia>;
  component: AudioComponent;
  query: string;
  setQuery: SetStateFunction<string>;
}

function PlayerControls({ player, component, query, setQuery }: PlayerProps) {
  const context = useContext(AudioContext);
  const isPlaying = player?.isPlaying;
  const isRecording = useGlobalTranscriptRocketRecordState((s) => s.isRecording());
  const [counter, setCounter] = usePlayerCounter(player);
  const [playbackSpeedVisible, setPlaybackSpeedVisible] = useState(false);

  useEffect(() => {
    if (counter === 0 && player) {
      player?.seekToNextCue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  const renderPlayPauseButton = () => {
    if (counter > 0) {
      return (
        <IconButton size="large" color="error" onClick={() => setCounter(-1)} title="Pause" active>
          <span style={{ fontSize: "2rem", fontWeight: "bold" }}>{counter}</span>
        </IconButton>
      );
    }
    if (isPlaying) {
      return (
        <IconButton size="large" color="error" onClick={player?.pause} title="Pause" active>
          <FaPause />
        </IconButton>
      );
    }
    return (
      <IconButton
        size="large"
        color="error"
        onClick={() => context.play(component.id)}
        title="Play"
        disabled={!player || isRecording}
      >
        <FaPlay className="ml-[5px]" />
      </IconButton>
    );
  };

  // const voiceRecognitionEnabled = player?.phraseMode === "automatic";

  return (
    <div className="px-4 py-4 md:px-8">
      <div className="grid gap-2" style={{ gridTemplateColumns: "1fr auto 1fr" }}>
        <div className="flex items-center justify-start">
          <div className="relative mr-2">
            <IconOutlineButton
              aria-label={`${player?.state?.trackSpeed} times track speed`}
              color="white"
              // disabled={player?.phraseMode === "automatic"}
              onClick={() => setPlaybackSpeedVisible((s) => !s)}
              title="Adjust speed"
            >
              {player?.state?.trackSpeed}x
            </IconOutlineButton>
            {playbackSpeedVisible && (
              <PlaybackSpeedDialog
                value={player?.state.trackSpeed || 0}
                onClose={(value) => {
                  if (value) {
                    player?.speed(value);
                  }
                  setPlaybackSpeedVisible(false);
                }}
              />
            )}
          </div>
          {!!component.can_download && (
            <div className="mr-2">
              <IconOutlineButton
                use="a"
                color="white"
                active
                // @ts-ignore
                download={component.url.split("/").pop() || "file.mp3"}
                href={component.url}
                title="Download this track"
              >
                <FiDownload />
              </IconOutlineButton>
            </div>
          )}
          {/*player?.phraseMode && (
            <IconOutlineButton
              color="white"
              role="checkbox"
              aria-checked={voiceRecognitionEnabled}
              active={voiceRecognitionEnabled}
              onClick={() => {
                player?.speed(1);
                player?.setPhraseMode(voiceRecognitionEnabled ? "manual" : "automatic");
              }}
              title="Toggle voice recognition on/off"
              className={clsx(player?.phraseMode === "automatic" && "bg-white hover:bg-white")}
            >
              <FiMic className={clsx(player?.phraseMode === "automatic" && "text-brand")} />
            </IconOutlineButton>
            )*/}
        </div>
        <div className="flex items-center justify-center">{renderPlayPauseButton()}</div>
        <div className="flex items-center justify-end">
          <div className="ml-2">
            <HeaderSearch query={query} onChange={setQuery} />
          </div>
        </div>
      </div>
      <div className="mt-2 flex items-center justify-between">
        <IconButton
          color="transparent"
          aria-label="Jump back"
          onClick={() => {
            player?.skip("back");
          }}
          disabled={isRecording}
        >
          <GrBackTen className={classes.forceSVGWhite} />
        </IconButton>
        <div className="flex h-full w-full flex-col items-center justify-center">
          {player && (
            <Slider
              player={player}
              disabled={isRecording}
              syncProgress={!context.activeTrack || context.activeTrack?.isVisible}
            />
          )}
        </div>
        <IconButton
          color="transparent"
          aria-label="Jump forward"
          onClick={() => {
            player?.skip("ahead");
          }}
        >
          <GrForwardTen className={classes.forceSVGWhite} />
        </IconButton>
      </div>
    </div>
  );
}

export default InteractiveAudioComponent;
