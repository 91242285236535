import type { UserComponentRatingEntity } from "@rocket/types";
import { SharedRootState } from "../../store/types";

/** Round up to nearest 5 then add a random seed */
export const randomize = (l: number) => Math.ceil(l / 5) * 5 * 1000 + (Math.random() * (100 - 1) + 1);

// Maps labels to their respective rating values
export const LabelRatingValues = {
  hard: 20,
  good: 80,
  easy: 100,
};

export const ComponentTypeIds = {
  phrase: 1,
  custom_phrase: 3,
  custom_flashcard: 12,
  video: 5,
  script_writer: 4,
};

/**
 * Updates ratings for a specific rateable test.
 * Component ID can be a Phrase ID or Translate It component.
 */
export function getUpdatedUserComponentRatings(props: {
  store: SharedRootState;
  componentId: number;
  componentType: keyof typeof ComponentTypeIds;
  rateableTestId: number;
  rating: number;
}): UserComponentRatingEntity[] {
  const { store, componentId, componentType, rateableTestId, rating } = props;
  // Find component
  const componentRatings = store.lesson.entities.user_rateable_test_component_ratings[rateableTestId];

  // No record of component ratings for this test
  const updatedComponentRatings = (() => {
    if (!componentRatings) {
      return [
        {
          component_id: componentId,
          component_type_id: ComponentTypeIds[componentType],
          value: rating,
        },
      ];
    }
    const componentIndex = componentRatings.findIndex((c) => c.component_id === componentId);
    const newRating = {
      component_id: componentId,
      component_type_id: ComponentTypeIds[componentType],
      value: rating,
    };

    // No record of component rating, add to array
    if (componentIndex === -1) {
      return [...componentRatings, newRating];
    }
    // Update component rating
    return [...componentRatings.slice(0, componentIndex), newRating, ...componentRatings.slice(componentIndex + 1)];
  })();

  return updatedComponentRatings;
}

/**
 * Returns the 0-100 value representation of the rating level
 */
export function getRatingValueFromLabel(ratingLabel: "hard" | "good" | "easy"): number {
  // Maps labels to their respective rating values
  const LabelRatingValues = {
    hard: 20,
    good: 80,
    easy: 100,
  };
  return LabelRatingValues[ratingLabel] || 0;
}
