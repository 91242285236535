import { SharedRootAction } from "../types";
import type { FeatureImageState } from "./types";
import * as userConstants from "../user/constants";
import * as dashboardConstants from "../dashboard/constants";

const initialState: FeatureImageState = {
  dataset: {},
};

export default function FeatureImageReducer(
  state: FeatureImageState = initialState,
  action: SharedRootAction,
): FeatureImageState {
  switch (action.type) {
    case userConstants.SYNC_USER_AND_PRODUCT: {
      return {
        ...state,
        dataset: {
          ...state.dataset,
          [action.payload.productId]: action.payload.payload.feature_image,
        },
      };
    }
    case dashboardConstants.DASHBOARD_REQUEST_SUCCESS: {
      return {
        ...state,
        dataset: {
          ...state.dataset,
          [action.payload.productId]: action.payload.payload.feature_image,
        },
      };
    }
    default:
      return state;
  }
}
