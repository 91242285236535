import { reservedRegexSyntax, tryCatchFn } from "../utils";
import { useContext, useMemo } from "react";

import type { MarkdownToJSX } from "markdown-to-jsx";
import { PhraseStringOptions } from "../components/Phrasebox/includes/PhraseString";
import { VocabContext } from "../components/Lesson/Vocab/VocabProvider";
import { VocabMap } from "@rocket/types";
import VocabTooltip from "../components/Lesson/Vocab/VocabTooltip";
import { WritingSystemIds as WS } from "../utils/constants";
import memoize from "fast-memoize";
import { isSafari } from "../utils/browser";
import { htmlRegex } from "../utils/stringUtils";

type MarkdownOptions = MarkdownToJSX.Options;

const partialHighlights = {
  [WS.hanzi]: true,
  [WS.kanji]: true,
  [WS.korean]: true,
};

const getVocabRegex = memoize((vocabMap: VocabMap, writingSystemId: number): RegExp => {
  const vocabKeys = Object.keys(vocabMap);

  if (isSafari || partialHighlights[writingSystemId]) {
    // Note: Safari doesn't support partial lookbehind,
    // so instead we're using the same regex for partial highlights
    const str = vocabKeys.join("|").replace(reservedRegexSyntax, "\\$1");
    return new RegExp(`(${str})`, "gi");
  }

  const regexString = vocabKeys
    //eslint-disable-next-line
    .map((item) => `((?<=^|[\s.,“"!? _])${item.replace(reservedRegexSyntax, "\\$1")}(?=[\s.”",!? _]|$))`)
    .join("|");

  return new RegExp(`(${regexString})`, "gi");
});

// eslint-disable-next-line no-irregular-whitespace
// const NO_BREAK_SPACE = " ";

export default function useParseVocab(
  inputText: string,
  writing_system_id?: number,
  options?: {
    color?: string;
    phraseStringOptions?: PhraseStringOptions;
  },
) {
  const vocab = useContext(VocabContext);

  const [text, markdownOptions] = useMemo((): [string, MarkdownOptions] => {
    let baseText = inputText;

    if (
      options?.phraseStringOptions?.disableVocabUnderline ||
      options?.color ||
      Object.keys(vocab.map).length === 0 ||
      // If the input text contains HTML, we don't want to parse it
      // Because it will start literally outputting the HTML tags
      // See: https://app.clickup.com/t/38tu51c
      htmlRegex.test(baseText)
    ) {
      return [baseText, { forceInline: true }];
    }
    const vocabMap = vocab.map;
    const [err, wordsRegex] = tryCatchFn(() => getVocabRegex(vocabMap, writing_system_id || 0));

    if (err || !wordsRegex) {
      console.warn(err, wordsRegex);
      return [baseText, { forceInline: true }];
    }

    let matchNumber = 0;
    const matches: RegExpExecArray | null = null;

    const vocabElements: MarkdownOptions["overrides"] = {};

    baseText = baseText.replace(wordsRegex, (matchText) => {
      const vocab = (() => {
        const lowerCase = matchText.toLowerCase();
        if (vocabMap[lowerCase]) {
          return vocabMap[lowerCase];
        }
        // Word matches can include punctuation on the left or right hand side of the selected string
        if (vocabMap[lowerCase.slice(1)]) {
          return vocabMap[lowerCase.slice(1)];
        }
        if (vocabMap[lowerCase.slice(0, lowerCase.length - 1)]) {
          return vocabMap[lowerCase.slice(0, lowerCase.length - 1)];
        }
        if (vocabMap[lowerCase.slice(1, lowerCase.length - 1)]) {
          return vocabMap[lowerCase.slice(1, lowerCase.length - 1)];
        }
        return undefined;
      })();

      if (vocab) {
        const ComponentName = `Vocab${matchNumber}`;
        vocabElements[ComponentName] = () => VocabTooltip({ vocab, children: matchText });
        // <VocabTooltip vocab={vocab}>{matchText}</VocabTooltip>;
        matchNumber += 1;
        return `&zwsp;<${ComponentName} />`;
      } else {
        console.warn("Vocab did not match?", matches, matchText, vocabMap);
        return matchText;
      }
    });

    // Replaces regular spaces with "NO-BREAK SPACE" so that "<Vocab0> <Vocab1>" displays correctly
    // const formattedText = !partial ? baseText.replace(spaceRegex, NO_BREAK_SPACE) : baseText;

    return [
      baseText,
      {
        forceInline: true,
        namedCodesToUnicode: { zwsp: "\u200B" },
        overrides: vocabElements,
      },
    ];
  }, [inputText, writing_system_id, options, vocab]);

  return { text, markdownOptions };
}
