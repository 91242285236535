import { RefObject, useLayoutEffect, useState } from "react";

/** Fetches the cues from a DOM element */
const useSubtitles = (trackRef: RefObject<HTMLTrackElement | null> | undefined) => {
  const [cues, setCues] = useState<TextTrackCue[]>([]);

  useLayoutEffect(() => {
    console.log("[useSubtitles] running");
    // Wait for the track element to render to the DOM
    if (!trackRef?.current || cues.length > 0) {
      return;
    }

    const trackElement = trackRef.current;

    if (trackElement.track.cues && trackElement.track.cues.length > 0) {
      setCues(Array.from(trackElement.track.cues) as TextTrackCue[]);
      return;
    }

    // Wait for track cues to load
    const trackCueLoadInterval = setInterval(() => {
      // Note: track cues may have not rendered yet, so we need to periodically check
      if (trackElement.track.cues && trackElement.track.cues.length > 0) {
        setCues(Array.from(trackElement.track.cues) as TextTrackCue[]);
        clearInterval(trackCueLoadInterval);
      }
    }, 100);

    // Also register event listeners to check for cues when they are added
    const loadCues = (e: Event) => {
      clearInterval(trackCueLoadInterval);
      // @ts-ignore
      setCues(Array.from(e.target?.track?.cues) as TextTrackCue[]);
    };
    trackElement.addEventListener("load", loadCues);
    trackElement.addEventListener("cuechange", loadCues);

    return () => {
      clearInterval(trackCueLoadInterval);
      trackElement.removeEventListener("load", loadCues);
      trackElement.removeEventListener("cuechange", loadCues);
    };
  }, [cues.length, trackRef]);

  return cues;
};

export default useSubtitles;
