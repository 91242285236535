import { useState } from "react";
import type { Vocab } from "@rocket/types";
import { Tooltip, Highlight } from "@rocket/ui";
import AddToVocabModal from "./VocabModals/AddToVocabModal";
import useTranslation from "../../../hooks/useTranslation";

export default function VocabTooltip(props: { children: string; vocab: Vocab }) {
  const t = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Tooltip width={150} label={<em>{props.vocab?.translation || t("no-translation")}</em>}>
        <Highlight onClick={() => setModalOpen(true)}>{props.children}</Highlight>
      </Tooltip>
      {modalOpen && (
        <AddToVocabModal
          mode={{
            val: "edit",
            vocab: props.vocab,
          }}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
}
