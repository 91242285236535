import { FaRegQuestionCircle } from "react-icons/fa";
import classes from "./TooltipButton.module.scss";
import Tooltip, { TooltipProps } from "../Tooltip/Tooltip";
import { ReactNode } from "react";

interface TooltipButtonProps {
  label: ReactNode | (() => JSX.Element);
  tooltipProps?: Omit<TooltipProps, "children" | "label">;
  size?: number;
  color?: string;
  onClick?: () => void;
}

export default function TooltipButton({ label, tooltipProps, size, onClick, color }: TooltipButtonProps) {
  return (
    <Tooltip {...tooltipProps} label={label}>
      {onClick ? (
        <button
          className={classes.instructionsTooltipContainer}
          onClick={onClick}
          onKeyDown={(ev) => {
            // enter or space
            if (ev.key === "Enter" || ev.key === " ") {
              onClick();
            }
          }}
        >
          <FaRegQuestionCircle size={size || 16} color={color} />
        </button>
      ) : (
        <span className={classes.instructionsTooltipContainer}>
          <FaRegQuestionCircle size={size || 16} color={color} />
        </span>
      )}
    </Tooltip>
  );
}
