import Markdown, { MarkdownToJSX } from "markdown-to-jsx";

import { useMemo } from "react";
import { replaceNewLinesBetweenLists, replaceSpacesBetweenSpanTags } from "../../../../utils/stringUtils";
import { defaultOptions } from "./options";

type RocketMarkdownProps = {
  children: string;
  className?: string;
  style?: string;
  options?: MarkdownToJSX.Options;
};

/** Includes h1/h2/thead overrides and some formatting fixes */
export default function RocketMarkdown({ children, options = defaultOptions, ...rest }: RocketMarkdownProps) {
  const parsedContent = useMemo(() => {
    const spanTagsHandled = replaceSpacesBetweenSpanTags(children || "");
    const listsHanded = replaceNewLinesBetweenLists(spanTagsHandled);
    return listsHanded;
  }, [children]);

  return (
    <Markdown {...rest} options={options}>
      {parsedContent}
    </Markdown>
  );
}
