import { FiChevronDown } from "react-icons/fi";
import type { InputHTMLAttributes } from "react";
import { clsx } from "clsx";

interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  options: Array<{ label: string; value: string | number }>;
  /** Disable first item to show it is a placeholder */
  firstIsPlaceholder?: boolean;
  /** Expand to available space */
  fullWidth?: boolean;
}

export default function Select(props: SelectProps) {
  const { options, firstIsPlaceholder, className, fullWidth, ...rest } = props;
  return (
    <div className={clsx("relative flex h-min", fullWidth && "w-full")}>
      <select
        className={clsx(
          "border-text2 relative h-[48px] w-full min-w-[150px] cursor-pointer appearance-none rounded-md border bg-white px-4 pr-14 font-bold dark:border-neutral-600 dark:bg-neutral-900",
          className,
        )}
        {...rest}
      >
        {options.map((option, i) => (
          <option key={i} value={option.value} disabled={firstIsPlaceholder && i === 0}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute right-0 top-0 flex h-full items-center justify-center p-4">
        <FiChevronDown className="h-[28px] w-[28px] text-rocketgreen-alt" />
      </div>
    </div>
  );
}
