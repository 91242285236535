import type { MouseEvent, ReactNode } from "react";

import { LiteralStringNotationButton } from "../../../Notation";
import type { PhraseString } from "@rocket/types";
import PhraseStringNotationButtons from "../../../Phrasebox/includes/PhraseStringNotationButtons";
import { clsx } from "clsx";
import styles from "./Flashcard.module.scss";

interface FlashcardProps {
  flipped: boolean;
  children: [ReactNode, ReactNode];
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  notationPhraseString?: PhraseString;
  literalString?: string | null;
  literalStringPlacement?: "front" | "back";
}

export default function Flashcard(props: FlashcardProps) {
  const { children, onClick, flipped } = props;

  return (
    <div
      onClick={onClick}
      className={clsx(
        "relative flex h-[350px] w-full items-center justify-center overflow-hidden",
        styles.container,
        flipped && styles.flipped,
      )}
    >
      <div
        className={clsx(
          "absolute h-5/6 w-full rounded-md bg-rocketgreen-extra-light text-center dark:bg-slate-700",
          styles.card,
          styles.front,
        )}
      >
        <span className="flex h-full flex-col items-center justify-center p-2">
          {children[0]}
          <div className="absolute right-0 top-0 mr-2 mt-2">
            {props.notationPhraseString && <PhraseStringNotationButtons phraseString={props.notationPhraseString} />}
            {props.literalStringPlacement === "front" && props.literalString && (
              <LiteralStringNotationButton literalString={props.literalString} />
            )}
          </div>
        </span>
      </div>
      <div
        className={clsx(
          "absolute h-5/6 w-full rounded-md bg-rocketorange-light text-center dark:bg-gray-900",
          styles.card,
          styles.back,
        )}
      >
        <span className="flex h-full flex-col items-center justify-center p-2">
          {children[1]}
          <div className="absolute right-0 top-0 mr-2 mt-2">
            {props.literalStringPlacement === "back" && props.literalString && (
              <LiteralStringNotationButton literalString={props.literalString} />
            )}
          </div>
        </span>
      </div>
    </div>
  );
}
