import type { APIRefreshOptions, APIResource } from "@rocket/types";
import { ActionType, action } from "typesafe-actions";
import Cache, { useCacheKeyListener, useCacheListener } from "../res/Cache";
import { Reducer, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";

import useGetter from "./useGetter";
import useSafeFn from "./useSafeFn";
import { getLaravelValidationError } from "../utils";

export type State<T> = APIResource<T> | undefined;

export const PromiseCache = new Cache();
/** Across multiple components, make sure that we're only fetching once if a cache key is provided */
const PendingCache = new Cache<Promise<any>>(); // new Map<string, Promise<any>>();

type usePromiseOptions = {
  /** Default: true. Whether the promise will execute immediately. Else, call execute */
  immediate?: boolean;
  /** Request only once */
  once?: boolean;
  /**
   * Used for persistence of the promise return.
   *
   * To update the payload, call `mutate(newState)` either as a return from usePromise(), or `import { mutate } from "usePromise"`;
   */
  cacheKey?: string;
  /** For subsequent promise calls, you may want to display a loading UI while still displaying the current data */
  refresh?: APIRefreshOptions;
  /** Throws the promise so that the loading UI can be handled higher in the UI heirarchy */
  suspense?: boolean;
};

export function usePromise<T>(promise?: () => Promise<T>, options: usePromiseOptions = {}) {
  const { cacheKey = "", immediate = true, once = false } = options;
  const [state, _dispatch] = useReducer<Reducer<State<T>, any>>(reducer, undefined);
  const getOptions = useGetter(options);
  const getState = useGetter(state);
  // Since we're working with async effects, we don't want to call dispatch() when the component consuming the hook is unmounted
  const dispatch = useSafeFn(_dispatch);
  const promiseRef = useRef(promise);
  const executingPromiseRef = useRef<Promise<any>>();

  const execute = useCallback(
    (newPromise?: () => Promise<T>, executeOptions?: { refresh?: APIRefreshOptions }) => {
      const promiseOptions = getOptions();
      const refresh = executeOptions?.refresh || promiseOptions.refresh;

      // Check if there's a cache hit to avoid running a new promise
      if (
        promiseOptions.cacheKey &&
        // Request is cached or is pending
        PromiseCache.has(promiseOptions.cacheKey) &&
        // Whether it's force refreshed
        !refresh?.value
      ) {
        return;
      }

      // Other instances of usePromise may be requesting the same data under the same cache key
      // We'd like to only request once and have the other instances wait on the same promise
      if (promiseOptions.cacheKey && PendingCache.has(promiseOptions.cacheKey)) {
        return PendingCache.get(promiseOptions.cacheKey)?.then((response) => {
          dispatch.current(actions.payload({ data: response }));
        });
      }

      const promiseFunction = promise || newPromise;

      // This _shouldn't_ happen
      if (!promiseFunction) {
        return;
      }

      promiseRef.current = promiseFunction;

      // Start executing promise
      if (refresh?.value && getState()?.status === "loaded") {
        dispatch.current(actions.refreshStart(refresh));
      } else {
        dispatch.current(actions.start());
      }

      const action = promiseFunction()
        .then((response) => {
          // Make sure that we aren't dispatching stale payloads when there's new promises running
          if (promiseRef.current === promiseFunction) {
            dispatch.current(actions.payload({ data: response, cacheKey: promiseOptions.cacheKey }));
          }
          // Used by other instances of usePromise waiting on this promise
          return response;
        })
        .catch((err) => {
          if (promiseRef.current === promiseFunction) {
            dispatch.current(
              actions.error(
                getLaravelValidationError(err) || err.error || err.message,
                err.response?.status,
                typeof err.response?.data === "object" ? err.response?.data?.code : undefined,
              ),
            );
          }
        })
        .finally(() => {
          if (promiseOptions.cacheKey) {
            // PromiseCache should now be storing the result
            PendingCache.delete(promiseOptions.cacheKey);
          }
        });

      // Add promise to pending cache, if exists
      if (promiseOptions.cacheKey) {
        PendingCache.set(promiseOptions.cacheKey, action);
      }

      // (Used for Suspense)
      executingPromiseRef.current = action;

      return action;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [!once ? promise : once, getOptions, getState, dispatch],
  );

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  // Listen for external changes to the cache
  useCacheKeyListener(PromiseCache, cacheKey, () => {
    const data = PromiseCache.get(cacheKey);
    if (!data) {
      execute();
    } else {
      dispatch.current(actions.payload({ data }));
    }
  });

  const data = PromiseCache.get(cacheKey);
  // Note: Make sure that a new object reference isn't returned if the data is the same
  const hasCacheEntry = PromiseCache.has(cacheKey) && (state?.status !== "loaded" || state.data !== data);

  const memoizedCacheItem = useMemo((): State<T> | null => {
    if (hasCacheEntry) {
      return {
        status: "loaded",
        data,
      } as State<T>;
    }
    return null;
  }, [hasCacheEntry, data]);

  const returnState = memoizedCacheItem || state;

  if (options.suspense && returnState?.status === "loading") {
    throw executingPromiseRef.current;
  }

  return {
    execute,
    state: returnState,
    reset: useCallback(() => dispatch.current(actions.reset({ cacheKey })), [cacheKey, dispatch]),
    mutate: useCallback(
      (data: T) => {
        dispatch.current(actions.payload({ data, cacheKey }));
      },
      [cacheKey, dispatch],
    ),
  };
}

// filter by start prefix
const isFetching = (cachePrefix?: string) => {
  if (!cachePrefix) {
    return Boolean(PendingCache.__cache.entries().next().value);
  }
  return Array.from(PendingCache.__cache.keys()).some((key) => {
    return key.startsWith(cachePrefix);
  });
};

export function useIsFetching(cachePrefix?: string) {
  const [state, setState] = useState(isFetching(cachePrefix));
  // Listen for external changes to the cache
  useCacheListener(PendingCache, () => setState(isFetching(cachePrefix)));
  return state;
}
/*
export function usePromiseCache<T>(cacheKey: string): T | undefined {
  const [state, setState] = useState<T | undefined>();
  // Listen for external changes to the cache
  useCacheKeyListener(PromiseCache, cacheKey, () => {
    setState(PromiseCache.get(cacheKey));
  });
  return state;
}
*/

export const actions = {
  start() {
    return action("start");
  },
  refreshStart(payload: APIRefreshOptions) {
    return action("refresh-start", payload);
  },
  reset(payload: { cacheKey?: string }) {
    return action("reset", payload);
  },
  payload(data: { data: any; cacheKey?: string }) {
    return action("payload", data);
  },
  error(error: string, statusCode: number, errorCode?: string) {
    return action("error", { error, statusCode, errorCode });
  },
};

export function resetCacheKey(cacheKey: string, notify = true) {
  PromiseCache.delete(cacheKey, notify);
}

export function resetCachePrefix(cacheKeyPrefix: string, notify = true) {
  PromiseCache.deletePrefix(cacheKeyPrefix, notify);
}

export function clearCache() {
  PromiseCache.clear();
}

type UsePromiseAction = ActionType<typeof actions>;

function reducer<T>(state: State<T>, action: UsePromiseAction): State<T> {
  switch (action.type) {
    case "start":
      return { status: "loading" };
    case "refresh-start": {
      if (state?.status !== "loaded") {
        return { status: "loading" };
      }
      return {
        ...state,
        refreshing: action.payload,
        data: state.data,
      };
    }
    case "reset": {
      const { cacheKey } = action.payload;
      if (cacheKey) {
        PromiseCache.delete(cacheKey);
      }
      return undefined;
    }
    case "payload": {
      const { data, cacheKey } = action.payload;
      if (cacheKey) {
        PromiseCache.set(cacheKey, data, false);
      }
      // Short-circuit to prevent hook changes
      if (state?.status === "loaded" && state.data === action.payload.data) {
        return state;
      }
      return { status: "loaded", data };
    }
    case "error":
      return {
        status: "error",
        errorText: action.payload.error,
        errorCode: action.payload.errorCode,
        statusCode: action.payload.statusCode,
      };
    default:
      return state;
  }
}

/** Mutates cache used by usePromise */
export function mutate<T>(cacheKey: string, data: T | ((currentState: T | undefined) => T | undefined)) {
  if (typeof data === "function") {
    // @ts-ignore
    const mutatedData = data(PromiseCache.get(cacheKey));

    if (typeof mutatedData !== "undefined") {
      PromiseCache.set(cacheKey, mutatedData);
    }
  } else {
    PromiseCache.set(cacheKey, data);
  }
}

export function useMutation<T, U>(params: { mutationFn: (params: T) => Promise<U>; options?: usePromiseOptions }) {
  const { mutationFn, options } = params;
  const promise = usePromise<Awaited<ReturnType<typeof mutationFn>>>(undefined, options);

  return {
    ...promise,
    mutate(params: T) {
      return promise.execute(() => mutationFn(params) as Promise<Awaited<U>>);
    },
  };
}

export default usePromise;
