import { keyCodes as defaultKeys, keyboardLocales } from "../utils/keymaps";
import { emulatedBackspace, emulatedDelete, getNewTextValue } from "../utils";
import { MouseEvent, RefObject, memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Checkbox } from "@rocket/ui";
import KeyButton from "./KeyButton";
import { KeyboardState } from "../WriteIt";
import Phrasebox from "../../../Phrasebox";
import { RateableTestUIContext } from "../../RateableTestUI/includes/context";
import { Course, SetStateFunction } from "@rocket/types";
import { clsx } from "clsx";
import useActiveCourse from "../../../../hooks/useActiveCourse";
import usePhraseTest from "../../../../hooks/usePhraseTest/usePhraseTest";
import { RatingButton } from "../../../Phrasebox/includes/RatingButton";
import { BreakpointComponent } from "../../../BreakpointComponent";
import { WriteItTextDiff } from "../../../../ui/TextDiff";
import { isRTL } from "../../../../utils";
import PhraseString from "../../../Phrasebox/includes/PhraseString";

interface Props {
  phraseTest: ReturnType<typeof usePhraseTest>;
  state: KeyboardState;
  setState: SetStateFunction<KeyboardState>;
  writingSystemId: number;
  textRef: React.MutableRefObject<HTMLTextAreaElement | null>;
}

export default function VirtualKeyboard({ textRef, writingSystemId, phraseTest, setState, state }: Props) {
  const activeCourse = useActiveCourse();
  const [casing, setCasing] = useState<"lower" | "upper">("lower");
  const [currentKey, setCurrentKey] = useState("");
  const [virtualDisabled, setVirtualDisabled] = useState(false);
  const uiContext = useContext(RateableTestUIContext);
  const didReveal = phraseTest.state.revealed.has(phraseTest.state.index);
  const currentPhrase = phraseTest.components.testPhrases[phraseTest.state.index];
  const locale: string = activeCourse?.locale ? activeCourse?.locale : "default";

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      element.value = "";
    }
  }, [phraseTest.state.index, textRef]);

  useEffect(() => {
    // Refocus the text input after the answer is revealed
    if (!didReveal) {
      setTimeout(() => {
        textRef.current?.focus();
      }, 50);
    }
  }, [didReveal, textRef]);

  const handleKeyClick = useCallback(
    (ev: MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const languageKey = ev.currentTarget.dataset.languageKey;
      if (!languageKey) {
        return;
      }
      setCurrentKey("");
      if (languageKey === "Shift") {
        setCasing(casing === "lower" ? "upper" : "lower");
        return;
      }
      if (textRef.current) {
        const { selectionStart, value } = getNewTextValue({
          textRef,
          textInput: textRef.current.value,
          value: languageKey,
        });
        textRef.current.value = value;
        textRef.current.setSelectionRange(selectionStart, selectionStart);
        textRef.current?.focus();
      }
    },
    [casing, textRef],
  );

  const textDiffElement = (() => {
    if (!state.submitted) {
      return undefined;
    }

    if (state.percent !== 100) {
      return (
        <span className={`font-serif ws-${writingSystemId}`}>
          <WriteItTextDiff writingDiff={state.writingDiff} isRTL={isRTL(currentPhrase?.course_id)} />
        </span>
      );
    }
    const phraseString = currentPhrase?.strings.find((ps) => ps.writing_system_id === writingSystemId);

    if (!phraseString) {
      return undefined;
    }

    return <PhraseString phraseString={phraseString} options={{ className: "!text-rocketgreen" }} />;
  })();

  return (
    <div className="flex flex-col p-2">
      {currentPhrase && (
        <Phrasebox
          key={`${currentPhrase.id}.${didReveal}`}
          playOnMount={!uiContext?.instructionsVisible}
          getPhraseStringOptions={() => ({
            hideLiteralStringNotation: !didReveal,
          })}
          onRecordButtonClick={() => {
            setState((s) => ({
              ...s,
              showDiffAndRating: false,
            }));
          }}
          recordButtonHidden={!didReveal}
          phrase={currentPhrase}
          Diff={state.showDiffAndRating && textDiffElement}
          RatingButton={
            state.showDiffAndRating && state.submitted && state.percent > 0 ? (
              <RatingButton percentage={state.percent} />
            ) : null
          }
          hidePhrases={!didReveal}
        />
      )}
      <div className="mt-4 flex justify-end">
        <Checkbox
          checked={virtualDisabled}
          onChange={() => {
            setVirtualDisabled(virtualDisabled ? false : true);
          }}
          label="Use native keyboard"
        />
      </div>
      <TextInput
        course={activeCourse}
        disabled={state.submitted}
        casing={casing}
        keyboardOnly={virtualDisabled}
        locale={locale}
        textRef={textRef}
        onKeyDown={setCurrentKey}
        onCasingChange={setCasing}
      />
      <BreakpointComponent mediaQuery="(min-width: 768px)">
        {!state.submitted && !virtualDisabled ? (
          <Keyboard currentKey={currentKey} casing={casing} locale={locale} onKeyClick={handleKeyClick} />
        ) : null}
      </BreakpointComponent>
    </div>
  );
}

const TextInput = memo(function TextInput(props: {
  textRef: React.MutableRefObject<HTMLTextAreaElement | null>;
  disabled?: boolean;
  course: Course | undefined;
  keyboardOnly?: boolean;
  casing: "lower" | "upper";
  locale: string;
  onCasingChange: (casing: "lower" | "upper") => void;
  onKeyDown: (key: string) => void;
}) {
  const { course, disabled, keyboardOnly, locale, casing, onCasingChange, onKeyDown, textRef } = props;

  const keydownHandler = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key !== "Enter") {
        e.nativeEvent.stopImmediatePropagation();
      }

      // Handle casing
      if (e.shiftKey && casing === "lower") {
        onCasingChange("upper");
      } else if (!e.shiftKey && casing === "upper") {
        onCasingChange("lower");
      }

      if (e.ctrlKey || e.key === "Shift") {
        return;
      }

      if (e.key === "CapsLock") {
        if (casing === "lower") {
          onCasingChange("upper");
        } else {
          onCasingChange("lower");
        }
      }

      const keyValue = (() => {
        if (keyboardLocales[locale]?.[casing][e.nativeEvent.code]) {
          return keyboardLocales[locale]?.[casing][e.nativeEvent.code];
        } else if (e.key === "Backspace" || e.key === "Delete" || e.key === " ") {
          return e.key;
        }
        return null;
      })();
      if (keyValue === "Backspace") {
        e.preventDefault();
        updateTextInputValue(
          textRef,
          emulatedBackspace({
            textElement: textRef.current,
            textInput: textRef.current?.value || "",
          }),
        );
      } else if (keyValue === "Delete") {
        e.preventDefault();
        updateTextInputValue(
          textRef,
          emulatedDelete({
            textElement: textRef.current,
            textInput: textRef.current?.value || "",
          }),
        );
      } else if (keyValue) {
        e.preventDefault();
        updateTextInputValue(
          textRef,
          getNewTextValue({
            textRef,
            textInput: textRef.current?.value || "",
            value: keyValue,
          }),
        );
      }

      onKeyDown(e.key);
    },
    [casing, locale, onCasingChange, onKeyDown, textRef],
  );

  const textareaProps: React.TextareaHTMLAttributes<HTMLTextAreaElement> = useMemo(() => {
    const baseObject: React.TextareaHTMLAttributes<HTMLTextAreaElement> = {
      // @ts-ignore
      ref: textRef,
      className:
        "dark:bg-neutral-900 min-h-[115px] w-full resize-y rounded-md border-rocketgreen-alt border p-4 my-4 text-text1 placeholder:text-slate-300 text-lg font-serif",
      name: "Write It Textbox",
      placeholder: `Write it in ${course?.slug === "russian" ? "Cyrillic" : course?.name} here`,
      //  value: state.textInput,
      disabled,
      spellCheck: false,
    };

    if (keyboardOnly) {
      return baseObject;
    }

    return {
      ...baseObject,
      autoFocus: true,
      onKeyDown: keydownHandler,
      onKeyUp: (e) => {
        if (e.key === "Shift") {
          onCasingChange(casing === "lower" ? "upper" : "lower");
        }
        // onKeyDown("");
      },
    };
  }, [casing, course?.name, course?.slug, disabled, keyboardOnly, keydownHandler, onCasingChange, textRef]);

  return <textarea {...textareaProps} />;
});

function updateTextInputValue(
  textRef: RefObject<HTMLTextAreaElement>,
  params: { selectionStart: number; value: string },
) {
  const { selectionStart, value } = params;
  if (textRef.current) {
    textRef.current.value = value;
    textRef.current.setSelectionRange(selectionStart, selectionStart);
    textRef.current?.focus();
  }
}

const Keyboard = memo(function MemoizedKeyboard(props: {
  casing: "upper" | "lower";
  locale: string;
  currentKey: string;
  onKeyClick: (ev: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) {
  const { casing, currentKey, locale, onKeyClick } = props;
  const keyboard = [];

  for (const [key, value] of defaultKeys) {
    keyboard.push(
      <div key={key} className="flex w-full justify-center">
        {value.map((_value: string) => {
          const defaultKey = keyboardLocales.default?.[casing][_value];
          const languageKey = keyboardLocales[locale]?.[casing][_value];
          const isActive = currentKey === defaultKey || (languageKey === "Shift" && casing === "upper");

          if (!defaultKey || !languageKey) {
            return null;
          }

          return (
            <Key
              key={_value}
              defaultKey={defaultKey}
              languageKey={languageKey}
              isActive={isActive}
              onClick={onKeyClick}
            />
          );
        })}
      </div>,
    );
  }

  return <>{keyboard}</>;
});

const excludedKeys = new Set(["!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "+", "/", "?", "\\", "|"]);

interface KeyProps {
  defaultKey: string;
  languageKey: string;
  onClick: (ev: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isActive: boolean;
}

function Key({ defaultKey, languageKey, onClick, isActive }: KeyProps) {
  return (
    <KeyButton
      data-language-key={languageKey}
      // @ts-ignore
      onClick={onClick}
      active={isActive}
      className={clsx(
        "m-0.5",
        !isNaN(Number(defaultKey)) && "invisible",
        excludedKeys.has(defaultKey) && "invisible",
        defaultKey === "-" && "invisible",
        defaultKey === "=" && "invisible",
      )}
    >
      <div className="relative m-0.5 flex h-full w-full items-center justify-center">
        <div className="absolute right-0 top-0 text-xs">{defaultKey.length > 1 ? "" : defaultKey}</div>
        <div className={clsx("font-bold", languageKey === "Shift" && "text-sm")}>{languageKey}</div>
      </div>
    </KeyButton>
  );
}
