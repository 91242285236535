import { FiMinusSquare, FiPlusSquare, FiCheck, FiMic, FiType } from "react-icons/fi";
import { useContext, useState } from "react";

import PhraseTestContext from "../../RateableTests/includes/context";
import { RocketRecordContext } from "../../../context/RocketRecordContext";
import { Tooltip } from "@rocket/ui";
import { clsx } from "clsx";
import Markdown from "markdown-to-jsx";

export default function DebugPanel() {
  const { phrase, useRocketRecordState } = useContext(RocketRecordContext)!;
  const isInPhraseTest = useContext(PhraseTestContext).testTypeId !== 0;
  const { result, flag } = useRocketRecordState();

  if (!phrase) {
    return null;
  }

  return (
    <div>
      <div>
        <small>
          <strong>ID: {phrase.id}</strong>
        </small>
      </div>
      {!isInPhraseTest && <AdminStatusTooltips />}

      {phrase.answer_alt && phrase.answer_alt.length > 2 ? (
        <div>
          <small>
            answer_alt: <strong>{JSON.parse(phrase.answer_alt).join(", ")}</strong>
          </small>
        </div>
      ) : null}

      {flag.status !== "INACTIVE" && (
        <div>
          <small>
            <strong>Status:</strong> {flag.status}
          </small>
        </div>
      )}
      {flag.status !== "ACTIVE" && typeof result?.percentage === "number" && (
        <div>
          <small>
            <strong>Percentage:</strong> {`${result.percentage.toFixed(0)}%`}
          </small>
        </div>
      )}

      <DebugLog />
    </div>
  );
}

function DebugLog() {
  const { useRocketRecordState } = useContext(RocketRecordContext)!;
  const { debugLog } = useRocketRecordState();
  const [expanded, setExpanded] = useState(false);

  if (debugLog.length === 0) {
    return null;
  }

  const formattedTimedLog = debugLog.map((log, i) => {
    const debugTime = debugLog[0]?.time || 0;
    const timestamp = i === 0 ? "0.00" : ((log.time - debugTime) / 1000).toFixed(2);
    return `[${timestamp}] ${log.message}`;
  });

  return (
    <div>
      <div className="flex">
        <small>
          <strong>Debug log</strong>
        </small>

        <button
          style={{ marginLeft: 5, padding: 5, background: "none", border: "none" }}
          onClick={() => setExpanded(!expanded)}
        >
          <div className="flex items-center">{expanded ? <FiMinusSquare /> : <FiPlusSquare />}</div>
        </button>
      </div>
      {expanded && (
        <ul
          className="border-t-1 max-h-[200px] overflow-auto border-slate-600 pl-8 pt-4"
          style={{
            listStyleType: "initial",
          }}
        >
          {formattedTimedLog.map((log, i) => (
            <li key={i.toString()}>
              <Markdown>{log}</Markdown>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

const iconStyles = "h-[14px] w-[14px] ml-1 mr-1";

function AdminStatusTooltips() {
  const { phrase } = useContext(RocketRecordContext)!;
  if (!phrase) {
    return null;
  }
  return (
    <div className="mt-1 flex">
      <Tooltip
        label={`Voice Recognition ${phrase.disable_voice_recognition ? "Disabled" : "Enabled"}`}
        orientation="beneath"
      >
        <FiMic
          size={20}
          className={clsx(iconStyles, phrase.disable_voice_recognition ? "text-slate-200" : "text-slate-600")}
        />
      </Tooltip>
      <Tooltip label={`Notations ${phrase.show_notations ? "Shown" : "Hidden"}`} orientation="beneath">
        <FiType size={20} className={clsx(iconStyles, !phrase.show_notations ? "text-slate-200" : "text-slate-600")} />
      </Tooltip>
      <Tooltip
        label={`${phrase.exclude_tests ? "Excluded from" : "Included in"} Reinforcement Activities`}
        orientation="beneath"
      >
        <FiCheck size={20} className={clsx(iconStyles, phrase.exclude_tests ? "text-slate-200" : "text-slate-600")} />
      </Tooltip>
      <Tooltip
        label={`${phrase.exclude_module_tests ? "Excluded from" : "Included in"} Module/Benchmark Tests`}
        orientation="beneath"
      >
        <FiCheck
          size={20}
          className={clsx(iconStyles, phrase.exclude_module_tests ? "text-slate-200" : "text-slate-600")}
        />
      </Tooltip>
    </div>
  );
}
