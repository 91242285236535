export default function ToolIcon() {
  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6018 7.4003C16.3575 7.64954 16.2206 7.98463 16.2206 8.33363C16.2206 8.68264 16.3575 9.01773 16.6018 9.26696L18.7351 11.4003C18.9843 11.6446 19.3194 11.7814 19.6684 11.7814C20.0174 11.7814 20.3525 11.6446 20.6018 11.4003L25.6284 6.37363C26.2989 7.85522 26.5019 9.50594 26.2104 11.1058C25.9189 12.7057 25.1467 14.1788 23.9968 15.3287C22.8469 16.4786 21.3738 17.2507 19.774 17.5422C18.1741 17.8337 16.5233 17.6307 15.0418 16.9603L5.82843 26.1736C5.29799 26.7041 4.57857 27.0021 3.82843 27.0021C3.07828 27.0021 2.35886 26.7041 1.82843 26.1736C1.29799 25.6432 1 24.9238 1 24.1736C1 23.4235 1.29799 22.7041 1.82843 22.1736L11.0418 12.9603C10.3713 11.4787 10.1683 9.82799 10.4598 8.2281C10.7513 6.62822 11.5235 5.15517 12.6734 4.00526C13.8233 2.85535 15.2964 2.08319 16.8962 1.79169C18.4961 1.50019 20.1468 1.70318 21.6284 2.37363L16.6151 7.38697L16.6018 7.4003Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
