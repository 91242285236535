import { useMemo } from "react";
import { useStoreDispatch, useStoreTyped } from "~/store";
import useScrollProgress from "./useScrollProgress";
import { Client } from "@rocket/shared/res/Api";

export default function LessonScrollProgressTracker({ lessonId }: { lessonId: number }) {
  const store = useStoreTyped();
  const dispatch = useStoreDispatch();

  const initialValue = useMemo(
    () => store.getState().lesson.entities.user_lesson_progress[lessonId]?.progress || 0,
    [store, lessonId],
  );

  useScrollProgress(initialValue, (newValue) => {
    // Post to the server to update the progress
    Client.post("v2/user-lesson-progress", {
      lesson_id: lessonId,
      progress: newValue,
    }).then(() => {
      // Update locally
      dispatch({
        type: "Lesson/UPDATE_ENTITY",
        payload: {
          user_lesson_progress: {
            [lessonId]: { progress: newValue },
          },
        },
      });
    });
  });

  return null;
}
