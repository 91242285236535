import { ActionType, action } from "typesafe-actions";
import { ratePlayIt, requestAddPoints } from "../../../../../store/lesson/actions";
import { useContext, useEffect, useReducer } from "react";
import { useSharedDispatch } from "../../../../../store";

import LessonContext from "../../../../../context/LessonContext";
import { RatedPhrase } from "../../../../../hooks/useRocketRecord/types";
import Voice from "../../../../Phrasebox/includes/RocketRecord/Voice";
import { detect } from "detect-browser";
import useGetter from "../../../../../hooks/useGetter";
import useInterval from "../../../../../hooks/useInterval";
import usePrevious from "../../../../../hooks/usePrevious";

interface Props {
  lessonId: number;
  callbacks: {
    onStart?: (transcriptId: number) => void;
    onEnd?: (status: State, transcriptId: number) => void;
  };
}

export interface State {
  /** Game state */
  status: "init" | "countdown" | "active" | "aborted" | "finished";
  /** Whether user is being tested or playing through recordings */
  mode: "test" | "playthrough" | "listen";
  /** Visual overlay indicator before game starts */
  secondsCounter: number;
  /** Current phrase in index. Checks against "state.numLines" to determine completion. */
  index: number;
  /** Number of transcript lines */
  numLines: number;
  /** Active transcript component ID */
  componentId: number;
  /** Active character */
  characterId: number;
  /** Track of ratings per line */
  ratingsPerLine: Map<number, { phraseId: number; result?: RatedPhrase; blobUrl?: string }>; // <phraseId, rating>
  /** Whether voice recognition is disabled (prevents sending requests to API to rate) */
  disableVoiceRecognition: boolean;
}

const initialState: State = {
  status: "init",
  mode: "test",
  secondsCounter: 0,
  index: 0,
  numLines: 0,
  characterId: 0,
  componentId: 0,
  ratingsPerLine: new Map(),
  disableVoiceRecognition: false,
};

/**
 * PlayIt hook that contains the state of PlayIt
 */
export default function usePlayIt(props: Props) {
  const [state, dispatchLocal] = useReducer(reducer, initialState);
  const getPrevState = useGetter(usePrevious(state));
  const getState = useGetter(state);
  const getProps = useGetter(props);
  const lessonId = useContext(LessonContext)!.id;
  const dispatch = useSharedDispatch();

  useEffect(
    function onStatusChange() {
      if (getPrevState()?.status === state.status) {
        return;
      }

      const currentState = getState();

      if (state.status === "countdown") {
        getProps().callbacks.onStart?.(currentState.componentId);
      } else if (state.status === "finished" || state.status === "aborted") {
        getProps().callbacks.onEnd?.(currentState, currentState.componentId);
      }

      if (!lessonId || currentState.mode === "playthrough" || currentState.mode === "listen") {
        return;
      }

      // Check whether PlayIt successfully completed
      if (state.status === "finished") {
        // Calculate average rating
        if (Voice.isAnyKindOfRecognitionSupported() && !state.disableVoiceRecognition) {
          const averageRatingPercent = Math.ceil(
            Array.from(currentState.ratingsPerLine).reduce(
              (acc, [, ratedPhrase]) => acc + (ratedPhrase.result?.percentageDisplay || 0),
              0,
            ) / currentState.ratingsPerLine.size,
          );
          // Update store & send API requests to rate playit
          dispatch(ratePlayIt(lessonId, currentState.componentId, currentState.characterId, averageRatingPercent));
        }

        dispatch(
          requestAddPoints({
            rewardType: "playItComplete",
            data: {
              lesson: lessonId,
            },
          }),
        );
      }
    },
    [dispatch, getProps, getState, lessonId, getPrevState, state.status, state.disableVoiceRecognition],
  );

  // Displays 3-2-1 timeout before starting.
  // Sets "started" to "true" after timeout finishes
  useInterval(() => dispatchLocal(actions.tick()), state.secondsCounter >= 1 ? 1000 : null);

  return {
    state,
    dispatch: dispatchLocal,
  };
}

/**
 * Actions used to modify the PlayIt state
 */
export const actions = {
  start(payload: { componentId: number; characterId: number; numLines: number }) {
    return action("START", payload);
  },
  setDisableVoiceRecognition(payload: { disabled: boolean }) {
    return action("SET_DISABLE_VOICE_RECOGNITION", payload);
  },
  listenConversation(payload: { componentId: number; numLines: number }) {
    return action("LISTEN_CONVERSATION", payload);
  },
  playbackConversation(payload: { componentId: number }) {
    return action("PLAYBACK_CONVERSATION", payload);
  },
  abort() {
    return action("ABORT");
  },
  tick() {
    return action("TICK");
  },
  recordFinish(params: { phraseId: number; result?: RatedPhrase; blobUrl?: string }) {
    return action("RECORD_FINISH", params);
  },
  next() {
    return action("NEXT");
  },
};

export type PlayItActions = ActionType<typeof actions>;

function reducer(state: State, action: PlayItActions): State {
  switch (action.type) {
    case "START": {
      // On Safari, we need to start immediately without any timeouts
      // because of a browser "feature" that avoids rogue media playback
      const isSafari = detect()?.name === "safari";
      return {
        ...state,
        ...action.payload,
        status: isSafari ? "active" : "countdown",
        mode: "test",
        secondsCounter: isSafari ? 0 : 3,
        ratingsPerLine: new Map(),
        index: 0,
      };
    }
    case "LISTEN_CONVERSATION":
      return {
        ...state,
        ...action.payload,
        status: "active",
        mode: "listen",
        index: 0,
      };
    case "PLAYBACK_CONVERSATION":
      return {
        ...state,
        status: "active",
        mode: "playthrough",
        index: 0,
      };
    case "ABORT":
      return {
        ...state,
        status: "aborted",
        index: 0,
        secondsCounter: 0,
      };
    case "TICK":
      return {
        ...state,
        secondsCounter: state.secondsCounter > 0 ? state.secondsCounter - 1 : 0,
        status: state.secondsCounter <= 1 ? "active" : "countdown",
      };
    case "RECORD_FINISH": {
      const { phraseId } = action.payload;
      const newRatings = new Map(state.ratingsPerLine);
      newRatings.set(phraseId, action.payload);
      return {
        ...state,
        ratingsPerLine: newRatings,
      };
    }
    case "NEXT": {
      const nextIndex = state.index + 1;
      const isFinished = nextIndex === state.numLines;
      return {
        ...state,
        status: isFinished ? "finished" : state.status,
        index: isFinished ? 0 : nextIndex,
      };
    }
    case "SET_DISABLE_VOICE_RECOGNITION": {
      return {
        ...state,
        disableVoiceRecognition: action.payload.disabled,
      };
    }
    default:
      return state;
  }
}
