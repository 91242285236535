import { Fragment } from "react";
import { clsx } from "clsx";
import { LessonEntity } from "@rocket/types";
import FaceliftLessonItem from "~/pages/members/dashboard/includes/components/FaceliftLessonItem";
import { SectionData } from "../utils/selector";
import { filters } from "@rocket/shared/utils/constants-web";
import { useStoreSelector } from "~/store";
import useTranslation from "@rocket/shared/hooks/useTranslation";
import RocketMarkdown from "@rocket/shared/components/Lesson/MarkdownComponent/includes/RocketMarkdown";

interface Props {
  subsections: SectionData[];
  suggestedLessonId: number;
  showSectionTitles: boolean;
  shouldShowLesson: (lessons: LessonEntity) => boolean;
}

export default function FaceliftModuleItem(props: Props) {
  const t = useTranslation();
  const { subsections, showSectionTitles, suggestedLessonId, shouldShowLesson } = props;
  const lessonStatus = useStoreSelector((store) => store.lesson.entities.user_lesson_status);
  const lessonProgress = useStoreSelector((store) => store.lesson.entities.user_lesson_progress);

  return (
    <div
      className={clsx(
        `flex flex-col gap-6 dark:shadow-none md:shadow-[0px_12px_36px_rgba(185,196,200,0.5)]`,
        // visibleSubsections.length > 0 && "md:mt-8 md:px-6 py-8 rounded-xl",
        subsections.length > 0 && "rounded-xl py-4 md:my-4 md:px-6",
      )}
    >
      {subsections.map((subsection) => {
        const visibleLessons = subsection.lessons.filter(shouldShowLesson);
        if (visibleLessons.length === 0) {
          return null;
        }
        const filter = filters[subsection.code];
        return (
          <div key={`s.${subsection.id}`}>
            {showSectionTitles && (
              <div className="mb-2 text-xl font-bold text-text2">{filter ? t(filter.code) : "Lessons"}</div>
            )}
            <div className="flex flex-col gap-2">
              {visibleLessons.map((lesson) => (
                <FaceliftLessonItem
                  key={lesson.id}
                  lesson={lesson}
                  suggested={suggestedLessonId === lesson.id}
                  isAvailable={Boolean(lessonStatus[lesson.id]?.is_available)}
                  progress={lessonProgress[lesson.id]?.progress || 0}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export function FaceliftFeaturedModuleItem(props: Props & { description: string }) {
  const t = useTranslation();
  const { subsections, suggestedLessonId, shouldShowLesson } = props;
  const lessonStatus = useStoreSelector((store) => store.lesson.entities.user_lesson_status);
  const lessonProgress = useStoreSelector((store) => store.lesson.entities.user_lesson_progress);
  const firstName = useStoreSelector((store) => store.user.profile?.first_name || "");
  const description = props.description.replace(/{{first_name}}/g, firstName);

  return (
    <div className="mt-8 flex flex-col gap-4 rounded-lg bg-brand p-6">
      <div className="text-white">
        <RocketMarkdown>{description}</RocketMarkdown>
      </div>
      {subsections.map((subsection) => {
        const visibleLessons = subsection.lessons.filter(shouldShowLesson);
        if (visibleLessons.length === 0) {
          return null;
        }
        const filter = filters[subsection.code];
        const firstLesson = visibleLessons[0];
        if (!firstLesson) {
          return null;
        }
        return (
          <Fragment key={`s.${subsection.id}`}>
            <div className={"flex items-center font-bold text-white"}>{filter ? t(filter.code) : "Lessons"}</div>
            <div className="flex flex-1 flex-col">
              <FaceliftLessonItem
                key={firstLesson.id}
                lesson={firstLesson}
                suggested={suggestedLessonId === firstLesson.id}
                isAvailable={Boolean(lessonStatus[firstLesson.id]?.is_available)}
                progress={lessonProgress[firstLesson.id]?.progress || 0}
              />
            </div>
          </Fragment>
        );
      })}
    </div>
  );
}
