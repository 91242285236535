import { RateableTestEntity } from "@rocket/types";
import { RateableTestMetadata } from "@rocket/shared/utils/constants-web";
import RateableTestTooltip from "~/components/ui/RateableTestTooltip";
import { clsx } from "clsx";
import { useRateableTestRatingLevel } from "@rocket/shared/hooks/useLessonRateableTests";
import { useStoreSelector } from "~/store";
import { twMerge } from "tailwind-merge";

type RateableTestIconProps = {
  rateableTest: RateableTestEntity;
  className?: string;
  iconClassName?: string;
};

export default function RateableTestIcon({ rateableTest, className, iconClassName }: RateableTestIconProps) {
  const ratingLevel = useRateableTestRatingLevel(rateableTest.id);
  const Icon = RateableTestMetadata[rateableTest.rateable_test_type_id]?.icon;

  if (!Icon) {
    console.warn(`No icon found for rateable test type ${rateableTest.rateable_test_type_id}`);
    return null;
  }

  return (
    <div
      className={clsx(
        !ratingLevel && "bg-brand2 dark:bg-neutral-600", // "bg-[#6bbddb]",
        ratingLevel === 3 && "bg-rocketgreen",
        ratingLevel === 2 && "bg-rocketorange",
        ratingLevel === 1 && "bg-rocketred",
        twMerge("h-7 w-7", className),
        "flex items-center justify-center rounded-full text-white outline outline-2 outline-surface2 dark:outline-0",
        className,
      )}
    >
      <Icon className={iconClassName ?? "h-4 w-4"} />
    </div>
  );
}

export function RateableTestIconWithTooltip({ rateableTest }: { rateableTest: RateableTestEntity }) {
  const ratingLevel = useRateableTestRatingLevel(rateableTest.id);
  const markedAsComplete = useStoreSelector(
    (store) => store.lesson.entities.user_rateable_test_ratings[rateableTest.id]?.marked_complete,
  );

  return (
    <RateableTestTooltip
      testTypeId={rateableTest.rateable_test_type_id}
      ratingLevel={ratingLevel}
      markedAsComplete={!!markedAsComplete}
    >
      <RateableTestIcon rateableTest={rateableTest} />
    </RateableTestTooltip>
  );
}
