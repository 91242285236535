import { useEffect, useMemo } from "react";
import { isIOS, isMobile } from "react-device-detect";

import { LinkButton } from "@rocket/ui/Button";
import { Modal } from "@rocket/ui";
import useLocalStorage from "@rocket/shared/hooks/useLocalStorage";

export default function AppPromptModal() {
  const [showAppPrompt, setShowAppPrompt] = useLocalStorage("showAppPrompt", true);

  useEffect(() => {
    return () => {
      setShowAppPrompt(false);
    };
  }, [setShowAppPrompt]);

  const link = useMemo(() => {
    if (isIOS) return "https://apps.apple.com/us/app/rocket-languages/id597293943";
    return "https://play.google.com/store/apps/details?id=com.rocketlanguages.app";
  }, []);

  if (!isMobile) return null;

  return (
    <Modal
      isOpen={showAppPrompt}
      onClose={() => {
        setShowAppPrompt(false);
      }}
    >
      <div className="">
        <h4 className="mb-2">It looks like you are on a mobile device!</h4>
        <div className="mb-4">
          <p className="mb-2">
            For the best performance and functionality we recommend using the Rocket Languages app on mobile devices.
            Your login and password will work on the app!
          </p>
          <p className="mb-2">Download from the {isIOS ? "App" : "Play"} store here.</p>
          <div className="my-6 flex justify-center">
            <LinkButton use="a" rel="noreferrer noopener" href={link}>
              DOWNLOAD
            </LinkButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}
