import { FiInfo, FiSettings } from "react-icons/fi";
import { HTMLAttributes, useContext } from "react";
import { IconButton, Modal } from "@rocket/ui";

import type { IconType } from "react-icons";
import Position from "./Position";
import { RateableTestMetadata } from "../../../utils/constants-web";
import { RateableTestUIContext } from "./includes/context";
import useActiveProduct from "../../../hooks/useActiveProduct";
import useCanMarkAsComplete from "../../../hooks/useCanMarkAsComplete";
import { useSharedDispatch } from "../../../store";
import useTranslation from "../../../hooks/useTranslation";

interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
  rateableTestTypeId?: number;
  testName: string;
  testSubheading?: string;
  ratingLevel?: number;
  position?: number;
  total?: number;
  icon?: IconType;
  hideProgress?: boolean;
  rateableTestId?: number;
}

export default function Header(props: HeaderProps) {
  const context = useContext(RateableTestUIContext);
  return (
    <>
      <div className="flex w-full items-center justify-between py-2">
        <div className="flex items-center">
          <RateableTestIcon
            rateableTestTypeId={props.rateableTestTypeId}
            rateableTestId={props.rateableTestId}
            icon={props.icon}
            ratingLevel={props.ratingLevel}
          />
          <div className="mx-3">
            <h3 className="text-brand dark:text-white">{props.testName}</h3>
            {props.testSubheading && (
              <h4 className="font-serif text-sm font-normal text-brand dark:text-text1">{props.testSubheading}</h4>
            )}
          </div>
        </div>
        <div className="flex items-center print:hidden">
          {!props.hideProgress && (
            <div className="ml-3">
              <Position ratingLevel={props.ratingLevel || 0}>
                {typeof props.position === "number" ? props.position : "..."}/
                {typeof props.position === "number" ? props.total : "..."}
              </Position>
            </div>
          )}
          {!!context?.instructions && (
            <IconButton
              aria-label="Open Instructions"
              color="grey"
              className="ml-3"
              onClick={context.toggleInstructions}
            >
              <FiInfo className="!h-[28px] !w-[28px]" />
            </IconButton>
          )}
          {!!context?.settings && (
            <IconButton aria-label="Open Settings" color="grey" className="ml-3" onClick={context.toggleSettings}>
              <FiSettings className="!h-[28px] !w-[28px]" />
            </IconButton>
          )}
        </div>
      </div>
      {context?.instructions && (
        <Modal isOpen={context.instructionsVisible} onClose={context.toggleInstructions}>
          {context.instructions}
        </Modal>
      )}
      {context?.settings && (
        <Modal isOpen={context.settingsVisible} onClose={context.toggleSettings}>
          {context.settings}
        </Modal>
      )}
    </>
  );
}

interface RateableTestIconProps {
  icon?: IconType;
  rateableTestTypeId?: number;
  rateableTestId?: number;
  ratingLevel?: number;
}

function RateableTestIcon(props: RateableTestIconProps) {
  const t = useTranslation();
  const Icon = props.icon;
  const canMarkAsComplete = useCanMarkAsComplete() && props.rateableTestId;
  const dispatch = useSharedDispatch();
  const productId = useActiveProduct()?.id || 0;
  const AltIcon = RateableTestMetadata[props.rateableTestTypeId || 0]?.icon;

  if (!Icon && !AltIcon) {
    return null;
  }

  const handleMarkAsComplete = () => {
    if (!canMarkAsComplete || !props.rateableTestId) {
      return;
    }

    if (!props.ratingLevel) {
      dispatch({
        type: "Lesson/REQUEST_RATE_TEST",
        payload: {
          productId,
          rateableTestId: props.rateableTestId,
          rating: 100,
          markComplete: true,
        },
      });
    } else {
      dispatch({
        type: "Lesson/REQUEST_RATE_TEST",
        payload: {
          productId,
          rateableTestId: props.rateableTestId,
          rating: 0,
          markComplete: false,
        },
      });
    }
  };

  const buttonColor = (
    {
      3: "success",
      2: "warning",
      1: "error",
      0: "primary",
    } as const
  )[props.ratingLevel || 0];

  const tooltip = (() => {
    if (!canMarkAsComplete) {
      return null;
    }
    return !props.ratingLevel ? t("mark-as-complete") : t("mark-as-incomplete");
  })();

  return (
    <IconButton
      aria-hidden
      data-tooltip={tooltip}
      color={buttonColor}
      onClick={handleMarkAsComplete}
      className={!canMarkAsComplete ? "cursor-default focus:ring-0" : undefined}
    >
      {Icon ? <Icon className={"!h-[28px] !w-[28px]"} /> : null}
      {!Icon && AltIcon ? <AltIcon /> : null}
    </IconButton>
  );
}
