import { ReactNode } from "react";
import { clsx } from "clsx";
import usePageTitle from "../hooks/usePageTitle";
import { useBannerStore } from "~/layouts/components/Banner/Banner.store";
import { useSidebar } from "~/components/ui/Sidebar.store";

type Props = {
  /** Document title */
  title: string;
  children: ReactNode;
  className?: string;
};

/**
 * Configures the document title & sidebar.
 *
 * Wrap every page with this component
 */
function FaceliftPage(props: Props) {
  usePageTitle(props.title);
  const isBannerVisible = useBannerStore((s) => s.visible);
  return (
    <div
      // Used to navigate to the top of the page
      id="top"
      className={clsx("w-full max-w-screen-xl scroll-mt-20 py-2 sm:p-4", isBannerVisible && "scroll-mt-52")}
    >
      <div className={clsx("min-h-[70vh]", props.className)}>{props.children}</div>
    </div>
  );
}

FaceliftPage.WithoutTitle = function FaceliftPageWithoutTitle(props: Omit<Props, "title">) {
  return (
    <div className="w-full max-w-screen-xl py-2 sm:p-4">
      <div className={clsx("min-h-[70vh]", props.className)}>{props.children}</div>
    </div>
  );
};

FaceliftPage.TwoColumns = function FaceliftPageTwoColumns(props: {
  className?: string;
  children: [ReactNode, ReactNode];
}) {
  const sidebarState = useSidebar((s) => s.state);
  const isBannerVisible = useBannerStore((s) => s.visible);

  return (
    <div className={clsx("flex overflow-x-hidden md:overflow-visible", props.className)}>
      <div
        className={clsx(
          "mr-0 hidden min-w-[20rem] md:block md:min-w-[20rem] lg:mr-10 lg:w-72 print:md:hidden",
          sidebarState === "open" && "!block w-52 min-w-[13rem]",
          sidebarState === "closed-md" && "!hidden",
        )}
      >
        {props.children[0]}
      </div>
      <div className={clsx("flex-1 scroll-mt-20 md:p-2", isBannerVisible && "scroll-mt-52")} id="content">
        {props.children[1]}
      </div>
    </div>
  );
};

export default FaceliftPage;
