export default function ThumbsUpIcon() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.16732 25.6673H4.66732C4.04848 25.6673 3.45499 25.4215 3.0174 24.9839C2.57982 24.5463 2.33398 23.9528 2.33398 23.334V15.1673C2.33398 14.5485 2.57982 13.955 3.0174 13.5174C3.45499 13.0798 4.04848 12.834 4.66732 12.834H8.16732M16.334 10.5007V5.83398C16.334 4.90573 15.9652 4.01549 15.3089 3.35911C14.6525 2.70273 13.7622 2.33398 12.834 2.33398L8.16732 12.834V25.6673H21.3273C21.89 25.6737 22.4361 25.4765 22.8649 25.112C23.2937 24.7475 23.5763 24.2404 23.6606 23.684L25.2706 13.184C25.3214 12.8496 25.2989 12.5081 25.2045 12.1833C25.1102 11.8584 24.9464 11.558 24.7245 11.3028C24.5025 11.0475 24.2277 10.8436 23.9191 10.7051C23.6106 10.5666 23.2755 10.4968 22.9373 10.5007H16.334Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
