import * as constants from "./constants";

import type {
  APISchema,
  LessonComponentUnion,
  Notation,
  NoteTypeSimple,
  PhraseStringNotation,
  PointsRewardUnion,
  UserComponentRatingEntity,
} from "@rocket/types";

import { action } from "typesafe-actions";
import type { LessonErrorResponse, LessonState } from "./types";

/**
 * Creates an action to initate a lesson request
 * Handled by lesson sagas.ts to perform a request using Axios, concurrently loading rateable tests.
 * A cancellation token is saved in the state
 * @param {number} lessonId
 */
export function asyncRequestLesson(payload: { lessonId: number; productId: number }) {
  return action(constants.ASYNC_REQUEST_LESSON, payload);
}

/**
 * Same as request lesson but product agnostic for admin area
 */
export function asyncAdminRequestLesson(payload: { lessonId: number }) {
  return action(constants.ASYNC_ADMIN_REQUEST_LESSON, payload);
}

/**
 * - Updates suggested lesson id on dashboard
 * - Clears a 'new' tag on the dashboard
 * @param lessonId
 */
export function visit(payload: { lessonId: number; productId: number }) {
  return action(constants.VISIT, payload);
}

export function setLessonComponents(payload: { lessonId: number; components: LessonComponentUnion[] }) {
  return action(constants.SET_LESSON_COMPONENTS, payload);
}

/**
 * Async request to add points.
 * NOTE: customField.phrase is set to the phrase ID (handled by the server)
 * See sagas.ts@requestAddPoints for server request
 */
export function requestAddPoints(payload: Omit<PointsRewardUnion, "courseId">) {
  return action(constants.REQUEST_ADD_POINTS, payload);
}

export function setPhraseRatingDisplay(payload: { lesson: number; phraseId: number; ratingPercentage: number }) {
  return action(constants.SET_PHRASE_RATING_DISPLAY, payload);
}

/**
 * Updates test-specific information (e.g. phrases ratings for specific rateable tests)
 */
export function updateComponentRatings(params: {
  rateableTestId: number;
  componentRatings: UserComponentRatingEntity[];
}) {
  return action(constants.UPDATE_COMPONENT_RATINGS, params);
}

/**
 * Updates the client with a rating. DOES NOT perform a request.
 */
export function rateTest(payload: {
  /** Test ID to be rated */
  rateableTestId: number;
  /** Rating between 0-100 */
  rating: number;
  /** Label that rating is artificially marked complete */
  markComplete?: boolean;
}) {
  return action(constants.RATE_TEST, payload);
}

/**
 * Applies to Quiz/Play It tests (instead of rateTest)
 * Rates a rateable test by sending a request to the server
 * Updates the client by dispatching RATE_TEST
 * See: lesson/sagas.ts@requestRateTest for async side effects (request)
 */
export function requestRateTest(payload: {
  /** Product Id */
  productId: number;
  // /** Lesson Id */
  // lessonId: number;
  /** Test ID to be rated */
  rateableTestId: number;
  /** Rating between 0-100 */
  rating: number;
  /** Label that rating is artificially marked complete */
  markComplete?: boolean;
}) {
  return action(constants.REQUEST_RATE_TEST, payload);
}

/**
 * Marks a lesson as "complete" (or incomplete).
 *
 * Handled through saga
 */
export function rateLesson(lessonId: number, rating: 0 | 100) {
  return action(constants.RATE_LESSON, {
    lessonId,
    rating,
  });
}

export function ratePlayIt(lessonId: number, transcriptId: number, characterId: number, rating: number) {
  return action(constants.RATE_PLAY_IT, {
    lessonId,
    transcriptId,
    characterId,
    rating,
  });
}

/**
 * Rates a specific phrase in a phrase test or flashcard test
 * Action: RATE_COMPONENT. Handled in lesson/sagas.ts@rateComponent
 */
export function rateComponent(payload: {
  /** Used for updating component ratings */
  lessonId: number;
  /** Component ID (e.g. phrase ID) to be rated */
  componentId: number;
  /** Rateable test ID linked to the component. */
  rateableTestId: number;
  /** Specific phrases get this rating, specific tests get this rating */
  rating: number;
  /** Amount of total components. Used in phrase tests. */
  totalComponents: number;
  /**
   * Component Type to be rated (e.g. Flashcard, quiz, role play mode).
   */
  componentType: "custom_phrase" | "phrase" | "video";
  /**
   * Whether the component belongs to a grouped test
   *
   * `phrasetest` will update the test rating if complete
   **/
  testMode: "phrasetest" | "groupedtest" | "benchmarktest";
}) {
  return action(constants.RATE_COMPONENT, payload);
}

/**
 * Resets a given rateable test ID on the client and server (i.e. hearit, writeit, knowit)
 * See lesson/sagas.ts@resetTest for the server request
 */
export function asyncResetTest(payload: { rateableTestId: number; lessonId: number }) {
  return action(constants.ASYNC_RESET_TEST, payload);
}

export function resetTestFail(rateableTestId: number) {
  return action(constants.RESET_TEST_FAIL, {
    rateableTestId,
  });
}

export function resetTestSuccess(rateableTestId: number) {
  return action(constants.RESET_TEST_SUCCESS, {
    rateableTestId,
  });
}

/**
 * Lesson request has started.
 */
export function requestStart(params: { lessonId: number }) {
  return action(constants.REQUEST_START, params);
}

/**
 * Lesson request was successful
 */
export function requestSuccess(params: {
  lessonId: number;
  /** Used for updating dashboard state with lesson metadata from payload */
  productId: number;
  /** Response payload from API */
  payload: APISchema["GET"]["v2/product/{product}/lesson/{lesson}"]["response"];
}) {
  return action(constants.REQUEST_SUCCESS, params);
}

/**
 * Same as lesson request, without productId
 */
export function adminRequestSuccess(params: {
  lessonId: number;
  /** Response payload from API */
  payload: APISchema["GET"]["v2/admin/lesson/{lesson}"]["response"];
}) {
  return action(constants.ADMIN_REQUEST_SUCCESS, params);
}

/**
 * Lesson request failed.
 */
export function requestFail(params: { lessonId: number; error: LessonErrorResponse }) {
  return action(constants.REQUEST_FAIL, params);
}

// Rateable Test Action creators

/**
 * Triggered from: RateablePhraseTest.tsx@resetTest => dispatch(asyncResetTest()) => sagas.ts@asyncResetTest
 */
export function resetTestRating(payload: { productId: number; lessonId: number; rateableTestId: number }) {
  return action(constants.RESET_TEST_RATING, payload);
}

// Lesson Progression

export function saveAudioProgress(payload: { seekTimeSeconds: number; audioComponentId: number }) {
  return action(constants.SAVE_AUDIO_PROGRESS, payload);
}

/**
 * Lesson has been marked as complete or incomplete
 *
 * Triggered from: MarkCompleteButton@onMarkComplete => dispatch(rateLesson()) => sagas.ts@rateLesson => updateLessonCompletion
 *
 * Mutation: Updates lesson.isDone property
 *
 * The lesson.isDone prop tracks completion status for lessons without rateable tests.
 *
 * Affects Lesson (LessonItem) & LessonScreen.
 */
export function updateLessonCompletion(lessonId: number, isDone: boolean) {
  return action(constants.UPDATE_LESSON_COMPLETION, {
    lessonId,
    isDone,
  });
}

export function setNotations(payload: { courseId: number; notations: Notation[] }) {
  return action(constants.SET_NOTATIONS, payload);
}

export function updateNote(payload: NoteTypeSimple) {
  return action(constants.UPDATE_NOTE, payload);
}

export function clearNote(payload: { lessonId: number }) {
  return action(constants.CLEAR_NOTE, payload);
}

export function addPhraseStringNotation(payload: {
  phraseId: number;
  phraseStringId: number;
  notation: PhraseStringNotation;
}) {
  return action(constants.ADMIN_ADD_PHRASE_STRING_NOTATION, payload);
}

export function deletePhraseStringNotation(payload: { phraseId: number; phraseStringId: number; notationId: number }) {
  return action(constants.ADMIN_DELETE_PHRASE_STRING_NOTATION, payload);
}

export function updatePhraseStringNotation(payload: {
  phraseId: number;
  phraseStringId: number;
  notation: PhraseStringNotation;
}) {
  return action(constants.ADMIN_UPDATE_PHRASE_STRING_NOTATION, payload);
}

export function updateEntity(payload: Partial<LessonState["entities"]>) {
  return action(constants.UPDATE_ENTITY, payload);
}
