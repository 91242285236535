import { useRef, useEffect } from "react";

// Hook
export default function useEventListener<K extends keyof HTMLElementEventMap>(
  eventName: K,
  handler: (this: HTMLDivElement, ev: HTMLElementEventMap[K]) => any,
  element: any,
  listen = true,
): void {
  // Create a ref that stores handler
  const savedHandler = useRef<typeof handler>();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const target = element || document;
    // Make sure element supports addEventListener
    const isSupported = listen && target && target.addEventListener;
    if (!isSupported) {
      console.warn(`Event ${eventName} is not supported by target: ${target}`);
      return;
    }

    // Create event listener that calls handler function stored in ref
    // @ts-ignore
    const eventListener = (event: Parameters<typeof handler>) => savedHandler.current(event);

    // Add event listener
    target.addEventListener(eventName, eventListener);

    // Remove event listener on cleanup
    return () => {
      target.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element, listen]); // Re-run if eventName or element changes
}
