import { clsx } from "clsx";
import styles from "./CourseButtonGrid.module.scss";
import { ReactNode } from "react";

type CourseButtonGridProps = {
  children: ReactNode;
};

export default function CourseButtonGrid(props: CourseButtonGridProps) {
  return <div className={clsx("gap-4", styles.courseButtonGrid)}>{props.children}</div>;
}
