import { FC, useMemo } from "react";
import { FiCheck, FiX } from "react-icons/fi";
import { Checkbox } from "@rocket/ui";
import { QuizAnswer } from "@rocket/types/index";

interface Props {
  answer: QuizAnswer;
  onClick: () => void;
  answered?: boolean;
  selected?: boolean;
}

const QuizOption: FC<Props> = ({ answer, onClick, answered, selected }) => {
  const { is_correct: isCorrect, text: title, explanation } = answer;

  const checkbox = useMemo(() => {
    if (answered && (selected || answer.is_correct)) {
      if (isCorrect) return <FiCheck className="mr-2 h-[32px] w-[32px] text-rocketgreen-light sm:mr-8" />;
      return <FiX className="mr-2 h-[32px] w-[32px] text-rocketred-light sm:mr-8" />;
    }
    return <Checkbox checked={!!selected} className="mr-2 w-[32px] text-rocketgreen-alt sm:mr-8" />;
  }, [answer.is_correct, answered, isCorrect, selected]);

  return (
    <button className={"flex w-full p-2 pl-4"} onClick={onClick} disabled={answered}>
      {checkbox}
      <div className={"text1 ml-4 flex flex-col items-start"}>
        <span className="text-left font-serif text-base sm:text-lg" dangerouslySetInnerHTML={{ __html: title }} />
        {answered && explanation ? (
          <div className="text-left">
            <span
              className="italic"
              dangerouslySetInnerHTML={{
                // Note: this is set as HTML entities because it includes html entities
                __html: explanation,
              }}
            />
          </div>
        ) : (
          <span>&nbsp;</span>
        )}
      </div>
    </button>
  );
};

export default QuizOption;
